import React, { useState, useEffect } from "react";
import axios from "axios";
import "./_myprofile.scss";
import HomeHeader from "../../components/HomeTraning/HomeHeader/HomeHeader";
import { getProfileUrl, isImage } from "../../utils/utils";
import { getHost, getDefaultConfig } from "../../utils/APIUtils";
import ProfileImage from "../admin/components/ProfileImage";
import Toast from "components/Toast/Toast";
import classNames from "classnames";
import AccountApis from "../../utils/accountApis";
import { FormattedMessage } from "react-intl";

const locale = process.env.REACT_APP_LOCALE;
const MyProfile = (props) => {
  const { userInfo, lectures, onUpdateUserInfo } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [openResetPassword, toggleResetPassword] = useState(false);
  const [passwordData, setPasswordForm] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [validation, setValidation] = useState({
    password: null,
    confirmPassword: null,
  });

  const handlePasswordForm = () => {
    if (openResetPassword) {
      console.log(passwordData);
      console.log(validation);
      resetPassword();
    } else {
      toggleResetPassword(!openResetPassword);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.trim();

    setPasswordForm({
      ...passwordData,
      [name]: value,
    });

    if (name === "password") {
      setValidation({
        ...validation,
        [name]: value !== "",
      });
    } else {
      setValidation({
        ...validation,
        [name]: passwordData.password === value,
      });
    }
  };

  const resetPassword = () => {
    if (passwordData.oldPassword === "") {
      let msg = "기존 비밀번호를 입력해주세요";
      if (locale === "en") msg = "Please enter your current password";
      Toast.warning(msg);
      return;
    }
    if (passwordData.password === "" || !validation.password) {
      let msg = "비밀번호를 정확히 입력해주세요";
      if (locale === "en") msg = "Please enter your password correctly";
      Toast.warning(msg);
      return;
    }
    if (passwordData.confirmPassword === "" || !validation.confirmPassword) {
      let msg = "새로운 비밀번호를 확인해주세요";
      if (locale === "en") msg = "Please check your new password";
      Toast.warning(msg);
      return;
    }

    AccountApis.updateStudentPassword(userInfo.orgId, userInfo.studentId, {
      oldPassword: passwordData.oldPassword,
      password: passwordData.password,
    })
      .then((res) => {
        let msg = "비밀번호 수정완료";
        if (locale === "en") msg = "Password changed";
        Toast.success(msg);
        console.log(res);
        toggleResetPassword(!openResetPassword);
        setPasswordForm({
          oldPassword: "",
          password: "",
          confirmPassword: "",
        });
        setValidation({
          password: null,
          confirmPassword: null,
        });
        setIsLoading(true);
      })
      .catch((e) => {
        console.log(e);
        let msg = "비밀번호 변경에 실패하였습니다. 다시 시도해주세요";
        if (locale === "en") msg = "Password change failed. Please try again";
        Toast.warning(msg);
        setIsLoading(true);
      });
  };

  const onFilesAdded = (files) => {
    const file = files[0];
    if (file.size > 10000000) {
      let msg = "최대 파일 크기는 10M입니다.";
      if (locale === "en") msg = "Maximum file size is 10M";
      Toast.warning(msg);
      return;
    }
    if (!isImage(file)) {
      let msg = "이미지만 추가할수 있습니다.";
      if (locale === "en") msg = "Only images can be added";
      Toast.warning(msg);
      return;
    }
    setIsLoading(true);
    const fileData = new FormData();
    fileData.append("file", file);

    axios
      .post(`${getHost()}/blob/v2/contents`, fileData, {
        ...getDefaultConfig(),
      })
      .then((fileRes) => {
        const contentId = fileRes.data.contentId;
        axios
          .put(
            `${getHost()}/academy/v2/accounts/${userInfo.userId}/students/${
              userInfo.studentId
            }/profile?contentId=${contentId}`,
            null,
            {
              ...getDefaultConfig(),
            }
          )
          .then((response) => {
            let msg = "프로필 수정 완료.";
            if (locale === "en") msg = "Profile modified";
            Toast.success(msg);
            onUpdateUserInfo();
            setIsLoading(false);
          })
          .catch((error) => {
            let msg = "프로필 수정에 실패했습니다. 잠시 후 다시 시도해주세요.";
            if (locale === "en")
              msg = "Profile modification failed. Please try again later";
            Toast.warning(msg);

            setIsLoading(false);
          });
      })
      .catch((error) => {
        let msg = "프로필 수정에 실패했습니다. 잠시 후 다시 시도해주세요.";
        if (locale === "en")
          msg = "Profile modification failed. Please try again later";
        Toast.warning(msg);
        setIsLoading(false);
      });
  };

  return (
    <>
      <HomeHeader lectures={lectures} />
      <div className="myprofile-layout">
        <div className="myprofile-layout-wrap">
          <div className="ht-title">
            <FormattedMessage id="hometraining.my-info.my-profile" />
          </div>
          <div className="imgarea">
            <div className={classNames("img", locale)}>
              <input
                id={"profileImage"}
                type="file"
                onChange={(e) => onFilesAdded(e.target.files)}
              />
              <label htmlFor="profileImage"></label>
              {userInfo.profileImage && (
                <img
                  src={getProfileUrl(userInfo.profileImage.contentId)}
                  alt={userInfo.name}
                />
              )}
            </div>
            <p>
              {userInfo.name}{" "}
              <span className="u-color-blue">{`(${userInfo.account})`}</span>
            </p>
          </div>
          <div className="textarea">
            <button
              className={classNames("classmode-btn", {
                "is-active": openResetPassword,
              })}
              onClick={() => {
                handlePasswordForm();
              }}
            >
              {openResetPassword ? (
                <FormattedMessage id="hometraining.my-info.save-password" />
              ) : (
                <FormattedMessage id="hometraining.my-info.set-password" />
              )}
            </button>
            <table>
              <tbody>
                <tr>
                  <th>
                    <FormattedMessage id="hometraining.my-info.password" />
                  </th>
                  <td>******</td>
                </tr>
                {/** block 클래스 , is-true, is-false*/}
                {openResetPassword && (
                  <>
                    <tr className={classNames("l-inputwrap pw-chk")}>
                      <th></th>
                      <td>
                        <div
                          className={classNames("inputwrap", {
                            // "is-true": validation.password !== "",
                            // "is-false": validation.password === false
                          })}
                        >
                          <FormattedMessage id="hometraining.my-info.old-password-placeholder">
                            {(message) => (
                              <input
                                type="password"
                                name="oldPassword"
                                value={passwordData.oldPassword}
                                onChange={handleInputChange}
                                required="required"
                                placeholder={message}
                              />
                            )}
                          </FormattedMessage>
                        </div>
                      </td>
                    </tr>
                    <tr className={classNames("l-inputwrap pw-chk")}>
                      <th></th>
                      <td>
                        <div
                          className={classNames("inputwrap", {
                            "is-true": validation.password !== null,
                            "is-false": validation.password === false,
                          })}
                        >
                          <FormattedMessage id="hometraining.my-info.new-password-placeholder">
                            {(message) => (
                              <input
                                type="password"
                                name="password"
                                value={passwordData.password}
                                onChange={handleInputChange}
                                required="required"
                                placeholder={message}
                              />
                            )}
                          </FormattedMessage>
                        </div>
                      </td>
                    </tr>
                    <tr className={classNames("l-inputwrap pw-chk-repeat")}>
                      <th></th>
                      <td>
                        <div
                          className={classNames("inputwrap", {
                            "is-true": validation.confirmPassword !== null,
                            "is-false": validation.confirmPassword === false,
                          })}
                        >
                          <FormattedMessage id="hometraining.my-info.confirm-new-password-placeholder">
                            {(message) => (
                              <input
                                type="password"
                                name="confirmPassword"
                                required="required"
                                value={passwordData.confirmPassword}
                                onChange={handleInputChange}
                                placeholder={message}
                              />
                            )}
                          </FormattedMessage>
                          <span className="false-ment">
                            <FormattedMessage id="hometraining.my-info.new-password-not-matched" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <th>
                    <FormattedMessage id="hometraining.my-info.birthday" />
                  </th>
                  {userInfo.birthday && (
                    <td>{`${userInfo.birthday.year}.${userInfo.birthday.month}.${userInfo.birthday.day}`}</td>
                  )}
                </tr>
                <tr>
                  <th>
                    <FormattedMessage id="hometraining.my-info.address" />
                  </th>
                  <td>
                    {`${userInfo.address.address1 || ""} ${
                      userInfo.address.address2 || ""
                    }`}
                  </td>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage id="hometraining.my-info.parent-contact" />
                  </th>
                  <td>{userInfo.parentMobile}</td>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage id="hometraining.my-info.student-contact" />
                  </th>
                  <td>{userInfo.mobile}</td>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage id="hometraining.my-info.school" />
                  </th>
                  <td>
                    {`${userInfo.grade}`}
                    <FormattedMessage id="hometraining.my-info.grade" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
