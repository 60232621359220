import React from "react";
import "./_game.scss";
import GameData from "pages/game/GameData";
import GameRankingList from "../../../components/Teacher/component/GameRankingList";
import { FormattedMessage } from "react-intl";

const vanila = GameData;
const locale = "ko";

const GameRanking = (props) => {
  const { game, classLocale } = props;
  let locale = classLocale || process.env.REACT_APP_LOCALE || "ko";
  return (
    <div className="gameranking-layout">
      <div className="small-title">
        <FormattedMessage id="classroom.todays-game" />
      </div>
      <div className="big-title u-color-deepyellow">
        {vanila[game][locale]["name"]}
      </div>
      <div className="gameranking-wrap">
        <GameRankingList {...props} />
      </div>
    </div>
  );
};

export default GameRanking;
