import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Skeleton,
  PageHeader,
  Button,
  Icon,
  message,
  List,
  Table,
  Typography,
  Checkbox,
  Popconfirm,
} from "antd";

import Title from "antd/lib/typography/Title";
import ReactToPrint from "react-to-print";

import { getDefaultConfig, getMainHost } from "utils/APIUtils";
import axios from "axios";

import QuizCard from "./QuizCard";
import {
  getQuizRandomSeed,
  getCourseTitle,
  getCourseSymbol,
  getCourseColor,
} from "./workbookUtil";

import ClassroomApis from "utils/classroomApis";
import * as classroomActions from "store/modules/classroom";

import "components/Quiz/style/index.scss";
import "components/Quiz/css/_layout.scss";
import "components/Quiz/css/Quiz.scss";
import "katex/dist/katex.min.css";

import "./index.scss";

export class LectureWorkBookPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      lecture: null,
      quetureQuizList: [],
    };
  }

  componentDidMount() {
    const { id, lectureId } = this.props.match.params;

    this.setState({ loading: true });

    ClassroomApis.getLecture(id, lectureId)
      .then((res) => {
        const curLecture = res;
        let quetureQuizList = [];
        let quetureSeq = 0;
        let lastQuetureId = "";
        let lastQuetureTitle = "";

        for (let i in curLecture.sections) {
          let s = curLecture.sections[i];
          if (s.type == "QUETURE") {
            quetureSeq++;
            lastQuetureId = s.quetureId;
            lastQuetureTitle = s.quetureTitle;
          }
          if (s.type == "QUIZ") {
            if (s.quetureId == lastQuetureId) {
              quetureQuizList.push({
                quetureSeq: quetureSeq,
                quetureId: s.quetureId,
                quetureTitle: lastQuetureTitle,
                quizIds: s.quiz.map(e => e.quizId), // NOTE: stored as default value; to be overrided
                // quizIds: ["12732455874204045", "12713578167537831", "12713497888559264"], // test coloring & counting quiz
                quizAuxType: s.auxType, // QUIZ8Y or null
              });
            }
          }
        }

        let quetureReqList = [];
        quetureQuizList.map((q) => {
          quetureReqList.push(
            axios.get(`${getMainHost()}/content/v1/quetures/${q.quetureId}`, {
              ...getDefaultConfig(),
            })
          );
        });

        axios.all(quetureReqList).then(
          axios.spread((...responses) => {
            responses.map((res) => {
              if (res.data) {
                const q = res.data;
                let quetureQuiz = quetureQuizList.find(
                  (e) => e.quetureId === res.data.quetureId
                );
                if (quetureQuiz.quizAuxType == "QUIZ8Y" && q.campusHomework8y && q.campusHomework8y.length > 0) {
                  quetureQuiz.quizIds = q.campusHomework8y;
                } else if (q.campusHomework && q.campusHomework.length > 0) {
                  quetureQuiz.quizIds = q.campusHomework;
                } else if (q.workbooks && q.workbooks["10"] && q.workbooks["10"].length > 0) {
                  quetureQuiz.quizIds = q.workbooks["10"];
                } else if (q.workbooks && q.workbooks["9"] && q.workbooks["9"].length > 0) {
                  quetureQuiz.quizIds = q.workbooks["9"];
                } // else use lecture's quizIds

                // update title to get heading '[]'s detached title
                quetureQuiz.quetureTitle = q.title;
              }
            });

            this.setState({
              lecture: res,
              quetureQuizList: quetureQuizList,
              loading: false,
            });
          })
        );
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { loading, lecture, quetureQuizList } = this.state;

    if (loading || !lecture) {
      return "loading...";
    }

    let courseTitle = getCourseTitle(lecture.courseTitle);
    let color = getCourseColor(lecture.courseTitle);
    let cs = getCourseSymbol(lecture.courseTitle);
    console.log("quetureQuizList: ", quetureQuizList);
    return (
      <Skeleton loading={loading} active>
        <PageHeader
          title={
            <Title level={2} style={{ marginBottom: 0 }}>
              {courseTitle} {lecture.title} 워크북 문제집
            </Title>
          }
          extra={[
            <Button
              onClick={() =>
                this.props.history.push(
                  `/admin/class/detail/${this.props.match.params.id}/lecture-solvingbook/${this.props.match.params.lectureId}`
                )
              }
            >
              <Icon type="double-right" />
              해설집
            </Button>,
            <span key="2">
              <ReactToPrint
                trigger={() => (
                  <Button>
                    <Icon type="printer" />
                    프린트
                  </Button>
                )}
                content={() => this.componentRef}
                documentTitle={`${courseTitle} ${lecture.title} 워크북 문제집`}
              />
            </span>,
          ]}
        />

        <div className="admin-page-print-content">
          <div
            style={{ position: "relative", padding: "0px" }}
            ref={(el) => (this.componentRef = el)}
          >
            {quetureQuizList.map((quetureQuiz, quetureQuizIndex) => (
              <div className={"queture-quiz-wrap break-page"}>
                <div className={`queture-quiz-title workbook-background-${cs}`}>
                  <div className={`index workbook-color-${cs}`}>
                    {lecture.title.replace("회차", "")}
                    <span style={{ fontSize: "26px" }}>
                      회차 #{quetureQuiz.quetureSeq}
                    </span>
                  </div>
                  <div className={`title`}>{quetureQuiz.quetureTitle}</div>
                </div>

                <div className="quiz-card-list">
                  {quetureQuiz.quizIds.map((quizId, index) => (
                    <QuizCard
                      current={quizId}
                      index={index}
                      currentQuizIndex={index}
                      showTrueAnswers={false}
                      forWorkbook={true}
                      randomSeed={getQuizRandomSeed(
                        lecture.lectureId,
                        quetureQuizIndex,
                        index
                      )}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Skeleton>
    );
  }
}

export default withRouter(LectureWorkBookPage);
