export default {
  "classroom.attendance": "출석체크를 해주세요",
  "classroom.lectureTime": "지금은 수업중입니다.",
  "classroom.quetureSeq": "{seq} 번째 깨처",
  "classroom.quetureSeq-1": "첫 번째 깨처",
  "classroom.quetureSeq-2": "두 번째 깨처",
  "classroom.quetureSeq-3": "세 번째 깨처",
  "classroom.quetureSeq-4": "네 번째 깨처",
  "classroom.quetureSeq-5": "다섯 번째 깨처",
  "classroom.quetureSeq-6": "여섯 번째 깨처",

  "classroom.quetureDescTitle": "오늘 배울 개념을 함께 알아볼까요!",
  "classroom.pictureQuizDescTitle":
    "오늘 배운 내용을 떠올리며 깨봉 그림퀴즈를 풀어보세요!",
  "classroom.quebonSongDescTitle": "깨봉송을 다같이 신나게 불러 봐요!",

  "classroom.queture-point": "깨처포인트",

  "classroom.makeTeamTitle": "이번 문제풀기 미션은",
  "classroom.soloPlay": "개인전",
  "classroom.soloPlayDesc": "스스로 문제를 풀어볼까요",
  "classroom.teamPlay": "팀전",
  "classroom.teamPlayDesc": "친구와 함께 문제를 풀어볼까요",
  "classroom.select-your-teammate": "오늘 나의 짝꿍을 선택해 보세요",
  "classroom.my-teammate-is": "나의 <br /> 짝꿍은",
  "classroom.move-to-leaders-seat": "팀장의 자리로 이동해 주세요",
  "classroom.leader": "팀장",
  "classroom.mark": "채점하기",
  "classroom.marking": "채점중...",
  "classroom.next-question": "다음문제",
  "classroom.prev-question": "이전문제",
  "classroom.finish": "완료",
  "classroom.incorrect": "틀렸어",
  "classroom.incorrect-desc": "다시 생각해보자",
  "classroom.correct": "정답이야",
  "classroom.correct-desc": "정말 대단한걸",
  "classroom.todays-game": "오늘의 게임",
  "classroom.todays-activity": "오늘의 활동",
  "classroom.todays-activity-kids": "오늘의 수학놀이",
  "classroom.todays-mvp": `오늘의 <span>MVP</span>`,
  "classroom.class-has-end": "수업이 끝났습니다",
  "classroom.check-home-training": "홈트레이닝에서 과제를 확인하세요",
  "classroom.view-solution": "문제풀이 보기",
  "classroom.solution": "문제풀이",
  "classroom.check-the-answer": "정답확인",
  "classroom.my-answer": "나의 답보기",
  "classroom.game-desc": "게임을 하면서 생각해 보아요!",
  "classroom.activity-desc": "함께 활동해 볼까요?",
  "classroom.activity-desc-kids": "함께 해 볼까요?",
  "classroom.order-1": "첫번째",
  "classroom.order-2": "두번째",
  "classroom.order-3": "세번째",
  "classroom.order-4": "네번째",
  "classroom.order-5": "다섯 번째",
  "classroom.order-6": "여섯 번째",
  "classroom.correct-questions": "{count}문제 정답!",
  "classroom.challengeQuizTitle": "도전 문제",
  "classroom.storytellingTitle": "수학동화",
  "classroom.pictureQuizTitle": "깨봉 그림퀴즈",
  "classroom.quebonSongTitle": "깨봉송",
  "classroom.standby-mode": "대기모드",
  "classroom.student-list": "학생리스트",
  "classroom.student-list-name": "이름",
  "classroom.student-list-age": "{age}세",
  "classroom.student-list-attendance": "출석",
  "classroom.student-list-queture-correct-rate": "깨처 {seq} 정답률",
  "classroom.student-list-numbers-of-mvps": "MVP 선정 횟수",
  "classroom.kids-student-list-numbers-of-mvps": "칭찬 선정 횟수",
  "classroom.kids-compliments": "칭찬해주세요.",
  "classroom.kids-attendance": "출석하기",
  "classroom.student-list-confirm": "확인",
  "classroom.online-class": "온라인 수업",
  "classroom.refresh-all-students": "전체 새로고침",
  "classroom.refresh-student": "학생 {studentName} 새로고침",
  "classroom.preview-next-page": "다음페이지 미리보기",
  "classroom.exit-class": "수업모드 나가기",
  "classroom.end-class": "수업 끝내기",
  "classroom.check-team-cpmposition": "팀 구성 확인하기",
  "classroom.start-watching-the-video": "깨처보기 시작",
  "classroom.view-lesson-plan": "교안보기",
  "classroom.queture-remocon": "리모콘",
  "classroom.queture-remocon-quebon-song": "깨봉송",
  "classroom.queture-remocon-home-schooling": "홈스쿨링",
  "classroom.queture-sync": "동기화",
  "classroom.view-quiz": "문제보기",
  "classroom.drag-text-quiz-desc": "알맞은 답을 끌어다 빈칸에 놓아주세요.",
  "classroom.drag-text-quiz-choices-desc": "보기",
  "classroom.make-team-left-people-in-team": "남은 인원 3명팀",
  "classroom.make-team-shuffle-random": "랜덤으로 섞기",
  "classroom.make-team-desc":
    "한 팀당 최소 2명부터 최대 6명까지 설정할 수 있습니다",
  "classroom.make-team-leader": "팀장",
  "classroom.make-team-random-toast": "랜덤 팀으로 구성됩니다.",
  "classroom.quiz-list-order-1": "첫 번째 문제",
  "classroom.quiz-list-order-2": "두 번째 문제",
  "classroom.quiz-list-order-3": "세 번째 문제",
  "classroom.quiz-list-order-4": "네 번째 문제",
  "classroom.quiz-list-order-5": "다섯 번째 문제",
  "classroom.view-quiz-list": "문제화면 보기",
  "classroom.quiz-list-correct-count": "{count}명 정답",
  "classroom.quiz-list-view-quiz": "보기",
  "classroom.quiz-list-check-the-answer": "정답확인",
  "classroom.quiz-list-note": "연습장",
  "classroom.quiz-list-draw": "그리기",
  "classroom.quiz-list-erase": "지우기",
  "classroom.quiz-list-delete-all": "전체삭제",
  "classroom.quiz-list-note-close": "닫기",
  "classroom.start-game": "게임하기 시작",
  "classroom.highest-score": "최고점수",
  "classroom.current-score": "현재점수",
  "classroom.move-to-mvp": "MVP로 이동",
  "classroom.summary-queture": "반짝깨처",
  "classroom.queture-playing": "재생중",
  "classroom.send-end-class-message": "수업종료 문자발송",
  "classroom.choose-to-text": "문자발송 대상 선택",
  "classroom.endclass-student-name": "이름",
  "classroom.endclass-student-attendance": "출석",
  "classroom.endclass-quiz": "문제풀기",
  "classroom.endclass-game": "게임",
  "classroom.endclass-text-content": "문자내용",
  "classroom.endclass-check-default": "기본",
  "classroom.endclass-check-absent": "결석",
  "classroom.endclass-check-incomplete": "미완료",
  "classroom.endclass-receive-number": "수신번호",
  "classroom.endclass-send-message": "문자 발송하기",
  "classroom.endclass-sending": "발송중",
  "classroom.endclass-confirm-text-content": "문자내용 확인",
  "classroom.endclass-send": "발송하기",
  "classroom.endclass-cancel": "취소",
  "classroom.endclass-toast-select-student": "학생 선택해주세요.",
  "classroom.endclass-toast-send-success": "발송 완료",
  "classroom.endclass-place": "{place}등",
  "classroom.section-name-opened": "출석확인",
  "classroom.section-name-queture": "{seq}번 깨처",
  "classroom.section-name-summary": "개념정리",
  "classroom.section-name-group": "팀설정",
  "classroom.section-name-quiz": "문제",
  "classroom.section-name-game": "게임",
  "classroom.section-name-activity": "활동",
  "classroom.section-name-mvp": "MVP",
  "classroom.section-name-closed": "수업종료 문자발송",
  "classroom.section-name-mail": "수업종료 메일발송",
  "classroom.section-name-challenge": "도전문제",
  "classroom.section-name-storytelling": "스토리텔링",
  "classroom.section-name-picturequiz": "그림퀴즈",
  "classroom.section-name-quebonsong": "깨봉송",
  "classroom.popup.queture-point": "깨처 포인트",
  "classroom.popup.caution": "주의사항",
};
