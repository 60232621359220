import React, { useState, useEffect } from "react";
import { filter } from "lodash";
import { getOrdinals, numberToText } from "../../../utils/utils";
import QuetureName from "../../admin/components/QuetureName";
import classNames from "classnames";
import { getSeqText } from "utils/utils";
import { FormattedMessage } from "react-intl";

const HomeworkTooltip = (props) => {
  const { top, left, lecture, homework, locale } = props;
  const [lectureData, setLectureData] = useState({
    seq: "",
    quetureList: [],
  });

  useEffect(() => {
    return () => {};
  }, [homework]);

  return (
    <div
      className="ht-tooltip"
      style={{
        left: left ? left + 10 : 10,
        top: top ? top + 10 : 10,
      }}
    >
      <div className="ht-tooltip-wrap">
        <div className="ht-tooltip__title">
          <strong>
            <FormattedMessage id="hometraining.calendar.class-info" />
          </strong>
          {/* {getSeqText(homework)} 수업 */}
          <FormattedMessage
            id="hometraining.calendar.class-seq"
            values={{ seq: homework.seq }}
          />
        </div>
        {homework.quetureList.map((item, index) => (
          <div key={"queture-" + index}>
            <p className="group-title" key={index}>
              <span>
                {/* {numberToText(index + 1)}번째 깨처 */}
                <FormattedMessage
                  id="hometraining.calendar.class-queture"
                  values={{
                    seq: locale === "en" ? index + 1 : numberToText(index + 1),
                    ordinal: getOrdinals(locale, index + 1, "번째"),
                  }}
                />
              </span>
              <QuetureName quetureId={item.quetureId} />
            </p>
            <ul className="quest">
              <li
                className={classNames({
                  "is-complete": item.isDone,
                })}
              >
                <FormattedMessage id="hometraining.calendar.homework-queture" />
              </li>
              {!item.skipQuiz && (
                <li
                  className={classNames({
                    "is-complete": item.completedQuiz,
                  })}
                >
                  <FormattedMessage id="hometraining.calendar.homework-quiz" />
                </li>
              )}
            </ul>
          </div>
        ))}
        {homework.uploadList.length > 0 && (
          <div>
            <p className="group-title mission">
              <span>
                <FormattedMessage id="hometraining.calendar.homework-misson" />
              </span>
            </p>
            <ul className="quest">
              {homework.uploadList.map((item, index) => (
                <li
                  key={"upload-" + index}
                  className={classNames({
                    "is-complete": item.isDone,
                  })}
                >
                  <FormattedMessage id="hometraining.calendar.homework-mission" />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeworkTooltip;
