import React, { Component } from "react";
import {
  Modal,
  Row,
  Typography,
  Col,
  Card,
  Tag,
  message,
  Radio,
  Button,
  Checkbox,
  Select,
} from "antd";
import {
  groupBy,
  map as _map,
  remove,
  unionBy,
  filter,
  differenceBy,
} from "lodash";
import { FormattedMessage } from "react-intl";

import ClassroomApis from "../../../../utils/classroomApis";
import Popup from "../../../../components/Popup/Popup";
import Toast from "../../../../components/Toast/Toast";

const { Title, Text } = Typography;
const locale = process.env.REACT_APP_LOCALE || "ko";

const TEAM_NAME_INTL = {
  ko: {
    "팀1": "팀1",
    "팀2": "팀2",
    "팀3": "팀3",
    "팀4": "팀4",
    "팀5": "팀5",
    "팀6": "팀6",
    "팀없음": "팀없음",
  },
  en: {
    "팀1": "Team 1",
    "팀2": "Team 2",
    "팀3": "Team 3",
    "팀4": "Team 4",
    "팀5": "Team 5",
    "팀6": "Team 6",
    "팀없음": "No Team",
  }
}

class MakeTeamPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: false,
      noGroups: [],
      studentList: [],
      groups: {},
      leaderInfo: {
        팀1: null,
        팀2: null,
        팀3: null,
        팀4: null,
        팀5: null,
        팀6: null,
      },
    };
  }

  componentDidMount() {
    const { lectureData } = this.props;
    const { students, groups } = lectureData;
    const { leaderInfo } = this.state;
    let _studentList = students;
    let _newLeaderInfo = leaderInfo;

    let _groups = {
      팀1: [],
      팀2: [],
      팀3: [],
      팀4: [],
      팀5: [],
      팀6: [],
    };

    if (groups && groups.length > 0) {
      _studentList = [];
      const unMatchedStudents = [];
      groups.map((group) => {
        _groups[group.groupName] = group.students;
        if (group.leader) {
          _newLeaderInfo[group.groupName] = group.leader.studentId;
        }
        group.students.map((student) => {
          student.team = group.groupName;

          // const _matchedStudents = [];
          // students.map(s => {
          //   if (student.studentId === s.studentId) {
          //     _matchedStudents.push(s);
          //   } else {
          //     unMatchedStudents.push(s);
          //   }
          // });
          const _matchedStudents = filter(students, {
            studentId: student.studentId,
          });
          if (_matchedStudents && _matchedStudents.length > 0) {
            student.avgScore = _matchedStudents[0]["avgScore"];
          } else {
            student.avgScore = 0;
          }
        });
        _studentList = [..._studentList, ...group.students];
        // _studentList = unionBy(group.students, _studentList, "studentId");
      });

      if (_studentList.length !== students.length) {
        const leftStudents = differenceBy(students, _studentList, "studentId");
        console.log("leftStudent: ", leftStudents);
        leftStudents.map((student) => {
          student.team = "";
          _studentList.push(student);
        });
        // _studentList = [..._studentList, ...leftStudents];
      }
    } else {
      _studentList.map((student) => {
        student.team = "";
      });
    }

    this.setState({
      studentList: _studentList,
      noGroups: _studentList,
      groups: _groups,
      leaderInfo: _newLeaderInfo,
    });
  }

  setTeamLeader(groupName, studentId) {
    console.log("groupName: ", groupName);
    console.log("studentId: ", studentId);
    const { leaderInfo } = this.state;

    const _newLeaderInfo = leaderInfo;
    _newLeaderInfo[groupName] = studentId;

    this.setState({
      leaderInfo: _newLeaderInfo,
    });
  }

  handleButtonClick() {
    const { lectureData, classroomId, onRandomChange, onSendMessage } =
      this.props;
    const { isChecked } = this.state;
    const data = { random: true, remainder: isChecked };
    ClassroomApis.groups(classroomId, lectureData.lectureId, data).then(
      (res) => {
        console.log("groups done: ", res);
        onRandomChange(res);
        const _data = {
          type: "attendance",
          data: res,
        };
        this.componentDidMount();
      }
    );
  }

  onChangeTeam(value, index) {
    const _newList = this.state.studentList;
    _newList[index]["team"] = value;
    const _groups = groupBy(_newList, "team");
    console.log("groups: ", _groups);
    this.setState({
      studentList: _newList,
      groups: _groups,
    });
  }

  handleRemove(key, studentId) {
    const _groups = this.state.groups;
    remove(_groups[key], function (student) {
      return student.studentId === studentId;
    });
    console.log(_groups);
    this.setState({
      groups: _groups,
    });
  }

  handleOK() {
    const { onDone, lectureData, classroomId, onSendMessage } = this.props;
    const { groups, leaderInfo } = this.state;

    const _groups = [];
    if (groups !== {}) {
      Object.keys(groups).map((key, index) => {
        if (key) {
          _groups.push({
            leaderStudentId: leaderInfo[key]
              ? leaderInfo[key]
              : groups[key][0]
              ? groups[key][0].studentId
              : null,
            name: key,
            studentIds: _map(groups[key], "studentId"),
          });
        }
      });
    }
    onDone({ groups: _groups });
  }

  render() {
    const { onDone, onClick, onCancel } = this.props;
    const { groups, studentList, leaderInfo, isChecked } = this.state;
    console.log("groups: ", groups);
    let teamExistenceStudents = [];

    console.log("leaderInfo: ", leaderInfo);
    const teamOptions = [
      { label: "팀1", label_en: "Team 1", value: "팀1" },
      { label: "팀2", label_en: "Team 2", value: "팀2" },
      { label: "팀3", label_en: "Team 3", value: "팀3" },
      { label: "팀4", label_en: "Team 4", value: "팀4" },
      { label: "팀5", label_en: "Team 5", value: "팀5" },
      { label: "팀6", label_en: "Team 6", value: "팀6" },
    ];

    Object.keys(groups).map((key, index) => {
      groups[key].map((student) => {
        teamExistenceStudents.push(student);
      });
    });

    let studentss = [];
    console.log(studentList);
    studentList.map((e) => {
      console.log(teamExistenceStudents.indexOf(e));
      if (teamExistenceStudents.indexOf(e) == -1) {
        console.log(e);
        studentss.push(e);
      }
    });
    console.log(studentss);
    return (
      <Popup className={"popup-student-list"} isOpen={true}>
        <div className="title">
          <FormattedMessage id="classroom.check-team-cpmposition" />
        </div>
        <div className="popup-closebtn" onClick={() => this.handleOK()}></div>
        <Checkbox
          value={isChecked}
          onClick={(e) => this.setState({ isChecked: !isChecked })}
          style={{ margin: "10px", marginLeft: "65%", position: "absolute" }}
        >
          <FormattedMessage id="classroom.make-team-left-people-in-team" />
        </Checkbox>
        <Button
          style={{ margin: "10px", marginLeft: "80%" }}
          onClick={() => {
            this.handleButtonClick();
          }}
        >
          <FormattedMessage id="classroom.make-team-shuffle-random" />
        </Button>

        <div>
          <FormattedMessage id="classroom.make-team-desc" />
        </div>
        <div className={"list__content"} style={{ height: "540px" }}>
          <Row gutter={16} style={{ margin: "auto", width: "auto" }}>
            {Object.keys(groups).map((key, index) => {
              if (key) {
                return (
                  <Col key={index} span={8}>
                    <Card
                      title={TEAM_NAME_INTL[locale][`${key}`]}
                      style={{
                        width: "auto",
                        minWidth: "100px",
                        borderStyle: "dashed",
                        minHeight: "240px",
                        marginBottom: "15px",
                      }}
                    >
                      {groups[key].map((student, sidx) => (
                        <Row gutter={16}>
                          <Col span={12}>
                            <Tag
                              key={student.studentId}
                              onClick={() => {
                                this.setTeamLeader(key, student.studentId);
                              }}
                              color={
                                leaderInfo[key] === student.studentId
                                  ? "blue"
                                  : ""
                              }
                            >
                              {student.name}
                              {leaderInfo[key] === student.studentId ? (
                                <b>
                                  (
                                  <FormattedMessage id="classroom.make-team-leader" />
                                  )
                                </b>
                              ) : (
                                ""
                              )}
                            </Tag>
                          </Col>
                          <Col span={2}>
                            <Select
                              style={{ marginBottom: "5px", width: "100px" }}
                              value={key}
                              placeholder={"선택해주세요"}
                              onChange={(e) => {
                                let val = [];
                                val = this.state.groups;
                                if (e === "팀없음") {
                                  val[key].splice(sidx, 1);

                                  if (leaderInfo[key] === student.studentId) {
                                    if (val[key][0] != null) {
                                      leaderInfo[key] = val[key][0].studentId;
                                    } else {
                                      leaderInfo[key] = null;
                                    }
                                  }
                                  this.setState({
                                    groups: val,
                                  });
                                } else {
                                  if (
                                    val[key][sidx].studentId === leaderInfo[key]
                                  ) {
                                    val[key].splice(sidx, 1);
                                    val[e].push(student);
                                    if (val[key][0] != null) {
                                      console.log(val[key]);
                                      leaderInfo[key] = val[key][0].studentId;
                                    } else {
                                      leaderInfo[key] = null;
                                    }

                                    if (leaderInfo[e] == null) {
                                      leaderInfo[e] = student.studentId;
                                    }
                                  } else {
                                    if (leaderInfo[e] == null) {
                                      leaderInfo[e] = student.studentId;
                                    }
                                    val[key].splice(sidx, 1);
                                    val[e].push(student);
                                  }
                                  this.setState({
                                    groups: val,
                                  });
                                }
                              }}
                            >
                              {teamOptions.map((o, idx) => (
                                <Select.Option key={idx} value={o.value}>
                                  {locale === "en" ? o.label_en : o.label}
                                </Select.Option>
                              ))}
                              <Select.Option key={7} value={"팀없음"}>
                                {TEAM_NAME_INTL[locale][`팀없음`]}
                              </Select.Option>
                            </Select>
                          </Col>
                        </Row>
                      ))}
                    </Card>
                  </Col>
                );
              }
            })}
          </Row>
          <Card style={{ margin: "8px" }} title={TEAM_NAME_INTL[locale][`팀없음`]}>
            <Row>
              {studentss.map((e) => (
                <div>
                  <Col span={5} style={{ textAlign: "left" }}>
                    {e.name}
                  </Col>
                  <Select
                    // value={"팀없음"}
                    value={TEAM_NAME_INTL[locale][`팀없음`]}
                    placeholder={`팀을 선택해주세요`}
                    style={{ minWidth: "100px" }}
                    onChange={(val) => {
                      console.log(val, e);
                      let _groups = groups;
                      if (_groups[val].length == 0)
                        leaderInfo[val] = e.studentId;
                      _groups[val].push(e);
                      this.setState({
                        groups: _groups,
                      });
                    }}
                  >
                    {teamOptions.map((o, idx) => (
                      <Select.Option key={idx} value={o.value}>
                        {locale === "en" ? o.label_en : o.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              ))}
            </Row>
          </Card>
        </div>
      </Popup>
    );
  }
}

export default MakeTeamPopup;
