import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Axios from "../../../utils/request";
import QueturePlayer from "../../../components/QueturePlayer/QueturePlayer";
import QuebonSongAnimation from "./QuebonSongAnimation/QuebonSongAnimation";
import QbPlayer from "../../../components/QbPlayer/QbPlayer";
import RemoteVideoController from "../components/RemoteVideoController";
import { Typography } from "antd";

class QuebonSong extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quebonSong: null,
    };
  }

  load = () => {
    const { data } = this.props;
    const { kidsQuebonSongId } = data;
    Axios.get(`/academy/v2/kidsQuebonSongs/${kidsQuebonSongId || 1}`).then(
      (res) => {
        this.setState({
          quebonSong: res,
        });
      }
    );
  };

  componentDidMount() {
    const { data } = this.props;
    this.load();
    console.log(data);
  }

  render() {
    const { startQuebonSong, curPage, viewType, isKids } = this.props;
    const { quebonSong } = this.state;
    console.log(startQuebonSong, "startQuebonSong");

    return (
      <>
        {curPage === 0 ? (
          <>
            {viewType === "preview" ? (
              <QuebonSongAnimation title={quebonSong?.title} />
            ) : (
              <div className="queturepoint-layout">
                <div className="queturepoint-layout-wrap">
                  <div className="small-title">
                    <FormattedMessage id={"classroom.quebonSongTitle"} />
                  </div>
                  <div className="big-title u-color-red">
                    {quebonSong?.title}
                  </div>
                  <div className="wrap">
                    <div className="page-content-title u-color-red">
                      {/* <span>{quebonSong?.point}</span> */}
                      <span>율동과 함께 깨봉송을 신나게 불러 봐요</span>
                    </div>
                    {/*자료구조 변경으로 point 필드 대신 prologue 필드 우선 사용*/}
                    <p className="page-content-ment" />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div style={{ maxWidth: "1280px" }}>
            {quebonSong?.content?.signedUrl && (
              <>
                {/* <QueturePlayer
                  content={{
                    url: quebonSong?.content?.signedUrl,
                    // url: 'https://quebon-static.s3.ap-northeast-2.amazonaws.com/brand/expr_1.mp4',
                    type: "main",
                    thumbnail: null,
                  }}
                /> */}
                {/* <video src={quebonSong?.content?.signedUrl} controls></video> */}
                {viewType === "preview" ? (
                  <QbPlayer
                    src={quebonSong?.content?.signedUrl}
                    viewType={viewType}
                    controls={false}
                  />
                ) : (
                  <div>
                    <RemoteVideoController isQuebonSong={true} />
                    <Typography.Text strong style={{ whiteSpace: "pre-wrap" }}>
                      {quebonSong?.text}
                    </Typography.Text>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

export default QuebonSong;
