import React, { PureComponent } from "react";
import { Image } from "./modules";
import { formatText } from "./utils/Formatter";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

//import { formatImgText } from "./utils/ImageFormatter";

class QuizDesc extends PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      showDesc: false,
      showReallyAnswer: false,
    };
  }
  componentDidMount = () => {
    if (this.props.isPreview && this.props.quizType === "admin") {
      this.setState(
        {
          showDesc: true,
        },
        () => {
          this.props.onToggleShowReallyTrue();
        }
      );
    } else if (this.props.isHomework || this.props.quizType === "admin") {
      this.setState(
        {
          showDesc: true,
        },
        () => {
          this.scrollToMyRef();
        }
      );
    }
  };
  componentWillUnmount = () => {
    // window.scrollTo( 0, 0);
    const rootElem = document.getElementById("root");
    rootElem.scrollIntoView({ behavior: "smooth" });
  };
  scrollToMyRef = () => {
    console.log(this.myRef.current);
    this.myRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  render() {
    const {
      quizCount,
      description,
      showReallyTrue,
      onToggleShowDesc,
      correct,
      onToggleShowReallyTrue,
      descImages,
    } = this.props;
    const { showDesc, showReallyAnswer } = this.state;
    console.log("description: ", description);
    console.log(this.props.quizCount ? this.props.quizCount : 0 + "vasom");
    return (
      <div
        className={classNames("quiz-content__solve", {
          "is-open": showDesc === true,
        })}
        ref={this.myRef}
      >
        <div
          className={classNames("quiz-content__solve-title", {})}
          onClick={() =>
            this.setState({
              showDesc: !showDesc,
            })
          }
        >
          <FormattedMessage id="classroom.view-solution" />
        </div>
        <div className="quiz-content__solve-content">
          <div className="title">
            <FormattedMessage id="classroom.solution" />
          </div>
          <p>{formatText(description.text, null, descImages)}</p>
          <div style={{ textAlign: "right", fontSize: "11pt" }}>
            {" "}
            <br></br>
            <FormattedMessage
              id="hometraining.quiz-answer-count"
              values={{ count: quizCount }}
            />
          </div>

          <Image
            image={description.image}
            imageMobile={description.imageMobile}
            frames={description.anim}
            texts={description.textsOnImage}
          />
        </div>
        {!correct && (
          <div className="quiz-content__solve-btns">
            <span>
              {showReallyTrue ? (
                <FormattedMessage id="classroom.my-answer" />
              ) : (
                <FormattedMessage id="classroom.check-the-answer" />
              )}
            </span>
            <div className="switch">
              <label>
                <input
                  type="checkbox"
                  checked={showReallyTrue}
                  onChange={(e) => onToggleShowReallyTrue()}
                  className="switch-control"
                />
                <span className="switch-label"></span>
              </label>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default QuizDesc;
