import React, { Component } from "react";
import CustomDragLayer from "./modules/CustomDragLayer";

import withDragDropContext from "./withDragDropContext";
import ResultToast from "./ResultToast";
import QuizView from "./QuizView";
import QuizDesc from "./QuizDesc";
import { connect } from "react-redux";
import correctAudioFile from "./assets/correct.mp3";
import failAudioFile from "./assets/fail.mp3";
import mutedAudioFile from "./assets/muted.mp3";
import Popup from "components/Popup/Popup";
import { isSafari, isMobileSafari } from "react-device-detect";
import Toast from "components/Toast/Toast";
import { parseAxiosError, getHost } from "utils/APIUtils";
import { actionLog } from "utils/LogUtils";
import QuizToolbar from "./QuizToolbar";
import SockJsClient from "react-stomp";

import "./style/index.scss";
import "./css/_layout.scss";
import "./css/Quiz.scss";
import "katex/dist/katex.min.css";
import { Button } from "antd";

const locale = process.env.REACT_APP_LOCALE || "ko";

const correctSound = new Audio(correctAudioFile);
const failSound = new Audio(failAudioFile);

class Quiz extends Component {
  _mounted = false;

  state = {
    answers: [],
    vars: {},
    solved: false,
    solvedResult: null,
    pending: false,
    correct: null,
    correctAnswers: null,
    corrects: null,
    description: null,
    noteOpen: false,
    showDesc: false,
    showReallyTrue: false,
    quizId: null,
    isPlaying: false,
    showQuizDoneAlert: false,
    connected: false,
    isUpdatingAnswers: false,
  };

  init = (data) => {
    const { quizId, quiz } = data;

    this.setState({
      correct: null,
      answers: [],
      correctAnswers: null,
      corrects: null,
      description: null,
      noteOpen: false,
      showDesc: false,
      showReallyTrue: false,
      quizId: null,
      isUpdatingAnswers: false,
      solvedQuizData: null,
      solved: false,
      vars: data.quiz.vars,
    });
  };

  // componentWillMount() {
  //   const { data } = this.props;
  //   if (data == null) {
  //     return;
  //   }

  //   if (data.quizId !== this.state.quizId) {
  //     this.init(data);
  //   } else {
  //     this.setState({
  //       vars: data.quiz.vars,
  //     });
  //   }
  // }

  componentDidMount() {
    this._mounted = true;
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("QUIZ_CHANNEL");
      listenChannel.addEventListener("message", this.handleQuizBroadcast);
    }
    const { data } = this.props;
    if (data == null) {
      return;
    }
    this.init(data);
  }

  componentWillUnmount() {
    this._mounted = false;
    if (window.BroadcastChannel) {
      let listenChannel = new BroadcastChannel("QUIZ_CHANNEL");
      listenChannel.removeEventListener("message", this.handleQuizBroadcast);
    }
    if (this.state.connected) {
      this.onDisconnect();
    }
    this.setState({
      answers: [], // 선택 된 답
      vars: {}, // 문제 변수 설정. solve 할때 request에 보냄
      solved: false, // 답변 여부
      pending: false, // 상태
      correct: null, // 정답여뷰
      correctAnswers: null, // 정답
      corrects: null, // 정답여뷰
      description: null, // 문제 풀이
      noteOpen: false, // 연습장 토글
      showDesc: false, // 문제 풀이 토글
      showReallyTrue: false, // 정답보기 토글
      quizId: null,
      isUpdatingAnswers: false,
      showQuizDoneAlert: false
    })
  }

  onConnect = () => {
    console.log("connect from queture");
    this.setState({
      connected: true,
    });
  };

  onDisconnect = () => {
    this.setState({
      connected: false,
    });
  };

  onMessageReceive = (msg, topic) => {
    console.log("receive msg: ", msg);
    if (msg.type === "remote") {
      this.handleQuizBroadcast(msg);
    }
  };

  handleQuizBroadcast = (res) => {
    const { isPreview } = this.props;
    const type = res.data && res.data.type;
    const value = res.data && res.data.value;
    if (!isPreview) {
      return;
    }
    if (type === "solveQuiz") {
      this.solve(true);
    } else if (type === "solveWithGuide") {
      if(this.props.viewType !=="guide") {
        this.onAnswerUpdated(value)
      }else if ( type === "sendValue"){
        console.log("sendValue")
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { data } = prevProps;

    // console.log('prevProps: ', prevProps);
    // console.log('thisProps: ', this.props);
    // console.log('prevState: ', prevState);
    // console.log('thisState: ', this.state);

    if (!data) {
      return;
    }

    if (
      data &&
      (prevProps.data.quizId !== this.props.data.quizId ||
        prevProps.data.timestamp !== this.props.data.timestamp)
    ) {
      // Toast.success('update - init: ')
      this.init(this.props.data);
    }
    if (prevState.quizId === this.state.quizId) {
      // Toast.success('quiz did update - return')
      return;
    }

    // if (data.quizId !== this.state.quizId || data.timestamp !== this.state.timestamp) {
    //   Toast.success('update - init: ')
    //   this.init(this.props.data);
    // } else {
    //   Toast.success('update vars: ')
    //   console.log(data);
    //   this.setState({
    //     vars: data.quiz.vars,
    //   });
    // }
  }

  // componentWillReceiveProps(nextProps) {
  //   const { data } = nextProps;
  //   if (data == null) {
  //     return;
  //   }

  //   if (
  //     data.quizId !== this.state.quizId ||
  //     data.timestamp !== this.state.timestamp
  //   ) {
  //     console.log(2);
  //     this.init(data);
  //   } else {
  //     console.log(3);
  //     this.setState({
  //       vars: data.quiz.vars,
  //     });
  //   }
  // }

  solve = async (isCorrect) => {
    const { data, solver } = this.props;
    const { answers, vars } = this.state;

    this.setState({
      solving: true,
    });

    if (isSafari || isMobileSafari) {
      // this.mutedAudio.play();
      // this.quizAudio.src = '';
      // this.quizAudio.play();
      // this.playAudio();
      this.playAudio();

      // this.quizAudio.muted = false;

      // await window.wait(1000);
      try {
        const r = await solver(data.quizId, answers, vars);
        const correct = this.onSolved(r);
        this.playAudio(isCorrect || correct);
      } catch (e) {
        const err = parseAxiosError(e, "quiz");
        actionLog("error", "", {
          status: err.status,
          code: err.code,
          message: err.message,
        });

        Toast.warning("문제가 발생했습니다. 잠시 후 다시 시도해주세요.");
        this.setState({
          solving: false,
        });
      }
    } else {
      solver(data.quizId, answers, vars)
        .then((r) => {
          if (!this._mounted) {
            return;
          }

          const correct = this.onSolved(r);
          this.playAudio(isCorrect || correct);
        })
        .catch((e) => {
          const err = parseAxiosError(e, "quiz");
          actionLog("error", "", {
            status: err.status,
            code: err.code,
            message: err.message,
          });

          Toast.warning("문제가 발생했습니다. 잠시 후 다시 시도해주세요.");
        });
    }
  };

  onSolved = (data) => {
    if (!this._mounted) {
      return;
    }

    const { correct, desc, correctAnswers, corrects } = data;
    const { isNextTx, status, isFreeUser } = this.props;

    // console.log('status: ', this.props.status);
    console.log("onsolved: ", data);

    data["answers"] = this.state.answers;

    this.setState({
      solved: true,
      solving: false,
      correct: correct,
      correctAnswers: correctAnswers,
      corrects: corrects,
      description: desc,
      solvedResult: data,
    });

    return correct;
  };

  playAudio = (correct) => {
    // const { correctAudio, failAudio } = this.refs;

    // const audio = correct ? this.correctAudio : this.failAudio;
    const audio = this.quizAudio;
    const sound = correct ? correctSound : failSound;

    audio.muted = false;

    if (correct === true) {
      audio.src = correctAudioFile;
    } else if (correct === false) {
      audio.src = failAudioFile;
    } else {
      audio.src = mutedAudioFile;
    }

    // console.log('audo readystate=' + audio.readyState);

    let p = audio.play();

    if (p && p.catch) {
      p.catch((e) => {
        if (sound) {
          console.log("failed to play audio, but retrying", e);

          let p2 = sound.play();
          if (p2 && p.catch) {
            p2.catch((e) => console.log("failed to play audio", e));
          }
        } else {
          console.log("failed to play audio", e);
        }
      });
    }
  };

  onAnswerUpdated = (answers) => {
    const { data } = this.props;
    const { quizId, quiz, timestamp } = data;

    this.setState({
      answers: answers,
    }, () => {
      if (quiz.type === 'drag_text' || quiz.type === 'ordering') {
        actionLog("quiz", "updateAnswers", {
          quizId: quizId,
          type: quiz.type,
          answers: this.state.answers
        });
      }
      setTimeout(() => {
        this.onUpdatingAnswer(false)
      }, 500);
    });
  };

  onUpdatingAnswer = async (state) => {
    await this.setState({
      isUpdatingAnswers: state
    }, () => {
      // Toast.success('updaing');
    })
  }

  toggleNote = () => {
    this.setState({
      noteOpen: !this.state.noteOpen,
    });
  };

  toggleShowDesc = () => {
    this.setState({
      showDesc: !this.state.showDesc,
    });
  };

  toggleShowReallyTrue = () => {
    this.setState({
      showReallyTrue: !this.state.showReallyTrue,
    });
  };

  render() {
    const {
      user,
      sendValue,
      expectedCount,
      data,
      status,
      hasGame,
      learnType,
      isRetry,
      isNextTx,
      preset,
      step,
      quizStep,
      stepQuizData,
      onChangeStep,
      onStep,
      onNext,
      onFinish,
      quizType,
      section,
      noScroll,
      isHomework,
      isPreview,
      solvedQuizData,
      readOnly,
      currentQuizIndex,
      solveWithGuide,
      viewType,
      receiveValue,
      quizReload,
      forWorkbook
    } = this.props;
    if (data == null) {
      return <div/>;
    }
    const {
      answers,
      solved,
      solvedResult,
      correct,
      corrects,
      correctAnswers,
      showDesc,
      description,
      showReallyTrue,
    } = this.state;

    const isJunior = user && user.productType === "junior";

    let solvedData = {
      solved,
      correct,
      correctAnswers,
      corrects,
      description,
    };
    let stepQuizAnswers = null;

    if (step && stepQuizData) {
      solvedData = {
        solved: true,
        solving: false,
        correct: stepQuizData.correct,
        correctAnswers: stepQuizData.correctAnswers,
        corrects: stepQuizData.corrects,
        description: stepQuizData.desc,
      };
      stepQuizAnswers = stepQuizData.answers;
      console.log(stepQuizData, stepQuizAnswers);
    }

    const type = data ? data.quiz.type : null;
    console.log("solvedData: ", solvedData);


    if (this.props.viewType === "guide") {
      if (this.state.connected == true) {
        if (solveWithGuide != null) {
          solveWithGuide(answers);
        }
      }
    }
    // console.log('stepQuizData: ', stepQuizData);
    // console.log('quiz data: ', data);
    return (
      <>
        {/* <ContentBg />
        <div className="content__wrapping"> */}
        <div
          className="quiz-content"
          style={{
            overflow: noScroll ? "hidden" : "",
            pointerEvents: this.props.viewType === "student" ? "none" : "",
          }}
        >
          {/* <button onClick={() => this.playAudio(true)}> 재생(정답) </button>
          <button onClick={() => this.playAudio(false)}> 재생(오답) </button> */}
          <div className="quiz-content__wrap">
            <QuizView
              solveQuiz={this.props.solveQuiz}
              onSendMessage={this.props.onSendMessage}
              quizReload={quizReload}
              sendValue={sendValue}
              key={data.quiz.quizId}
              type={type}
              data={data}
              receiveValue={receiveValue}
              answers={answers}
              viewType={viewType}
              solved={solved || solvedData.solved}
              isEng={data.title && data.title.startsWith("ENG_")}
              learnType={learnType} // quizType: challenge, run, review , preTest
              stepQuizAnswers={stepQuizAnswers}
              solvedData={solvedData}
              onUpdatingAnswer={this.onUpdatingAnswer}
              quizType={quizType}
              showReallyTrue={
                this.props.defaultShowReallyTrue || showReallyTrue
              }
              onAnswerUpdated={this.onAnswerUpdated}
              currentQuizIndex={currentQuizIndex}
              forWorkbook={forWorkbook}
              readOnly={readOnly}
            />
            {!step &&
            description &&
            solvedData.description && (
              <QuizDesc
                descImages={data?.quiz?.commentaryImages?.map((c) => c.signedUrl) || null}
                quizCount={solvedData.corrects ? solvedData.corrects.length : 0}
                description={step ? solvedData.description : description}
                showReallyTrue={showReallyTrue}
                correct={step ? solvedData.correct : correct}
                onToggleShowReallyTrue={this.toggleShowReallyTrue}
                quizType={quizType}
                isHomework={isHomework}
                isPreview={isPreview}
              />
            )}
            {step && stepQuizData && quizType !== "onlyView" && (
              <QuizDesc
                descImages={data?.quiz?.commentaryImages?.map((c) => c.signedUrl) || null}
                quizCount={solvedData.corrects ? solvedData.corrects.length : 0}
                description={step ? solvedData.description : description}
                showReallyTrue={showReallyTrue}
                correct={step ? solvedData.correct : correct}
                onToggleShowReallyTrue={this.toggleShowReallyTrue}
                quizType={quizType}
                isHomework={isHomework}
                isPreview={isPreview}
              />
            )}

            {!isPreview && (
              <QuizToolbar
                quizCount={solvedData.corrects ? solvedData.corrects.length : 0}
                solved={step ? solvedData.solved : solved}
                solvedResult={solvedResult}
                correct={step ? solvedData.correct : correct}
                onSolve={this.solve}
                answers={answers}
                onStep={onStep}
                {...this.props}
                step={step}
                quizStep={quizStep}
                quizType={quizType}
                onChangeStep={onChangeStep}
                isHomework={isHomework}
                solvedQuizData={solvedQuizData}
                readOnly={readOnly}
              />
            )}
          </div>
        </div>
        {!isPreview && solved && correct !== null && (
          <ResultToast correct={correct}/>
        )}
        {/* <Note onToggleNote={this.toggleNote} isOpen={this.state.noteOpen} /> */}
        <CustomDragLayer/>

        <audio ref={(e) => (this.quizAudio = e)}/>
        {isPreview && quizType !== "onlyView" && this.props.classroomId && (
          <SockJsClient
            // ref={e => (this.clientRef = e)}
            url={`${getHost()}/academy/controller`}
            topics={[
              `/topic/${this.props.classroomId}/all`,
              // `/topic/${classroomId}/users/${user.userId}`,
              // `/topic/${classroomId}/${user.type}`
            ]}
            onConnect={this.onConnect}
            onDisconnect={this.onDisconnect}
            onMessage={this.onMessageReceive}
            debug={false}
          />
        )}
      </>
    );
  }
}

export default connect(
  ({ scroll, auth }) => ({
    user: auth.user,
    noScroll: scroll.noScroll,
    isFreeUser: auth.user.free,
  }),
  null
)(withDragDropContext(Quiz));
// export default withDragDropContext(Quiz);
