import React, { Component } from "react";
import {
  Modal,
  Tag,
  Input,
  Tooltip,
  Icon,
  Button,
  Typography,
  message,
  Divider,
  List,
} from "antd";
import Axios from "utils/request";
import QuizId from "./QuizId";
import { FormattedMessage } from "react-intl";

const locale = process.env.REACT_APP_LOCALE || "ko";

class ExamInfoPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      visible: false,
      inputVisible: true,
      inputValue: "",
      examName: "",
      deleted: false,
    };
  }
  addExam = (data) => {
    const { onUpdate } = this.props;
    console.log("add exams: ", data);
    Axios.post(`/academy/v2/exams`, data).then((data) => {
      console.log("add exams: ", data);
      if (onUpdate) {
        onUpdate();
      }
      this.closeModal();
    });
  };
  editExam = (data) => {
    const { onUpdate, exam } = this.props;
    console.log("add exams: ", data);
    Axios.put(`/academy/v2/exams/${exam.id}`, data).then((data) => {
      console.log("add exams: ", data);
      if (onUpdate) {
        onUpdate();
      }
      this.closeModal();
    });
  };

  handleOk = () => {
    if (!this.state.examName) {
      let msg = "이름을 입력해 주세요.";
      if (locale === "en") {
        msg = "Please enter a name.";
      }
      message.error(msg);
      return;
    }
    if (this.state.inputValue.length > 0) {
      let msg = "아이디를 확인해 주세요.";
      if (locale === "en") {
        msg = "Please check the ID.";
      }
      message.error(msg);
      return;
    }
    if (this.props.type === "add") {
      this.addExam({
        name: this.state.examName,
        quizIds: this.state.tags,
      });
    } else {
      this.editExam({
        name: this.state.examName,
        quizIds: this.state.tags,
      });
    }
  };
  handleCancel = () => {
    this.closeModal();
  };

  showModal = () => {
    const { exam } = this.props;
    this.setState({
      visible: true,
      tags: exam ? exam.quizIds : [],
      examName: exam ? exam.name : "",
      deleted: exam ? exam.deleted : false,
    });
  };
  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    console.log(tags);
    this.setState({ tags });
  };

  handleInputChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({ [name]: value });
  };

  checkQuizId = (quizId) => {
    return Axios.get(`/quiz/v2/quizzes/${quizId}`)
      .then((data) => {
        return true;
      })
      .catch((e) => {
        message.error("잘못된 문제 아이디 입니다.");
        return false;
      });
  };

  handleInputConfirm = async () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      await this.checkQuizId(inputValue) // 5228073327821825, 1915469608591416, 407320900177936
        .then((data) => {
          if (data) {
            tags = [...tags, inputValue];
          }
        })
        .catch((e) => {
          console.log("e: ", e);
        });
    }
    console.log(tags);
    this.setState({
      tags,
      // inputVisible: false,
      inputValue: "",
    });
  };

  handleConfirmQuizIds = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue) {
      const values = inputValue.split("\n");

      let _tags = [];
      if (tags && tags.length > 0) {
        _tags = [...tags];
        values.map((value) => {
          if (!tags.includes(inputValue)) {
            _tags.push(value);
          }
        });
      } else {
        _tags = [...values];
      }
      console.log("valus: ", _tags);
      this.setState({
        tags: _tags,
        inputValue: "",
      });
    }
  };

  saveInputRef = (input) => (this.input = input);

  render() {
    const { btnName, title, icon } = this.props;
    const { examName, tags, inputVisible, inputValue, deleted } = this.state;
    return (
      <div>
        <Button
          type="primary"
          onClick={this.showModal}
          style={{ marginRight: 4, marginTop: -10, marginBottom: -10 }}
        >
          {btnName}
          <Icon type={icon} />
        </Button>
        <Modal
          title={title}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          cancelText={<FormattedMessage id="admin.tests.cancel" />}
          okText={btnName}
          width={640}
        >
          <div>
            <div style={{ marginBottom: 8 }}>
              {deleted && (
                <div
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  {/* (삭제된 테스트) */}
                  <FormattedMessage id="admin.tests.deleted-test" />
                </div>
              )}
              <div>
                <Typography.Text strong>
                  <FormattedMessage id="admin.tests.col-name" />{" "}
                </Typography.Text>
                <FormattedMessage id="admin.tests.col-name-placeholder">
                  {(msg) => (
                    <Input
                      type="text"
                      name="examName"
                      value={examName}
                      onChange={this.handleInputChange}
                      placeholder={msg}
                    />
                  )}
                </FormattedMessage>
              </div>
              <Divider />
              <Typography.Text strong>
                {/* 문제 아이디 입력 */}
                <FormattedMessage id="admin.tests.col-quiz-ids" />
              </Typography.Text>
              {inputVisible && (
                <FormattedMessage id="admin.tests.col-quiz-ids-placeholder">
                  {(msg) => (
                    <Input.TextArea
                      ref={this.saveInputRef}
                      // type="text"
                      name="inputValue"
                      style={{ width: "100%" }}
                      value={inputValue}
                      onChange={this.handleInputChange}
                      // onBlur={this.handleInputConfirm}
                      // onPressEnter={this.handleInputConfirm}
                      placeholder={msg}
                      rows={4}
                    />
                  )}
                </FormattedMessage>
              )}
              <Button
                onClick={this.handleConfirmQuizIds}
                type="primary"
                style={{
                  float: "right",
                  margin: "8px 0",
                }}
              >
                <FormattedMessage id="admin.tests.confirm-quiz-ids" />
              </Button>
              <Divider />
              <Typography.Text strong>
                <FormattedMessage id="admin.tests.quiz-list" />
              </Typography.Text>
              <br />
              <List
                dataSource={tags}
                style={{ overflow: "hidden" }}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Button
                        key="list-item-delete"
                        onClick={() => this.handleClose(item)}
                      >
                        삭제
                      </Button>,
                    ]}
                    style={{
                      width: "50%",
                      float: "left",
                    }}
                  >
                    <QuizId quizId={item} index={index} />
                  </List.Item>
                )}
              />
              {/* {tags.map((quizId, index) => (
                <QuizId
                  key={index}
                  index={index}
                  quizId={quizId}
                  onDelete={(quizId) => this.handleClose(quizId)}
                />
              ))} */}
              {/* {tags.map((tag, index) => (
                <Tag
                  key={tag}
                  style={{ margin: 4, fontSize: 16, lineHeight: "20px" }}
                  closable
                  onClose={() => this.handleClose(tag)}
                >
                  {tag}
                </Tag>
              ))} */}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ExamInfoPopup;
