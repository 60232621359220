import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TrueFalseButton } from './modules';

class TrueFalseQuiz extends Component {
  constructor(props) {
    super(props)

    this.state = {
      answer: null
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      this.setState({
        answer: null
      });
    }
  }

  onAnswerSet = (tf) => {
    const { onAnswerUpdated } = this.props;
    this.setState({
      answer: tf
    }, () => {
      onAnswerUpdated([tf]);
    })
  }

  render() {
    const { isEng, stepQuizAnswers, solvedData, showReallyTrue } = this.props;

    const { answer } = this.state;
    return (
      <div className="quiz-content__answer t-answer-tf">
        <div className="btn-wrap">
          <TrueFalseButton
            btnType={true}
            isEng={isEng}
            solvedData={solvedData}
            showReallyTrue={showReallyTrue}
            selected={stepQuizAnswers ?
              (stepQuizAnswers.length > 0 && stepQuizAnswers[0] === true) : this.props.answers[0] === true}
            onAnswerSet={this.onAnswerSet} />
          <TrueFalseButton
            btnType={false}
            isEng={isEng}
            solvedData={solvedData}
            showReallyTrue={showReallyTrue}
            selected={stepQuizAnswers ? (stepQuizAnswers.length > 0 && stepQuizAnswers[0] === false) : this.props.answers[0] === false}
            onAnswerSet={this.onAnswerSet} />
        </div>
      </div>
    )
  }

}

TrueFalseQuiz.propTypes = {
  data: PropTypes.object,
  onAnswerUpdated: PropTypes.func
}

export default TrueFalseQuiz;
