import React, { useEffect, Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import * as hometrainingActions from "store/modules/hometraining";
import update from "immutability-helper";
import HomeHeader from "../../components/HomeTraning/HomeHeader/HomeHeader";
import "./_main.scss";
import ClassroomApis from "../../utils/classroomApis";
import HometrainingApis from "../../utils/hometrainingApis";
import {
  numberToText,
  getProfileUrl,
  getKoreanToday,
  getWeekdayTable,
  formateKoreanTime,
  formateEngTime,
} from "../../utils/utils";
import AccountApis from "../../utils/accountApis";
import MiniCalendar from "./components/MiniCalendar";
import WeeklyCalendar from "./components/WeeklyCalendar";
import { groupBy } from "lodash";
import Axios from "utils/request";
import { getSeqText } from "utils/utils";
import { FormattedMessage, injectIntl } from "react-intl";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      homework: {
        completed: false,
        date: null,
        orgDate: null,
        endDate: null,
        lecture: null,
        seq: null,
        quetureList: [],
        uploadList: [],
      },
      curYear: new Date().getFullYear(),
      curMonth: new Date().getMonth() + 1,
      curWeekLectures: [],
      classroom: null,
      dateRows: null,
      curWeek: null,
      curWeekIndex: null,
      dates: [],
      todayInWeek: 0,
      lectureByWeekNum: null,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    HometrainingApis.currentLecture(user)
      .then((isStart) => {
        if (isStart) {
          this.props.history.push(`/classroom/${user.classroom.classroomId}`);
        } else {
          this.getCurrentHomework();

          AccountApis.getClassroomDetail(
            user.orgId,
            user.classroom.classroomId
          ).then((res) => {
            console.log("classroom: ", res);
            this.setState(
              {
                classroom: res,
              },
              () => {
                this.renderCalendar();
              }
            );
          });
          this.props.HometrainingActions.getLectures();
        }
      })
      .catch((e) => {
        this.getCurrentHomework();

        AccountApis.getClassroomDetail(
          user.orgId,
          user.classroom.classroomId
        ).then((res) => {
          console.log("classroom: ", res);
          this.setState(
            {
              classroom: res,
            },
            () => {
              this.renderCalendar();
            }
          );
        });
        this.props.HometrainingActions.getLectures();
      });
  }

  getCurrentHomework() {
    this.setState({
      isLoading: true,
    });
    HometrainingApis.current()
      .then((res) => {
        console.log("currenthomework: ", res);
        if (res) {
          const _date = new Date(res.lecture.date);
          const _seq = res.lecture.seq;
          const _title = res.lecture.title;
          const _quetureList = [];
          const _uploadList = [];
          let _completed = true;
          let _completedQuiz = true;
          const studentId = this.props.user.userId;

          res.items.map((item) => {
            if (item.type === "queture") {
              const quetureIndexText = numberToText(_quetureList.length + 1);

              const _quetureId = item.value;
              const _curSection = res.lecture.sections.find(
                (s) => s.type === "QUIZ" && s.quetureId === _quetureId
              );
              const _quizList = _curSection && _curSection.quiz;

              console.log(_quizList);
              let correctCount = 0;
              _quizList.map((quiz) => {
                if (quiz.solvedMap[studentId]) {
                  if (
                    quiz.solvedMap[studentId].correct === false &&
                    !quiz.solvedMap[studentId].retry
                  ) {
                    console.log(quiz.solvedMap[studentId].retry);
                    _completedQuiz = false;
                  }
                  if (
                    quiz.solvedMap[studentId].correct === true ||
                    (quiz.solvedMap[studentId].retry &&
                      quiz.solvedMap[studentId].retry.correct)
                  ) {
                    correctCount++;
                  }
                } else {
                  _completedQuiz = false;
                }
              });

              _quetureList.push({
                type: item.type,
                isDone: item.myHomework,
                completedQuiz: _completedQuiz,
                skipQuiz: correctCount === _quizList.length,
                text: quetureIndexText + "번째 깨처 영상 복습",
                quetureId: item.value,
              });

              if (!item.myHomework || !_completedQuiz) {
                _completed = false;
              }
              console.log("is quiz completed: ", _completedQuiz);
            }

            if (item.type === "upload") {
              _uploadList.push({
                type: item.type,
                isDone: item.myHomework,
                text: "학습미션",
              });
              if (!item.myHomework) {
                _completed = false;
              }
            }
          });
          this.setState({
            isLoading: false,
            homework: {
              completed: _completed,
              date: `${_date.getFullYear()}.${
                _date.getMonth() + 1
              }.${_date.getDate()} `,
              orgDate: res.lecture.date,
              endDate: res.endDate,
              seq: _seq,
              title: _title,
              quetureList: _quetureList,
              uploadList: _uploadList,
            },
          });
        } else {
          this.props.history.push("/review");
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          isLoading: false,
        });
      });
  }

  getLectureDays = (days) => {
    const locale = this.props.intl.locale
    let _days = [];
    const weekdayTable = getWeekdayTable();
    if (days) {
      days.map((d) => {
        const _time = d.time;
        if(locale === 'en') {
          const _day = weekdayTable.find((week) => week.value === d.day).label_en;
          _days.push(`${_day} at ${formateEngTime(_time)}`);
        } else {
          const _day = weekdayTable.find((week) => week.value === d.day).label;
          _days.push(`${_day} ${formateKoreanTime(_time)}`);
        }
      });
    }

    return _days.join(",");
  };

  renderCalendar = (weekIndex) => {
    const { curWeek, curWeekIndex, classroom } = this.state;

    const year = this.state.curYear;
    const month = this.state.curMonth;

    const cursor = new Date(year, month - 1, 1);
    const today = new Date();
    let todayInWeek = 0;

    let dates = [];
    let row = [];

    if (cursor.getDay() > 0) {
      const lastMonth = new Date(year, month - 1, -(cursor.getDay() - 1));
      while (lastMonth.getMonth() !== cursor.getMonth()) {
        row.push({
          year: lastMonth.getFullYear(),
          month: lastMonth.getMonth() + 1,
          date: lastMonth.getDate(),
          old: true,
        });

        lastMonth.setDate(lastMonth.getDate() + 1);
      }
    }

    while (cursor.getMonth() === month - 1) {
      const dayOfWeek = cursor.getDay();

      if (cursor.getDate() === today.getDate()) {
        console.log(cursor.getDate() + " today idnex", dates.length);
        todayInWeek = dates.length;
      }

      if (dayOfWeek === 0 && row.length > 0) {
        dates.push(row);
        row = [];
      }

      row.push({
        year: cursor.getFullYear(),
        month: cursor.getMonth() + 1,
        date: cursor.getDate(),
      });

      cursor.setDate(cursor.getDate() + 1);
    }

    if (row.length < 7) {
      const count = 7 - row.length;
      for (let i = 0; i < count; i++) {
        row.push({
          year: cursor.getFullYear(),
          month: cursor.getMonth() + 1,
          date: cursor.getDate(),
          old: true,
        });
        cursor.setDate(cursor.getDate() + 1);
      }
      console.log("last: ", row);
    }

    if (row.length > 0) {
      dates.push(row);
    }

    const dateRows = dates.map((dr, index) =>
      dr.map((r, i) => {
        const key =
          "" +
          r.year +
          "-" +
          (r.month < 10 ? "0" + r.month : r.month) +
          "-" +
          (r.date < 10 ? "0" + r.date : r.date);
        return (
          <td
            key={i}
            className={classNames({
              "prev-date": r.old,
              "is-today":
                r.date === today.getDate() &&
                r.month === today.getMonth() + 1 &&
                r.year === today.getFullYear(),
            })}
          >
            <div className={i === 0 ? "date u-color-false" : "date"}>
              <span className={r.old ? "old" : null}>{r.date}</span>
            </div>
          </td>
        );
      })
    );

    let index = todayInWeek;
    if (weekIndex === 0) {
      index = 0;
    }

    if (weekIndex === -1) {
      index = dateRows.length - 1;
    }

    const _lectures = {};
    Object.keys(classroom.lectures).map((key, index) => {
      const weeklyData = dates[index];
      // console.log(weeklyData);
      const newKey = key.split(" ")[0];
      const myDate = new Date(newKey);
      _lectures[newKey] = { ...classroom.lectures[key][0] };
      _lectures[newKey]["weekNum"] = myDate.getWeek();
    });

    this.setState({
      dates: dates,
      dateRows: dateRows,
      curWeek: curWeek && weekIndex !== 0 ? curWeek : dates[index],
      curWeekIndex: index,
      todayInWeek: todayInWeek,
      curWeekLectures: _lectures,
      lectureByWeekNum: groupBy(_lectures, "weekNum"),
    });
  };

  handleChangeWeekIndex = (index) => {
    const { dates, curWeekIndex } = this.state;
    if (index === curWeekIndex) {
      return;
    }
    this.setState({
      curWeekIndex: index,
      curWeek: dates[index],
    });
  };

  handlePrevMonth = () => {
    const { curYear, curMonth } = this.state;
    const prevDate = new Date(curYear, curMonth - 2, 1);

    this.setState(
      {
        curMonth: prevDate.getMonth() + 1,
        curYear: curMonth === 1 ? curYear - 1 : curYear,
      },
      () => this.renderCalendar(-1)
    );
  };
  handleNextMonth = () => {
    const { curYear, curMonth } = this.state;
    const nextDate = new Date(curYear, curMonth, 1);
    this.setState(
      {
        curMonth: nextDate.getMonth() + 1,
        curYear: curMonth === 12 ? curYear + 1 : curYear,
      },
      () => {
        this.renderCalendar(0);
      }
    );
  };

  handleToday = () => {
    const today = new Date();
    this.setState(
      {
        curMonth: today.getMonth() + 1,
        curYear: today.getFullYear(),
      },
      () => {
        this.renderCalendar(this.state.todayInWeek);
      }
    );
  };

  handlePrevWeek = () => {
    const { curWeekIndex } = this.state;
    if (curWeekIndex > 0) {
      this.setState({
        curWeekIndex: curWeekIndex - 1,
      });
    } else {
      this.handlePrevMonth();
    }
  };

  handleNextWeek = () => {
    const { curWeekIndex, dateRows } = this.state;
    if (curWeekIndex < dateRows.length - 1) {
      this.setState({
        curWeekIndex: curWeekIndex + 1,
      });
    } else {
      this.handleNextMonth();
    }
  };

  render() {
    const {
      isLoading,
      homework,
      classroom,
      curYear,
      curMonth,
      dateRows,
      curWeek,
      curWeekIndex,
      dates,
      curWeekLectures,
      lectureByWeekNum,
    } = this.state;
    const { lectures, user } = this.props;

    // console.log("currentHomework: ", homework);
    console.log("dates: ", dates);
    console.log("classroom: ", classroom);
    console.log("dateRows: ", dateRows);
    console.log("curWeek: ", curWeek);
    console.log("curMonth: ", curMonth);
    console.log("curWeekLectures: ", curWeekLectures);
    console.log("lectureByWeekNum: ", lectureByWeekNum);
    console.log("lectures: ", lectures);

    if (user && user.orgType === "school") {
      this.props.history.push("/homework");
      return null;
    }

    return (
      <>
        <HomeHeader lectures={lectures} />
        <div className="ht-main-layout">
          <div className="ht-main-layout-wrap">
            {/* 과제 완료했을 경우 is-complete */}
            {homework.seq && (
              <div className="class-panel">
                <p className="class-panel__task">
                  {/* {`${getSeqText(homework)} 수업과제`} */}
                  <FormattedMessage
                    id="hometraining.homework-lesson"
                    values={{ seq: homework.seq }}
                  />
                  <span>{`[ ${homework.date}]`}</span>
                </p>
                <p className="class-panel__status">
                  <strong>
                    {homework.completed ? (
                      <FormattedMessage id="hometraining.homework-complete" />
                    ) : (
                      <FormattedMessage id="hometraining.homework-incomplete" />
                    )}
                  </strong>
                  {homework.completed ? (
                    <span onClick={() => this.props.history.push("/review")}>
                      <FormattedMessage id="hometraining.homework-review-queture" />
                    </span>
                  ) : (
                    <span onClick={() => this.props.history.push("/homework")}>
                      <FormattedMessage id="hometraining.homework-todays-assignment" />
                    </span>
                  )}
                </p>
                <ul className="class-panel__list none">
                  {/* 과제 완료했을 경우 is-complete */}
                  {homework.quetureList.map((item, index) => (
                    <li
                      key={"queture" + index}
                      className={classNames({
                        "is-complete": item.isDone,
                      })}
                    >
                      {item.text} <span></span>
                    </li>
                  ))}
                  {homework.uploadList.map((item, index) => (
                    <li
                      key={"upload" + index}
                      className={classNames({
                        "is-complete": item.isDone,
                      })}
                    >
                      {item.text} <span></span>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="class-info">
              <div className="class-info__profile">
                {user && (
                  <div className="imgwrap">
                    <div className="img">
                      {user.profileImage && (
                        <img
                          src={getProfileUrl(user.profileImage.contentId)}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="class-info__profile-name">
                      {user.name}
                      <span className="u-color-blue">{`(${user.account})`}</span>
                    </div>
                  </div>
                )}
                <MiniCalendar
                  dateRows={dateRows}
                  curWeekIndex={curWeekIndex}
                  curYear={curYear}
                  curMonth={curMonth}
                  onPrevMonth={this.handlePrevMonth}
                  onNextMonth={this.handleNextMonth}
                  onChangeWeekIndex={this.handleChangeWeekIndex}
                />
              </div>

              {classroom && (
                <WeeklyCalendar
                  weeklyData={dates[curWeekIndex]}
                  lectures={curWeekLectures}
                  classroom={classroom}
                  homework={homework}
                  lectureStatus={user && user.lectureStatus}
                  user={user}
                  onPrevWeek={this.handlePrevWeek}
                  onNextWeek={this.handleNextWeek}
                  onToday={this.handleToday}
                  onGetLectureDays={this.getLectureDays}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    ({ auth, hometraining }) => ({
      pending: auth.pending || hometraining.pending,
      user: auth.user,
      logged: auth.logged,
      lectures: hometraining.lectures,
    }),
    (dispatch) => ({
      HometrainingActions: bindActionCreators(hometrainingActions, dispatch),
    })
  )(injectIntl(Main))
);
