import Axios from "./request";
import { message } from "antd";

const apiPrefix = "/academy/v2/accounts/";

const getOrgInfo = orgId => {
  return Axios.get(`${apiPrefix}${orgId}`);
};

const getStudentList = (orgId, active, currentClassroom, page, count, orderBy, filter, value) => {
  let query = `?page=${page}&count=${count}`;
  if (active === true || active === false) {
    query += `&active=${active}`;
  }
  if (currentClassroom === true || currentClassroom === false) {
    query += `&currentClassroom=${currentClassroom}`;
  }
  if (orderBy) {
    query += `&orderBy=${orderBy}`;
  }
  if (filter) {
    query += `&filter=${filter}`;
  }
  if (value) {
    query += `&value=${value}`;
  }

  return Axios.get(`${apiPrefix}${orgId}/students${query}`);
};
const getClassroomStudentList = (orgId, classroomId, page, count, orderBy, withReport) => {
  let query = `?page=${page}&count=${count}`;
  if (orderBy) {
    query += `&orderby=${orderBy}`;
  }
  if (withReport) {
    query += `&withReport=true`;
  }
  return Axios.get(
    `${apiPrefix}${orgId}/classrooms/${classroomId}/students${query}`
  );
};

const updateStudent = (orgId, studentId, data) => {
  return Axios.post(`${apiPrefix}${orgId}/students/${studentId}`, data);
};

const updateStudentProfile = (orgId, studentId, contentId) => {
  return Axios.put(
    `${apiPrefix}${orgId}/students/${studentId}/profile?contentId=${contentId}`,
    null
  );
};

const changeStudentClass = (orgId, studentId, classroomId) => {
  return Axios.post(
    `${apiPrefix}${orgId}/students/${studentId}/change-class?classroomId=${classroomId}`,
    null
  );
};

const resetStudentPassword = (orgId, studentId, password) => {
  let query = "";
  if (password) {
    query += "?password=" + escape(password);
  }

  return Axios.post(
    `${apiPrefix}${orgId}/students/${studentId}/reset-password${query}`,
    null
  );
};
const resetGuidePassword = (orgId, guideId, password) => {
  let query = "";
  if (password) {
    query += "?password=" + escape(password);
  }
  console.log(query);
  return Axios.post(
    `${apiPrefix}${orgId}/guides/${guideId}/reset-password${query}`,
    null
  );
};

const updateStudentPassword = (orgId, studentId, data) => {
  return Axios.put(`${apiPrefix}${orgId}/students/${studentId}/password`, data);
};

const addGuide = (orgId, data) => {
  return Axios.post(`${apiPrefix}${orgId}/guides`, data);
};
const editGuide = (orgId, guideId, data) => {
  return Axios.put(`${apiPrefix}${orgId}/guides/${guideId}`, data);
};
const updateGuidePermissions = (orgId, guideId, data) => {
  return Axios.put(`${apiPrefix}${orgId}/guides/${guideId}/permissions`, data);
};
const getClassroomDetail = (orgId, classroomId) => {
  return Axios.get(`${apiPrefix}${orgId}/classrooms/${classroomId}`);
};
const getGuideClassroomDetail = (guideId, classroomId) => {
  return Axios.get(`/academy/v2/guides/${guideId}/classrooms/${classroomId}`);
};
const changeGuideState = (orgId, guideId, state) => {
  return Axios.put(
    `${apiPrefix}${orgId}/guides/${guideId}/state?state=${state}`
  );
};

const finishClassroom = (orgId, classroomId) => {
  return Axios.post(`${apiPrefix}${orgId}/classrooms/${classroomId}/finish`);
};

const initClassroom = (orgId, classroomId) => {
  return Axios.post(`${apiPrefix}${orgId}/classrooms/${classroomId}/init`);
};

const addClassroomCourse = (orgId, classroomId, data) => {
  return Axios.put(
    `${apiPrefix}${orgId}/classrooms/${classroomId}/course`,
    data
  );
};

const changeClassroomName = (orgId, classroomId, name) => {
  return Axios.put(
    `${apiPrefix}${orgId}/classrooms/${classroomId}/name?name=${encodeURIComponent(name)}`
  );
};

const changeClassroomSchedule = (orgId, classroomId, days) => {
console.log(days)
  return Axios.put(
    `${apiPrefix}${orgId}/classrooms/${classroomId}/days` , days
  );
};

const resetClassroomLecture = (orgId, classroomId, lectureId) => {
  return Axios.post(`${apiPrefix}${orgId}/classrooms/${classroomId}/lectures/${lectureId}/reset`);
};

const deleteClassroomLecture = (orgId, classroomId, lectureId) => {
  return Axios.delete(`${apiPrefix}${orgId}/classrooms/${classroomId}/lectures/${lectureId}`);
};

const changeClassroomState = (orgId, classroomId, state) => {
  return Axios.put(
    `${apiPrefix}${orgId}/classrooms/${classroomId}/state?state=${encodeURIComponent(state)}`
  );
};

const closeClassroom = (orgId, classroomId) => {
  return Axios.post(`${apiPrefix}${orgId}/classrooms/${classroomId}/close`);
};


const changeStudentState = (orgId, studentId, reason,state) => {
  return Axios.put(
    `${apiPrefix}${orgId}/students/${studentId}/state?state=${state}&reason=${reason}`
  );
};

const uploadStudents = orgId => {
  return Axios.post(`${apiPrefix}${orgId}/students/bulk`);
};

const deleteStudent = (orgId, studentId) => {
  return Axios.delete(`${apiPrefix}${orgId}/students/${studentId}`);
};

const updateStudentReason = (orgId, studentId, reason) => {
  return Axios.put(`${apiPrefix}${orgId}/students/${studentId}/reason?reason=${reason}`);
}

const AccountApis = {
  getStudentList,
  getClassroomStudentList,
  getOrgInfo,
  updateStudent,
  updateStudentProfile,
  changeStudentClass,
  resetStudentPassword,
  addGuide,
  editGuide,
  updateGuidePermissions,
  updateStudentPassword,
  getClassroomDetail,
  getGuideClassroomDetail,
  changeGuideState,
  finishClassroom,
  initClassroom,
  addClassroomCourse,
  changeClassroomName,
  changeClassroomSchedule,
  resetClassroomLecture,
  deleteClassroomLecture,
  changeClassroomState,
  changeStudentState,
  resetGuidePassword,
  uploadStudents,
  deleteStudent,
  updateStudentReason
};

export default AccountApis;
