import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Skeleton,
  PageHeader,
  Button,
  Icon,
  message,
  List,
  Table,
  Typography,
  Checkbox,
  Popconfirm
} from "antd";
import Title from "antd/lib/typography/Title";
import ReactToPrint from "react-to-print";

import axios from "axios";
import { getDefaultConfig, getHost } from "utils/APIUtils";

import { LectureWorkBookPage } from "./LectureWorkBookPage";
import QuizDescCard from "./QuizDescCard";
import QuizAnswerCard from "./QuizAnswerCard";
import { getQuizRandomSeed, getCourseTitle, getCourseColor, getCourseSymbol } from "./workbookUtil";

import ClassroomApis from "utils/classroomApis";
import * as classroomActions from "store/modules/classroom";


import "components/Quiz/style/index.scss";
import "components/Quiz/css/_layout.scss";
import "components/Quiz/css/Quiz.scss";
import "katex/dist/katex.min.css";

import "./index.scss";


class LectureSolvingBookPage extends LectureWorkBookPage {

  // overrides only render() against LectureWorkbookPage

  render() {
    const { loading, lecture, quetureQuizList } = this.state;

    if (loading || !lecture) {
       return "loading..."
    }

    let courseTitle = getCourseTitle(lecture.courseTitle);
    let color = getCourseColor(lecture.courseTitle);
    let cs = getCourseSymbol(lecture.courseTitle);

    return (
      <Skeleton loading={loading} active>
        <PageHeader
          title={
            <Title level={2} style={{ marginBottom: 0 }}>
               {courseTitle} {lecture.title} 워크북 해설집
            </Title>
          }
          extra={[
            <Button onClick={() => this.props.history.push(`/admin/class/detail/${this.props.match.params.id}/lecture-workbook/${this.props.match.params.lectureId}`)}>
              <Icon type="double-right" />문제집
            </Button>
            ,
            <span key="2">
                <ReactToPrint
                  trigger={() => ( <Button><Icon type="printer" />프린트</Button>)}
                  content={() => this.componentRef}
                  documentTitle={ `${courseTitle} ${lecture.title} 워크북 해설집` }
                />
            </span>,
          ]} />

        <div className="admin-page-print-content">
          <div style={{ position: "relative", padding: "0px" }} ref={(el) => (this.componentRef = el)} >
            {quetureQuizList.map((quetureQuiz, quetureQuizIndex) => (
              <div className={quetureQuizIndex >= 1 ? "queture-quiz-wrap break-page" : "queture-quiz-wrap"}>
                <table className={`queture-quiz-solving-page-header workbook-color-${cs}`}>
                  <tbody>
                  <tr>
                    <td className="solving-page-header-detail">&nbsp;</td>
                    <td className="solving-page-header-title">정답과 해설</td>
                    <td className="solving-page-header-dot">
                      · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · 
                    </td>
                  </tr>
                  </tbody>
                </table>

                <div className="queture-quiz-solving-title">
                   <div className={`index workbook-color-${cs}`}>
                       {lecture.title.replace('회차', '')}<span style={{ fontSize: '30px' }}>회차</span>
                   </div>
                   <div className={`numberTag workbook-solving-tag-${cs}`}>
                       # {quetureQuiz.quetureSeq}
                   </div>
                   <div className="title">
                      {quetureQuiz.quetureTitle}
                   </div>
                </div>

                <div className="queture-quiz-heading-speechbubble">정답</div>
                <div className="queture-quiz-solving-answer">
                {quetureQuiz.quizIds.map((quizId, index) => (
                   <div className="queture-quiz-solving-answer-item">
                    <table>
                      <tbody>
                      <tr>
                        <td><div className="queture-quiz-solving-answer-number">{index + 1}.</div></td>
                        <td>
                          <div className="queture-quiz-solving-answer-value">
                            <QuizAnswerCard quizId={quizId} quizIndex={index} forWorkbook={true}
                             randomSeed={getQuizRandomSeed(lecture.lectureId, quetureQuizIndex, index)}
                            />
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                   </div>
                ))}
                </div>

                <div className="queture-quiz-heading-speechbubble">해설</div>
                {quetureQuiz.quizIds.map((quizId, index) => (
                  <QuizDescCard quizId={quizId} quizIndex={index} forWorkbook={true}
                    randomSeed={getQuizRandomSeed(lecture.lectureId, quetureQuizIndex, index)}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </Skeleton>
    );
  }
}

export default LectureSolvingBookPage;

