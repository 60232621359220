import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { values, orderBy, map as _map, filter } from "lodash";
import "./_component.scss";
import { getProfileUrl } from "../../../utils/utils";
import fakeAvatar3 from "./img/fakeAvatar3.svg";
import SimpleGameRankingList from "./SimpleGameRankingList";
import { Row, Col } from "antd";
import { FormattedMessage } from "react-intl";


const fakeAvatar1 =
  "https://images-na.ssl-images-amazon.com/images/M/MV5BMTU4NTM1MTExOF5BMl5BanBnXkFtZTcwMTYwODMyMw@@._V1_UY256_CR2,0,172,256_AL_.jpg";
const fakeAvatar2 =
  "https://images-na.ssl-images-amazon.com/images/M/MV5BMjgzNjJiMjgtMzllZS00NGRjLTgyOWEtYzVhNzgxODE0ZDA3XkEyXkFqcGdeQXVyNTU0OTE5MjA@._V1_UX172_CR0,0,172,256_AL_.jpg";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const GameRankingList = props => {
  const { ranking, students, attendances } = props;
  const [rankingList, setRankingList] = useState([]);
  const [timer, setTimer] = useState(null);
  const [random, setRandom] = useState(false);
  const prevData = usePrevious(rankingList);

  useEffect(() => {
    let _rankingList = {};
    console.log(ranking);

    if (students) {
      let _studentList = filter(students, function (o) {
        return (
          attendances[o.studentId] &&
          attendances[o.studentId].status &&
          attendances[o.studentId].status === "ok"
        );
      });

      _studentList.map(student => {
        const rankingData = {
          student: student,
          time: 0,
          score: 0,
          currentScore: 0,
          changed: false,
          modifiedTime: 0
        };
        // _rankingList.push(rankData);
        _rankingList[student.studentId] = rankingData;
        // }
        //   if (!rankedStudentIds.includes(student.studentId)) {
      });
    }

    ranking.map(r => {
      if (_rankingList[r.student.studentId]) {
        _rankingList[r.student.studentId].currentScore = r.currentScore;
        _rankingList[r.student.studentId].score = r.score;
        // _rankingList[r.student.studentId].score = Math.round(
        //   Math.random() * 10
        // );
        _rankingList[r.student.studentId].time = r.time;
      }
    });

    const newRankingList = orderBy(
      values(_rankingList),
      ["score", "modifiedTime"],
      ["desc", "asc"]
    );
    if (prevData) {
      newRankingList.map((r, index) => {
        if (r.student.studentId !== prevData[index].student.studentId) {
          r["changed"] = true;
        }
      });
    }
    console.log("prev list: ", prevData);
    console.log("new list: ", newRankingList);
    setRankingList(newRankingList);

    if (timer != null) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        setTimer(null);
      }, 500)
    );

    return () => {
      setRankingList(null);
    };
  }, [ranking, random]);

  if (props.isSimple && rankingList) {
    return <SimpleGameRankingList data={rankingList}/>;
  }

  return (
    <>
      {rankingList && rankingList.length != 0 && rankingList && rankingList.length <= 6 ? (
        <div>
        <span style={{
          marginLeft: '58%',
          marginBottom: '5px',
          scolor: "#ffa842",
          font: '24px HGSoftGGothicssi',
          fontWeight: 600
        }}>
          <FormattedMessage id="classroom.highest-score" />
        </span>
          <span style={{
            marginLeft: '14%',
            marginBottom: '5px',
            color: "#3289c9",
            font: '18px HGSoftGGothicssi',
            fontWeight: 600
          }}>
          <FormattedMessage id="classroom.current-score" />
        </span>
        </div>) : (<div>
        <span style={{
          marginLeft: '32%',
          marginBottom: '5px',
          scolor: "#ffa842",
          font: '24px HGSoftGGothicssi',
          fontWeight: 600
        }}>
          <FormattedMessage id="classroom.highest-score" />
        </span>
        <span style={{
          marginLeft: '6%',
          marginBottom: '5px',
          color: "#3289c9",
          font: '18px HGSoftGGothicssi',
          fontWeight: 600
        }}>
          <FormattedMessage id="classroom.current-score" />
        </span>
        <span style={{
          marginLeft: '36%',
          marginBottom: '5px',
          scolor: "#ffa842",
          font: '24px HGSoftGGothicssi',
          fontWeight: 600
        }}>
          <FormattedMessage id="classroom.highest-score" />
        </span>
        <span style={{
          marginLeft: '5.5%',
          marginBottom: '5px',
          color: "#3289c9",
          font: '18px HGSoftGGothicssi',
          fontWeight: 600
        }}>
          <FormattedMessage id="classroom.current-score" />
        </span>
      </div>)}
      <button style={{ display: "none" }} onClick={() => setRandom(!random)}>
        {1}
      </button>
      <ul
        className={classNames("ranking", {
          "column-list": rankingList && rankingList.length > 6
        })}
      >
        {rankingList &&
        rankingList.map((item, index) => (
          <div>
            <li
              key={index}
              className={classNames("rankinglist turn", {
                over: item.score && item.score > 0 && item.changed && timer,
                one: item.score && item.score > 0 && index === 0,
                two: item.score && item.score > 0 && index === 1,
                three: item.score && item.score > 0 && index === 2
              })}
            >
              <div className="card-wrap">
                <div className="face front" style={{ width: '700px' }}>
                  <div className="img-wrap">
                    <div className={classNames("img solo")}
                         src={
                           (item.student.profileImage &&
                             getProfileUrl(
                               item.student.profileImage.contentId
                             )) ||
                           fakeAvatar3
                         }
                         alt=""
                    />
                  </div>
                  <div className="name-wrap">
                    <span className={classNames("name")}>
                      {item.student.name}
                    </span>
                  </div>
                  <span className="timer"
                        style={{
                          marginRight: '150px'
                        }}>{`${item.score}`}</span>
                  {rankingList && rankingList.length <= 12 && (<span className={"timer"} style={{
                    color: "black",
                    maxWidth                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                : '120px',
                    width: '120px',
                    borderRadius: '0.5625vw',
                    backgroundColor: "rgba(255,255,255,0.2)",
                    border: '2px solid #3289c9'
                  }}>{`${item.currentScore}`}</span>)}
                </div>
              </div>
            </li>
          </div>
        ))}
      </ul>
    </>
  );
};

export default GameRankingList;
