import React, { Component } from "react";
import { Table, Divider, Tag } from "antd";
import { Link } from "react-router-dom";
import { getWeekdayTable, getWeekdayName } from "../../../../../utils/utils";
import { filter, mapKeys, orderBy, remove, map as _map } from "lodash";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";

export class ClassTable extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      defaultPageSize: 10,
      currentPage: 1,
    };
  }

  onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    this.setState({
      currentPage: pagination.current,
    });
  };

  render() {
    const { defaultPageSize, currentPage } = this.state;
    const { isFinishedList, hasGuidePermission } = this.props;
    console.log("hasGuidePermission: ", hasGuidePermission);

    const weekdays = getWeekdayTable();
    const checkNewDays = 1000 * 60 * 60 * 24 * 1;
    let columns = [
      /*
      {
        title: '#',
        dataIndex: "name",
        key: "index",
        render: (name, record, index) => {
          return <span>{(currentPage - 1) * defaultPageSize + index + 1}</span>;
        },
      },
      */
      {
        title: (
          <FormattedMessage
            id="admin.classroom.list-col-course"
            values={{ course: "" }}
          />
        ),
        dataIndex: "courseTitle",
        key: "cource",
        sorter: (a, b) => a.courseTitle.localeCompare(b.courseTitle),
        defaultSortOrder: "ascend",
        filters: this.context.type === "campus" && [
          {
            text: (
              <FormattedMessage
                id="admin.classroom.list-col-course"
                values={{ course: "A" }}
              />
            ),
            value: "A",
          },
          {
            text: (
              <FormattedMessage
                id="admin.classroom.list-col-course"
                values={{ course: "B" }}
              />
            ),
            value: "B",
          },
          {
            text: (
              <FormattedMessage
                id="admin.classroom.list-col-course"
                values={{ course: "C" }}
              />
            ),
            value: "C",
          },
          {
            text: (
              <FormattedMessage
                id="admin.classroom.list-col-course"
                values={{ course: "D" }}
              />
            ),
            value: "D",
          },
          {
            text: (
              <FormattedMessage
                id="admin.classroom.list-col-course"
                values={{ course: "E" }}
              />
            ),
            value: "E",
          },
          {
            text: (
              <FormattedMessage id="admin.classroom.list-col-course-other" />
            ),
            value: "-",
          },
        ],
        onFilter: (value, record) =>
          record.courseTitle
            ? record.courseTitle.startsWith(value) ||
              (value == "-" &&
                !"ABCDE".includes(record.courseTitle.substring(0, 1)))
            : value == "-",
        render: (courseTitle) => {
          return <span style={{ fontWeight: "bold" }}>{courseTitle}</span>;
        },
      },
      {
        title: <FormattedMessage id="admin.classroom.list-col-class" />,
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, record, index) => (
          <Link
            to={`/admin/class/detail/${record.classroomId}`}
            style={{ textDecoration: "underline" }}
          >
            {record.createdTime &&
              new Date() < new Date(record.createdTime + checkNewDays) && (
                <Tag color="#108ee9">New</Tag>
              )}
            <span>{text}</span>
          </Link>
        ),
      },
      {
        title: <FormattedMessage id="admin.classroom.list-col-branch" />,
        dataIndex: "organization.name",
        key: "organization.name",
      },
      {
        // NOTE: record.state might be non-'finish' with isFinisied=true
        title: <FormattedMessage id="admin.classroom.list-col-status" />,
        dataIndex: "state",
        key: "state",
        render: (state, record) =>
          state === "deleted" ? (
            <span style={{ color: "red" }}>
              <FormattedMessage id="admin.classroom.list-value-delete" />
            </span>
          ) : record.isFinished ? (
            <span style={{ color: "blue" }}>
              <FormattedMessage id="admin.classroom.list-value-terminated" />
            </span>
          ) : state === "finish" ? (
            <span style={{ color: "blue" }}>
              <FormattedMessage id="admin.classroom.list-value-force-terminated" />
            </span>
          ) : state === "current" ? (
            <span>
              <FormattedMessage id="admin.classroom.list-value-current" />
            </span>
          ) : (
            <span>?({state})</span>
          ),
      },
      {
        title: <FormattedMessage id="admin.classroom.list-col-progress" />,
        dataIndex: "completedLectures",
        key: "completedLectures",
        sorter: (a, b) => a.completedLectures - b.completedLectures,
        render: (text, record, index) =>
          record.state !== "deleted" && record.isFinished ? (
            <span style={{ color: "blue" }}>
              {/* *{`${text}회차 / 총${record.totalLectures}회차`}* */}
              *
              <FormattedMessage
                id="admin.classroom.list-value-lesson-of-total"
                values={{ seq: text, total: record.totalLectures }}
              />
              *
            </span>
          ) : (
            <span>
              <FormattedMessage
                id="admin.classroom.list-value-lesson-of-total"
                values={{ seq: text, total: record.totalLectures }}
              />
            </span>
          ),
      },
      {
        title: <FormattedMessage id="admin.classroom.list-col-schedule" />,
        dataIndex: "days",
        key: "days",
        filters: [
          {
            text: <FormattedMessage id="admin.classroom.list-value-mon" />,
            value: "mon",
          },
          {
            text: <FormattedMessage id="admin.classroom.list-value-tue" />,
            value: "tue",
          },
          {
            text: <FormattedMessage id="admin.classroom.list-value-wed" />,
            value: "wed",
          },
          {
            text: <FormattedMessage id="admin.classroom.list-value-thu" />,
            value: "thu",
          },
          {
            text: <FormattedMessage id="admin.classroom.list-value-fri" />,
            value: "fri",
          },
          {
            text: <FormattedMessage id="admin.classroom.list-value-sat" />,
            value: "sat",
          },
          {
            text: <FormattedMessage id="admin.classroom.list-value-sun" />,
            value: "sun",
          },
        ],
        onFilter: (value, record) => _map(record.days, "day").includes(value),
        render: (days) => (
          <span>
            {days &&
              days.map((day, key) => {
                return (
                  <Tag color="blue" key={key}>
                    {/* {`${getWeekdayName(day.day)} ${day.time}`} */}
                    <FormattedMessage
                      id={"admin.classroom.list-value-" + day.day}
                    />
                    &nbsp;{day.time}
                  </Tag>
                );
              })}
          </span>
        ),
      },
      {
        title: <FormattedMessage id="admin.classroom.list-col-start-date" />,
        dataIndex: "startDate",
        key: "startDate",
        sorter: (a, b) => a.startDate.localeCompare(b.startDate),
        render: (text, record) =>
          record.state === "deleted" ? (
            <span style={{ color: "red" }}>({text})</span>
          ) : (
            <span>{text}</span>
          ),
      },
      {
        title: <FormattedMessage id="admin.classroom.list-col-end-date" />,
        dataIndex: "endDate",
        key: "endDate",
        sorter: (a, b) => a.endDate.localeCompare(b.endDate),
        render: (text, record) =>
          record.state === "deleted" ? (
            <span style={{ color: "red" }}>({text})</span>
          ) : record.isFinished ? (
            <span style={{ color: "blue" }}>*{text}*</span>
          ) : (
            <span>{text}</span>
          ),
      },
      {
        title: <FormattedMessage id="admin.classroom.list-col-students" />,
        dataIndex: "studentsCount",
        key: "studentsCount",
        sorter: (a, b) => a.studentsCount - b.studentsCount,
        render: (studentsCount, record, index) => (
          <>
            {studentsCount > 0 ? (
              <Link
                to={`/admin/class/detail/${record.classroomId}`}
                style={{ textDecoration: "underline" }}
              >
                <span>
                  {/* {studentsCount}명 */}
                  <FormattedMessage
                    id="admin.classroom.list-value-student-count"
                    values={{ count: studentsCount }}
                  />
                </span>
              </Link>
            ) : (
              <span>
                <FormattedMessage
                  id="admin.classroom.list-value-student-count"
                  values={{ count: studentsCount }}
                />
              </span>
            )}
          </>
        ),
      },
      {
        title: <FormattedMessage id="admin.classroom.list-col-teacher" />,
        dataIndex: "guide",
        key: "guide",
        sorter: (a, b) =>
          a.guide
            ? b.guide
              ? a.guide.name.localeCompare(b.guide.name)
              : 1
            : b.guide
            ? -1
            : 0,
        render: (guide) => (
          <>
            {guide ? (
              hasGuidePermission ? (
                <Link to={`/admin/guide/detail/${guide.guideId}`}>
                  <span>{guide.name}</span>
                </Link>
              ) : (
                <span>{guide.name}</span>
              )
            ) : (
              <font color="red">
                <FormattedMessage id="admin.classroom.list-value-no-teacher" />
              </font>
            )}
          </>
        ),
      },
    ];

    let data = this.props.classroomList;
    if (this.props.todayOnly) {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();

      const todayStr =
        year +
        "-" +
        (month >= 10 ? month : "0" + month) +
        "-" +
        (day >= 10 ? day : "0" + day);

      data = filter(this.props.classroomList, function (o) {
        const _lectures = mapKeys(o.lectures, function (value, key) {
          return key.split(" ")[0];
        });
        const lectureKeys = Object.keys(_lectures);
        const hasTodayLectures = lectureKeys.includes(todayStr);

        return hasTodayLectures ? true : false;
      });
      console.log("todayOnly: ", data);
    }

    if (this.props.searchName) {
      const name = this.props.searchName.toLowerCase();
      data = filter(data, (c) => c.name && c.name.toLowerCase().includes(name));
    } else {
      if (this.props.finishedOnly) {
        data = filter(data, (c) => c.isFinished || c.state === "finish");
      }
      if (this.props.showDeleted) {
        data = filter(data, (c) => c.state === "deleted");
      } else {
        data = filter(data, (c) => c.state !== "deleted");
      }
    }

    if (this.props.sortType) {
      const _sortType = this.props.sortType;
      if (_sortType === "startDate") {
        data = orderBy(data, ({ startDate }) => new Date(startDate) || "", [
          "asc",
        ]);
      } else if (_sortType === "endDate") {
        data = orderBy(data, ({ endDate }) => new Date(endDate) || "", ["asc"]);
      }
    }

    // if (isFinishedList) {
    //   columns = columns.filter((item) => !(item.title === "진행현황"));
    // }

    console.log("orgtype: ", this.props.orgType);
    if (this.props.orgType === "school") {
      columns = remove(columns, (item) => item.key !== "days");
      console.log("col: ", columns);
    }

    console.log("data: ", this.props.classroomList);
    //    pagination={{
    //      defaultPageSize: this.state.defaultPageSize,
    //    }}
    return (
      <Table
        rowKey="classroomId"
        dataSource={data}
        columns={columns}
        pagination={{
          defaultPageSize: this.state.defaultPageSize,
        }}
        onChange={this.onChange}
      />
    );
  }
}

export default ClassTable;
