import React, { useState, useEffect } from "react";
import { values, filter } from "lodash";
import HometrainingApis from "../../../utils/hometrainingApis";
import Axios from "../../../utils/request";
import { useMount } from "react-use";
import { formatText } from "../../../components/Quiz/utils/Formatter";
import { formatImgText } from "../../../components/Quiz/utils/ImageFormatter";
import { FormattedMessage } from "react-intl";

const PreviewQuetureGuide = (props) => {
  const { lectureData, queture, quetureId } = props;
  const [isLoading, setLoading] = useState(false);
  const [getQueture, setQueture] = useState(queture);

  useEffect(() => {
    if (!getQueture) {
      setTimeout(() => {
        loadQueture();
      }, 1000);
    }
    return () => {};
  });

  console.log(getQueture);

  const loadQueture = () => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    Axios.get(
      `/content/v2/quetures/${quetureId}?withQuetureTeachingGuide=${false}&withQuetureCampusNote=${true}`
    )
      .then((r) => {
        setLoading(false);
        setQueture(r);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  let picturesUrl = [];

  if (
    getQueture &&
    getQueture.quetureCampusNote &&
    getQueture.quetureCampusNote.pictures
  )
    getQueture.quetureCampusNote.pictures.map((e) =>
      picturesUrl.push(e.signedUrl)
    );

  return (
    <div>
      <div className="popup ps-chk" style={{ left: "135px" }}>
        <div className="popup-content">
          <li
            style={{
              overflowX: "flep",
              overflowY: "auto",
              height: "600px",
              width: "400px",
            }}
          >
            <h1>
              <FormattedMessage id="classroom.popup.queture-point" />
            </h1>
            {getQueture &&
            getQueture.quetureCampusNote &&
            getQueture.quetureCampusNote.checkPoint
              ? formatImgText(
                  getQueture.quetureCampusNote.checkPoint,
                  null,
                  picturesUrl ? picturesUrl : null
                )
              : ""}
            <h1><FormattedMessage id="classroom.popup.caution" /></h1>
            {getQueture &&
            getQueture.quetureCampusNote &&
            getQueture.quetureCampusNote.precautions
              ? formatImgText(
                  getQueture.quetureCampusNote.precautions,
                  null,
                  picturesUrl ? picturesUrl : null
                )
              : ""}
          </li>
        </div>
      </div>
    </div>
  );
};

export default PreviewQuetureGuide;
