import React, { useState, useEffect, useRef } from "react";
import Popup from "../../../components/Popup/Popup";
import classNames from "classnames";
import { indexOf, keys, pickBy } from "lodash";
import {
  getOrdinals,
  getSectionName,
  numberToText,
} from "../../../utils/utils";
import { FormattedMessage } from "react-intl";
const LecturePathPopup = (props) => {
  const { isOpen, seq, sectionIndex, onSelect, section, sections, onSection } =
    props;

  const itemsEl = useRef(null);
  useEffect(() => {
    // const element = document.getElementById("currentSection");

    // console.log(element);
    // console.log(element && element.offsetTop);

    console.log(itemsEl.current);
    console.log(sectionIndex);

    setTimeout(() => {
      // window.scrollTo({
      //   behavior: element ? "smooth" : "auto",
      //   top: element ? element.offsetTop : 0
      // });
    }, 100);
  }, [isOpen]);

  const quetureSectionIndexes = keys(
    pickBy(sections, function (o) {
      return o.type === "QUETURE";
    })
  );

  function getQuetureIndex(index) {
    const quetureSectionIndex = indexOf(quetureSectionIndexes, "" + index);
    return quetureSectionIndex + 1;
    // return numberToText(1 + quetureSectionIndex);
  }

  return (
    <Popup isOpen={isOpen} className="popup-lecture-path">
      <div className="lecture-route-wrap" id="test">
        <ul className="lecture-route">
          {sections.map((sec, index) => (
            <li
              key={index}
              // id={index === sectionIndex ? "currentSection" : null}
              className={classNames({
                "is-gone-view": index < sectionIndex,
                "is-view": index === sectionIndex,
              })}
              // ref={() => itemsEl[sectionIndex]}
              // ref={el => (this.itemRefs[m.id] = el)}
              onClick={() => {
                onSection(index);
                onSelect();
              }}
            >
              {/* { && <span ref={myRef}></span>} */}
              <p>
                {/* {sec.type === "QUETURE"
                  ? getSectionName(sec.type, getQuetureIndex(index))
                  : getSectionName(sec.type)} */}
                <FormattedMessage
                  id={getSectionName(sec.type)}
                  values={{
                    seq:
                      sec.type === "QUETURE"
                        ? getQuetureIndex(index)
                        : index + 1,
                    ordinal: getOrdinals(props.locale, getQuetureIndex(index), "등"),
                  }}
                />
              </p>
            </li>
          ))}
        </ul>
      </div>
    </Popup>
  );
};

export default LecturePathPopup;
