import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import * as guideActions from "store/modules/guide";
import * as courseSimpleActions from "store/modules/courseSimple";
import * as studentActions from "store/modules/student";
import Axios from "../../../../utils/request";
import {
  Skeleton,
  Row,
  Col,
  List,
  Tag,
  Spin,
  PageHeader,
  Button,
  Icon,
  Descriptions,
  Typography,
  Table,
  Divider,
  Statistic,
  Popconfirm,
  message,
  Tooltip,
  Checkbox,
} from "antd";
import { Link, withRouter } from "react-router-dom";
import { values, flatten, filter, countBy, merge, map as _map } from "lodash";
import MakeTeamPopup from "../../components/Popup/MakeTeamPopup";
import ConfirmHomeworkPopup from "../../components/Popup/ConfirmHomeworkPopup";
import ChangeGuidePopup from "../../components/Popup/ChangeGuidePopup";
import ClassroomApis from "../../../../utils/classroomApis";
import SetHomeworkPopup from "../../components/Popup/SetHomeworkPopup";
import QuetureName from "../../components/QuetureName";
import { getKoreanDate, formateFullKoreanDate } from "../../../../utils/utils";
import ClassroomLectureList from "../../components/ClassroomLectureList";
import AccountApis from "../../../../utils/accountApis";
import { ConstantsContext } from "utils/ConstantsContext";
import AddCoursePopup from "../../components/Popup/AddCoursePopup";
import ClassroomStudentList from "../../components/ClassroomStudentList";
import ChangeClassroomStudentListPopup from "../../components/Popup/ChangeClassroomStudentListPopup";
import CommonApis from "../../../../utils/commonApis";
import axios from "axios";
import ChangeClassroomNamePopup from "../../components/Popup/ChangeClassroomNamePopup";
import ChangeClassroomDayPopup from "../../components/Popup/ChangeClassroomDayPopup";
import { FormattedMessage, injectIntl } from "react-intl";

const { Title, Text } = Typography;

class ClassroomDetailPage extends Component {
  static contextType = ConstantsContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingList: false,
      classroomData: null,
      showMakeTeamPopup: null,
      showConfirmHomeworkPopup: false,
      showChangeGuidePopup: false,
      showDelayClassPopup: false,
      showAddCoursePopup: false,
      showChangeStudentListPopup: false,
      showChangeClassroomNamePopup: false,
      showChangeClassroomDayPopup: false,
      isActiveInputField: false,
      nextCourse: "",
      showHomeworkPopup: null,
      latestLectureId: null,
      enableDangerousFeature: false,
    };
  }

  componentDidMount() {
    const { user, ClassroomActions, CourseSimpleActions } = this.props;
    const { id } = this.props.match.params;
    console.log("this id: ", id);
    if (user) {
      if (id) {
        this.getClassroomDetail(id);
      } else {
        ClassroomActions.getClassroomList(user.orgId || user.userId).then(
          (res) => {
            if (res && res.length > 0) {
              console.log("res", res[0]);
              this.getClassroomDetail(res[0]["classroomId"]);
            }
          }
        );
      }
      CourseSimpleActions.getCourseSimpleList();
    }
  }

  getClassroomDetail = (classroomId) => {
    const { user } = this.props;
    this.setState({
      loading: true,
    });
    const _orgId = user.orgId || user.userId;
    if (user.type === "org") {
      AccountApis.getClassroomDetail(_orgId, classroomId)
        .then((res) => {
          console.log("classroom detail: ", res);

          this.setState({
            loading: false,
            classroomData: res,
            nextCourse: res.nextCourse,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
        });
    } else {
      AccountApis.getGuideClassroomDetail(user.userId, classroomId)
        .then((res) => {
          console.log("classroom detail: ", res);

          this.setState({
            loading: false,
            classroomData: res,
            nextCourse: res.nextCourse,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
        });
    }
  };

  updateGuide(guide) {
    const { id: classroomId } = this.props.match.params;

    // const classrooms = _map(guide.classrooms, "classroomId");
    // console.log(classrooms.includes(classroomId));
    // if (!classrooms.includes(classroomId)) {
    //   classrooms.push(classroomId);
    // }
    // guide["classrooms"] = classrooms;
    Axios.put(
      `/academy/v2/accounts/${this.props.user.orgId}/guides/${guide.guideId}/classrooms/${classroomId}`,
      guide
    )
      .then((res) => {
        console.log("update classroom: ", res);
        this.getClassroomDetail(classroomId);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  updateClassroomStudents(list) {
    const { id: classroomId } = this.props.match.params;
    this.setState({
      loading: true,
    });
    CommonApis.addClassroomStudents(this.props.user.orgId, classroomId, list)
      .then(
        axios.spread((...responses) => {
          console.log("add classroom students res: ", responses);
          message.success("학생 추가 완료");
          this.setState({
            loading: false,
          });
          this.getClassroomDetail(classroomId);
        })
      )
      .catch((e) => {
        message.warning("학생 추가 실패");
        this.setState({
          loading: false,
        });
      });
  }

  componentDidUpdate(nextProps, prevState) {
    const { user, ClassroomActions } = this.props;
    const { id } = this.props.match.params;

    if (prevState.classroomData) {
      const { id } = this.props.match.params;
      const { id: nextId } = nextProps.match.params;
      if (id !== nextId) {
        this.getClassroomDetail(id);
      }
    }
    if (this.props.user && this.props.user.type !== nextProps.user.type) {
      console.log("update detail");
      ClassroomActions.getClassroomList(user.orgId || user.userId).then(
        (res) => {
          if (res && res.length > 0) {
            console.log("res", res[0]);
            if (id) {
              this.getClassroomDetail(id);
            } else {
              this.getClassroomDetail(res[0]["classroomId"]);
            }
          }
        }
      );
    }
  }

  render() {
    const { user, userPermission, pending, classroomList, courseSimpleList } =
      this.props;
    const { classroomData, enableDangerousFeature } = this.state;
    const buttonStyle = {
      marginBottom: 8,
    };
    const classroomdata = classroomData;
    console.log("classroom data: ", classroomdata);
    console.log("courseSimpleList: ", courseSimpleList);

    const isDeleted = classroomData && classroomData.state === "deleted";
    const locale = this.props.intl.locale;
    return (
      <Skeleton loading={this.state.loading || pending} active>
        {classroomData && classroomList && (
          <>
            <PageHeader
              title={
                <Title level={2} style={{ marginBottom: 0 }}>
                  <span>
                    {/* {`${this.context.className}: ${classroomData.name}`} */}
                    <FormattedMessage
                      id="admin.classroom-detail.title"
                      values={{ classroomName: classroomData.name }}
                    />
                  </span>
                  <font
                    style={{
                      marginRight: "15px",
                      marginLeft: "15px",
                      fontSize: "20px",
                      fontWeight: "400",
                      color: "#ff1111",
                    }}
                  >
                    <FormattedMessage
                      id="admin.classroom-detail.upcoming-course"
                      values={{
                        nextCourse: !this.state.isActiveInputField
                          ? classroomData.nextCourse
                            ? classroomData.nextCourse
                            : locale === "en"
                            ? "TBA"
                            : "미정"
                          : "",
                      }}
                    />
                    {classroomData.title}
                  </font>
                  {this.state.isActiveInputField ? (
                    <span>
                      <input
                        onChange={(e) => {
                          this.setState({ nextCourse: e.target.value });
                        }}
                        onClick={() => {
                          this.setState({ isActiveInputField: true });
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            this.setState({
                              isActiveInputField: false,
                            });
                            Axios.put(
                              `/academy/v2/accounts/${this.props.user.orgId}/classrooms/${classroomData.classroomId}/nextCourse?nextCourse=${e.target.value}`
                            ).then(
                              axios.spread((...responses) => {
                                console.log(
                                  "add classroom students res: ",
                                  responses
                                );
                                let msg = "코스 변경 완료";
                                if (locale === "en") {
                                  msg = "Course changed";
                                }
                                message.success(msg);
                                this.setState({
                                  loading: false,
                                });
                                this.getClassroomDetail(
                                  classroomData.classroomId
                                );
                              })
                            );
                          }
                        }}
                        defaultValue={this.state.nextCourse}
                      ></input>
                      <Button
                        onClick={(e) => {
                          this.setState({
                            isActiveInputField: false,
                          });
                          Axios.put(
                            `/academy/v2/accounts/${this.props.user.orgId}/classrooms/${classroomData.classroomId}/nextCourse?nextCourse=${this.state.nextCourse}`
                          ).then(
                            axios.spread((...responses) => {
                              console.log(
                                "add classroom students res: ",
                                responses
                              );
                              let msg = "코스 변경 완료";
                              if (locale === "en") {
                                msg = "Course changed";
                              }
                              message.success(msg);
                              this.setState({
                                loading: false,
                              });
                              this.getClassroomDetail(
                                classroomData.classroomId
                              );
                            })
                          );
                        }}
                      >
                        <FormattedMessage id="admin.classroom-detail.confirm" />
                      </Button>
                    </span>
                  ) : (
                    <Button
                      onClick={() => {
                        this.setState({
                          isActiveInputField: true,
                        });
                      }}
                    >
                      <FormattedMessage id="admin.classroom-detail.edit" />
                    </Button>
                  )}
                  {isDeleted ? (
                    <font color="red">
                      {" "}
                      &nbsp;{" "}
                      <FormattedMessage id="admin.classroom-detail.course-deleted" />
                    </font>
                  ) : classroomData.completedLectures ===
                    classroomData.totalLectures ? (
                    <font color="blue">
                      &nbsp;
                      <FormattedMessage id="admin.classroom-detail.course-terminated" />
                    </font>
                  ) : classroomData.state === "finish" ? (
                    <font color="blue">
                      {" "}
                      &nbsp;{" "}
                      <FormattedMessage id="admin.classroom-detail.course-force-terminated" />
                    </font>
                  ) : (
                    ""
                  )}

                  {/*<input></input>*/}
                </Title>
              }
              extra={[
                <span key="3">
                  {(userPermission === "master" ||
                    (userPermission && userPermission.classUpdate)) &&
                    enableDangerousFeature &&
                    (this.context.type === "school" ||
                      this.context.type === "campus") && (
                      <span>
                        <Button
                          style={{ marginRight: "15px" }}
                          type="primary"
                          onClick={() => {
                            this.setState({
                              showChangeClassroomDayPopup: true,
                            });
                          }}
                        >
                          <FormattedMessage id="admin.classroom-detail.change-class-schedule" />
                        </Button>
                        <Button
                          type="primary"
                          onClick={() =>
                            this.setState({
                              showChangeClassroomNamePopup: true,
                            })
                          }
                        >
                          <FormattedMessage id="admin.classroom-detail.change-class-name" />
                          <Icon type="edit" />
                        </Button>
                      </span>
                    )}
                  {this.state.showChangeClassroomDayPopup && (
                    <ChangeClassroomDayPopup
                      title={
                        <FormattedMessage id="admin.classroom-detail.change-class-schedule" />
                      }
                      visible={this.state.showChangeClassroomDayPopup === true}
                      maskClosable={false}
                      scheduleList={classroomdata}
                      onSelect={(data) => {
                        if (data != null) {
                          let daylist = data;
                          console.log(daylist);
                          AccountApis.changeClassroomSchedule(
                            this.props.user.orgId,
                            classroomData.classroomId,
                            {
                              days: daylist,
                            }
                          );
                        }
                        this.setState({
                          showChangeClassroomDayPopup: false,
                        });
                      }}
                      onCancel={() => {
                        this.setState({
                          showChangeClassroomDayPopup: false,
                        });
                      }}
                    />
                  )}
                  {this.state.showChangeClassroomNamePopup && (
                    <ChangeClassroomNamePopup
                      title={
                        <FormattedMessage id="admin.classroom-detail.change-class-name" />
                      }
                      visible={this.state.showChangeClassroomNamePopup === true}
                      maskClosable={false}
                      value={classroomData.name}
                      onSelect={(data) => {
                        console.log(data);
                        if (data) {
                          this.setState({
                            loading: true,
                          });
                          AccountApis.changeClassroomName(
                            user.orgId,
                            classroomData.classroomId,
                            data
                          )
                            .then((res) => {
                              let msg = "변경 완료";
                              if (locale === "en") {
                                msg = "Name changed";
                              }
                              message.success(msg);
                              console.log("change classroom name done: ", res);
                              this.getClassroomDetail(res["classroomId"]);
                              this.props.ClassroomActions.getClassroomList(
                                user.orgId
                              );
                            })
                            .catch((e) => {
                              let msg = "변경 실패";
                              if (locale === "en") {
                                msg = "Failed to change";
                              }
                              message.error(msg);
                              this.setState({
                                loading: false,
                              });
                            });
                        }
                        this.setState({
                          showChangeClassroomNamePopup: false,
                        });
                      }}
                      onCancel={() => {
                        this.setState({
                          showChangeClassroomNamePopup: false,
                        });
                      }}
                    />
                  )}
                </span>,

                <span key="2">
                  {!isDeleted &&
                    (userPermission === "master" ||
                      (userPermission && userPermission.classUpdate)) &&
                    classroomData.completedLectures ===
                      classroomData.totalLectures && (
                      <Button
                        type="primary"
                        onClick={() =>
                          this.setState({ showAddCoursePopup: true })
                        }
                      >
                        <FormattedMessage id="admin.classroom-detail.add-class" />
                        <Icon type="plus-circle" />
                      </Button>
                    )}
                  {this.state.showAddCoursePopup && (
                    <AddCoursePopup
                      title={
                        <FormattedMessage id="admin.classroom-detail.add-class" />
                      }
                      visible={this.state.showAddCoursePopup === true}
                      maskClosable={false}
                      onSelect={(data) => {
                        console.log(data);
                        if (data) {
                          this.setState({
                            loading: true,
                          });
                          AccountApis.addClassroomCourse(
                            user.orgId,
                            classroomData.classroomId,
                            data
                          )
                            .then((res) => {
                              message.success("추가 완료");
                              console.log("add classroomcouse done: ", res);
                              this.getClassroomDetail(res["classroomId"]);
                            })
                            .catch((e) => {
                              message.error("추가 실패");
                              this.setState({
                                loading: false,
                              });
                            });
                        }
                        this.setState({
                          showAddCoursePopup: false,
                        });
                      }}
                      onCancel={() => {
                        this.setState({
                          showAddCoursePopup: false,
                        });
                      }}
                      courseSimpleList={this.props.courseSimpleList}
                    />
                  )}
                </span>,

                <Popconfirm
                  key="1"
                  // title={`${this.context.className} (${classroomData.name})를 강제 종료 하시겠습니까?`}
                  title={
                    <FormattedMessage
                      id="admin.classroom-detail.force-quit-class-desc"
                      values={{
                        class: classroomData.name,
                      }}
                    />
                  }
                  onConfirm={() => {
                    AccountApis.finishClassroom(
                      user.orgId,
                      classroomData.classroomId
                    )
                      .then((res) => {
                        message.success(
                          locale === "en"
                            ? "successfully Force Quit Class "
                            : "강제 종료 완료"
                        );
                        this.getClassroomDetail(classroomData.classroomId);
                      })
                      .catch((e) => {
                        message.error(
                          locale === "en" ? "Failed" : "강제 종료 실패"
                        );
                      });
                  }}
                  onCancel={() =>
                    message.warning(
                      locale === "en" ? "Canceled" : "강제 종료 취소"
                    )
                  }
                  okText={
                    <FormattedMessage id="admin.classroom-detail.confirm" />
                  }
                  placement="bottomRight"
                  cancelText={
                    <FormattedMessage id="admin.classroom-detail.cancel" />
                  }
                >
                  {!isDeleted &&
                    enableDangerousFeature &&
                    (userPermission === "master" ||
                      (userPermission && userPermission.classUpdate)) &&
                    classroomData.state !== "finish" &&
                    classroomData.completedLectures !==
                      classroomData.totalLectures && (
                      <Button type="danger">
                        {/* {`${this.context.className} 강제 종료`} */}
                        <FormattedMessage id="admin.classroom-detail.class-force-quit" />
                        <Icon type="minus-circle" />
                      </Button>
                    )}
                </Popconfirm>,

                <Popconfirm
                  key="1"
                  title={`${this.context.className} (${classroomData.name})를 강제 종료를 취소 하시겠습니까?`}
                  onConfirm={() => {
                    AccountApis.changeClassroomState(
                      user.orgId,
                      classroomData.classroomId,
                      "current"
                    )
                      .then((res) => {
                        message.success("강제 종료 취소 완료");
                        this.getClassroomDetail(classroomData.classroomId);
                      })
                      .catch((e) => {
                        message.error("강제 종료 취소 실패");
                      });
                  }}
                  onCancel={() => message.warning("강제 종료 취소 취소")}
                  okText="확인"
                  placement="bottomRight"
                  cancelText="취소"
                >
                  {!isDeleted &&
                    enableDangerousFeature &&
                    (userPermission === "master" ||
                      (userPermission && userPermission.classUpdate)) &&
                    classroomData.state === "finish" &&
                    classroomData.completedLectures !==
                      classroomData.totalLectures && (
                      <Button type="danger">
                        {`${this.context.className} 강제 종료 취소`}
                        <Icon type="minus-circle" />
                      </Button>
                    )}
                </Popconfirm>,

                <Popconfirm
                  key="4"
                  // title={`${this.context.className} (${classroomData.name})를 초기화 하시겠습니까?  **주의** 모든 수업 및 연관 기록이 삭제됩니다.`}
                  title={
                    <FormattedMessage
                      id="admin.classroom-detail.init-class-desc"
                      values={{
                        class: classroomData.name,
                      }}
                    />
                  }
                  onConfirm={() => {
                    this.setState({
                      loading: true,
                    });
                    AccountApis.initClassroom(
                      user.orgId,
                      classroomData.classroomId
                    )
                      .then((res) => {
                        let msg = "초기화 완료";
                        if (locale === "en") {
                          msg = "successfully initialized";
                        }
                        message.success(msg);
                        this.setState({
                          loading: false,
                        });
                        this.getClassroomDetail(classroomData.classroomId);
                      })
                      .catch((e) => {
                        let msg = "초기화 실패";
                        if (locale === "en") {
                          msg = "Failed";
                        }
                        message.error(msg);
                        this.setState({
                          loading: false,
                        });
                      });
                  }}
                  onCancel={() =>
                    message.warning(locale === "en" ? "Canceld" : "초기화 취소")
                  }
                  okText={
                    <FormattedMessage id="admin.classroom-detail.confirm" />
                  }
                  placement="bottomRight"
                  cancelText={
                    <FormattedMessage id="admin.classroom-detail.cancel" />
                  }
                >
                  {!isDeleted &&
                    enableDangerousFeature &&
                    (userPermission === "master" ||
                      (userPermission && userPermission.classCreateDelete)) && (
                      <Button type="danger">
                        <FormattedMessage id="admin.classroom-detail.reset-class" />
                        <Icon type="warning" />
                      </Button>
                    )}
                </Popconfirm>,

                <Popconfirm
                  key="5"
                  // title={`${this.context.className} (${classroomData.name})를 삭제 하시겠습니까?`}
                  title={
                    <FormattedMessage
                      id="admin.classroom-detail.delete-class-desc"
                      values={{
                        class: classroomData.name,
                      }}
                    />
                  }
                  onConfirm={() => {
                    this.setState({
                      loading: true,
                    });
                    AccountApis.changeClassroomState(
                      user.orgId,
                      classroomData.classroomId,
                      "deleted"
                    )
                      .then((res) => {
                        let msg = "삭제 완료";
                        if (locale === "en") {
                          msg = "successfully deleted";
                        }
                        message.success(msg);
                        this.setState({
                          loading: false,
                        });
                        this.getClassroomDetail(classroomData.classroomId);
                      })
                      .catch((e) => {
                        let msg = "삭제 실패";
                        if (locale === "en") {
                          msg = "Failed";
                        }

                        message.error(msg);
                        this.setState({
                          loading: false,
                        });
                      });
                  }}
                  onCancel={() =>
                    message.warning(locale === "en" ? "Canceled" : "삭제 취소")
                  }
                  okText={
                    <FormattedMessage id="admin.classroom-detail.confirm" />
                  }
                  placement="bottomRight"
                  cancelText={
                    <FormattedMessage id="admin.classroom-detail.cancel" />
                  }
                >
                  {!isDeleted &&
                    enableDangerousFeature &&
                    (userPermission === "master" ||
                      (userPermission && userPermission.classCreateDelete)) && (
                      <Button type="danger">
                        <FormattedMessage id="admin.classroom-detail.delete-class" />
                        <Icon type="delete" />
                      </Button>
                    )}
                </Popconfirm>,

                <Popconfirm
                  key="6"
                  title={`${this.context.className} (${classroomData.name})를 삭제 취소 하시겠습니까?`}
                  onConfirm={() => {
                    this.setState({
                      loading: true,
                    });
                    AccountApis.changeClassroomState(
                      user.orgId,
                      classroomData.classroomId,
                      "current"
                    )
                      .then((res) => {
                        message.success("삭제 취소  완료");
                        this.setState({
                          loading: false,
                        });
                        this.getClassroomDetail(classroomData.classroomId);
                      })
                      .catch((e) => {
                        message.error("삭제 취소 실패");
                        this.setState({
                          loading: false,
                        });
                      });
                  }}
                  onCancel={() => message.warning("삭제 취소하지 않음")}
                  okText="확인"
                  placement="bottomRight"
                  cancelText="취소"
                >
                  {isDeleted &&
                    enableDangerousFeature &&
                    (userPermission === "master" ||
                      (userPermission && userPermission.classCreateDelete)) && (
                      <Button type="danger">
                        {`${this.context.className} 삭제 취소`}
                        <Icon type="undo" />
                      </Button>
                    )}
                </Popconfirm>,

                <span key="4" align="right">
                  {(userPermission === "master" ||
                    (userPermission &&
                      (userPermission.lectureStart ||
                        userPermission.classCreateDelete))) && (
                    <Checkbox
                      defaultChecked={enableDangerousFeature}
                      onClick={() => {
                        this.setState({
                          enableDangerousFeature: !enableDangerousFeature,
                        });
                        this.getClassroomDetail(classroomData.classroomId);
                      }}
                    >
                      <FormattedMessage id="admin.classroom-detail.management" />
                    </Checkbox>
                  )}
                </span>,
              ]}
            >
              <Row type="flex">
                <span style={{ fontSize: "16px", fontWeight: "300" }}>
                  <FormattedMessage
                    id="admin.classroom-detail.summary"
                    values={{
                      current: classroomData.completedLectures,
                      total: classroomData.totalLectures,
                      status:
                        classroomData.completedLectures <
                        classroomData.totolLectures
                          ? locale === "en"
                            ? ""
                            : "진행"
                          : locale === "en"
                          ? "Completed"
                          : "완료",
                      studentCount: classroomData.studentsCount,
                    }}
                  />
                  {classroomData.guide ? (
                    <Link
                      to={`/admin/guide/detail/${classroomData.guide.guideId}`}
                    >
                      {`${classroomData.guide && classroomData.guide.name}`}
                    </Link>
                  ) : (
                    <font color="red">
                      <FormattedMessage id="admin.classroom-detail.summary-none" />
                    </font>
                  )}
                </span>
              </Row>
            </PageHeader>
            <div className="admin-page-content">
              <Row gutter={24} style={{ padding: 24 }}>
                <Col span={4}>
                  <Title level={4}>
                    {/* {`${classroomData.course.title} 전체 ${this.context.className} 목록`} */}
                    {classroomData.course.title}
                    <FormattedMessage id="admin.classroom-detail.total-class-list" />
                    <Tag color="blue">
                      <FormattedMessage
                        id="admin.classroom-detail.total-class-count"
                        values={{
                          count: filter(classroomList, function (c) {
                            return (
                              c.course.courseId ===
                              classroomData.course.courseId
                            );
                          }).length,
                        }}
                      />
                    </Tag>
                  </Title>
                  <Divider />
                  <List
                    header={
                      <Text strong>
                        <FormattedMessage id="admin.classroom-detail.running-classes" />
                      </Text>
                    }
                    dataSource={filter(classroomList, function (c) {
                      return (
                        c.state !== "deleted" &&
                        c.course.courseId === classroomData.course.courseId &&
                        c.completedLectures !== c.totalLectures &&
                        c.state !== "finish"
                      );
                    })}
                    renderItem={(item) => (
                      <List.Item>
                        <Link to={`/admin/class/detail/${item.classroomId}`}>
                          {item.classroomId === classroomData.classroomId && (
                            <Icon type="caret-right" />
                          )}
                          <Text
                            strong={
                              item.classroomId === classroomData.classroomId
                            }
                          >
                            {item.name}
                          </Text>
                        </Link>
                      </List.Item>
                    )}
                  />
                  <List
                    // locale={{ emptyText: "xxx" }}
                    header={
                      <Text strong>
                        <FormattedMessage id="admin.classroom-detail.terminated-classes" />
                      </Text>
                    }
                    dataSource={filter(classroomList, function (c) {
                      return (
                        c.state !== "deleted" &&
                        c.course.courseId === classroomData.course.courseId &&
                        c.completedLectures === c.totalLectures &&
                        c.state !== "finish"
                      );
                    })}
                    renderItem={(item) => (
                      <List.Item>
                        <Link to={`/admin/class/detail/${item.classroomId}`}>
                          {item.classroomId === classroomData.classroomId && (
                            <Icon type="caret-right" />
                          )}
                          <Text
                            strong={
                              item.classroomId === classroomData.classroomId
                            }
                          >
                            {item.name}
                          </Text>
                        </Link>
                      </List.Item>
                    )}
                  />
                </Col>
                <Col span={20}>
                  <div>
                    <Row type="flex" justify="space-between">
                      <Title level={4}>
                        <FormattedMessage id="admin.classroom-detail.class-management" />
                      </Title>
                      <div>
                        {(userPermission === "master" ||
                          (userPermission && userPermission.studentUpdate)) && (
                          <Button
                            style={{ marginRight: 8 }}
                            loading={this.state.loadingList}
                            onClick={() => {
                              this.setState({
                                loadingList: true,
                              });
                              this.props.StudentActions.getStudentList(
                                classroomData.organization.orgId,
                                {
                                  withDischargedStudent: false,
                                }
                              )
                                .then((res) => {
                                  this.setState({
                                    showChangeStudentListPopup: true,
                                    loadingList: false,
                                  });
                                })
                                .catch((e) => {});
                            }}
                          >
                            {this.state.loadingList ? (
                              "Loading..."
                            ) : (
                              <>
                                <Icon type="usergroup-add" />
                                <FormattedMessage id="admin.classroom-detail.add-student" />
                              </>
                            )}
                          </Button>
                        )}
                        {this.state.showChangeStudentListPopup && (
                          <ChangeClassroomStudentListPopup
                            title={
                              <FormattedMessage id="admin.classroom-detail.add-student" />
                            }
                            visible={
                              this.state.showChangeStudentListPopup === true
                            }
                            maskClosable={false}
                            onSelect={(list) => {
                              console.log(list);
                              if (list && list.length > 0) {
                                this.updateClassroomStudents(list);
                              }
                              this.setState({
                                showChangeStudentListPopup: false,
                              });
                            }}
                            onCancel={() => {
                              this.setState({
                                showChangeStudentListPopup: false,
                              });
                            }}
                            classroomData={classroomData}
                            studentList={this.props.studentList}
                          />
                        )}
                        {(userPermission === "master" ||
                          (userPermission && userPermission.classUpdate)) && (
                          <Button
                            style={{ marginRight: 8 }}
                            onClick={() => {
                              if (
                                !this.props.guideList ||
                                this.props.guideList.length === 0
                              ) {
                                this.props.GuideActions.getGuideList(
                                  this.props.user.orgId
                                )
                                  .then((res) => {
                                    this.setState({
                                      showChangeGuidePopup: true,
                                    });
                                  })
                                  .catch((e) => {});
                              } else {
                                this.setState({
                                  showChangeGuidePopup: true,
                                });
                              }
                            }}
                          >
                            <Icon type="user" />
                            {/* {`${this.context.className} ${this.context.guideName}변경`} */}
                            <FormattedMessage id="admin.classroom-detail.change-teacher" />
                          </Button>
                        )}
                        <ChangeGuidePopup
                          title={
                            <FormattedMessage id="admin.classroom-detail.change-teacher" />
                          }
                          visible={this.state.showChangeGuidePopup === true}
                          maskClosable={false}
                          onSelect={(guide) => {
                            console.log(guide);
                            if (guide) {
                              this.updateGuide(guide);
                            }
                            this.setState({
                              showChangeGuidePopup: false,
                            });
                          }}
                          onCancel={() => {
                            this.setState({
                              showChangeGuidePopup: false,
                            });
                          }}
                          classroomData={classroomData}
                          guideList={this.props.guideList}
                        />
                        <Button>
                          <Link
                            to={`/admin/class/detail/${classroomData.classroomId}/lectures`}
                          >
                            <Icon type="calendar" />
                            <FormattedMessage id="admin.classroom-detail.view-all-class" />
                          </Link>
                        </Button>
                      </div>
                    </Row>
                    {classroomData && (
                      <ClassroomLectureList
                        onUpdate={this.getClassroomDetail}
                        user={user}
                        type="current"
                        classroomId={classroomData.classroomId}
                        userPermission={userPermission}
                        onUpdateLatestLectureId={(id) =>
                          this.setState({ latestLectureId: id })
                        }
                        onUpdateClassroom={() =>
                          this.getClassroomDetail(classroomData.classroomId)
                        }
                        enableDangerousFeature={enableDangerousFeature}
                        locale={locale}
                      />
                    )}
                    {classroomData && (
                      <ClassroomStudentList
                        classroomData={classroomData}
                        classroomId={classroomData.classroomId}
                        orgId={user.orgId || user.userId}
                        latestLectureId={this.state.latestLectureId}
                        locale={locale}
                        // students={classroomData.students}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(
    ({ auth, classroom, courseSimple, guide, student }) => ({
      pending:
        auth.pending ||
        classroom.pending ||
        courseSimple.pending ||
        guide.pending,
      auth: auth,
      user: auth.user,
      logged: auth.logged,
      userPermission:
        auth.user.type === "org" ? "master" : auth.user.permission,
      classroomList: classroom.classroomList,
      courseSimpleList: courseSimple.courseSimpleList,
      guideList: guide.guideList,
      studentList: student.studentList,
    }),
    (dispatch) => ({
      ClassroomActions: bindActionCreators(classroomActions, dispatch),
      CourseSimpleActions: bindActionCreators(courseSimpleActions, dispatch),
      GuideActions: bindActionCreators(guideActions, dispatch),
      StudentActions: bindActionCreators(studentActions, dispatch),
    })
  )(injectIntl(ClassroomDetailPage))
);
