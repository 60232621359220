import React, { Component } from "react";
import { Modal, Typography, Select, Row, Col, Table, Input } from "antd";
import { filter } from "lodash";
import { FormattedMessage } from "react-intl";

export default class ResetStudentPasswordPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "smcho12345!",
    };
  }

  handleOk = () => {
    if (this.props.orgType === "campus") {
      this.props.handleOk();
    } else {
      this.props.handleOk(this.state.password);
    }
  };

  render() {
    const { studentData, orgType } = this.props;
    console.log(studentData);
    console.log(orgType);
    return (
      <Modal onOk={this.handleOk} {...this.props}>
        {orgType === "campus" && (
          <div>
            <Typography.Text strong>
              {/* {`${studentData.name}(${studentData.account}) 학생의 비밀번호를 기본값(생년월일)으로
            초기화하시겠습니까?`} */}
              <FormattedMessage
                id="admin.reset-student-password-popup-desc"
                values={{
                  name: studentData.name,
                  account: studentData.account,
                }}
              />
            </Typography.Text>
          </div>
        )}

        <Table dataSource={[studentData]} rowKey="studentId" pagination={false}>
          <Table.Column
            title={<FormattedMessage id="admin.reset-student-password-popup-student-info" />}
            dataIndex="name"
            key="name"
            render={(text, record) => (
              <span>{`${text}(${record.account})`}</span>
            )}
          />
          <Table.Column
            title={<FormattedMessage id="admin.reset-student-password-popup-default-password" />}
            dataIndex="birthday"
            key="beforeClassroomName"
            render={(text) =>
              orgType === "campus" ? (
                <span>{`${text.year}${
                  text.month < 10 ? "0" + text.month : text.month
                }${text.day < 10 ? "0" + text.day : text.day}`}</span>
              ) : (
                <Input
                  type="text"
                  value={this.state.password}
                  onChange={(e) => {
                    this.setState({ password: e.target.value.trim() });
                  }}
                />
              )
            }
          />
        </Table>
      </Modal>
    );
  }
}
