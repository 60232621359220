import React, { Component } from "react";
import { connect } from "react-redux";
import NoticeApis from "../../../../utils/noticeApis";
import {
  Skeleton,
  PageHeader,
  Typography,
  Button,
  Icon,
  Row,
  Select,
  Input,
  Table, Tag
} from "antd";
import { Link, withRouter } from "react-router-dom";
import { getKoreanDate } from "../../../../utils/utils";
import NewsletterApis from "../../../../utils/newsletterApis";
import { FormattedMessage } from "react-intl";

const count = 10;
class NewsletterListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      noticeList: [],
      searchType: "all",
      page: 0,
      totalPages: 1
    };
  }

  componentDidMount() {
    this.getNoticeList();
  }

  getNoticeList = (page = 0) => {
    NewsletterApis.getNewsletterList(page, count)
      .then(res => {
        console.log("notice list: ", res);
        this.setState({
          noticeList: res.list,
          page: res.page,
          totalPages: res.totalPages
        });
      })
      .catch(e => {
        console.log("notice list error: ", e);
      });
  };

  onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    this.setState(
      {
        page: pagination.current - 1
      },
      () => {
        this.getNoticeList(pagination.current - 1);
      }
    );
  };

  render() {
    const { noticeList, isLoading, page, totalPages } = this.state;
    const { user, userPermission } = this.props;

    return (
      <Skeleton loading={isLoading} active>
        <PageHeader
          title={
            <Typography.Title level={2} style={{ marginBottom: 0 }}>
              <FormattedMessage id="admin.newsletter.title" />
            </Typography.Title>
          }
          extra={
            userPermission === "master"
              ? [
                <Button
                  key="1"
                  type="primary"
                  onClick={() => this.props.history.push("/admin/newsletter/add")}
                >
                  <FormattedMessage id="admin.newsletter.add-newsletter" />
                  <Icon type="plus-circle" />
                </Button>
              ]
              : null
          }
        />
        <div className="admin-page-content">
          <Row
            type="flex"
            justify="end"
            style={{ marginBottom: 28, display: "none" }}
          >
            <Select
              defaultValue="all"
              value={this.state.searchType}
              onChange={value =>
                this.setState({
                  searchType: value
                })
              }
            >
              <Select.Option value="all">제목+내용</Select.Option>
              <Select.Option value="title">제목만</Select.Option>
              <Select.Option value="content">내용만</Select.Option>
            </Select>
            <Input.Search
              style={{ width: 200, marginLeft: 8 }}
            />
          </Row>
          <Table
            rowKey="noticeId"
            dataSource={noticeList}
            columns={[
              {
                title: <FormattedMessage id="admin.newsletter.col-seq" />,
                key: "index",
                render: (text, record, index) => (
                  <span>{record.newsletterId}</span>
                )
              },
              {
                title: <FormattedMessage id="admin.newsletter.col-id" />,
                key: "newsletterId",
                render: (text, record, index) => (
                  <span>{(record.newsletterId * 13875)^1319761}</span>
                )
              },
              {
                title: <FormattedMessage id="admin.newsletter.col-title" />,
                key: "title",
                dataIndex: "title",
                render: (text,record) => <Link
                  to={`/admin/newsletter/detail/${record.newsletterId}`}
                  style={{ textDecoration: "underline" }}
                >
                  <span>{text}</span>
                </Link>

              },
              {
                title: <FormattedMessage id="admin.newsletter.col-date" />,
                key: "createdTime",
                dataIndex: "createdTime",
                render: text => <span>{getKoreanDate(text, true)}</span>
              }
            ]}

            pagination={{
              defaultPageSize: count,
              total: totalPages * count
            }}
            onChange={this.onChange}
          />
        </div>
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      pending: auth.pending,
      auth: auth,
      user: auth.user,
      logged: auth.logged,
      userPermission: auth.user.type === "org" ? "master" : auth.user.permission
    }),
    null
  )(NewsletterListPage)
);
