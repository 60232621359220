import classNames from "classnames";
import update from "immutability-helper";
import noScroll from "no-scroll";
import React, { Component } from "react";
import { isEdge, isIE } from "react-device-detect";
import NoteChk from "./NoteChk";
import "./Note.scss";
import "./NoteExtra.scss";
import { FormattedMessage } from "react-intl";

const colorNames = {
  black: "#000",
  white: "#fff",
  yellow: "#FFA820",
  alert: "#ff5a5a",
  blue: "#55A3FF",
};
const defaultState = {
  lines: [],
  currentLine: [],
  color: "#000",
  width: 3,
  drawing: false,
  eraser: false,
  showPens: true,
  currentPen: "black",
};

const usePercent = false;

class Note extends Component {
  state = defaultState;

  componentDidMount() {
    this.resizeCanvas();

    window.addEventListener("resize", this.resizeCanvas);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeCanvas);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.resizeCanvas();
      this.drawLines();
      noScroll.on();
    } else if (prevProps.isOpen && !this.props.isOpen) {
      noScroll.off();
    }
    if (this.props.viewType !== "guide" && this.props.remoteNote !== null) {
      if (prevProps.remoteNote !== this.props.remoteNote) {
        if (
          this.props.remoteNote.currentLine.length === 0 &&
          this.props.remoteNote.lines.length === 0
        ) {
          this.resetCanvas();
        } else {
          this.setState(
            {
              currentLine: this.props.remoteNote.currentLine,
              color: this.props.remoteNote.color,
              // width: this.props.remoteNote.width,
              // drawing: this.props.remoteNote.drawing,
              // eraser: this.props.remoteNote.eraser,
              // showPens: this.props.remoteNote.showPens,
              // currentPen: this.props.remoteNote.currentPen,
            },
            this.end
          );
        }
      }
      if (
        prevProps.removeLineId !== this.props.removeLineId &&
        this.props.removeLineId !== null
      ) {
        const _lines = this.state.lines.filter(
          (l) =>
            Number.parseInt(l.id) !== Number.parseInt(this.props.removeLineId)
        );
        this.setState({ lines: _lines }, this.drawLines);
      }
    }
  }

  resizeCanvas = () => {
    const { canvas, buffer } = this.refs;
    canvas.width = canvas ? canvas.scrollWidth : 0;
    canvas.height = canvas ? canvas.scrollHeight : 0;

    buffer.width = canvas.width;
    buffer.height = canvas.height;

    if (canvas.width > 0 && canvas.height > 0) {
      this.drawLines();
    }
  };

  start = (x, y) => {
    this.setState({
      drawing: true,
      currentLine: [[x, y]],
    });
  };

  move = (x, y) => {
    const { eraser } = this.state;
    if (eraser && !isIE && !isEdge) {
      const { canvas } = this.refs;
      const { lines } = this.state;
      const ctx = canvas.getContext("2d");

      const _x = usePercent ? this.pctToPxHor(x) : x;
      const _y = usePercent ? this.pctToPxVert(y) : y;

      const erased = lines.filter((l) => !ctx.isPointInPath(l.path, _x, _y));
      const erasedLine = lines.filter((l) => ctx.isPointInPath(l.path, _x, _y));

      console.log("erased: ", erased);
      console.log("erasedLine: ", erasedLine);
      if (
        erasedLine &&
        erasedLine.length > 0 &&
        this.props.viewType === "guide"
      ) {
        const _data = {
          type: "removeLine",
          value: {
            removeId: erasedLine[0].id,
          },
        };
        this.props.onSendMessage(_data);
      }
      this.setState(
        {
          lines: erased,
        },
        this.drawLines
      );
    }

    this.setState(
      {
        currentLine: update(this.state.currentLine, {
          $push: [[x, y]],
        }),
      },
      this.drawLines
    );
  };

  end = () => {
    const { currentLine, eraser } = this.state;

    if (this.props.viewType === "guide" && !eraser) {
      const _data = {
        type: "drawNote",
        value: {
          ...this.state,
        },
      };
      this.props.onSendMessage(_data);
    }
    if (currentLine.length <= 1) {
      this.setState({
        drawing: false,
        currentLine: [],
      });
      return;
    }

    if (!eraser || isIE || isEdge) {
      const path = new Path2D();
      if (usePercent) {
        path.moveTo(
          this.pctToPxHor(currentLine[0][0]),
          this.pctToPxVert(currentLine[0][1])
        );
      } else {
        path.moveTo(currentLine[0][0], currentLine[0][1]);
      }
      for (var i = 1; i < currentLine.length; i++) {
        if (usePercent) {
          path.lineTo(
            this.pctToPxHor(currentLine[i][0]),
            this.pctToPxVert(currentLine[i][1])
          );
        } else {
          path.lineTo(currentLine[i][0], currentLine[i][1]);
        }
      }

      let _id = 0;
      if (this.state.lines.length > 0) {
        const lastLine = this.state.lines[this.state.lines.length - 1];
        _id = lastLine.id + 1;
      }
      this.setState(
        {
          drawing: false,
          currentLine: [],
          lines: update(this.state.lines, {
            $push: [
              {
                id: _id,
                color: this.state.color,
                width: this.state.width,
                path: path,
                eraser: eraser,
              },
            ],
          }),
        },
        this.drawLines
      );
    } else {
      this.setState(
        {
          drawing: false,
          currentLine: [],
          // eraser: false, //TODO ?
        },
        this.drawLines
      );
    }
  };

  handleTouchStart = (e) => {
    if (this.state.drawing) {
      return;
    }

    e.stopPropagation();

    const touch = e.touches[0];

    if (usePercent) {
      this.start(
        this.pxToPctHor(touch.clientX),
        this.pxToPctVert(touch.clientY)
      );
    } else {
      // const { canvas } = this.refs;
      // const clientRect = canvas.getBoundingClientRect();
      // let x = touch.clientX - clientRect.x;
      // let y = touch.clientY - clientRect.y;
      // this.start(x, y);
    }
    // this.start(touch.clientX, touch.clientY);
  };

  handleTouchMove = (e) => {
    if (!this.state.drawing) {
      return;
    }

    e.preventDefault();

    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.changedTouches[0];
      if (usePercent) {
        this.move(
          this.pxToPctHor(touch.clientX),
          this.pxToPctVert(touch.clientY)
        );
      } else {
        const { canvas } = this.refs;
        const clientRect = canvas.getBoundingClientRect();
        let x = touch.clientX - clientRect.x;
        let y = touch.clientY - clientRect.y;
        this.move(x, y);

        // this.move(touch.clientX, touch.clientY);
      }
    }
  };

  handleTouchEnd = (e) => {
    if (!this.state.drawing) {
      return;
    }

    e.preventDefault();

    this.end();
  };

  handleMouseDown = (e) => {
    if (this.state.drawing) {
      return;
    }

    e.stopPropagation();
    if (usePercent) {
      this.start(this.pxToPctHor(e.clientX), this.pxToPctVert(e.clientY));
    } else {
      const { canvas } = this.refs;
      const clientRect = canvas.getBoundingClientRect();
      let x = e.clientX - clientRect.x;
      let y = e.clientY - clientRect.y;
      this.start(x, y);
      // this.start(e.clientX, e.clientY);
    }
  };

  handleMouseMove = (e) => {
    if (!this.state.drawing) {
      return;
    }

    e.stopPropagation();
    if (usePercent) {
      this.move(this.pxToPctHor(e.clientX), this.pxToPctVert(e.clientY));
    } else {
      const { canvas } = this.refs;
      const clientRect = canvas.getBoundingClientRect();
      let x = e.clientX - clientRect.x;
      let y = e.clientY - clientRect.y;
      this.move(x, y);

      // this.move(e.clientX, e.clientY);
    }
  };

  handleMouseUp = (e) => {
    if (!this.state.drawing) {
      return;
    }

    e.stopPropagation();

    this.end();
  };

  drawLines = () => {
    const { canvas, buffer } = this.refs;
    const { lines, currentLine, color, width, eraser } = this.state;

    const ctx = buffer.getContext("2d");
    ctx.clearRect(0, 0, buffer.width, buffer.height);

    lines.forEach((line) => {
      const { color, width, path } = line;

      if (line.eraser === true) {
        ctx.globalCompositeOperation = "destination-out";
        ctx.strokeStyle = "rgba(0,0,0,1)";
        ctx.lineWidth = 10;
      } else {
        ctx.globalCompositeOperation = "source-over";
        ctx.strokeStyle = color;
        ctx.lineWidth = width;
      }

      ctx.stroke(path);
    });

    ctx.globalCompositeOperation = "source-over";
    ctx.strokeStyle = color;
    ctx.lineWidth = width;

    if (currentLine.length > 0) {
      if (!eraser || isIE || isEdge) {
        if (eraser) {
          ctx.globalCompositeOperation = "destination-out";
          ctx.strokeStyle = "rgba(0,0,0,1)";
          ctx.lineWidth = 16;
        }
        ctx.beginPath();
        currentLine.forEach((p, i) => {
          if (i === 0) {
            if (usePercent) {
              ctx.moveTo(this.pctToPxHor(p[0]), this.pctToPxVert(p[1]));
            } else {
              ctx.moveTo(p[0], p[1]);
            }
          } else {
            if (usePercent) {
              ctx.lineTo(this.pctToPxHor(p[0]), this.pctToPxVert(p[1]));
            } else {
              ctx.lineTo(p[0], p[1]);
            }
          }
        });
        ctx.stroke();
      }
    }

    const fCtx = canvas.getContext("2d");
    fCtx.clearRect(0, 0, canvas.width, canvas.height);
    fCtx.drawImage(buffer, 0, 0);
  };

  changeColor = (e, colorName) => {
    e.stopPropagation();
    let color = colorNames[colorName];
    this.setState({
      eraser: false,
      color: color,
      currentPen: colorName,
    });
  };

  togglePens = () => {
    let color = colorNames["black"];
    this.setState({
      eraser: false,
      color: color,
      currentPen: "black",
      // showPens: !this.state.showPens
    });
  };

  resetCanvas = () => {
    const { canvas, buffer } = this.refs;

    const ctx = buffer.getContext("2d");
    const fCtx = canvas.getContext("2d");
    ctx.clearRect(0, 0, buffer.width, buffer.height);
    fCtx.clearRect(0, 0, canvas.width, canvas.height);

    this.setState(
      {
        lines: [],
        currentLine: [],
      },
      () => {
        if (this.props.viewType === "guide") {
          const _data = {
            type: "drawNote",
            value: {
              ...this.state,
            },
          };
          this.props.onSendMessage(_data);
        }
      }
    );
  };

  useErase = () => {
    this.setState({
      // showPens: false,
      eraser: true,
      currentPen: "erase",
    });
  };

  changeWidth = (width) => {
    this.setState({
      width: width,
    });
  };
  getWidthDivStyle = (width) => {
    const style = {
      width: "100px",
      height: "15px",
      borderBottom: width + "px solid",
      marginBottom: "15px",
    };
    return style;
  };

  pctToPxHor(intPct) {
    const { canvas } = this.refs;
    return (intPct / 100) * canvas.width;
  }

  pctToPxVert(intPct) {
    const { canvas } = this.refs;
    return (intPct / 100) * canvas.height;
  }

  pxToPctHor(intPx) {
    const { canvas } = this.refs;
    return (intPx / canvas.width) * 100;
  }

  pxToPctVert(intPx) {
    const { canvas } = this.refs;
    return (intPx / canvas.height) * 100;
  }

  render() {
    const { eraser, currentPen } = this.state;
    console.log("lines: ", this.state.lines);
    console.log("current lines: ", this.state.currentLine);
    const penClasses = classNames(
      "c-notepad__list-pen",
      this.state.showPens ? "is-select" : "",
      "is-" + this.state.currentPen
    );
    return (
      <div className={"c-notepad " + (this.props.isOpen ? " is-open" : "")}>
        <div
          className={classNames("c-notepad-canvas", {
            "is-erase": eraser,
            "is-redpen": currentPen === "alert",
            "is-blackpen": currentPen === "black",
            "is-whitepen": currentPen === "white",
            "is-yellowpen": currentPen === "yellow",
            "is-bluepen": currentPen === "blue",
            "none-event": this.props.viewType !== "guide",
          })}
        >
          <canvas
            ref="canvas"
            onMouseDown={this.handleMouseDown}
            onTouchStart={this.handleTouchStart}
            onMouseMove={this.handleMouseMove}
            onTouchMove={this.handleTouchMove}
            onMouseUp={this.handleMouseUp}
            onTouchEnd={this.handleTouchEnd}
          />
          <canvas ref="buffer" style={{ display: "none" }} />
        </div>
        {/* 
        <div className="c-notepad__wrap">
          <div className="c-notepad__btn" onClick={this.props.onToggleNote}></div>
          <ul className="c-notepad__list">
            <li className={penClasses} onClick={this.togglePens}>
              <div className="c-notepad__color">
                <span className="semi-black" onClick={(e) => this.changeColor(e, 'black')}></span>
                <span className="white" onClick={(e) => this.changeColor(e, 'white')}></span>
                <span className="false" onClick={(e) => this.changeColor(e, 'alert')}></span>
              </div>
            </li>
            <li className="c-notepad__list-page" onClick={this.resetCanvas}></li>
            <li className={classNames("c-notepad__list-erase", {
              'is-on': this.state.eraser
            })} onClick={this.useErase}></li>
          </ul>
        </div>
        */}
        {this.props.viewType === "guide" && (
          <div className={classNames("c-notepad-new", {})}>
            <strong className="" onClick={this.props.onToggleNote}>
              {this.props.isOpen ? (
                <FormattedMessage id="classroom.quiz-list-note-close" />
              ) : (
                <FormattedMessage id="classroom.quiz-list-note" />
              )}
            </strong>
            <ul
              className={classNames({
                // none: this.state.drawing
              })}
            >
              <li
                className="do-render"
                onClick={(e) => this.changeColor(e, "black")}
              >
                <FormattedMessage id="classroom.quiz-list-draw" />
              </li>
              <li className="palette-wrap" onClick={this.togglePens}>
                <div className="">
                  {/* <span
                    className="white-color palette"
                    onClick={(e) => this.changeColor(e, "white")}
                  >
                    <NoteChk strokeColor="#B2B2B2" />
                  </span> */}
                  <span
                    className="black-color palette"
                    onClick={(e) => this.changeColor(e, "black")}
                  >
                    <NoteChk strokeColor="white" />
                  </span>
                  <span
                    className="red-color palette"
                    onClick={(e) => this.changeColor(e, "alert")}
                  >
                    <NoteChk strokeColor="white" />
                  </span>
                  <span
                    className="yellow-color palette"
                    onClick={(e) => this.changeColor(e, "yellow")}
                  >
                    <NoteChk strokeColor="white" />
                  </span>
                  <span
                    className="blue-color palette"
                    onClick={(e) => this.changeColor(e, "blue")}
                  >
                    <NoteChk strokeColor="white" />
                  </span>
                </div>
              </li>
              <li
                className={classNames("do-erase", {
                  "is-on": this.state.eraser,
                })}
                onClick={this.useErase}
              >
                <FormattedMessage id="classroom.quiz-list-erase" />
              </li>
              <li className="do-delete" onClick={this.resetCanvas}>
              <FormattedMessage id="classroom.quiz-list-delete-all" />
              </li>
            </ul>
          </div>
        )}

        <div className="c-notepad-close">
          <span>연습장 나가기</span>
          <div className="close"></div>
        </div>
      </div>
    );
  }
}

{
  /*
  <div className="quiz-note-width">
    <div className="quiz-note-width-container">
        <div onClick={() => this.changeWidth(1)}
            style={this.getWidthDivStyle(1)} />
    </div>
    <div className="quiz-note-width-container">
        <div onClick={() => this.changeWidth(3)}
            style={this.getWidthDivStyle(3)} />
    </div>
    <div className="quiz-note-width-container">
        <div onClick={() => this.changeWidth(8)}
            style={this.getWidthDivStyle(8)} />
    </div>
  </div>
*/
}

export default Note;
