import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authActions from "store/modules/auth";
import { Link } from "react-router-dom";
import classNames from "classnames";
import queryString from "query-string";
import Classmodebtn from "../../../components/Teacher/button/Classmodebtn";
import { isEmail } from "../../../utils/utils";
import { message } from "antd";
import BrowserAlert from "components/Popup/BrowserAlert";

import "./_classlogin.scss";
import logo from "../../../style/components/img/logo.svg";
import { FormattedMessage } from "react-intl";

const ClassLogin = (props) => {
  console.log(props.location.pathname);
  const pathname = props.location.pathname;
  const isAdminLogin = pathname.includes("admin");
  const { tab } = queryString.parse(props.location.search);
  const [count, setCount] = useState(0);
  const [currTab, setTab] = useState(
    isAdminLogin ? "teacher" : tab || "student"
  );
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    account: "",
    password: "",
  });
  const [validate, setValidate] = useState({
    account: "",
    password: "",
  });

  function updateFormData(e) {
    const value = e.target.value.trim();

    setForm({
      ...form,
      [e.target.name]: value,
    });
    setValidate({
      ...validate,
      [e.target.name]: value.length > 0,
    });
  }

  function submitLogin() {
    if (!form.account || !form.password) {
      setValidate({
        account: form.account.length > 0,
        password: form.password.length > 0,
      });
      return;
    }

    if (currTab === "teacher" && !isEmail(form.account)) {
      message.warning("이메일을 입력해주세요");
      return;
    }
    if (currTab === "student" && isEmail(form.account)) {
      message.warning("아이디를 정확히 입력해주세요");
      return;
    }
    setLoading(true);
    props.AuthActions.login({
      account: form.account,
      password: form.password,
    })
      .then((res) => {
        console.log("logged in!");
        // props.history.push("/");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    const { type } = props;
    if (type && type !== "none" && !isLoading) {
      console.log("type: ", type);
      if (type === "academy_student") {
        props.history.push("/");
      } else {
        props.history.push("/admin/class");
      }
    }
    if (count === 5) {
      setTab("teacher");
    }
  }, [props.type, isLoading, count]);

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      submitLogin();
    }
  }

  return (
    <div className="classlogin-layout">
      <div className="classlogin-layout-wrap">
        <div className="classlogin-inner-wrap">
          <div className="logo" onClick={() => setCount(count + 1)}>
            <img src={logo} alt="" />
          </div>
          <div className="tab">
            <div className="tab-nav is-active" style={{ cursor: "default " }}>
              {currTab === "student" ? (
                <FormattedMessage id="login.student-login" />
              ) : (
                <span>
                  <FormattedMessage id="login.admin-login" />
                </span>
              )}
            </div>
          </div>
          <div className="inputarea">
            <div
              className={classNames("inputwrap login", {
                "is-true": validate.account === true,
                "is-false": validate.account === false,
              })}
            >
              <FormattedMessage id={"login-form.id-placeholder"}>
                {(placeholder) => (
                  <input
                    type="text"
                    name="account"
                    className=""
                    placeholder={placeholder}
                    required
                    value={form.account}
                    onChange={updateFormData}
                    onKeyPress={handleKeyPress}
                  />
                )}
              </FormattedMessage>

              <div className="false-ment">
                <FormattedMessage id={"login-form.id-placeholder"} />
              </div>
            </div>
            <div
              className={classNames("inputwrap pw", {
                "is-true": validate.password === true,
                "is-false": validate.password === false,
              })}
            >
              <FormattedMessage id={"login-form.password-placeholder"}>
                {(placeholder) => (
                  <input
                    type="password"
                    name="password"
                    id=""
                    className=""
                    placeholder={placeholder}
                    value={form.password}
                    onChange={updateFormData}
                    onKeyPress={handleKeyPress}
                    required
                  />
                )}
              </FormattedMessage>

              <div className="false-ment">
                <FormattedMessage id={"login-form.password-placeholder"} />
              </div>
            </div>
          </div>

          {currTab === "student" && (
            <div className="find-account none">
              <ul>
                <li>
                  <Link to="/findaccount" classes="findid">
                    {/* <Link to="/test-input" classes="findid"> */}
                    아이디 찾기
                  </Link>
                </li>
                <li>
                  <Link to="/login-test">비밀번호 찾기</Link>
                </li>
              </ul>
            </div>
          )}
          <div className="btnwrap">
            <Classmodebtn
              onClick={submitLogin}
              text={<FormattedMessage id="login-form.login-button" />}
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
      {/* <BrowserAlert /> */}
    </div>
  );
};

export default connect(
  ({ auth }) => ({
    pending: auth.pending,
    type: auth.user.type,
    callback: auth.callback,
    error: auth.error,
  }),
  (dispatch) => ({
    AuthActions: bindActionCreators(authActions, dispatch),
  })
)(ClassLogin);
