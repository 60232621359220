export const constants = {
  campus: {
    type: "campus",
    currName: "커리큘럼",
    className: "클래스",
    guideName: "선생님",
    registeredStudent: "재학생",
    dropoutStudent: "퇴원생",
    classNameIntl: {
      "ko": "클래스",
      "en": "Class"
    },
    guideNameIntl: {
      "ko": "선생님",
      "en": "Teacher",
    },
    registeredStudentIntl: {
      "ko": "재학생",
      "en": "Students"
    },
    dropoutStudentIntl: {
      "ko": "퇴원생",
      "en": "Discharged Students"
    }
  },
  school: {
    type: "school",
    currName: "학년",
    className: "반",
    guideName: "선생님",
    registeredStudent: "재학생",
    dropoutStudent: "전학생",
    classNameIntl: {
      "ko": "반",
      "en": "Class"
    },
    guideNameIntl: {
      "ko": "선생님",
      "en": "Teacher",
    },
    registeredStudentIntl: {
      "ko": "재학생",
      "en": "Students"
    },
    dropoutStudentIntl: {
      "ko": "전학생",
      "en": "Discharged Students"
    }
  }
};
