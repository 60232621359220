import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Skeleton,
  PageHeader,
  Button,
  Icon,
  message,
  List,
  Table,
  Typography,
  Checkbox,
  Popconfirm,
  Switch,
} from "antd";
import Title from "antd/lib/typography/Title";
import ReactToPrint from "react-to-print";
import Axios from "utils/request";
import queryString from "query-string";
import { formateDate } from "utils/utils";
import ExamQuizCard from "./component/QuizCard";
import QuizCard from "../workbook/QuizCard";

import logo from "./logo.svg";
import bg from "./test_2.svg";

import "components/Quiz/style/index.scss";
import "components/Quiz/css/_layout.scss";
import "components/Quiz/css/Quiz.scss";
import "katex/dist/katex.min.css";

import "./index.scss";
import ExamInfoPopup from "./component/ExamInfoPopup";
import { FormattedMessage } from "react-intl";

const devquizIds = [5228073327821825, 1915469608591416, 407320900177936];

class ExamManagePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      reportData: null,
      studentData: null,
      isEdit: false,
      comment: "",
      name: null,
      exams: null,
      currentExam: null,
      sectionNames: [],
      sectionExams: [],
      showTrueAnswers: false,
      enableDangerousFeature: false,
      showDeleted: false,
      useWorkBookStyle: true,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    // test data
    // this.setState({
    //   currentExam: {
    //     name: "A101_1",
    //     quizIds: devquizIds,
    //   },
    // });

    if (user) {
      this.loadExams();
    }
  }

  loadExams = (showDeleted) => {
    if (showDeleted == undefined) {
      showDeleted = this.state.showDeleted;
    }
    this.setState({
      isLoading: true,
    });
    Axios.get(`/academy/v2/exams?showDeleted=${showDeleted}`).then((data) => {
      data.forEach((e) => {
        e.key = e.name;
        e.nameSection = e.name.substring(0, 1);
        e.namePrefix = e.name.match(/^[^()\-]*[^0-9]+/);
        e.nameNum = Number(
          e.name.substring(String(e.name.match(/^[^(]+/)).length).match(/\d+/)
        );
        if (isNaN(e.nameNum)) {
          e.nameNum = -1;
        }
      });
      data.sort((a, b) => {
        return a.namePrefix > b.namePrefix
          ? 1
          : a.namePrefix < b.namePrefix
          ? -1
          : a.nameNum - b.nameNum;
      });
      let section = "";
      let exams = [];
      let sectionNames = [];
      let sectionExams = [];
      data.forEach((e) => {
        if (e.nameSection != section) {
          if (section != "") {
            sectionNames.push(section);
            sectionExams.push(exams);
          }
          section = e.nameSection;
          exams = [];
        }
        exams.push(e);
      });
      if (exams.length > 0) {
        sectionNames.push(section);
        sectionExams.push(exams);
      }

      this.setState({
        exams: data,
        isLoading: false,
        currentExam: null,
        sectionNames: sectionNames,
        sectionExams: sectionExams,
      });
    });
  };

  updateExamDeleted = (exam, deleted) => {
    let data = {
      ...exam,
      deleted: deleted,
    };
    Axios.put(`/academy/v2/exams/${exam.id}`, data)
      .then((data) => {
        console.log("update exams deleted: ", data);
        this.loadExams();
      })
      .catch((e) => {
        message.warning("테스트 삭제 실패");
      });
  };

  render() {
    const {
      isLoading,
      isEdit,
      currentExam,
      exams,
      sectionNames,
      sectionExams,
      showTrueAnswers,
      enableDangerousFeature,
      showDeleted,
    } = this.state;
    const { pending, auth } = this.props;

    const sectionColumns = [
      {
        title: "코스",
        dataIndex: "title",
        key: "title",
        render: (text, item, index) =>
          item.title && (
            <span>
              {/* {item.title} 코스&nbsp; [테스트{item.children.length}개] */}
              <FormattedMessage
                id="admin.tests.course-name"
                values={{
                  title: item.title,
                  testsCount: item.children.length,
                }}
              />
            </span>
          ),
      },

      {
        title: "테스트",
        dataIndex: "name",
        key: "name",
        render: (text, item, index) =>
          item.name &&
          (item.deleted ? (
            <span>
              <b style={{ color: "red" }}>{item.name}</b>&nbsp;
              {/* [문제{item.quizIds.length}개] (삭제됨) */}
              <FormattedMessage
                id="admin.tests.test-quiz"
                values={{ count: item.quizIds.length }}
              />
              <FormattedMessage id="admin.tests.test-deleted" />
            </span>
          ) : (
            <span>
              <b>{item.name}</b>&nbsp;{" "}
              <FormattedMessage
                id="admin.tests.test-quiz"
                values={{ count: item.quizIds.length }}
              />
            </span>
          )),
      },

      {
        title: "작업",
        dataIndex: "actions",
        key: "actions",
        align: "center",
        render: (text, item, index) =>
          item.name && (
            <>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    if (currentExam && currentExam.id === item.id) {
                      if (showTrueAnswers) {
                        return;
                      }
                      this.setState({ showTrueAnswers: true });
                    } else {
                      this.setState({
                        currentExam: item,
                        showTrueAnswers: true,
                      });
                    }
                  }}
                  style={{ marginRight: 4, marginTop: -6, marginBottom: -6 }}
                  type={
                    currentExam && currentExam.id === item.id && showTrueAnswers
                      ? "primary"
                      : "dashed"
                  }
                >
                  <FormattedMessage id="admin.tests.show-answers" />{" "}
                  <Icon type="question-circle" />
                </Button>
                <Button
                  onClick={() => {
                    if (currentExam && currentExam.id === item.id) {
                      if (!showTrueAnswers) {
                        return;
                      }
                      this.setState({ showTrueAnswers: false });
                    } else {
                      this.setState({
                        currentExam: item,
                        showTrueAnswers: false,
                      });
                    }
                  }}
                  style={{ marginRight: 4, marginTop: -6, marginBottom: -6 }}
                  type={
                    currentExam &&
                    currentExam.id === item.id &&
                    !showTrueAnswers
                      ? "primary"
                      : "dashed"
                  }
                >
                  <FormattedMessage id="admin.tests.view" /> <Icon type="eye" />
                </Button>

                {currentExam && currentExam.id === item.id ? (
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        style={{
                          marginRight: 4,
                          marginTop: -6,
                          marginBottom: -6,
                        }}
                      >
                        {" "}
                        <FormattedMessage id="admin.tests.print" />{" "}
                        <Icon type="printer" />{" "}
                      </Button>
                    )}
                    content={() => this.componentRef}
                  />
                ) : (
                  <Button
                    disabled
                    type="dashed"
                    style={{ marginRight: 4, marginTop: -6, marginBottom: -6 }}
                  >
                    {" "}
                    <FormattedMessage id="admin.tests.print" />{" "}
                    <Icon type="printer" />{" "}
                  </Button>
                )}

                {enableDangerousFeature && (
                  <>
                    <ExamInfoPopup
                      onUpdate={this.loadExams}
                      type="edit"
                      exam={item}
                      title={<FormattedMessage id="admin.tests.edit-test" />}
                      btnName={<FormattedMessage id="admin.tests.edit" />}
                      icon={"edit"}
                    />
                    {item.deleted ? (
                      <Button
                        type="default"
                        style={{
                          marginRight: 4,
                          marginTop: -6,
                          marginBottom: -6,
                        }}
                        onClick={() => {
                          this.updateExamDeleted(item, false);
                        }}
                      >
                        <FormattedMessage id="admin.tests.undo" />
                        <Icon type="undo" />
                      </Button>
                    ) : (
                      <Popconfirm
                        key={"deleteButton" + item.id}
                        title={
                          <FormattedMessage id="admin.tests.delete-test-confirm" />
                        }
                        onConfirm={() => this.updateExamDeleted(item, true)}
                        onCancel={() => message.error("테스트 삭제취소")}
                        okText={
                          <FormattedMessage id="admin.tests.delete-test" />
                        }
                        placement="topRight"
                        cancelText={
                          <FormattedMessage id="admin.tests.cancel" />
                        }
                      >
                        <Button
                          type="danger"
                          style={{
                            marginRight: 4,
                            marginTop: -6,
                            marginBottom: -6,
                          }}
                        >
                          <FormattedMessage id="admin.tests.delete" />
                          <Icon type="delete" />
                        </Button>
                      </Popconfirm>
                    )}
                  </>
                )}
              </div>
            </>
          ),
      },
    ];

    const sectionData = [];
    for (let i = 0; i < sectionNames.length; i++) {
      sectionData.push({
        key: "section" + i,
        title: sectionNames[i],
        children: sectionExams[i],
      });
    }

    const expandedRowRender = (record, index, indent, expanded) => {
      console.log("expandRowRender:");
      const columns = [{ title: "테스트", dataIndex: "name", key: "name" }];
      const exams = this.state.sectionExams[index];
      return (
        <Table
          columns={columns}
          dataSource={exams}
          pagination={false}
          showHeader={false}
        />
      );
    };

    console.log("### AUTH:", auth);

    return (
      <Skeleton loading={isLoading || pending} active>
        <PageHeader
          title={
            <Title level={2} style={{ marginBottom: 0 }}>
              <FormattedMessage id="admin.tests.title" />
              {auth && auth.user && auth.user.orgQuizSetName
                ? ": " + auth.user.orgQuizSetName
                : ""}
            </Title>
          }
          extra={[
            <span key="3">
              <Checkbox
                checked={enableDangerousFeature ? true : false}
                onClick={() => {
                  if (enableDangerousFeature && showDeleted) {
                    this.setState({
                      enableDangerousFeature: false,
                      showDeleted: false,
                    });
                    this.loadExams(false);
                  } else {
                    this.setState({
                      enableDangerousFeature: !enableDangerousFeature,
                    });
                  }
                }}
              >
                <FormattedMessage id="admin.tests.management" />
              </Checkbox>
              {enableDangerousFeature && (
                <Checkbox
                  checked={showDeleted ? true : false}
                  onClick={() => {
                    const newValue = !showDeleted;
                    this.setState({ showDeleted: newValue });
                    this.loadExams(newValue);
                  }}
                >
                  <FormattedMessage id="admin.tests.show-deleted" />
                </Checkbox>
              )}
              {enableDangerousFeature && (
                <div
                  style={{
                    display: "inline-block",
                    paddingTop: "10px",
                    paddingLeft: "10px",
                  }}
                >
                  <ExamInfoPopup
                    onUpdate={this.loadExams}
                    type="add"
                    title={<FormattedMessage id="admin.tests.add-test" />}
                    btnName={<FormattedMessage id="admin.tests.add" />}
                    icon={"plus"}
                  />
                </div>
              )}
            </span>,
          ]}
        ></PageHeader>
        <div className="admin-page-content">
          {sectionData && (
            <Table
              columns={sectionColumns}
              dataSource={sectionData}
              expandable={{
                expandedRowRender,
                expandRowByClick: true,
                indentSize: 60,
              }}
              pagination={false}
              showHeader={false}
              expandRowByClick={true}
            />
          )}
        </div>

        {currentExam && !isLoading && (
          <>
            <br />
            <PageHeader
              title={
                <Title level={3} style={{ marginBottom: 0 }}>
                  {currentExam.name}
                </Title>
              }
              extra={[
                <span key="2">
                  {isEdit ? (
                    <Button onClick={() => message.warning("저장해주세요")}>
                      <FormattedMessage id="admin.tests.print-quiz" />
                      <Icon type="printer" />
                    </Button>
                  ) : (
                    <>
                      <Switch
                        checkedChildren="NEW"
                        unCheckedChildren={
                          <FormattedMessage id="admin.tests.default" />
                        }
                        checked={this.state.useWorkBookStyle}
                        onChange={() =>
                          this.setState({
                            useWorkBookStyle: !this.state.useWorkBookStyle,
                          })
                        }
                        style={{ marginRight: 8 }}
                      />
                      <ReactToPrint
                        trigger={() => (
                          <Button>
                            {" "}
                            <FormattedMessage id="admin.tests.print" />{" "}
                            <Icon type="printer" />{" "}
                          </Button>
                        )}
                        content={() => this.componentRef}
                      />
                    </>
                  )}
                </span>,
              ]}
            ></PageHeader>
            {this.state.useWorkBookStyle ? (
              <div className="admin-page-print-content">
                <div
                  style={{ position: "relative", padding: "0px" }}
                  ref={(el) => (this.componentRef = el)}
                >
                  <div className={"queture-quiz-wrap break-page"}>
                    <div className={`queture-quiz-title checkup`}>
                      <span className="logo-text"></span>
                      <div className={`title-wrap`}>
                        <span className="text">
                          {<FormattedMessage id="admin.tests.checkup" />}
                        </span>
                        <span className="sub-text">{currentExam.name}</span>
                      </div>
                      <div className="name">
                        <FormattedMessage id="admin.tests.name" /> :
                      </div>
                    </div>

                    <div className="quiz-card-list">
                      {currentExam.quizIds.map((quizId, index) => (
                        <QuizCard
                          current={quizId}
                          index={index}
                          currentQuizIndex={index}
                          showTrueAnswers={false}
                          forWorkbook={true}
                          // randomSeed={getQuizRandomSeed(
                          //   lecture.lectureId,
                          //   quetureQuizIndex,
                          //   index
                          // )}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="admin-page-content">
                <div style={{ position: "relative" }}>
                  {currentExam.quizIds.map((quizId, index) => (
                    <div className="print-a4"></div>
                  ))}
                  <div
                    ref={(el) => (this.componentRef = el)}
                    className="checkup-wrap"
                    // style={{ width: 900, margin: "0 auto" }}
                  >
                    <div className="quiz-cover">
                      {/* 
                <img src={logo} alt=""/>
                <div className="cover-title">
                  TEST <br /> {currentExam.name}
                </div>
                <div className="cover-footer">
                  <span className="name-label">Name : </span>
                  <span className="name-area">   __________</span>
                </div>
                */}
                      <img src={bg} alt="" />
                      <div className="cover-title">
                        {/*
                    <p>* 표지 부분은 웹에서 작게보여도 무시해주세요. 실제 프린트에서는 정상으로 나옵니다. 이 텍스트도 실제 프린트에서는 적용되지 않습니다.</p>
                  */}
                        TEST <br /> {currentExam.name}
                      </div>
                    </div>
                    <div className="quiz-card-list break-page">
                      {currentExam.quizIds.map((quizId, index) => (
                        <ExamQuizCard
                          current={quizId}
                          index={index}
                          currentQuizIndex={index}
                          showTrueAnswers={showTrueAnswers}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      pending: auth.pending,
      auth: auth,
      user: auth.user,
      logged: auth.logged,
      userPermission:
        auth.user.type === "org" ? "master" : auth.user.permission,
      org: auth.orgId,
    }),
    null
  )(ExamManagePage)
);
