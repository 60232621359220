import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import * as studentActions from "store/modules/student";
import {
  PageHeader,
  Typography,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Upload,
  Button,
  message,
  Skeleton,
} from "antd";
import moment from "moment";
import Axios from "../../../../utils/request";
import SearchAddressPopup from "../../components/Popup/SearchAddressPopup";
import StudentProfile from "../../components/StudentProfile";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";

const { Title } = Typography;
const locale = process.env.REACT_APP_LOCALE || "ko";

class StudentEditPage extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      ableChangeId: false,
      ableChangeIdPopup: false,
      oldAccountId: "",
      showAddressPopup: false,
      isLoading: false,
      studentData: {
        classroom: null,
        account: "",
        classroomId: "",
        name: "",
        birthday: "",
        grade: "",
        mobile: "",
        parentMobile: "",
        parentEmail: "",
        school: "",
        address1: "",
        address2: "",
        hideLecture: "",
      },
      profileImageId: null,
    };
  }

  componentDidMount() {
    const { user, ClassroomActions } = this.props;
    const { studentId } = this.props.match.params;
    console.log("this studentId: ", studentId);
    this.props.form.setFields({
      account: "",
      ableChangeId: false,
      classroomId: "",
      name: "",
      oldAccountId: "",
      birthday: moment(new Date()),
      grade: "",
      mobile: "",
      parentMobile: "",
      parentEmail: "",
      school: "",
      address1: "",
      address2: "",
      hideLecture: "",
    });

    if (user && studentId) {
      ClassroomActions.getClassroomList(user.orgId);
      this.getStudentDetail(studentId);
    }
  }

  getStudentDetail(studentId) {
    const { user } = this.props;
    this.setState({
      isLoading: true,
    });
    Axios.get(`/academy/v2/accounts/${user.orgId}/students/${studentId}`)
      .then((res) => {
        console.log("student detail: ", res);
        this.setState({
          isLoading: false,
          studentData: res,
          oldAccountId: res.account,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.props.form);
    const { selectedCourse, selectedStartDate, classIndex } = this.state;
    this.props.form.validateFields((err, values) => {
      console.log("Received values of form: ", values);
      const birth = moment(values.birthday).format("YYYY-MM-DD");
      const day = moment(values.birthday).date();
      const month = moment(values.birthday).month() + 1;
      console.log(birth);
      console.log(month);
      console.log(day);

      if (this.state.oldAccountId != values.account) {
        const _data = {
          accountId: values.account,
          oldAccountId: this.state.oldAccountId,
        };
      }
      if (!err) {
        const data = {
          account: values.account,
          classroomId: values.classroomId,
          name: values.name,
          // birthday: {
          //   day: day < 10 ? day : "0" + day,
          //   month: month < 10 ? month : "0" + month,
          //   year: moment(values.birthday).year()
          // },
          birthday: null,
          grade: values.grade,
          mobile: values.mobile,
          parentMobile: values.parentMobile,
          parentEmail: values.parentEmail,
          school: values.school,
          profileImageId: this.state.profileImageId,
          address: {
            address1: values.address1,
            address2: values.address2,
            zipCode: values.zipcode,
          },
          description: values.description,
          hideLecture: values.hideLecture,
          orgId: this.props.user.orgId,
        };
        if (values.birthday) {
          data["birthday"] = {
            day: day < 10 ? day : "0" + day,
            month: month < 10 ? month : "0" + month,
            year: moment(values.birthday).year(),
          };
        }
        console.log("post data: ", data);
        this.setState(
          {
            isLoading: true,
          },
          () => {
            this.updateStudent(data);
          }
        );
      }
    });
  };

  updateStudentAccountId = (data) => {
    const { user, form } = this.props;
    const { studentData } = this.state;

    Axios.put(
      `/academy/v2/accounts/${user.orgId}/students/${studentData.studentId}/accountId`,
      data
    );
  };

  updateStudent = (data) => {
    const { user, form } = this.props;
    const { studentData } = this.state;

    Axios.put(
      `/academy/v2/accounts/${user.orgId}/students/${studentData.studentId}`,
      data
    )
      .then((res) => {
        let msg = '수정되었습니다.'
        if(locale === 'en') {
          msg = 'Updated successfully.'
        }
        message.success(msg);
        // message.success(
        //   <FormattedMessage id="admin.edit-student.edit-success" />
        // );
        this.props.history.push(`/admin/student/detail/${res.studentId}`);
        this.setState({
          studentData: res,
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        message.error(<FormattedMessage id="admin.edit-student.edit-fail" />);
        this.setState({
          isLoading: false,
        });
      });
  };

  handleUploadProfile = (contentId) => {
    if (contentId) {
      this.setState({
        profileImageId: contentId,
      });
    }
  };

  render() {
    const { studentData, ableChangeId, ableChangeIdPopup } = this.state;
    const { pending, classroomList } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
    return (
      <>
        <Skeleton loading={this.state.isLoading || pending} active>
          <PageHeader
            title={
              <Title level={2}>
                <FormattedMessage id="admin.edit-student.title" />
              </Title>
            }
          />
          <div className="admin-page-content">
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col span={6}>
                  <StudentProfile
                    onUploadProfile={this.handleUploadProfile}
                    student={studentData}
                  />
                </Col>
                <Col span={18}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-name" />
                        }
                      >
                        {getFieldDecorator("name", {
                          initialValue: studentData.name,
                          rules: [
                            {
                              required: true,
                              message:
                                locale === "en"
                                  ? " Please input student name"
                                  : "학생 이름을 입력해주세요.",
                            },
                          ],
                        })(
                          <Input
                            placeholder={
                              locale === "en"
                                ? " Please input student name"
                                : "학생 이름을 입력해주세요."
                            }
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-id" />
                        }
                      >
                        {getFieldDecorator("account", {
                          initialValue: studentData.account,
                          rules: [
                            {
                              required: true,
                              message:
                                locale === "en"
                                  ? " Please input ID"
                                  : "아이디 입력해주세요.",
                            },
                          ],
                        })(
                          <Input
                            style={{
                              background: !ableChangeId
                                ? "rgba(143,143,143,0.07)"
                                : "#ffffff",
                            }}
                            readOnly={!ableChangeId}
                            placeholder={
                              locale === "en"
                                ? " Please input ID"
                                : "아이디 입력해주세요."
                            }
                            onClick={() => {
                              this.setState({
                                ableChangeIdPopup: !ableChangeIdPopup,
                              });
                            }}
                          ></Input>
                        )}
                      </Form.Item>
                      <Form.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-school-name" />
                        }
                      >
                        {getFieldDecorator("school", {
                          initialValue: studentData.school,
                          rules: [
                            // {
                            //   required: true,
                            //   message: "학교를 입력해주세요."
                            // }
                          ],
                        })(
                          <Input
                            placeholder={
                              locale === "en"
                                ? " Please input school name"
                                : "학교를 입력해주세요."
                            }
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-student-contact" />
                        }
                      >
                        {getFieldDecorator("mobile", {
                          initialValue: studentData.mobile,
                          rules: [
                            // {
                            //   required: true,
                            //   message: "학생 연락처를 입력해주세요."
                            // }
                          ],
                        })(
                          <Input
                            placeholder={
                              locale === "en"
                                ? " Please input student contact"
                                : "학생 연락처를 입력해주세요."
                            }
                            onChange={function (e) {
                              e.target.value = e.target.value.replaceAll(
                                " ",
                                ""
                              );
                            }}
                          />
                        )}
                      </Form.Item>
                      {this.context.type === "campus" && (
                        <Form.Item
                          label={
                            <FormattedMessage id="admin.student-detail.col-restricted-viewing-of-unattended-classes" />
                          }
                        >
                          {getFieldDecorator("hideLecture", {
                            initialValue: studentData.hideLecture
                              ? studentData.hideLecture
                              : "",
                            rules: [
                              // {
                              //   required: true,
                              //   message: "미수강수업 조회제한."
                              // }
                            ],
                          })(
                            <Select
                              placeholder={
                                locale === "en"
                                  ? "Please select an option"
                                  : "옵션을 선택해주세요"
                              }
                            >
                              <Select.Option value="">
                                <FormattedMessage id="admin.edit-student.restricted-viewing-of-unattended-classes-unlimit" />
                              </Select.Option>
                              <Select.Option value="AB">
                                <FormattedMessage id="admin.edit-student.restricted-viewing-of-unattended-classes-ab" />
                              </Select.Option>
                              <Select.Option value="ABC">
                                <FormattedMessage id="admin.edit-student.restricted-viewing-of-unattended-classes-abc" />
                              </Select.Option>
                              <Select.Option value="ABCD">
                                <FormattedMessage id="admin.edit-student.restricted-viewing-of-unattended-classes-abcd" />
                              </Select.Option>
                            </Select>
                          )}
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={12}>
                      {
                        <Form.Item
                          label={
                            <FormattedMessage id="admin.student-detail.col-class-name" />
                          }
                        >
                          {getFieldDecorator("classroomId", {
                            initialValue:
                              studentData.classroom &&
                              studentData.classroom.name,
                            rules: [
                              // {
                              //   required: true,
                              //   message: "클래스를 선택해주세요."
                              // }
                            ],
                          })(
                            <Input
                              readOnly
                              placeholder={
                                locale === "en"
                                  ? " Please select a class"
                                  : "클래스를 선택해주세요."
                              }
                            />
                          )}
                        </Form.Item>
                      }
                      <Form.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-birthday" />
                        }
                      >
                        {getFieldDecorator("birthday", {
                          initialValue: moment(
                            studentData.birthday
                              ? new Date(
                                  studentData.birthday.year +
                                    "-" +
                                    studentData.birthday.month +
                                    "-" +
                                    studentData.birthday.day
                                )
                              : null
                          ),
                          rules: [
                            {
                              required: this.context.type === "campus",
                              message:
                                locale === "en"
                                  ? " Please select birthday"
                                  : "생년월일을 선택해주세요.",
                            },
                          ],
                        })(
                          <DatePicker
                            placeholder={
                              locale === "en"
                                ? " Please select birthday"
                                : "생년월일을 선택해주세요."
                            }
                            format="YYYY-MM-DD"
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-grade" />
                        }
                      >
                        {getFieldDecorator("grade", {
                          initialValue: studentData.grade,
                          rules: [
                            // {
                            //   required: true,
                            //   message: "학년을 선택해주세요."
                            // }
                          ],
                        })(
                          <Select
                            placeholder={
                              locale === "en"
                                ? " Please select grade"
                                : "학년을 선택해주세요."
                            }
                          >
                            <Select.Option value="1">1</Select.Option>
                            <Select.Option value="2">2</Select.Option>
                            <Select.Option value="3">3</Select.Option>
                            <Select.Option value="4">4</Select.Option>
                            <Select.Option value="5">5</Select.Option>
                            <Select.Option value="6">6</Select.Option>
                          </Select>
                        )}
                      </Form.Item>
                      <Form.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-parent-contact" />
                        }
                      >
                        {getFieldDecorator("parentMobile", {
                          initialValue: studentData.parentMobile,
                          rules: [
                            {
                              required: this.context.type === "campus",
                              message:
                                locale === "en"
                                  ? " Please input parent contact"
                                  : "학부모 연락처를 입력해주세요.",
                            },
                          ],
                        })(
                          <Input
                            type="number"
                            placeholder={
                              locale === "en"
                                ? " Please input parent contact"
                                : "학부모 연락처를 입력해주세요."
                            }
                            onChange={function (e) {
                              e.target.value = e.target.value.replaceAll(
                                " ",
                                ""
                              );
                              if (e.target.value.length >= 15) {
                                e.target.value = e.target.value.slice(0, 15);
                              }
                            }}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-parent-email" />
                        }
                      >
                        {getFieldDecorator("parentEmail", {
                          initialValue: studentData.parentEmail,
                          rules: [
                            {
                              required: this.context.type === "campus",
                              message:
                                locale === "en"
                                  ? " Please input parent email"
                                  : "학부모 이메일을 입력해주세요.",
                            },
                          ],
                        })(
                          <Input
                            placeholder={
                              locale === "en"
                                ? " Please input parent email"
                                : "학부모 이메일을 입력해주세요."
                            }
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-note" />
                        }
                      >
                        {getFieldDecorator("description", {
                          initialValue: studentData.description || null,
                        })(
                          <Input.TextArea
                            placeholder={
                              locale === "en"
                                ? " Please input note"
                                : "메모를 입력해주세요."
                            }
                          />
                        )}
                      </Form.Item>

                      <>
                        {locale === "en" ? (
                          <Form.Item
                            label={
                              <FormattedMessage id="admin.student-detail.col-address" />
                            }
                          >
                            {getFieldDecorator("address1", {
                              initialValue:
                                studentData?.address &&
                                studentData?.address?.address1,
                              rules: [
                                {
                                  required: true,
                                  // message: "주소찾기를 통해 입력해주세요."
                                },
                              ],
                            })(<Input placeholder="Address 1" />)}
                          </Form.Item>
                        ) : (
                          <Form.Item
                            label={
                              <FormattedMessage id="admin.student-detail.col-address" />
                            }
                          >
                            {getFieldDecorator("address1", {
                              initialValue:
                                studentData?.address &&
                                studentData?.address?.address1,
                              rules: [
                                // {
                                //   required: true,
                                //   message: "주소찾기를 통해 입력해주세요."
                                // }
                              ],
                            })(
                              <Input
                                placeholder="주소찾기를 통해 입력해주세요."
                                readOnly
                                disabled
                              />
                            )}
                            <Button
                              onClick={() =>
                                this.setState({
                                  showAddressPopup: true,
                                })
                              }
                            >
                              주소찾기
                            </Button>
                            <SearchAddressPopup
                              title={`주소찾기`}
                              visible={this.state.showAddressPopup === true}
                              maskClosable={false}
                              onDone={(address1, zipcode) => {
                                console.log("address1: ", address1);
                                console.log("zipcode: ", zipcode);
                                this.props.form.setFieldsValue({
                                  address1: address1,
                                  zipcode: zipcode,
                                });
                                this.setState({
                                  showAddressPopup: false,
                                });
                              }}
                              onCancel={() => {
                                this.setState({
                                  showAddressPopup: false,
                                });
                              }}
                              footer={null}
                            />
                          </Form.Item>
                        )}
                        <Form.Item label="">
                          {getFieldDecorator("address2", {
                            initialValue:
                              studentData?.address &&
                              studentData?.address.address2,
                            rules: [
                              // {
                              //   required: true,
                              //   message: "상세주소를 입력해주세요."
                              // }
                            ],
                          })(
                            <Input
                              placeholder={
                                locale === "en"
                                  ? "Address 2"
                                  : "상세주소를 입력해주세요."
                              }
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          label="zipcode"
                          style={{
                            display: locale === "en" ? "block" : "none",
                          }}
                        >
                          {getFieldDecorator("zipcode", {
                            initialValue:
                            studentData?.address &&
                            studentData?.address.zipCode,
                            rules: [
                              {
                                required: locale === "en" ? true : false,
                              },
                            ],
                          })(<Input placeholder="zipcode" />)}
                        </Form.Item>
                      </>

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={this.state.isLoading}
                        >
                          {/* 수정완료 */}
                          <FormattedMessage id="admin.edit-student.confirm-edit" />
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </Skeleton>
      </>
    );
  }
}

export default connect(
  ({ auth, classroom }) => ({
    pending: auth.pending || classroom.pending,
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    classroomList: classroom.classroomList,
  }),
  (dispatch) => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
    StudentActions: bindActionCreators(studentActions, dispatch),
  })
)(Form.create()(StudentEditPage));
