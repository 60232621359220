export default {
  "hometraining.menu.today": "Today's Assignment",
  "hometraining.menu.review": "Review Quetures",
  "hometraining.menu.game": "Quebon Playgrounds",
  "hometraining.menu.setting": "Setting",
  "hometraining.menu.logout": "Logout",
  "hometraining.homework-lesson": "Lesson {seq} Assignments",
  "hometraining.homework-incomplete":
    "You Haven't Completed Your Assignment Yet!",
  "hometraining.homework-complete": "You've Completed Today's Assignment!",
  "hometraining.homework-review-queture": "Go to Review Quetures",
  "hometraining.homework-todays-assignment": "Go to Today's Assignment",
  "hometraining.calendar.date-format": "{year}.{month}",
  "hometraining.calendar.today": "Today",
  "hometraining.calendar.weekday.mon": "Mon.",
  "hometraining.calendar.weekday.tue": "Tue.",
  "hometraining.calendar.weekday.wed": "Wed.",
  "hometraining.calendar.weekday.thu": "Thu.",
  "hometraining.calendar.weekday.fri": "Fri.",
  "hometraining.calendar.weekday.sat": "Sat.",
  "hometraining.calendar.weekday.sun": "Sun.",
  "hometraining.calendar.weekday.monday": "Monday",
  "hometraining.calendar.weekday.tuesday": "Tuesday",
  "hometraining.calendar.weekday.wednesday": "Wednesday",
  "hometraining.calendar.weekday.thursday": "Thursday",
  "hometraining.calendar.weekday.friday": "Friday",
  "hometraining.calendar.weekday.saturday": "Saturday",
  "hometraining.calendar.weekday.sunday": "Sunday",
  "hometraining.calendar.class-time": "Class Time : Every {days}",
  "hometraining.calendar.class-end": "Lesson Completed",
  "hometraining.calendar.mvp-selected": "Selected",
  "hometraining.calendar.game-ranking": "Game Ranking",
  "hometraining.calendar.game-ranking-place": "{place}{ordinal}",
  "hometraining.calendar.class-scheduled": "Class Scheduled",
  "hometraining.calendar.class": "Class",
  "hometraining.calendar.assignments": "Assignments",
  "hometraining.calendar.review-in-progress": "Review <span>in Progress</span>",
  "hometraining.calendar.review-completed": "Review <span>Completed</span>",
  "hometraining.calendar.mission-in-progress":
    "Mission <span>in Progress</span>",
  "hometraining.calendar.mission-completed": "Mission <span>Completed</span>",
  "hometraining.calendar.class-info": "Class Info",
  "hometraining.calendar.class-seq": "Lesson {seq}",
  "hometraining.calendar.class-queture": "{seq}{ordinal} Queture",
  "hometraining.calendar.homework-queture": "Review Queture",
  "hometraining.calendar.homework-quiz": "Resolve Incorrect Quiz",
  "hometraining.calendar.homework-mission": "Mission",
  "hometraining.homework-empty": "No Today's Assignments",
  "hometraining.homework-lesson-assignments": "Lesson {seq} Assignments",
  "hometraining.homework-lesson-end-date": "By {month}-{day}",
  "hometraining.homework-queture-complete": "Completed",
  "hometraining.homework-queture-incomplete": "Incomplete",
  "hometraining.homework-queture-summary": "View Summary",
  "hometraining.homework-queture-review": "Review",
  "hometraining.homework-quiz-start": "Solve Incorrect Quiz",
  "hometraining.player-replay": "Replay",
  "hometraining.player-end": "End",
  "hometraining.player-end-ment": "Queture is over",
  "hometraining.quiz-exit": "Exit",
  "hometraining.quiz-confirm-answer-button-cancel": "Continue",
  "hometraining.quiz-confirm-answer-button-confirm": "Grade",
  "hometraining.quiz-answer-count": "Total Number of Questions : {count}",
  "hometraining.review-filter-newest": "Newest",
  "hometraining.review-filter-oldest": "Oldest",
  "hometraining.review-filter-all": "All",
  "hometraining.review-filter-course": "Course {courseName}",
  "hometraining.review-filter-select-course": "Select a Course",
  "hometraining.review-filter-lesson-range":
    "Lesson {startLesson} ~ Lesson {endLesson}",
  "hometraining.review-filter-select-lesson": "Please select a session",
  "hometraining.review-search-input-placeholder": "Please enter a keyword",
  "hometraining.review-empty": "No Review Quetures",
  "hometraining.review-lesson-title":
    "{courseName} course {seq}{ordinals} Lessons",
  "hometraining.review-lesson-date": "{month}/{day}/{year}",
  "hometraining.review-queture": "Review",
  "hometraining.review-resolve-quiz": "Resolve Quiz",
  "hometraining.game-play": "Play",
  "hometraining.game-empty": "No games in <Quebon Playgrounds>",
  "hometraining.my-info.my-profile": "My Profile",
  "hometraining.my-info.password": "Password",
  "hometraining.my-info.set-password": "Set Password",
  "hometraining.my-info.save-password": "Save Password",
  "hometraining.my-info.old-password-placeholder":
    "Please enter your current password.",
  "hometraining.my-info.new-password-placeholder":
    "Please enter a new password.",
  "hometraining.my-info.confirm-new-password-placeholder":
    "Please enter a new password again.",
  "hometraining.my-info.new-password-not-matched":
    "The new password does not match.",
  "hometraining.my-info.birthday": "Date of Birth",
  "hometraining.my-info.address": "Home Address",
  "hometraining.my-info.parent-contact": "Parent's Contact",
  "hometraining.my-info.student-contact": "Student's Contact",
  "hometraining.my-info.school": "School(Grade)",
  "hometraining.my-info.grade": "Grade",
};
