import React, { useState } from "react";
import "./_mediaplayer.scss";
import styled from "styled-components";
import { ReactComponent as play } from "./img/Amediaplayer-play-btn.svg";
import QueturePlayer from "../../QueturePlayer/QueturePlayer";
import { getDefaultConfig, getHost } from "utils/APIUtils";
import {
  Select,
  Slider,
  Button,
  Icon,
  Row,
  Col,
  Typography,
  Divider,
  Checkbox,
  Modal,
} from "antd";
import RemoteVideoList from "../../../pages/classMode/components/RemoteVideoList";
import RemoteVideoController from "../../../pages/classMode/components/RemoteVideoController";
import PauseClass from "pages/classMode/etcpage/PauseClass";
import { FormattedMessage } from "react-intl";

const Playbtn = styled(play)`
  .fill {
    ${(props) => props.fill}
  }
`;
const MediaPlayer = (props) => {
  const [data, setData] = useState({
    isStopAsyncMode: true,
    progress: 0,
    time: 0,
    volume: 80,
    mute: false,
    speed: 1,
    resolutions: [],
    autoResolution: true,
    currentResolution: null,
    resolutionName: null,
  });

  const {
    quetureData,
    userId,
    seq,
    viewType,
    curPage,
    onChangeSummaryIndex,
    quetureList,
    type,
    isOnline,
  } = props;

  console.log(type);
  console.log("remocon data: ", data);
  console.log("quetureList: ", quetureList);
  console.log("media player props: ", props);
  if (
    (props.startStudentQueture || (isOnline && type === "summary")) &&
    viewType === "student"
  ) {
    return (
      <div>
        {quetureData && (
          <QueturePlayer
            apiHost={getHost()}
            userId={userId}
            content={{
              data: quetureData,
              type: type ? type : "main",
            }}
            seq={seq}
            resolution="auto"
            autoplay
            style={{ outline: "none" }}
            noControll={true}
            isRemote={
              (props.startStudentQueture || (isOnline && type === "summary")) &&
              viewType === "student"
            }
            viewType={viewType}
            classroomId={props.classroomId}
          />
        )}
      </div>
    );
  } else if (viewType === "preview") {
    return (
      <div>
        {isOnline ||
          (!isOnline && (
            <div style={{ maxWidth: 1280 }}>
              {quetureData && (
                <QueturePlayer
                  apiHost={getHost()}
                  userId={userId}
                  content={{
                    data: quetureData,
                    type: type ? type : "main",
                  }}
                  seq={seq}
                  resolution="auto"
                  autoplay
                  style={{ outline: "none", width: "100%", marginBottom: 20 }}
                  noControll={true}
                  isRemote={
                    (props.startStudentQueture ||
                      (isOnline && type === "summary")) &&
                    viewType === "student"
                  }
                  viewType={viewType}
                  classroomId={props.classroomId}
                />
              )}
            </div>
          ))}
      </div>
    );
  } else if (viewType === "guide") {
    return (
      <div>
        <Checkbox
          defaultChecked={true}
          checked={data.isStopAsyncMode}
          onChange={(e) => {
            setData({ isStopAsyncMode: e.target.checked });
          }}
        >
          <FormattedMessage id="classroom.queture-sync" />
        </Checkbox>
        <div style={{ maxWidth: 640 }}>
          {quetureData && (
            <QueturePlayer
              stopAsyncMode={function () {
                setData({ isStopAsyncMode: false });
              }}
              isStopAsyncMode={data.isStopAsyncMode}
              isOnline={isOnline}
              apiHost={getHost()}
              userId={userId}
              content={{
                data: quetureData,
                type: type ? type : "main",
              }}
              seq={seq}
              resolution="auto"
              autoplay
              style={{ outline: "none", width: "100%", marginBottom: 20 }}
              noControll={true}
              isRemote={
                (props.startStudentQueture ||
                  (isOnline && type === "summary")) &&
                viewType === "student"
              }
              classroomId={props.classroomId}
              onSendMessage={props.onSendMessage}
              viewType={viewType}
            />
          )}
        </div>
        <div>
          {type && type === "summary" && (
            <Button
              type="primary"
              onClick={() => {
                onChangeSummaryIndex(10);
              }}
              size={"large"}
              style={{ background: "#13c2c2", borderColor: "#13c2c2" }}
            >
              <FormattedMessage id="classroom.move-to-mvp" />
            </Button>
          )}
          <Divider style={{ margin: "16px 0px" }} />
          {quetureList && (
            <RemoteVideoList
              title={<FormattedMessage id="classroom.summary-queture" />}
              curIndex={curPage}
              list={quetureList}
              onSelect={onChangeSummaryIndex}
            />
          )}
          <Divider style={{ margin: "16px 0px" }} />
          <RemoteVideoController
            isRemote={
              props.startStudentQueture || (isOnline && type === "summary")
            }
            onSendMessage={props.onSendMessage}
          />
        </div>
      </div>
    );
  } else {
    return <PauseClass />;
  }
};

export default MediaPlayer;
