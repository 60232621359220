import React, { Component } from "react";
import {
  Table,
  List,
  Button,
  message,
  Popconfirm,
  Skeleton,
  DatePicker,
  Warning,
  Icon,
} from "antd";
import { filter, flatten, values } from "lodash";
import {
  formateFullEngDate,
  formateFullKoreanDate,
  formateQuetureName,
  getLessonSeq,
} from "../../../utils/utils";
import SetHomeworkPopup from "./Popup/SetHomeworkPopup";
import AccountApis from "../../../utils/accountApis";
import ClassroomApis from "../../../utils/classroomApis";
import MakeTeamPopup from "./Popup/MakeTeamPopup";
import ConfirmHomeworkPopup from "./Popup/ConfirmHomeworkPopup";
import UpdateClassroomLectureTimePopup from "./Popup/UpdateClassroomLectureTimePopup";
import Axios from "../../../utils/request";
import { withRouter } from "react-router-dom";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";
import moment from "moment";

const pageCount = 10;
class ClassroomLectureList extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      lectures: [],
      page: 0,
      totalPages: 1,
      showMakeTeamPopup: null,
      showConfirmHomeworkPopup: null,
      showChangeGuidePopup: false,
      showDelayClassPopup: false,
      showHomeworkPopup: null,
      showUpdateTimePopup: null,
    };
  }

  async componentDidMount() {
    const { classroomId, type } = this.props;
    const list = await this.getLectureList(classroomId, this.state.page, type);

    this.updateLectureToDB(list);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.classroomId !== this.props.classroomId ||
      prevProps.type !== this.props.type
    ) {
      this.getLectureList(
        this.props.classroomId,
        this.state.page,
        this.props.type
      );
    }
  }

  onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    this.setState(
      {
        page: pagination.current - 1,
      },
      () => {
        this.getLectureList(
          this.props.classroomId,
          pagination.current - 1,
          this.props.type
        );
      }
    );
  };

  getLectureList = async (classroomId, page, type) => {
    this.setState({ loading: true });

    const res = await ClassroomApis.lectures(
      classroomId,
      page,
      pageCount,
      type
    );

    this.setState({
      lectures: res.list,
      page: res.page,
      totalPages: res.totalPages,
      loading: false,
    });

    console.log("get lecture list: ", res);
    return res.list;
  };

  updateLectureTime(lectureId, timestamp) {
    const { id: classroomId } = this.props.match.params;

    ClassroomApis.updateLectureTime(classroomId, lectureId, timestamp)
      .then((res) => {
        console.log("update lecture time: ", res);
        let msg = "수업 시간 변경 완료";
        if (this.props.locale === "en") {
          msg = "Class time changed";
        }
        message.success(msg);
        const { lectures } = this.state;
        const index = lectures.findIndex((l) => l.lectureId === lectureId);
        if (index >= 0) {
          let newLectures = [...lectures];
          newLectures[index] = res;
          this.setState({ lectures: newLectures });
        }
      })
      .catch((e) => {
        console.log("update lecture time: ", e);
      });
  }

  delayLecture(lectureId) {
    const { id: classroomId } = this.props.match.params;

    ClassroomApis.delay(classroomId, lectureId)
      .then((res) => {
        console.log("delay lecture: ", res);
        let msg = "수업 연기 완료";
        if (this.props.locale === "en") {
          msg = "Class postpone complete";
        }
        message.success(msg);
        this.props.onUpdate(classroomId);
      })
      .catch((e) => {
        console.log("delay lecture failed: ", e);
      });
  }

  startLecture(classroomId, lectureId, state) {
    if (state === "current") {
      this.enterLecture(classroomId);
    } else {
      ClassroomApis.start(classroomId, lectureId).then((res) => {
        console.log("start lecture: ", res);
        this.enterLecture(classroomId);
      });
    }
  }

  forcedCompleteLecture(classroomId, lectureId) {
    ClassroomApis.start(classroomId, lectureId)
      .then((res) => {
        ClassroomApis.complete(classroomId, lectureId)
          .then((res) => {
            console.log("forced complete lecture: ", res);
            const { lectures } = this.state;
            const index = lectures.findIndex((l) => l.lectureId === lectureId);
            if (index >= 0) {
              let newLectures = [...lectures];
              newLectures[index] = res;
              this.setState({ lectures: newLectures });
              if (this.props.onUpdateClassroom) {
                this.props.onUpdateClassroom();
              }
            }
          })
          .catch((e) => {
            console.log(
              "forced complete lecture failed: ",
              classroomId,
              lectureId
            );
            message.warning("수업완료처리 실패");
          });
      })
      .catch((e) => {
        console.log("forced start lecture failed: ", classroomId, lectureId);
        message.warning("수업 완료처리 실패");
      });
  }

  resetLecture(orgId, classroomId, lectureId) {
    AccountApis.resetClassroomLecture(orgId, classroomId, lectureId)
      .then((res) => {
        console.log("reset classroom lecture: ", res);
        const { lectures } = this.state;
        const index = lectures.findIndex((l) => l.lectureId === lectureId);
        if (index >= 0) {
          let newLectures = [...lectures];
          newLectures[index] = res;
          this.setState({ lectures: newLectures });
          if (this.props.onUpdateClassroom) {
            this.props.onUpdateClassroom();
          }
        }
      })
      .catch((e) => {
        console.log("reset classroom lecture: ", classroomId, lectureId);
        if (this.props.onUpdateClassroom) {
          this.props.onUpdateClassroom();
        }
        let msg = "수업 초기화 처리 실패";
        if (this.props.locale === "en") {
          msg = "Failed to reset lecture";
        }
        message.warning(msg);
      });
  }

  deleteLecture(orgId, classroomId, lectureId) {
    AccountApis.deleteClassroomLecture(orgId, classroomId, lectureId)
      .then((res) => {
        console.log("delete classroom lecture: ", res);
        const { lectures } = this.state;
        const index = lectures.findIndex((l) => l.lectureId === lectureId);
        if (index >= 0) {
          let newLectures = [...lectures];
          newLectures[index] = res;
          this.setState({ lectures: newLectures });
          if (this.props.onUpdateClassroom) {
            this.props.onUpdateClassroom();
          }
        }
      })
      .catch((e) => {
        console.log("reset classroom lecture: ", classroomId, lectureId);
        if (this.props.onUpdateClassroom) {
          this.props.onUpdateClassroom();
        }
        message.warning("수업 삭제 처리 실패");
      });
  }

  enterLecture(classroomId) {
    this.props.history.push(`/classroom/${classroomId}`);
    window.open(`/classroom/${classroomId}?preview=1`, "_blank");
  }

  updateLectureToDB = (list) => {
    const lectures = list;
    const { classroomId } = this.props;
    lectures.map((lecture) =>
      Axios.post(
        `/academy/v2/class/${classroomId}/lectures/${lecture.lectureId}/init`
      )
    );
    console.log("Lectures to DB Update finished!");
  };

  render() {
    const {
      classroomId,
      type,
      user,
      userPermission,
      enableDangerousFeature,
      locale,
    } = this.props;
    const { lectures, totalPages } = this.state;
    const buttonStyle = { margin: "2px 2px" };

    let currentLectureIndex = filter(lectures, function (o) {
      return o.state === "completed";
    }).length;

    let columns = [
      {
        title: <FormattedMessage id="admin.classroom-detail.col-lesson" />,
        dataIndex: "seq",
        key: "seq",
        render: (text, record, index) => (
          <>
            <div>
              <FormattedMessage
                id="admin.classroom-detail.value-lesson"
                values={{ lessonSeq: getLessonSeq(record) }}
              />
            </div>
            <div>
              <small>{record.courseTitle}</small>
            </div>
          </>
        ),
      },
      {
        title: <FormattedMessage id="admin.classroom-detail.col-list" />,
        dataIndex: "",
        key: "",
        render: (text, record, index) => {
          let quizQuetures = record.sections
            ? record.sections
                .map((e) => (e.type === "QUIZ" ? e.quetureId : null))
                .filter((e) => e != null)
            : [];
          return (
            <List
              dataSource={
                record.sections
                  ? record.sections.filter(
                      (e) =>
                        e.type === "QUETURE" &&
                        quizQuetures.includes(e.quetureId)
                    )
                  : []
              }
              renderItem={(section) => {
                return (
                  <List.Item>
                    {"ㆍ"}
                    {formateQuetureName(section.quetureTitle)}
                  </List.Item>
                );
              }}
            />
          );
        },
      },
      {
        title: <FormattedMessage id="admin.classroom-detail.col-date" />,
        dataIndex: "date",
        key: "date",
        render: (date, record, index) =>
          this.context.type === "campus" ? (
            <>
              <span
                style={{ ...buttonStyle, cursor: "pointer" }}
                onClick={() => {
                  console.log(
                    "updateTime button clicked",
                    date,
                    record.lectureId,
                    index
                  );
                  this.setState({
                    showUpdateTimePopup: index,
                  });
                }}
              >
                {`${
                  date
                    ? locale === "en"
                      ? formateFullEngDate(date)
                      : formateFullKoreanDate(date)
                    : ""
                }`}
              </span>
              {this.state.showUpdateTimePopup === index && (
                <UpdateClassroomLectureTimePopup
                  // title={`${record.title} 수업 시각 변경`}
                  title={
                    <FormattedMessage
                      id="admin.classroom-detail.change-class-date"
                      values={{ lessonSeq: getLessonSeq(record) }}
                    />
                  }
                  visible={this.state.showUpdateTimePopup === index}
                  maskClosable={false}
                  width={800}
                  dateTime={date}
                  onOk={(timestamp) => {
                    console.log(
                      "update classroom lecture time",
                      record.lectureId,
                      moment(timestamp).format("YYYY-MM-DDTHH:mm:ss")
                    );
                    
                    this.updateLectureTime(
                      record.lectureId,
                      moment(timestamp).format("YYYY-MM-DDTHH:mm:ss")
                    );
                    this.setState({ showUpdateTimePopup: null });
                  }}
                  onCancel={() => {
                    this.setState({ showUpdateTimePopup: null });
                  }}
                />
              )}
            </>
          ) : (
            <span>{`${
              date
                ? locale === "en"
                  ? formateFullEngDate(date)
                  : formateFullKoreanDate(date)
                : ""
            }`}</span>
          ),
      },
      {
        title: <FormattedMessage id="admin.classroom-detail.col-preparation" />,
        key: "prepare",
        render: (text, record, index) => (
          <span>
            {/* <Button
              style={{ ...buttonStyle }}
              onClick={() =>
                this.setState({
                  showMakeTeamPopup: index,
                })
              }
              disabled={!record.students}
            >
              팀만들기
            </Button>*/}
            {/* {record.seq <= 4 && ( */}
            <Button
              style={{ ...buttonStyle }}
              onClick={() => {
                this.props.history.push(
                  `/admin/class/detail/${classroomId}/lecture-preview/${record.lectureId}`
                );
              }}
            >
              <FormattedMessage id="admin.classroom-detail.value-preview" />
            </Button>
            {/* <br /> */}
            {/* <Button
              style={{ ...buttonStyle }}
              onClick={() =>
                this.setState({
                  showConfirmHomeworkPopup: index,
                })
              }
              // disabled={!record.students}
            >
              <FormattedMessage id="admin.classroom-detail.value-check-assignments" />
            </Button>
            <Button
              style={{ ...buttonStyle }}
              onClick={() =>
                this.setState({
                  showHomeworkPopup: index,
                })
              }
            >
              <FormattedMessage id="admin.classroom-detail.value-setting-assignments" />
            </Button> */}
            {this.state.showHomeworkPopup === index && (
              <SetHomeworkPopup
                visible={this.state.showHomeworkPopup === index}
                maskClosable={false}
                width={800}
                onDone={(value, id) => {
                  ClassroomApis.setHomework(classroomId, record.lectureId, id, {
                    type: "upload",
                    value: value,
                  })
                    .then((res) => {
                      console.log("add homwwork:", res);
                      this.props.onUpdate(classroomId);
                      message.warning("과제 설정 완료하였습니다.");
                    })
                    .catch((e) => {
                      console.log("add homework failed: ", e);
                      message.warning("과제 설정 실패하였습니다.");
                    });

                  this.setState({
                    showHomeworkPopup: null,
                  });
                }}
                onCancel={() => {
                  this.setState({
                    showHomeworkPopup: null,
                  });
                }}
                homework={record.homework}
              />
            )}
            {/* this.state.showMakeTeamPopup === index && (
              <MakeTeamPopup
                title={`${record.title} 수업 팀만들기`}
                visible={this.state.showMakeTeamPopup === index}
                maskClosable={false}
                width={800}
                onDone={(groups) => {
                  ClassroomApis.groups(classroomId, record.lectureId, groups)
                    .then((res) => {
                      console.log("groups done: ", res);
                      this.props.onUpdate(classroomId);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                  this.setState({
                    showMakeTeamPopup: null,
                  });
                }}
                onCancel={() => {
                  this.setState({
                    showMakeTeamPopup: null,
                  });
                }}
                lectureData={record}
              />
            ) */}
            {this.state.showConfirmHomeworkPopup === index && (
              <ConfirmHomeworkPopup
                title={`${record.title} 수업 과제확인`}
                visible={this.state.showConfirmHomeworkPopup === index}
                maskClosable={false}
                width={800}
                lectureData={record}
                onOk={() => {
                  this.setState({
                    showConfirmHomeworkPopup: null,
                  });
                }}
                onCancel={() => {
                  this.setState({
                    showConfirmHomeworkPopup: null,
                  });
                }}
              />
            )}
          </span>
        ),
      },
      /*
      {
        title: "수업리포트",
        key: "report",
        render: (text, record) => (
          <span>
            <Button
              type="primary"
              ghost
              onClick={() => {
                this.props.history.push(
                  `/admin/class/detail/${classroomId}/lecture-report/${record.seq}`
                );
              }}
            >
              {record.report && record.report.completed
                ? "내용보기"
                : "작성하기"}
            </Button>
          </span>
        ),
      },
      */
    ];

    columns.push({
      title: <FormattedMessage id="admin.classroom-detail.col-progress" />,
      key: "lectureActions",
      render: (text, record, index) => (
        <>
          {index <= currentLectureIndex ? (
            <span>
              {(userPermission === "master" ||
                (userPermission && userPermission.lectureStart)) && (
                <Button
                  style={{ ...buttonStyle }}
                  type="primary"
                  onClick={() => {
                    const startTime = new Date(record.date);
                    const now = new Date();
                    const halfHour = 1000 * 60 * 30;
                    const isStart =
                      parseInt(now - startTime) + parseInt(halfHour) >= 0;
                    console.log("startTime: ", startTime, now)
                    if (!isStart && this.context.type === "campus") {
                      let msg = "수업시간 30분전 시작 가능합니다.";
                      if (locale === "en")
                        msg = "You can start 30 minutes before class time.";
                      message.warning(msg);
                      return;
                    }
                    this.startLecture(
                      classroomId,
                      record.lectureId,
                      record.state
                    );
                  }}
                  disabled={record.state === "completed"}
                >
                  {record.state === "completed" ? (
                    <FormattedMessage id="admin.classroom-detail.value-completed" />
                  ) : (
                    <FormattedMessage id="admin.classroom-detail.value-start" />
                  )}
                </Button>
              )}

              {record.state === "pending" &&
                (userPermission === "master" ||
                  (userPermission && userPermission.classCreateDelete)) && (
                  <>
                    <Popconfirm
                      key="1"
                      // title={`"${record.title}" 수업을 연기처리 하시겠습니까?`}
                      title={
                        <FormattedMessage
                          id="admin.classroom-detail.delay-lesson-desc"
                          values={{ seq: getLessonSeq(record) }}
                        />
                      }
                      onConfirm={() => this.delayLecture(record.lectureId)}
                      onCancel={() =>
                        message.error(
                          this.props.locale ? "Canceled" : "수업 연기 취소"
                        )
                      }
                      okText={
                        <FormattedMessage id="admin.classroom-detail.value-postpone" />
                      }
                      placement="bottomRight"
                      cancelText={
                        <FormattedMessage id="admin.classroom-detail.cancel" />
                      }
                    >
                      <Button style={{ ...buttonStyle }}>
                        <FormattedMessage id="admin.classroom-detail.value-postpone" />
                      </Button>
                    </Popconfirm>
                  </>
                )}

              {enableDangerousFeature &&
                record.state !== "completed" &&
                this.context.type !== "campus" &&
                (userPermission === "master" ||
                  (userPermission &&
                    (userPermission.lectureStart ||
                      userPermission.classCreateDelete))) && (
                  <>
                    <Popconfirm
                      key="2"
                      title={`"${record.title}" 수업을 완료처리 하시겠습니까?`}
                      onConfirm={() =>
                        this.forcedCompleteLecture(
                          classroomId,
                          record.lectureId
                        )
                      }
                      onCancel={() => message.error("수업 완료처리 취소")}
                      okText="완료처리"
                      placement="bottomRight"
                      cancelText="취소"
                    >
                      <Button style={{ ...buttonStyle }} type="danger">
                        <FormattedMessage id="admin.classroom-detail.complete-lesson" />
                      </Button>
                    </Popconfirm>
                  </>
                )}

              {enableDangerousFeature &&
                this.context.type === "campus" &&
                record.state === "completed" &&
                (userPermission === "master" ||
                  (userPermission &&
                    (userPermission.lectureStart ||
                      userPermission.classCreateDelete))) && (
                  <>
                    <Popconfirm
                      key="1"
                      // title={`"${record.title}" 수업을 초기화 하시겠습니까? (출석,풀이결과,과제 등 연관 기록이 모두 삭제 됩니다)`}
                      title={
                        <FormattedMessage
                          id="admin.classroom-detail.reset-lesson-title"
                          values={{
                            seq: getLessonSeq(record),
                          }}
                        />
                      }
                      onConfirm={() =>
                        this.resetLecture(
                          user.orgId,
                          classroomId,
                          record.lectureId
                        )
                      }
                      onCancel={() =>
                        message.error(
                          locale === "en"
                            ? "Reset Lesson Canceled"
                            : "수업 초기화 취소"
                        )
                      }
                      okText={
                        <FormattedMessage id="admin.classroom-detail.reset-lesson" />
                      }
                      placement="bottomRight"
                      cancelText={
                        <FormattedMessage id="admin.classroom-detail.cancel" />
                      }
                    >
                      <Button style={{ ...buttonStyle }} type="danger">
                        <FormattedMessage id="admin.classroom-detail.reset-lesson" />
                      </Button>
                    </Popconfirm>
                  </>
                )}

              {enableDangerousFeature &&
                this.context.type === "campus" &&
                record.state !== "completed" &&
                (userPermission === "master" ||
                  (userPermission && userPermission.classCreateDelete)) && (
                  <>
                    <Popconfirm
                      key="1"
                      // title={`"${record.title}" 수업을 삭제하시겠습니까? (수업에 대한 모든 정보가 삭제됩니다 )`}
                      title={
                        <FormattedMessage
                          id="admin.classroom-detail.delete-lesson-desc"
                          values={{ seq: getLessonSeq(record) }}
                        />
                      }
                      onConfirm={() =>
                        this.deleteLecture(
                          user.orgId,
                          classroomId,
                          record.lectureId
                        )
                      }
                      onCancel={() =>
                        message.error(
                          locale === "en" ? "Canceled" : "수업 삭제 취소"
                        )
                      }
                      okText={
                        <FormattedMessage id="admin.classroom-detail.delete-lesson" />
                      }
                      placement="bottomRight"
                      cancelText={
                        <FormattedMessage id="admin.classroom-detail.cancel" />
                      }
                    >
                      <Button style={{ ...buttonStyle }} type="danger">
                        <FormattedMessage id="admin.classroom-detail.delete-lesson" />
                      </Button>
                    </Popconfirm>
                  </>
                )}
            </span>
          ) : (
            <span>
              {enableDangerousFeature &&
                this.context.type === "campus" &&
                record.state !== "completed" &&
                (userPermission === "master" ||
                  (userPermission && userPermission.classCreateDelete)) && (
                  <>
                    <Popconfirm
                      key="1"
                      // title={`"${record.title}" 수업을 삭제하시겠습니까? (수업에 대한 모든 정보가 삭제됩니다 )`}
                      title={
                        <FormattedMessage
                          id="admin.classroom-detail.delete-lesson-desc"
                          values={{
                            seq: getLessonSeq(record),
                          }}
                        />
                      }
                      onConfirm={() =>
                        this.deleteLecture(
                          user.orgId,
                          classroomId,
                          record.lectureId
                        )
                      }
                      onCancel={() =>
                        message.error(
                          locale === "en" ? "Canceled" : "수업 삭제 취소"
                        )
                      }
                      okText={
                        <FormattedMessage id="admin.classroom-detail.delete-lesson" />
                      }
                      placement="bottomRight"
                      cancelText={
                        <FormattedMessage id="admin.classroom-detail.cancel" />
                      }
                    >
                      <Button style={{ ...buttonStyle }} type="danger">
                        <FormattedMessage id="admin.classroom-detail.delete-lesson" />
                      </Button>
                    </Popconfirm>
                  </>
                )}
            </span>
          )}
        </>
      ),
    });
    // }

    return (
      // <Skeleton loading={this.state.loading} active>
      <Table
        style={{ margin: "24px 0" }}
        loading={this.state.loading}
        dataSource={lectures}
        rowKey="lectureId"
        pagination={
          type === "current"
            ? false
            : {
                defaultPageSize: pageCount,
                total: totalPages * pageCount,
              }
        }
        columns={columns}
        onChange={this.onChange}
      ></Table>
      // </Skeleton>
    );
  }
}

export default withRouter(ClassroomLectureList);
