import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import * as guideActions from "store/modules/guide";
import Axios from "../../../../utils/request";
import {
  Skeleton,
  Row,
  Col,
  List,
  Tag,
  Spin,
  PageHeader,
  Button,
  Icon,
  Descriptions,
  Typography,
  Table,
  Divider,
  Statistic,
  Popconfirm,
  message,
  Tabs,
  Checkbox,
} from "antd";
import { Link, withRouter } from "react-router-dom";
import { values, flatten, filter, merge, map as _map } from "lodash";
import MakeTeamPopup from "../../components/Popup/MakeTeamPopup";
import ConfirmHomeworkPopup from "../../components/Popup/ConfirmHomeworkPopup";
import ChangeGuidePopup from "../../components/Popup/ChangeGuidePopup";
import ClassroomApis from "../../../../utils/classroomApis";
import SetHomeworkPopup from "../../components/Popup/SetHomeworkPopup";
import QuetureName from "../../components/QuetureName";
import { getKoreanDate, formateFullKoreanDate } from "../../../../utils/utils";
import ClassroomLectureList from "../../components/ClassroomLectureList";
import AccountApis from "../../../../utils/accountApis";
import { FormattedMessage } from "react-intl";

const { Title, Text } = Typography;

class ClassroomLecturesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showMakeTeamPopup: null,
      showConfirmHomeworkPopup: false,
      showChangeGuidePopup: false,
      showDelayClassPopup: false,
      showHomeworkPopup: null,
      enableDangerousFeature: false,
    };
  }

  componentDidMount() {
    const { user, ClassroomActions } = this.props;
    const { id } = this.props.match.params;
    console.log("this id: ", id);
  }

  getClassroomDetail = (classroomId) => {
    const { user } = this.props;
    this.setState({
      loading: true,
    });
    const _orgId = user.orgId || user.userId;
    AccountApis.getClassroomDetail(_orgId, classroomId)
      .then((res) => {
        console.log("classroom detail: ", res);

        this.setState({
          loading: false,
          classroomList: [res],
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
      });
  };

  delayLecture(lectureId) {
    const { id: classroomId } = this.props.match.params;

    ClassroomApis.delay(classroomId, lectureId)
      .then((res) => {
        console.log("delay lecture: ", res);
        message.success("수업 연기 완료");
        this.getClassroomDetail(classroomId);
      })
      .catch((e) => {
        console.log("delay lecture failed: ", e);
      });
  }

  startLecture(classroomId, lectureId, state) {
    if (state === "current") {
      this.enterLecture(classroomId);
    } else {
      ClassroomApis.start(classroomId, lectureId).then((res) => {
        console.log("start lecture: ", res);
        this.enterLecture(classroomId);
      });
    }
  }

  enterLecture(classroomId) {
    this.props.history.push(`/classroom/${classroomId}`);
    window.open(`/classroom/${classroomId}?preview=1`, "_blank");
  }

  render() {
    const { user, userPermission, pending, classroomList } = this.props;
    const { enableDangerousFeature } = this.state;
    const { id: classroomId } = this.props.match.params;
    const buttonStyle = {
      marginBottom: 8,
    };

    return (
      <Skeleton loading={this.state.loading || pending} active>
        {classroomId && (
          <>
            <PageHeader
              title={
                <Title level={2} style={{ marginBottom: 0 }}>
                  <FormattedMessage id="admin.classroom-lectures.title" />
                </Title>
              }
              extra={[
                <span key="1" align="right">
                  {(userPermission === "master" ||
                    (userPermission &&
                      (userPermission.lectureStart ||
                        userPermission.classCreateDelete))) && (
                    <Checkbox
                      defaultChecked={enableDangerousFeature}
                      onClick={() => {
                        this.setState({
                          enableDangerousFeature: !enableDangerousFeature,
                        });
                      }}
                    >
                      <FormattedMessage id="admin.classroom-lectures.management" />
                    </Checkbox>
                  )}
                </span>,
              ]}
            ></PageHeader>
            <div className="admin-page-content">
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane
                  tab={
                    <FormattedMessage id="admin.classroom-lectures.upcoming" />
                  }
                  key="1"
                >
                  <ClassroomLectureList
                    classroomId={classroomId}
                    type="pending"
                    user={user}
                    userPermission={userPermission}
                    onUpdate={() =>
                      console.log("onUpdate ClassroomLectureList")
                    }
                    onUpdateClassroom={() =>
                      this.getClassroomDetail(classroomId)
                    }
                    enableDangerousFeature={enableDangerousFeature}
                    locale={process.env.REACT_APP_LOCALE || "ko"}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <FormattedMessage id="admin.classroom-lectures.completed" />
                  }
                  key="2"
                >
                  <ClassroomLectureList
                    classroomId={classroomId}
                    type="completed"
                    user={user}
                    userPermission={userPermission}
                    onUpdate={() =>
                      console.log("onUpdate ClassroomLectureList")
                    }
                    onUpdateClassroom={() =>
                      this.getClassroomDetail(classroomId)
                    }
                    enableDangerousFeature={enableDangerousFeature}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </>
        )}
      </Skeleton>
    );
  }
}

export default withRouter(
  connect(
    ({ auth, classroom }) => ({
      pending: auth.pending || classroom.pending,
      auth: auth,
      user: auth.user,
      logged: auth.logged,
      userPermission:
        auth.user.type === "org" ? "master" : auth.user.permission,
      classroomList: classroom.classroomList,
    }),
    (dispatch) => ({
      ClassroomActions: bindActionCreators(classroomActions, dispatch),
    })
  )(ClassroomLecturesPage)
);
