import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { getLessonSeq } from "utils/utils";

const ReportQuetureList = ({ rowSpan, list, isBreakPage }) => {
  console.log("reportQuetuerList: ", list);
  return (
    <table className={classNames("curri", { "break-page": isBreakPage })}>
      <tbody>
        <tr>
          <th className="title-count">
            <FormattedMessage id="admin.report.lesson" />
          </th>
          <th className="title-queture">
            <FormattedMessage id="admin.report.queture-name" />
          </th>
          <th className="title-activity">
            <FormattedMessage id="admin.report.key-concepts-and-activities" />
          </th>
          <th className="title-queture-correct-rate">
            <FormattedMessage id="admin.report.learning-level" />
          </th>
          <th className="title-queture-homework-queture">
            <FormattedMessage id="admin.report.review-tasks" />
          </th>
        </tr>
        {list.map((lecture, index) => {
          // const _quetureList = lecture.quetures.filter((q, i) => q.quizzes);
          const _quetureList = lecture.quetures;
          let wasHomeworkQuetureCompleted = true;

          //return lecture.quetures.map((q, i) => {
          return _quetureList.map((q, i) => {
            // if (!q.quizzes) {
            //    return;  // skip queture without quizzes (by YJ as Sophie's req at 2022-02-04)
            // }

            // NOTE: handle solved=0 case as no-data
            let countClass = lecture.mvp ? "count_mvp" : "count_normal";
            let correctRate =
              !lecture.absent && q.solved && q.quizzes
                ? Math.round((100 * q.corrects) / q.quizzes)
                : null;
            let correctState =
              correctRate >= 80 ? (
                <font color="#2020FF">
                  <FormattedMessage id="admin.report.learning-level-high" />
                </font>
              ) : correctRate >= 20 ? (
                <font color="#444444">
                  <FormattedMessage id="admin.report.learning-level-mid" />
                </font>
              ) : correctRate >= 0 ? (
                <font color="#FF2020">
                  <FormattedMessage id="admin.report.learning-level-low" />
                </font>
              ) : (
                "-"
              );

            if (
              lecture.completed &&
              q.homeworkQuetureCompleted === false &&
              wasHomeworkQuetureCompleted
            ) {
              wasHomeworkQuetureCompleted = false;
            }

            return (
              <tr
                key={`lecture-${index}-${i}`}
                className={
                  "curri striped-rows-" + (index % 2 ? "dark" : "bright")
                }
                style={{
                  pageBreakBefore: "auto",
                  pageBreakInside: "avoid",
                  pageBreakAfter: "auto",
                }}
              >
                {i === 0 && (
                  <td
                    rowSpan={_quetureList.length}
                    className="count"
                    style={{
                      pageBreakBefore: "auto",
                      pageBreakInside: "avoid",
                      pageBreakAfter: "auto",
                    }}
                  >
                    <div className={countClass}>
                      {/* {lecture.title} */}
                      <FormattedMessage
                        id="admin.report.lesson-value"
                        values={{
                          lessonSeq: getLessonSeq(lecture),
                        }}
                      />
                      {!lecture.completed ? (
                        <span className="count_absent">
                          <font color="grey">
                            {" "}
                            <FormattedMessage id="admin.report.class-hours-not-completed" />
                          </font>
                        </span>
                      ) : lecture.absent ? (
                        <span className="count_absent">
                          {" "}
                          <FormattedMessage id="admin.report.absences" />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <span className="count_date">
                        {String(lecture.date).replaceAll("-", ".")}
                      </span>
                    </div>
                  </td>
                )}
                <td className="queture">{q.title}</td>
                <td className="activity">
                  {q.point === null || q.point === ""
                    ? q.conceptTags.join(",")
                    : q.point}

                  {/*
                      NOTE: DO NOT EXPOSE q.point valus until major quetures's report_queture_point's are set
                            (now, junior quetures only) (Sophie, 2021-12-13)
                    */}
                  {/*
                      (q.point && false)? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: q.point.replace("\n", "<br/>"),
                        }}
                      ></div>)
                    : */}
                </td>
                <td className="queture_correct_rate">
                  {correctRate != null ? correctState : "-"}
                </td>
                <td className="queture_homework_queture">
                  {!lecture.completed
                    ? "-"
                    : q.quizzes
                    ? q.homeworkQuetureCompleted
                      ? "O"
                      : "X"
                    : q.homeworkQueture
                    ? "O"
                    : "X"}
                  {/* (!lecture.completed || !q.quizzes) ? '-' :
                        (q.homeworkQuetureCompleted ? 'O' : 'X')+' ('+
                        (q.quetureStatus == 'ok' ? 'O' : 'X')+'/'+
                        ((q.quizzes <= q.corrects) ? '-' : (q.quizzes <= q.corrects + q.reviewSolved) ? 'O' : 'X')+')' +
                        '['+q.quizzes+' '+q.corrects+' '+q.reviewSolved+']' */}
                </td>
                {/*
                  // NOTE: homeworkWorkbook field does not mean quiz solved
                  <td className="queture_homework_workbook">
                    {q.homeworkWorkbook? 'O' : 'X'}
                  </td>
                  <td rowSpan={_quetureList.length} className="correct_rate">
                    {lecture.solved > 0 ? Math.round(100 * lecture.corrects / lecture.solved)+'%' : '-'}
                  </td>
                  */}
              </tr>
            );
          });
        })}
      </tbody>
    </table>
  );
};

export default ReportQuetureList;
