export default {
  "login.admin-login": "캠퍼스 로그인",
  "login.student-login": "학습자 로그인",
  "login-form.id-placeholder": "아이디를 입력해주세요.",
  "login-form.password-placeholder": "비밀번호를 입력해주세요.",
  "login-form.login-button": "로그인",
  "login-form.login-loading": "로그인중...",
  "login-error-message":
    "잘못된 계정 또는 비밀번호가 입력되었습니다.\n다시 시도해 주세요.",
};
