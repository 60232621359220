import React, { Component } from "react";
import NewsletterApis from "../../../../utils/newsletterApis";
import Logo from "../../../../style/components/img/main_img.svg";
import { Button } from "antd";
import ShareLinkPopup from "../../../../components/Popup/ShareLinkPopup"
import * as url from "url";

class PublishedNewsletterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      isLoading: false,
      showShareLinkPopup: false,
      data: null
    }
  }

  componentDidMount() {
    this.load();
  }

  load() {
    const ss = window.location.pathname.split(/\//);
    const id = ss[ss.length - 1];

    this.setState({
      id: id,
      isLoading: true,
      showShareLinkPopup: false,
    });

    NewsletterApis.getNewsletter(id).then((res) => {
      this.setState({
        data: res,
        isLoading: false
      })
    })
  }

  render() {
    const { id, isLoading, data, showShareLinkPopup } = this.state;
    console.log(data)
    if (isLoading) {
      return <div></div>
    }
    return (<div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
        <div style={{
          width: '360px',
        }}>
          <img src={Logo}></img>

          <div style={{ fontFamily: 'Noto Sans' }}>
            <p dangerouslySetInnerHTML={{ __html: data ? data["text"] : '' }}
               style={{ color: '#000000' }}></p>
          </div>
          <div style={{ width: 'auto', display: 'flex' }}>
            <Button style={{
              height: "50px",
              width: '140px',
              backgroundColor: '#3289C9',
              borderRadius: '50px',
              margin: 'auto',
              marginBottom: '80px'
            }}
                    onClick={e => {
                      this.setState({
                        showShareLinkPopup: true
                      })
                    }}
            >
              <p style={{
                fontFamily: 'Noto Sans KR',
                letterSpacing: '-0.03em',
                fontSize: '16px',
                color: '#ffffff',
                display: 'inline-flex',
                alignItems: "center",
                textAlign: "center",
                lineHeight: '150%'
              }}>
                공유하기
              </p>
            </Button>
          </div>
          {
            showShareLinkPopup &&
            <ShareLinkPopup
              elem={true}
              isOpen={showShareLinkPopup}
              link={process.env.REACT_APP_DOMAIN + this.props.location.pathname}
              kakaoLink={process.env.REACT_APP_DOMAIN + this.props.location.pathname}
              onClose={() => {
                this.setState({ showShareLinkPopup: false })
              }}/>
          }
        </div>
      </div>
    );
  }
}

export default PublishedNewsletterPage;