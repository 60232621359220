export default {
  "classroom.attendance": "Attendance",
  "classroom.lectureTime": "Lecture Time!",
  "classroom.quetureSeq": "{seq}th Queture",
  "classroom.quetureSeq-1": "{seq}st Queture",
  "classroom.quetureSeq-2": "{seq}nd Queture",
  "classroom.quetureSeq-3": "{seq}rd Queture",
  "classroom.quetureSeq-4": "{seq}th Queture",
  "classroom.quetureSeq-5": "{seq}th Queture",
  "classroom.quetureSeq-6": "{seq}th Queture",

  "classroom.quetureDescTitle": "What are we learning today?",
  "classroom.pictureQuizDescTitle": "오늘 배운 내용을 떠올리며 깨봉 그림퀴즈를 풀어보세요!",
  "classroom.quebonSongDescTitle": "깨봉송을 다같이 신나게 불러 봐요!",
  
  "classroom.queture-point": "Queture Point",

  "classroom.makeTeamTitle": "Mission of the Quiz is",
  "classroom.soloPlay": "Solo Play",
  "classroom.soloPlayDesc": "Let's solve the Quiz individually.",
  "classroom.teamPlay": "Team Play",
  "classroom.teamPlayDesc": "Let's solve the Quiz with the friends.",
  "classroom.select-your-teammate": "Select your teammate!",
  "classroom.my-teammate-is": `My teammate <br /> is`,
  "classroom.move-to-leaders-seat": "Move to the leader's seat",
  "classroom.leader": "Leader",
  "classroom.mark": "Let's Check!",
  "classroom.marking": "Checking",
  "classroom.next-question": "Next",
  "classroom.prev-question": "Prev",
  "classroom.finish": "Finish",
  "classroom.incorrect": "Incorrect",
  "classroom.incorrect-desc": "Try Again",
  "classroom.correct": "Correct",
  "classroom.correct-desc": "Awesome!",
  "classroom.todays-game": "Today's Game",
  "classroom.todays-activity": "Today's Activity",
  "classroom.todays-activity-kids": "Today's Activity",
  "classroom.todays-mvp": `Today's <span>MVP</span>`,
  "classroom.class-has-end": "The class has ended",
  "classroom.check-home-training": "Check the assignments in Home Training.",
  "classroom.view-solution": "Solution",
  "classroom.solution": "Solution",
  "classroom.check-the-answer": "Check the answer",
  "classroom.my-answer": "My answer",
  "classroom.game-desc": "Think while playing the game.",
  "classroom.activity-desc": "Shall we do this together?",
  "classroom.activity-desc-kids": "Shall we do this together?",
  "classroom.order-1": "1st",
  "classroom.order-2": "2nd",
  "classroom.order-3": "3rd",
  "classroom.order-4": "4th",
  "classroom.order-5": "5th",
  "classroom.order-6": "6th",
  "classroom.correct-questions": "{count} Correct",
  "classroom.challengeQuizTitle": "도전 문제",
  "classroom.storytellingTitle": "수학동화",
  "classroom.pictureQuizTitle": "깨봉 그림퀴즈",
  "classroom.quebonSongTitle": "깨봉송",
  "classroom.standby-mode": "Standby Mode",
  "classroom.student-list": "Student List",
  "classroom.student-list-name": "Name",
  "classroom.student-list-age": "Age {age}",
  "classroom.student-list-attendance": "Attendance",
  "classroom.student-list-queture-correct-rate": "Queture {seq} Correct Rate",
  "classroom.student-list-numbers-of-mvps": "Numbers of MVPs",
  "classroom.kids-student-list-numbers-of-mvps": "Numbers of compliments selected",
  "classroom.kids-compliments": "Compliments",
  "classroom.kids-attendance": "Attendance",
  "classroom.student-list-confirm": "Confirm",
  "classroom.online-class": "Online Class",
  "classroom.refresh-all-students": "Refresh All Students",
  "classroom.refresh-student": "Refresh Student {studentName}",
  "classroom.preview-next-page": "Preview the Next Page",
  "classroom.exit-class": "Exit the Class Mode",
  "classroom.end-class": "End",
  "classroom.check-team-cpmposition": "Check Team Composition",
  "classroom.start-watching-the-video": "Start Watching the Video",
  "classroom.view-lesson-plan": "View Lesson Plan",
  "classroom.queture-remocon": "Remote Control",
  "classroom.queture-remocon-quebon-song": "Quebon Song",
  "classroom.queture-remocon-home-schooling": "Home Schooling",
  "classroom.queture-sync": "Synchronization",
  "classroom.view-quiz": "View Quiz",
  "classroom.drag-text-quiz-desc": "Drag the text to the correct position.",
  "classroom.drag-text-quiz-choices-desc": "Choices",
  "classroom.make-team-left-people-in-team": "3 people left in team",
  "classroom.make-team-shuffle-random": "Shuffle Random",
  "classroom.make-team-desc": "You can Set a Minimum of 2 and a Maximum of 6 people per Team.",
  "classroom.make-team-leader": "Leader",
  "classroom.make-team-random-toast": "Randomized teams are formed.",
  "classroom.quiz-list-order-1": "Quiz 1",
  "classroom.quiz-list-order-2": "Quiz 2",
  "classroom.quiz-list-order-3": "Quiz 3",
  "classroom.quiz-list-order-4": "Quiz 4",
  "classroom.quiz-list-order-5": "Quiz 5",
  "classroom.view-quiz-list": "View the Quiz Screen",
  "classroom.quiz-list-correct-count": "{count} Correct",
  "classroom.quiz-list-view-quiz": "View",
  "classroom.quiz-list-check-the-answer": "Check the Answer",
  "classroom.quiz-list-note": "Note",
  "classroom.quiz-list-draw": "Draw",
  "classroom.quiz-list-erase": "Erase",
  "classroom.quiz-list-delete-all": "Delete All",
  "classroom.quiz-list-note-close": "Close",
  "classroom.start-game": "Start Game",
  "classroom.highest-score": "Highest Score",
  "classroom.current-score": "Current Score",
  "classroom.move-to-mvp": "Go to MVP",
  "classroom.summary-queture": "Summary Queture",
  "classroom.queture-playing": "Playing",
  "classroom.send-end-class-message": "Send end-of-class Text Message",
  "classroom.choose-to-text": "Choose Who to Text",
  "classroom.endclass-student-name": "Name",
  "classroom.endclass-student-attendance": "Attendance",
  "classroom.endclass-quiz": "Quiz",
  "classroom.endclass-game": "Game",
  "classroom.endclass-text-content": "Text Content",
  "classroom.endclass-check-default": "Default",
  "classroom.endclass-check-absent": "Absences",
  "classroom.endclass-check-incomplete": "Incomplete",
  "classroom.endclass-receive-number": "Receiving Number",
  "classroom.endclass-send-message": "Send Message",
  "classroom.endclass-sending": "Sending",
  "classroom.endclass-confirm-text-content": "Confirm Text Content",
  "classroom.endclass-send": "Send",
  "classroom.endclass-cancel": "Cancel",
  "classroom.endclass-toast-select-student": "Please select a student.",
  "classroom.endclass-toast-send-success": "Text message sent successfully.",
  "classroom.endclass-place": "{place}{ordinal}",
  "classroom.section-name-opened": "Attendance",
  "classroom.section-name-queture": "{seq}{ordinal} Queture",
  "classroom.section-name-summary": "Summary",
  "classroom.section-name-group": "Team Setting",
  "classroom.section-name-quiz": "Quiz",
  "classroom.section-name-game": "Game",
  "classroom.section-name-activity": "Activity",
  "classroom.section-name-mvp": "MVP",
  "classroom.section-name-closed": "End Class",
  "classroom.section-name-mail": "End Class - Mail",
  "classroom.section-name-challenge": "Challenge Quiz",
  "classroom.section-name-storytelling": "Storytelling",
  "classroom.section-name-picturequiz": "Picture Quiz",
  "classroom.section-name-quebonsong": "Quebon Song",
  "classroom.popup.queture-point": "Queture Point",// 깨처 포인트
  "classroom.popup.caution": "Caution",// 주의사항
};
