import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import Toast from "components/Toast/Toast";
import throttle from "lodash.throttle";
import logo from "./logo.svg";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";

import "./_Header.scss";
import { Tooltip } from "antd";

class HomeHeader extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      isFixedTop: false,
    };

    this.handleScroll = throttle(this.handleScroll, 200);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("click", this.handleClickOutside);
  }
  handleScroll = () => {
    const { isFixedTop } = this.state;
    const currentScrollPos = window.pageYOffset;
    const changeFixedTop = currentScrollPos > 10;
    if (
      (isFixedTop && currentScrollPos > 10) ||
      (!isFixedTop && currentScrollPos <= 10)
    ) {
      return;
    }
    this.setState({
      isFixedTop: changeFixedTop,
    });
  };

  render() {
    const headerClasses = classNames("ht-header", {
      "is-fixed-top": this.state.isFixedTop,
    });
    return (
      <div className={headerClasses}>
        <div className="ht-header-wrap">
          <Link to={this.context.type === "campus" ? "/home" : "/homework"}>
            <img src={logo} alt="" className="logo" />
          </Link>

          {!this.props.lectures ? (
            <ul
              className="nav"
              style={{ cursor: "pointer" }}
              onClick={() => Toast.warning("수업 후 사용가능합니다.")}
            >
              <li>
                <FormattedMessage id="hometraining.menu.today" />
              </li>
              <li>
                <FormattedMessage id="hometraining.menu.review" />
              </li>
              <li>
                <FormattedMessage id="hometraining.menu.game" />
              </li>
            </ul>
          ) : (
            <ul className="nav">
              {/* 선택된 상태인 li에 is-active */}
              <li>
                <NavLink to="/homework" activeClassName="is-active">
                  <FormattedMessage id="hometraining.menu.today" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/review" activeClassName="is-active">
                  <FormattedMessage id="hometraining.menu.review" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/game" activeClassName="is-active">
                  <FormattedMessage id="hometraining.menu.game" />
                </NavLink>
              </li>
            </ul>
          )}
          {this.context.type === "school" && (
            <span className="qp-wrap">
              {this.props.user && (
                <Tooltip
                  title={
                    <span>{`수업 중 정답을 많이 맞추거나 오늘의 과제를 열심히 하면 QP가 쌓여요!`}</span>
                  }
                  // trigger={"click"}
                >
                  <span className="qp">
                    <strong>{`${this.props.user.classroom.course.title}`}</strong>
                    {`${this.props.user.point} QP`}
                  </span>
                  <span className="qp">
                    <span>
                      <strong>전체</strong>
                      {`${this.props.user.point} QP`}
                    </span>
                  </span>
                </Tooltip>
              )}
            </span>
          )}
          <ul className="login-info" style={{ marginLeft: "363px" }}>
            <li>
              <NavLink to="/my-info">
                <FormattedMessage id="hometraining.menu.setting" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/logout">
                <FormattedMessage id="hometraining.menu.logout" />
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

// export default HomeHeader;
export default connect(
  ({ auth }) => ({
    pending: auth.pending,
    user: auth.user,
  }),
  null
)(HomeHeader);
