import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import * as studentActions from "store/modules/student";
import {
  PageHeader,
  Typography,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Upload,
  Button,
  message,
  Skeleton,
  Checkbox,
} from "antd";
import moment from "moment";
import Axios from "../../../../utils/request";
import SearchAddressPopup from "../../components/Popup/SearchAddressPopup";
import StudentProfile from "../../components/StudentProfile";
import { values, mapValues, filter } from "lodash";
import { parseAxiosError } from "../../../../utils/APIUtils";
import CommonApis from "../../../../utils/commonApis";
import AccountApis from "../../../../utils/accountApis";
import axios from "axios";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";

const { Title } = Typography;
const locale = process.env.REACT_APP_LOCALE || "ko";

class GuideEditPage extends Component {
  static contextType = ConstantsContext;

  constructor(props) {
    super(props);

    this.state = {
      showAddressPopup: false,
      isLoading: false,
      guideData: null,
      profileImageId: null,
      orgList: [],
      oldClassroomIds: [],
    };
  }

  loadOrg = () => {
    Axios.get(`/academy/v2/accounts`).then((e) => {
      this.setState({
        orgList: e,
      });
    });
  };

  componentDidMount() {
    const { user, ClassroomActions } = this.props;
    const { guideId } = this.props.match.params;
    console.log("this guideId: ", guideId);
    // this.props.form.setFields({
    //   account: "",
    //   name: "",
    //   mobile: "",
    //   birthday: moment(new Date()),
    //   joinDate: moment(new Date()),
    //   address1: "",
    //   address2: ""
    // });

    if (user && guideId) {
      this.getGuideDetail(guideId);
      ClassroomActions.getClassroomList(user.orgId);
      this.loadOrg();
    }
  }

  getGuideDetail(guideId) {
    const { user } = this.props;
    this.setState({
      isLoading: true,
    });
    Axios.get(`/academy/v2/accounts/${user.orgId}/guides/${guideId}`)
      .then((res) => {
        console.log("guide detail: ", res);
        this.setState({
          isLoading: false,
          guideData: res,
          oldClassroomIds: res.classrooms.map((c) => c.classroomId),
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.props.form);
    const { profileImageId, oldClassroomIds } = this.state;
    this.props.form.validateFields((err, values) => {
      console.log("Received values of form: ", values);

      const unlinkClassroomIds = oldClassroomIds.filter(
        (cid) => values.classroomIds.indexOf(cid) == -1
      );
      console.log("### classroomIds", values.classroomIds);
      console.log("### unlinkClassromIds", unlinkClassroomIds);

      if (!err) {
        const year = moment(values.birthday).year();
        let day = Number.parseInt(moment(values.birthday).date());
        let month = Number.parseInt(moment(values.birthday).month() + 1);
        day = day > 10 ? day : "0" + day;
        month = month > 10 ? month : "0" + month;

        const joinYear = moment(values.joinDate).year();
        const joinMonth = Number.parseInt(moment(values.joinDate).month() + 1);
        const joinDay = Number.parseInt(moment(values.joinDate).date());

        const data = {
          orgIdList: values.organizationViewList,
          profileImageId: profileImageId,
          name: values.name,
          account: values.account,
          birthday: values.birthday
            ? {
                day: day,
                month: month,
                year: year,
              }
            : null,
          mobile: values.mobile || null,
          joinDate: {
            day: joinDay > 10 ? joinDay : "0" + joinDay,
            month: joinMonth > 10 ? joinMonth : "0" + joinMonth,
            year: joinYear,
          },
          address: {
            address1: values.address1,
            address2: values.address2,
            zipCode: values.zipcode,
          },
        };
        console.log("post data: ", data);
        this.setState(
          {
            isLoading: true,
          },
          () => {
            this.upqdateGuide(data, values.classroomIds, unlinkClassroomIds);
          }
        );
      }
    });
  };

  upqdateGuide = (data, classroomIds, unlinkClassroomIds) => {
    AccountApis.editGuide(
      this.props.user.orgId,
      this.state.guideData.guideId,
      data
    )
      .then((res) => {
        // form.resetFields();
        if (classroomIds || unlinkClassroomIds) {
          CommonApis.setMultipleClassrooms(
            this.props.user.orgId,
            res.guideId,
            classroomIds,
            unlinkClassroomIds
          ).then(
            axios.spread((...responses) => {
              console.log("set multiple classrooms res: ", responses);
              this.onDone(this.state.guideData.guideId);
            })
          );
        } else {
          this.onDone(this.state.guideData.guideId);
        }
      })
      .catch((e) => {
        console.log(e);
        const error = parseAxiosError(e);
        message.error(`${this.context.guideName} 수정 실패`);
        message.error(`${error.code}: ${error.message}`);
        this.setState({
          isLoading: false,
        });
      });
  };

  onDone = (guideId) => {
    let msg = "선생님 수정 완료";
    if (locale === "en") {
      msg = "Teacher edits complete";
    }
    message.success(msg);
    this.setState({
      isLoading: false,
    });
    this.props.history.push(`/admin/guide/detail/${guideId}`);
  };

  handleUploadProfile = (contentId) => {
    if (contentId) {
      this.setState({
        profileImageId: contentId,
      });
    }
  };

  handleChangeClassroom = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  render() {
    const { isLoading, guideData, orgList } = this.state;
    const { pending, classroomList: classrooms } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;

    let classroomList = [];
    if (classrooms && guideData) {
      classroomList = filter(classrooms, function (o) {
        return !o.guide || (o.guide && o.guide.guideId === guideData.guideId);
      });
    }

    return (
      <>
        <Skeleton loading={this.state.isLoading || pending} active>
          <PageHeader
            title={
              <Title level={2}>
                {/* {`${this.context.guideName} 상세정보 수정`} */}
                <FormattedMessage id="admin.teachers.edit-teachers" />
              </Title>
            }
          />
          <div className="admin-page-content">
            {guideData && (
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col span={6}>
                    <StudentProfile
                      onUploadProfile={this.handleUploadProfile}
                      student={guideData}
                    />
                  </Col>
                  <Col span={18}>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <FormattedMessage id="admin.teachers.col-name" />
                          }
                        >
                          {getFieldDecorator("name", {
                            initialValue: guideData.name,
                            rules: [
                              {
                                required: true,
                                message: "학생 이름을 입력해주세요.",
                              },
                            ],
                          })(<Input placeholder="학생 이름을 입력해주세요." />)}
                        </Form.Item>
                        <Form.Item
                          label={
                            <FormattedMessage id="admin.teachers.col-birthday" />
                          }
                        >
                          {getFieldDecorator("birthday", {
                            initialValue: guideData.birthday
                              ? moment(
                                  new Date(
                                    guideData.birthday.year +
                                      "-" +
                                      guideData.birthday.month +
                                      "-" +
                                      guideData.birthday.day
                                  )
                                )
                              : null,
                            rules: [
                              {
                                required: this.context.type === "campus",
                                message: "생년월일을 선택해주세요.",
                              },
                            ],
                          })(
                            <DatePicker
                              placeholder="생년월일을 선택해주세요."
                              format="YYYY-MM-DD"
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <FormattedMessage id="admin.teachers.col-id" />
                          }
                        >
                          {getFieldDecorator("account", {
                            initialValue: guideData.account,
                            rules: [
                              {
                                required: true,
                                message: "아이디를 입력해주세요.",
                              },
                            ],
                          })(
                            <Input
                              placeholder="아이디를 입력해주세요."
                              readOnly
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          label={
                            <FormattedMessage id="admin.teachers.col-contact" />
                          }
                        >
                          {getFieldDecorator("mobile", {
                            initialValue: guideData.mobile,
                            rules: [
                              // {
                              //   required: true,
                              //   message: "학생 연락처를 입력해주세요."
                              // }
                            ],
                          })(
                            <Input
                              placeholder="연락처를 입력해주세요."
                              onChange={function (e) {
                                e.target.value = e.target.value.replaceAll(
                                  " ",
                                  ""
                                );
                              }}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <FormattedMessage id="admin.teachers.col-hire-date" />
                          }
                        >
                          {getFieldDecorator("joinDate", {
                            initialValue: guideData.joinDate
                              ? moment(
                                  new Date(
                                    guideData.joinDate.year +
                                      "-" +
                                      guideData.joinDate.month +
                                      "-" +
                                      guideData.joinDate.day
                                  )
                                )
                              : null,
                            rules: [
                              {
                                required: true,
                                message: "입사일을 선택해주세요.",
                              },
                            ],
                          })(
                            <DatePicker
                              placeholder="입사일을 선택해주세요."
                              format="YYYY-MM-DD"
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        {locale === "en" ? (
                          <>
                            <Form.Item
                              label={
                                <FormattedMessage id="admin.teachers.col-address" />
                              }
                            >
                              {getFieldDecorator("address1", {
                                initialValue:
                                  guideData.address &&
                                  guideData.address.address1,
                                rules: [
                                  {
                                    required: true,
                                    // message: "주소찾기를 통해 입력해주세요."
                                  },
                                ],
                              })(<Input placeholder="Address 1" />)}
                            </Form.Item>
                          </>
                        ) : (
                          <Form.Item
                            label={
                              <FormattedMessage id="admin.teachers.col-address" />
                            }
                          >
                            {getFieldDecorator("address1", {
                              initialValue:
                                guideData.address && guideData.address.address1,
                              rules: [
                                // {
                                //   required: true,
                                //   message: "주소찾기를 통해 입력해주세요."
                                // }
                              ],
                            })(
                              <Input
                                placeholder="주소찾기를 통해 입력해주세요."
                                readOnly
                                disabled
                              />
                            )}
                            <Button
                              onClick={() =>
                                this.setState({
                                  showAddressPopup: true,
                                })
                              }
                            >
                              주소찾기
                            </Button>
                            <SearchAddressPopup
                              title={`주소찾기`}
                              visible={this.state.showAddressPopup === true}
                              maskClosable={false}
                              onDone={(address1, zipcode) => {
                                console.log("address1: ", address1);
                                console.log("zipcode: ", zipcode);
                                this.props.form.setFieldsValue({
                                  address1: address1,
                                  zipcode: zipcode,
                                });
                                this.setState({
                                  showAddressPopup: false,
                                });
                              }}
                              onCancel={() => {
                                this.setState({
                                  showAddressPopup: false,
                                });
                              }}
                              footer={null}
                            />
                          </Form.Item>
                        )}
                        <Form.Item label="">
                          {getFieldDecorator("address2", {
                            initialValue:
                              guideData.address && guideData.address.address2,
                            rules: [
                              // {
                              //   required: true,
                              //   message: "상세주소를 입력해주세요."
                              // }
                            ],
                          })(
                            <Input
                              placeholder={
                                locale === "en"
                                  ? "Address 2"
                                  : "상세주소를 입력해주세요."
                              }
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          label="zipcode"
                          style={{
                            display: locale === "en" ? "block" : "none",
                          }}
                        >
                          {getFieldDecorator("zipcode", {
                            initialValue:
                              guideData?.address && guideData?.address.zipCode,
                            rules: [
                              {
                                required: locale === "en" ? true : false,
                              },
                            ],
                          })(<Input placeholder="zipcode" />)}
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          label={
                            <FormattedMessage id="admin.teachers.col-office" />
                          }
                        >
                          {getFieldDecorator("organizationViewList", {
                            initialValue:
                              guideData &&
                              values(
                                mapValues(
                                  guideData.organizationViewList,
                                  function (o) {
                                    return o.orgId;
                                  }
                                )
                              ),
                          })(
                            <Checkbox.Group
                              style={{ width: "100%" }}
                              onChange={this.handleChangeClassroom}
                            >
                              <Row>
                                {orgList.map((c) => (
                                  <Col span={6} key={c.orgId}>
                                    <Checkbox value={c.orgId}>
                                      {c.name}
                                    </Checkbox>
                                  </Col>
                                ))}
                              </Row>
                            </Checkbox.Group>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <FormattedMessage id="admin.teachers.col-classes" />
                          }
                        >
                          {getFieldDecorator("classroomIds", {
                            initialValue:
                              guideData &&
                              values(
                                mapValues(guideData.classrooms, function (o) {
                                  return o.classroomId;
                                })
                              ),
                          })(
                            <Checkbox.Group
                              style={{ width: "100%" }}
                              onChange={this.handleChangeClassroom}
                            >
                              <Row>
                                {classroomList.map((c) => (
                                  <Col span={6} key={c.classroomId}>
                                    <Checkbox value={c.classroomId}>
                                      {c.name}
                                    </Checkbox>
                                  </Col>
                                ))}
                              </Row>
                            </Checkbox.Group>
                          )}
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.isLoading}
                          >
                            {/* 수정완료 */}
                            <FormattedMessage id="admin.teachers.edit-confirm" />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </div>
        </Skeleton>
      </>
    );
  }
}

export default connect(
  ({ auth, classroom }) => ({
    pending: auth.pending || classroom.pending,
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    classroomList: classroom.classroomList,
  }),
  (dispatch) => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
  })
)(Form.create()(GuideEditPage));
