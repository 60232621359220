import React, { useState, useEffect } from "react";
import { useMouse } from "react-use";
import LectureTooltip from "./LectureTooltip";
import {
  formateEngTime,
  formateKoreanTime,
  getOrdinals,
} from "../../../utils/utils";
import { filter, orderBy, values } from "lodash";
import { FormattedMessage } from "react-intl";

const LectureContent = (props) => {
  const { lecture, lectureStatus, user } = props;

  const [isMvp, setMvp] = useState(false);
  const [gameRanking, setGameRanking] = useState(null);

  const ref = React.useRef(null);
  const { docX, docY, posX, posY, elX, elY, elW, elH } = useMouse(ref);
  useEffect(() => {
    if (lecture && lecture.sections) {
      const game = filter(lecture.sections, function (o) {
        return o.type === "GAME";
      });
      if (game && game[0] && game[0].ranking) {
        game[0].ranking.map((r, index) => {
          if (r.student.studentId === user.studentId) {
            setGameRanking(index + 1);
          }
        });
      }
      if (lectureStatus && lectureStatus[lecture.lectureId]) {
        const curLectureStatus = lectureStatus[lecture.lectureId];
        if (curLectureStatus.mvp) {
          setMvp(true);
        }
      }
    }

    return () => {
      setMvp(false);
      setGameRanking(null);
    };
  }, []);

  return (
    <div className="wrap" ref={ref}>
      {lecture.state === "completed" ? (
        <>
          <p>
            <span>
              <FormattedMessage id="hometraining.calendar.class-end" />
            </span>
          </p>
          <div className="time" style={{ fontSize: 12 }}>
            {/* {formateKoreanTime(lecture.date.split(" ")[1]) + "수업"} */}
            {props.locale === "en"
              ? formateEngTime(lecture.date.split(" ")[1])
              : formateKoreanTime(lecture.date.split(" ")[1])}
            &nbsp;
            <FormattedMessage id="hometraining.calendar.class" />
          </div>
          {isMvp && (
            <p>
              <span>MVP</span>
              <FormattedMessage id="hometraining.calendar.mvp-selected" />
            </p>
          )}
          {gameRanking && (
            <p>
              <FormattedMessage id="hometraining.calendar.game-ranking" />
              <span>
                <FormattedMessage
                  id="hometraining.calendar.game-ranking-place"
                  values={{
                    place: gameRanking,
                    ordinal: getOrdinals(props.locale, gameRanking, "등"),
                  }}
                />
              </span>
            </p>
          )}
        </>
      ) : lecture.state === "pending" ? (
        <>
          <p>
            <span>
              <FormattedMessage id="hometraining.calendar.class-scheduled" />
            </span>
          </p>
          <div className="time" style={{ fontSize: 12 }}>
            {/* {formateKoreanTime(lecture.date.split(" ")[1]) + "수업"} */}
            {props.locale === "en"
              ? formateEngTime(lecture.date.split(" ")[1])
              : formateKoreanTime(lecture.date.split(" ")[1])}
            &nbsp;
            <FormattedMessage id="hometraining.calendar.class" />
          </div>
        </>
      ) : null}
      <LectureTooltip
        lecture={lecture}
        top={elY}
        left={elX}
        locale={props.locale}
      />
    </div>
  );
};

export default LectureContent;
