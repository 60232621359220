import React, { useState, useEffect } from "react";
import ACheckbox from "../../../components/Teacher/selector/ACheckbox";
import Classmodebtn from "../../../components/Teacher/button/Classmodebtn";
import { Skeleton } from "antd";
import {
  orderBy,
  values,
  mapValues,
  remove,
  filter,
  map,
  sortedUniq,
} from "lodash";
import classNames from "classnames";

import "./_sendmsg.scss";
import axios from "axios";
import { getHost, getDefaultConfig } from "../../../utils/APIUtils";
import Toast from "components/Toast/Toast";
import PreviewMsgPopup from "../components/PreviewMsgPopup";
import { FormattedMessage, injectIntl } from "react-intl";
import { getOrdinals } from "utils/utils";

const msgCommon = `
        
홈트레이닝[오늘의 과제] 접속 링크 : www.quebon.ac  ※ 홈트레이닝은 반드시 PC 환경에서 크롬을 통해 진행해주세요.
학생 아이디 : [[학생 아이디]]
비밀번호 : 학생 생년월일 8자리
`;
const msgCommon_en = `
        
Link to home training [Today's assignment] : www.quebon.ac  ※ Home training must be done through Chrome on a PC en
Student Id : [[Student ID]]
Password : 8 digits of the student's date of birth
`;
const msgContentType = {
  basic:
    `안녕하세요 {{n}}번째 수업이 모두 끝났습니다.
오늘 <<이름>>(이)가 배운 깨처는 (영역) 영역의
(깨처)
입니다. 

수업 직후 깨처 다시보기와 함께 [오늘의 과제] 및 워크북을 진행해주세요. 
워크북은 매 수업이 끝날 때마다 유인물로 지급됩니다. 
부모님께서 직접 채점해주시고 틀린 문제는 다시 풀도록 지도해주세요. 
이해하기 어렵거나 설명이 필요한 문제는 별도로 체크해주시기 바랍니다. ` +
    msgCommon,
  absence:
    `Hello, <<이름>>학생이 오늘 결석을 했네요.
[오늘의 과제]와 워크북을 진행해주세요.` + msgCommon,
  unfinish:
    `Hello, 오늘은 <<이름>> 학생이 함께 수업 마무리를 하지 못했습니다.
[오늘의 과제]와 워크북을 진행해주세요.` + msgCommon,
};
const msgContentType_en = {
  basic:
    `Hello, the class {{n}} has come to an end.
The Queture that <<name>> learned today is the (QuetureName) in Theme (ThemeName).

Immediately after the class, please do the Queture replay, along with the assignment of the day and the workbook.
The workbook will be handed out as a handout at the end of each class.
Have your parents grade the papers themselves and encourage you to redo any question you get wrong.
Please check off any questions you don't understand or need clarification on. ` +
    msgCommon_en,
  absence:
    `Hello, <<Name>> student was absent today. 
Please proceed with [Today's assignment] and workbook.` + msgCommon_en,
  unfinish:
    `Hello. Today, <<Name>> student was unable to finish the class together.
Please proceed with [Today's assignment] and workbook.` + msgCommon_en,
};

const Sendmsg = (props) => {
  const { lectureData, classroomId } = props;
  const { seq, attendances, students, sections, lectureId } = lectureData;
  const [studentList, setStudentList] = useState([]);
  const [checkedStudentList, setCheckStudentList] = useState([]);
  const [sendMsgList, setSendMsgList] = useState([]);
  const [msgType, setMsgType] = useState("basic");
  const [correctCount, setCorrectCount] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [quetureData, setQuetureData] = useState(null);
  const [msgContent, setMsgContent] = useState("");
  const locale = props?.intl?.locale || "ko";

  useEffect(() => {
    if (locale === "en") {
      setMsgContent(msgContentType_en["basic"]);
    }

    return () => {};
  }, [locale]);

  useEffect(() => {
    const quetureSections = filter(sections, function (o) {
      return o.type === "QUETURE";
    });

    const quizSections = filter(sections, function (o) {
      return o.type === "QUIZ";
    });

    console.log("quizList", quizSections);
    let _studentInfoList = {};

    students.map((student) => {
      student["count"] = 0;
      if (student.name) _studentInfoList[student.studentId] = student;
    });

    axios
      .all(
        quetureSections.map((q) =>
          axios.get(`${getHost()}/content/v2/quetures/${q.quetureId}`, {
            ...getDefaultConfig(),
          })
        )
      )
      .then(
        axios.spread((...responses) => {
          console.log("queture list data: ", responses);
          const quetureData = [];
          const regionMap = {};

          responses.map((r, i) => {
            const region =
              r.data.region && !regionMap[r.data.region] ? r.data.region : null;
            if (region) {
              regionMap[region] = true;
            }
            quetureData.push({
              title: `${i + 1}. ${r.data.title}`,
              region: region,
            });
          });

          setQuetureData(quetureData);
        })
      );

    // add quiz correct count
    quizSections &&
      quizSections.map((quizInfo) => {
        const quizList = quizInfo.quiz;
        quizList.map((quiz) => {
          if (
            Object.keys(quiz.solvedMap) &&
            Object.keys(quiz.solvedMap).length > 0
          ) {
            Object.keys(quiz.solvedMap).map((studentId) => {
              if (_studentInfoList[studentId]) {
                if (quiz.solvedMap[studentId].correct === true) {
                  _studentInfoList[studentId]["count"]++;
                }
              }
            });
          }
        });
      });

    // Game
    const gameSections = filter(sections, function (o) {
      return o.type === "GAME";
    });
    const gameRanking =
      gameSections && gameSections[0] && gameSections[0].ranking;
    if (gameRanking) {
      gameRanking.map((r, index) => {
        if (_studentInfoList[r.student.studentId]) {
          _studentInfoList[r.student.studentId].score = r.score;
          _studentInfoList[r.student.studentId].time = r.time;
          _studentInfoList[r.student.studentId].gameRanking = index + 1;
        }
      });
    }

    // add attendance info
    const attendanceList = values(
      mapValues(attendances, function (value, key) {
        value.studentId = key;
        return value;
      })
    );

    students.map((student) => {
      const _studentId = student.studentId;

      const attendanceInfo = filter(attendanceList, function (o) {
        return o.studentId === _studentId;
      });

      console.log("attendanceInfo", attendanceInfo);

      student["attendanceTime"] = attendanceInfo[0].timestamp;
      student["attendanceStatus"] = attendanceInfo[0].status;
    });

    const _studentList = orderBy(
      values(
        mapValues(_studentInfoList, function (value, key) {
          value.studentId = key;
          return value;
        })
      ),
      ["attendanceTime"],
      ["asc"]
    );

    console.log("_studentInfoList: ", _studentList);

    const correctCount = map(_studentInfoList, "count").sort().reverse();
    setCorrectCount(sortedUniq(correctCount));

    setStudentList(_studentList);

    return () => {};
  }, [lectureData]);

  function handleCheckStudent(e) {
    console.log(e.target.checked);
    console.log(e.target.value);

    let _checkedStudentList = checkedStudentList;
    let _sendList = sendMsgList;

    if (e.target.checked) {
      _checkedStudentList = [e.target.value, ..._checkedStudentList];
      const _student = filter(students, function (o) {
        return o.studentId === e.target.value;
      });
      _sendList = [..._student, ..._sendList];
    } else {
      _checkedStudentList = remove(_checkedStudentList, function (n) {
        return n !== e.target.value;
      });
      _sendList = remove(_sendList, function (o) {
        return o.studentId !== e.target.value;
      });
    }
    setCheckStudentList(_checkedStudentList);
    setSendMsgList(_sendList);
  }

  function handleChangeMsgType(e) {
    setMsgType(e.target.value);
    setMsgContent(
      locale === "en"
        ? msgContentType_en[e.target.value]
        : msgContentType[e.target.value]
    );
  }

  function handleMsgContent(e) {
    setMsgContent(e.target.value);
  }

  function handlePreviewData() {
    if (sendMsgList.length === 0) {
      let msg = "학생을 선택해주세요";
      if (locale === "en") {
        msg = "Please select a student";
      }
      Toast.warning(msg);
      return;
    }

    const _previewData = [...sendMsgList];

    _previewData.map((student) => {
      // _previewData.push({
      //   student: student,
      //   template: parseMsgContent(student, null, seq)
      // });
      student["template"] = parseMsgContent(student, null, seq);
    });

    setPreviewData(_previewData);
    console.log(sendMsgList);
    return;
  }

  function handleSendMsg() {
    if (sendMsgList.length === 0) {
      let msg = "학생을 선택해주세요";
      if (locale === "en") {
        msg = "Please select a student";
      }
      Toast.warning(msg);
      return;
    }

    const _sendMsgList = [];
    setLoading(true);
    previewData.map((student) => {
      _sendMsgList.push(
        axios.post(
          `${getHost()}/academy/v2/class/${classroomId}/lectures/${lectureId}/students/${
            student.studentId
          }/messages`,
          {
            template: student.template,
          },
          {
            ...getDefaultConfig(),
          }
        )
      );
    });

    axios.all(_sendMsgList).then(
      axios.spread((...responses) => {
        console.log("queture responses: ", responses);
        let msg = "발송 완료";
        if (locale === "en") {
          msg = "Send Complete";
        }

        Toast.success(msg);
        setLoading(false);
        setPreviewData(null);
      })
    );
  }

  function parseMsgContent(student, quetureNames, seq) {
    if (!student) {
      return null;
    }

    console.log("lectureData", lectureData);

    let _content = msgContent;

    const nameRegex = /\<\<(.*?)\>\>/gim;
    const nameText = student.name;
    const accountRegex = /\[\[(.*?)\]\]/gim;
    const quetureRegexKo = /\(깨처\)/gim;
    const quetureRegexEn = /\(QuetureName\)/gim;
    const quetureRegex = locale === "en" ? quetureRegexEn : quetureRegexKo;
    const quetureText =
      quetureData && quetureData.length > 0
        ? quetureData.map((q) => q.title).reduce((a, b) => a + "\n" + b)
        : "-";
    const regionRegexKo = /\(영역\)( 영역의)?/gim;
    const regionRegexEn = /\(ThemeName\)?/gim;
    const regionRegex = locale === "en" ? regionRegexEn : regionRegexKo;
    const regions = quetureData.filter((q) => q.region).map((q) => q.region);
    let regionText = null;
    if (locale === "en") {
      regionText =
        regions && regions.length > 0
          ? regions.reduce((a, b) => a + ", " + b)
          : "";
    } else {
      regionText =
        regions && regions.length > 0
          ? regions.reduce((a, b) => a + ", " + b) + "$1"
          : "";
    }
    console.log("regions: ", regions);
    const seqRegex = /\{\{(.*?)\}\}/gim;

    _content = _content
      .replace(nameRegex, nameText)
      .replace(accountRegex, student.account)
      .replace(quetureRegex, quetureText)
      .replace(regionRegex, regionText);

    if (seq) {
      _content = _content.replace(seqRegex, seq);
    }

    //     if (seq !== 0) {
    //       _content =
    //         _content +
    //         `

    // 홈트레이닝[오늘의 과제] 접속 링크 : www.quebon.ac  ※ 홈트레이닝은 반드시 PC 환경에서 크롬을 통해 진행해주세요.
    // 학생 아이디 : ${student.account}
    // 비밀번호 : 학생 생년월일 8자리

    // ※ 워크북은 매 수업이 끝날 때마다 유인물로 지급됩니다.
    // ※ 해설지(4회차 수업 분량)를 보시고, 매주 수업 날 전까지 부모님께서 직접 채점해주세요.
    // `;
    //     }

    return _content;
  }
  // ※ 홈트레이닝 접속 후 우측 상단의 설정 메뉴를 통해 자녀의 사진을 등록해주세요.
  console.log("checked Student List: ", checkedStudentList);
  console.log("send msg Student List: ", sendMsgList);
  console.log("msgType: ", msgType);
  console.log("studentList: ", studentList);
  console.log("correcount: ", correctCount);

  return (
    <Skeleton loading={!lectureData} active>
      <div className="msg-layout">
        <div className="big-title u-color-blue">
          <FormattedMessage id="classroom.send-end-class-message" />
        </div>
        <div className="msg-content">
          <section className="msg-target">
            <div className="section-title">
              <FormattedMessage id="classroom.choose-to-text" />
            </div>
            <div className="msg-target-wrap">
              <ul className="title">
                <li>
                  <FormattedMessage id="classroom.endclass-student-name" />
                </li>
                <li>
                  <FormattedMessage id="classroom.endclass-student-attendance" />
                </li>
                <li>
                  <FormattedMessage id="classroom.endclass-quiz" />
                </li>
                <li>
                  <FormattedMessage id="classroom.endclass-game" />
                </li>
              </ul>
              <div className="content">
                <table className="info">
                  <tbody>
                    {studentList.map((student, index) => (
                      <tr
                        key={index}
                        className={classNames({
                          "not-attend":
                            !student.attendanceTime || student.status === "no",
                        })}
                      >
                        <td>
                          <ACheckbox
                            id={`student-chk-${student.studentId}`}
                            name="studentChk"
                            onChange={(e) => handleCheckStudent(e)}
                            value={student.studentId}
                            checked={checkedStudentList.includes(
                              student.studentId
                            )}
                          />
                        </td>
                        <td className="name">
                          {student.name}{" "}
                          <span>
                            <FormattedMessage
                              id="classroom.student-list-age"
                              values={{
                                age:
                                  (student.birthday && student.birthday.age) ||
                                  0,
                              }}
                            />
                          </span>
                        </td>
                        {!student.attendanceTime || student.status === "no" ? (
                          <td className="attend" colSpan="3">
                            <FormattedMessage id="classroom.endclass-check-absent" />
                          </td>
                        ) : (
                          <>
                            <td className="attend">
                              <FormattedMessage
                                id="classroom.endclass-place"
                                values={{
                                  place: index + 1,
                                  ordinal: getOrdinals(locale, index + 1, "등"),
                                }}
                              />
                            </td>
                            <td className="ps">
                              {/* {student.count > 0
                                ? correctCount.findIndex(function (o) {
                                    return o === student.count;
                                  }) +
                                  1 +
                                  "등"
                                : ""} */}
                              <FormattedMessage
                                id="classroom.endclass-place"
                                values={{
                                  place:
                                    correctCount.findIndex(function (o) {
                                      return o === student.count;
                                    }) + 1,
                                  ordinal: getOrdinals(
                                    locale,
                                    correctCount.findIndex(function (o) {
                                      return o === student.count;
                                    }) + 1,
                                    "등"
                                  ),
                                }}
                              />
                            </td>
                            <td className="game">
                              {student?.gameRanking ? (
                                <FormattedMessage
                                  id="classroom.endclass-place"
                                  values={{
                                    place: student?.gameRanking,
                                    ordinal: getOrdinals(
                                      locale,
                                      index + 1,
                                      "등"
                                    ),
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section className="msg-write">
            <div className="section-title">
              <FormattedMessage id="classroom.endclass-text-content" />
            </div>
            <div className="msg-write-wrap">
              <div className="chkbox-wrap">
                <ACheckbox
                  text={
                    <FormattedMessage id="classroom.endclass-check-default" />
                  }
                  id={`basic`}
                  name="msgType"
                  value={"basic"}
                  checked={msgType === "basic"}
                  onChange={(e) => handleChangeMsgType(e)}
                />
                <ACheckbox
                  text={
                    <FormattedMessage id="classroom.endclass-check-absent" />
                  }
                  id={`absence`}
                  name="msgType"
                  value={"absence"}
                  checked={msgType === "absence"}
                  onChange={(e) => handleChangeMsgType(e)}
                />
                <ACheckbox
                  text={
                    <FormattedMessage id="classroom.endclass-check-incomplete" />
                  }
                  id={`unfinish`}
                  name="msgType"
                  value={"unfinish"}
                  checked={msgType === "unfinish"}
                  onChange={(e) => handleChangeMsgType(e)}
                />
              </div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                value={msgContent}
                onChange={(e) => handleMsgContent(e)}
              />
              <div className="section-title">
                <FormattedMessage id="classroom.endclass-receive-number" />
              </div>
              <div className="recieve-num">
                <ul>
                  {sendMsgList.map((student) => (
                    <li key={student.studentId}>
                      <span className="name">{student.name}</span>
                      <span className="num">{student.parentMobile}</span>
                    </li>
                  ))}
                </ul>
              </div>
              {quetureData && (
                <Classmodebtn
                  text={
                    isLoading ? (
                      <FormattedMessage id="classroom.endclass-sending" />
                    ) : (
                      <FormattedMessage id="classroom.endclass-send" />
                    )
                  }
                  onClick={() => {
                    handlePreviewData();
                  }}
                />
              )}
            </div>
          </section>
        </div>
      </div>
      {previewData !== null && (
        <PreviewMsgPopup
          title={
            <FormattedMessage id="classroom.endclass-confirm-text-content" />
          }
          visible={previewData !== null}
          maskClosable={false}
          // width={800}
          onOk={() => {
            if (isLoading) {
              return;
            }
            handleSendMsg();
          }}
          onCancel={() => {
            setPreviewData(null);
          }}
          okText={<FormattedMessage id="classroom.endclass-send" />}
          cancelText={<FormattedMessage id="classroom.endclass-cancel" />}
          data={previewData}
        />
      )}
    </Skeleton>
  );
};

export default injectIntl(Sendmsg);
