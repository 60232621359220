export default {
  "admin.header.campus-type-campus": "Quebon Academy",
  "admin.header.campus-type-school": "Quebon Campus",
  "admin.header.menu-classroom": "{name}es",
  "admin.header.menu-student": "Students",
  "admin.header.menu-guide": "Teachers",
  "admin.header.menu-test": "Test",
  "admin.header.menu-newsletter": "Newsletter",
  "admin.header.menu-board": "Board",
  "admin.header.menu-logout": "Logout",
  "admin.classroom.list-title": "Class List",
  "admin.classroom.list-filter-today": "View Only Today's Lessons",
  "admin.classroom.list-filter-terminated": "View Only Terminated Classes",
  "admin.classroom.list-filter-deleted": "View Only Deleted Classes",
  "admin.classroom.list-search-placeholder": "Class Name",
  "admin.classroom.list-create-class": "Create Class",
  "admin.classroom.list-col-course": "Course {course}",
  "admin.classroom.list-col-course-other": "Other",
  "admin.classroom.list-col-class": "Class",
  "admin.classroom.list-col-branch": "Branch",
  "admin.classroom.list-col-status": "Status",
  "admin.classroom.list-col-progress": "Progress",
  "admin.classroom.list-col-schedule": "Schedule",
  "admin.classroom.list-col-start-date": "Start Date",
  "admin.classroom.list-col-end-date": "End Date",
  "admin.classroom.list-col-students": "Students",
  "admin.classroom.list-col-teacher": "Teacher",
  "admin.classroom.list-value-delete": "(Deleted)",
  "admin.classroom.list-value-current": "Current",
  "admin.classroom.list-value-terminated": "*Terminated*",
  "admin.classroom.list-value-force-terminated": "*Force Terminated*",
  "admin.classroom.list-value-lesson-of-total": "Lesson {seq} of {total}",
  "admin.classroom.list-value-mon": "Mon",
  "admin.classroom.list-value-tue": "Tue",
  "admin.classroom.list-value-wed": "Wed",
  "admin.classroom.list-value-thu": "Thu",
  "admin.classroom.list-value-fri": "Fri",
  "admin.classroom.list-value-sat": "Sat",
  "admin.classroom.list-value-sun": "Sun",
  "admin.classroom.list-value-student-count": "{count}",
  "admin.classroom.list-value-no-teacher": "-",
  "admin.classroom-detail.title": "Class: {classroomName}",
  "admin.classroom-detail.upcoming-course":
    "Next Upcoming Course : {nextCourse}",
  "admin.classroom-detail.course-tba": "TBA",
  "admin.classroom-detail.course-deleted": "(Deleted)",
  "admin.classroom-detail.course-terminated": "*Terminated*",
  "admin.classroom-detail.course-force-terminated": "*Force Terminated*",
  "admin.classroom-detail.edit": "Edit",
  "admin.classroom-detail.confirm": "Confirm",
  "admin.classroom-detail.cancel": "Cancel",
  "admin.classroom-detail.management": "Management",
  "admin.classroom-detail.add-class": "Add Class",
  "admin.classroom-detail.change-class-schedule": "Change Class Schedule",
  "admin.classroom-detail.change-class-name": "Rename Class",
  "admin.classroom-detail.reset-class": "Initialize Class",
  "admin.classroom-detail.delete-class": "Delete Class",
  "admin.classroom-detail.class-force-quit": "Class Force Quit",
  "admin.classroom-detail.summary":
    "Progress: {current}/{total} {status} / Students: {studentCount} / Teacher: ",
  "admin.classroom-detail.summary-none": "(None)",
  "admin.classroom-detail.total-class-list": "All Classes List",
  "admin.classroom-detail.total-class-count": "Total {count}",
  "admin.classroom-detail.running-classes": "Running Classes",
  "admin.classroom-detail.terminated-classes": "Terminated Classes",
  "admin.classroom-detail.class-management": "Class Management",
  "admin.classroom-detail.add-student": "Add Students",
  "admin.classroom-detail.change-teacher": "Change Class Teacher",
  "admin.classroom-detail.view-all-class": "View All Classes",
  "admin.classroom-detail.col-lesson": "Lesson",
  "admin.classroom-detail.col-list": "List",
  "admin.classroom-detail.col-date": "Date",
  "admin.classroom-detail.col-preparation": "Preparation",
  "admin.classroom-detail.col-progress": "Progress",
  "admin.classroom-detail.value-lesson": "Lesson {lessonSeq}",
  "admin.classroom-detail.value-preview": "Preview",
  "admin.classroom-detail.value-check-assignments": "Check Assignments",
  "admin.classroom-detail.value-setting-assignments": "Assignments",
  "admin.classroom-detail.value-start": "Start",
  "admin.classroom-detail.value-postpone": "Postpone",
  "admin.classroom-detail.value-completed": "Lesson Completed",
  "admin.classroom-detail.delete-lesson": "Delete Lesson",
  "admin.classroom-detail.reset-lesson": "Reset Lesson",
  "admin.classroom-detail.complete-lesson": "Complete Lesson",
  "admin.classroom-detail.student-list": "Student List({count})",
  "admin.classroom-detail.student-col-name": "Name",
  "admin.classroom-detail.student-col-id": "ID",
  "admin.classroom-detail.student-col-age": "Age",
  "admin.classroom-detail.student-col-birth": "Birthdau",
  "admin.classroom-detail.student-col-school": "School",
  "admin.classroom-detail.student-col-contact": "Parent Contact",
  "admin.classroom-detail.student-col-recent-assignment":
    "Recent Assignments/Reminders",
  "admin.classroom-detail.student-col-recent-report":
    "Recently Graded/Collected/Issued/Consulted/Viewed Reports",
  "admin.classroom-detail.student-value-age": "{age}",
  "admin.classroom-detail.student-value-no-assignments": "(No Assignments)",
  "admin.classroom-detail.student-value-course-not-completed":
    "(Course not Completed) / ",
  "admin.classroom-detail.student-value-not-check-answer": "Not Check Answer",
  "admin.classroom-detail.student-value-not-exam": "Not Exam",
  "admin.classroom-detail.student-value-registered": "Registered",
  "admin.classroom-detail.student-value-not-register": "Not Register",
  "admin.classroom-detail.student-value-published": "Published",
  "admin.classroom-detail.student-value-not-publish": "Not Publish",
  "admin.classroom-detail.student-value-completed": "Completed",
  "admin.classroom-detail.student-value-incomplete": "Incomplete",
  "admin.classroom-detail.student-value-lesson": "Lesson {lessonSeq}",
  "admin.classroom-detail.student-value-lesson-range": "Lesson {start}~{end}",
  "admin.classroom-detail.reset-lesson-title":
    "Reset the Lesson {seq}? (This will delete all associated records, including attendance, results, assignments, and more.)]",
  "admin.classroom-detail.delay-lesson-desc":
    "Do you want to postpone the Lesson {seq}?",
  "admin.classroom-detail.delete-lesson-desc":
    "Do you want to delete the Lesson {seq}?",
  "admin.classroom-detail.force-quit-class-desc":
    "Do you want to force-quit the Lesson {seq}?",
  "admin.classroom-detail.init-class-desc":
    "Do you want to init the Class {class}?",
  "admin.classroom-detail.delete-class-desc":
    "Do you want to delete the Lesson {class}?",
  "admin.classroom-detail.change-teacher-desc":
    "Do you want to change the teacher of the Class {class}?",
  "admin.classroom-detail.change-class-date": "Change Lesson {lessonSeq} schedule ",
  "admin.classroom-detail.current-value": "Current",
  "admin.classroom-detail.change-value": "Change",
  //클래스 생성하기
  "admin.classroom-create.label-curriculum": "Select Curriculum",
  "admin.classroom-create.label-language": "Select Language",
  "admin.classroom-create.label-teacher": "Select Teacher",
  "admin.classroom-create.label-startDate": "Start Date",
  "admin.classroom-create.label-lessonDay": "Lesson Day",
  "admin.classroom-create.next": "Next",
  "admin.classroom-create.cancel": "Cancel",
  "admin.classroom-create.language-ko": "Korean",
  "admin.classroom-create.language-en": "English",
  "admin.classroom-create.lesson-schedule": "Lesson Schedule",
  // 수업내용미리보기
  "admin.preview-lesson.title": "Preview Lesson Content",
  "admin.preview-lesson.picture-quiz": "Picture Quiz",
  "admin.preview-lesson.math-picture-diary": "Math Picture Diary",
  "admin.preview-lesson.workbook-excercises": "Workbook Excercises",
  "admin.preview-lesson.workbook-commentary": "Workbook Commentary",
  "admin.preview-lesson.activity-documents": "Activity Documents",
  "admin.preview-lesson.workbooks": "Workbooks",
  "admin.preview-lesson.commentaries": "Commentaries",
  "admin.preview-lesson.reference": "[Reference]",
  "admin.preview-lesson.tab-queture": "{quetureName}(Queture)",
  "admin.preview-lesson.tab-game": "{gameName}(Game)",
  "admin.preview-lesson.tab-activity": "{activityName}(Activity)",
  "admin.preview-lesson.theme-name": "Theme Name",
  "admin.preview-lesson.related-concepts": "Related Concepts",
  "admin.preview-lesson.lesson-plans": "Lesson Plans",
  "admin.preview-lesson.view-lesson-plans": "View Lesson Plans",
  "admin.preview-lesson.unregistered-lesson-plan": "Unregistered Lesson Plan",
  "admin.preview-lesson.queture-video": "Queture",
  "admin.preview-lesson.queture-point": "Queture Point",
  "admin.preview-lesson.caution": "Caution",
  "admin.preview-lesson.view-quiz": "Quiz",
  "admin.preview-lesson.game-name": "Game Name",
  "admin.preview-lesson.learning-keyword": "Learning Keyword",
  "admin.preview-lesson.game-desc": "Game Description",
  "admin.preview-lesson.start-game": "Start Game",
  "admin.preview-lesson.activity-name": "Activity Name",
  "admin.preview-lesson.activity-preparation": "Activity Preparation",
  "admin.preview-lesson.activity-desc": "Activity Description",
  "admin.preview-lesson.activity-detail": "Activity Detail",
  "admin.preview-lesson.activity-video": "Activity Video",
  "admin.preview-lesson.quiz-order-name": "{order}{ordinal} Quiz",
  // 수업관리 전체보기
  "admin.classroom-lectures.title": "View All Classes",
  "admin.classroom-lectures.management": "Management",
  "admin.classroom-lectures.upcoming": "Upcoming",
  "admin.classroom-lectures.completed": "Completed",

  // 선생님관리
  "admin.teachers.title": "Manage Teachers",
  "admin.teachers.add-teacher": "Register Teacher",
  "admin.teachers.total-teachers": "Full list of teachers {count}",
  "admin.teachers.basic-info": "Basic Information",
  "admin.teachers.set-permission": "Set Permissions",
  "admin.teachers.col-category": "Category",
  "admin.teachers.col-id": "ID",
  "admin.teachers.col-name": "Name",
  "admin.teachers.col-contact": "Contact",
  "admin.teachers.col-birthday": "Birthday",
  "admin.teachers.col-office": "Office",
  "admin.teachers.col-classes": "Responsible Classes",
  "admin.teachers.value-category-empoyed": "Employed",
  "admin.teachers.value-category-resignation": "Resignation",
  "admin.teachers.value-classes-view-all": "View all classes ({count})",
  "admin.teachers.value-classes-none": "None",
  "admin.teachers.col-view-all-students": "View all students",
  "admin.teachers.col-student-settings": "Student Settings",
  "admin.teachers.col-enroll-students": "Enroll Students",
  "admin.teachers.col-class-settings": "Class Settings",
  "admin.teachers.col-registering-class": "Registering Class",
  "admin.teachers.col-start-class": "Start Class",
  "admin.teachers.col-manage-newsletter": "Manage Newsletter",
  "admin.teachers.col-select-all": "Select All",
  "admin.teachers.edit": "Edit",
  "admin.teachers.save": "Save",
  "admin.teachers.cancel": "Cancel",
  "admin.teachers.cancel-confirm": "Are you sure you want to cancel?",
  "admin.teachers.col-hire-date": "Hire Date",
  "admin.teachers.col-address": "Address",
  "admin.teachers.register": "Register",
  "admin.teachers.teacher-name": "Teacher: {name}",
  "admin.teachers.edit-teacher": "Edit",
  "admin.teachers.reset-password": "Reset Password",
  "admin.teachers.reset-password-desc":
    "Would you like to reset your password to the default value (birthdate)?",
  "admin.teachers.reset": "Reset",
  "admin.teachers.change-to-retire": "Change to retired status",
  "admin.teachers.change-to-retire-desc":
    "Would you like to change {name} to retired status?",
  "admin.teachers.change-to-working": "Change to working status",
  "admin.teachers.change-to-working-desc":
    "Would you like to change {name} to working status?",
  "admin.teachers.change": "Change",
  "admin.teachers.delete-class-desc": "Would you like to delete {name}?",
  "admin.teachers.manage-learning-reports": "Managing Learning Reports",
  "admin.teachers.col-class": "Class",
  "admin.teachers.col-recently-completed-course": "Recently Completed Course",
  "admin.teachers.col-lesson": "Lesson",
  "admin.teachers.col-period": "Period",
  "admin.teachers.col-student": "Student",
  "admin.teachers.col-test-score": "Test Score",
  "admin.teachers.col-note": "Note",
  "admin.teachers.col-publish-report": "Publish Report",
  "admin.teachers.col-consultation-history": "Consultation History",
  "admin.teachers.col-view-report": "View Report",
  "admin.teachers.value-state-complete": "Complete",
  "admin.teachers.value-state-incomplete": "Incomplete",
  "admin.teachers.value-state-not-publish": "Not Published",
  "admin.teachers.value-state-not-test": "Not Tested",
  "admin.teachers.value-state-register": "Registered",
  "admin.teachers.value-state-not-register": "Not Registered",
  "admin.teachers.value-lesson": "Lesson {lessonRange}",
  "admin.teachers.value-view-learning-report": "Learning Report",
  "admin.teachers.edit-teachers": "Edit Teachers",
  "admin.teachers.edit-confirm": "Edit",

  // 학생관린
  "admin.students.title": "Manage Students",
  "admin.students.add-student": "Add Student",
  "admin.students.active-class-students": "Students in Active Classes",
  "admin.students.inactive-class-students": "Students in Inactive Classes",
  "admin.students.discharged-students": "Discharged Students",
  "admin.students.all-active-class-students": "All Students in Active Classes",
  "admin.students.all-inactive-class-students":
    "All Students in Inactive Classes",
  "admin.students.all-discharged-students": "All Discharged Students",
  "admin.students.list-col-name": "Name",
  "admin.students.list-col-age": "Age",
  "admin.students.list-col-id": "ID",
  "admin.students.list-col-teacher": "Teacher",
  "admin.students.list-col-class": "Class",
  "admin.students.list-col-school": "School",
  "admin.students.list-col-none": "None",
  "admin.students.list-filter-by": " ",
  "admin.students.list-unassigned-class": "Unassigned Class",
  "admin.students.list-col-student-contact": "Student Contact",
  "admin.students.list-col-parent-contact": "Parent Contact",
  "admin.students.list-col-parent-email": "Parent Email",
  "admin.students.list-col-learning-management": "Learning Management",
  "admin.students.list-col-learning-report": "Learning Report",
  "admin.students.list-search-palceholder": "Search by Name, ID, School, Class",
  // 학생 상세
  "admin.student-detail.student": "Student",
  "admin.student-detail.discharged-student": "Discharged Student",
  "admin.student-detail.student-list": "Student List",
  "admin.student-detail.edit-student": "Edit Student",
  "admin.student-detail.change-class": "Change Class",
  "admin.student-detail.reset-password": "Reset Password",
  "admin.student-detail.change-id": "Change ID",
  "admin.student-detail.set-as-discharged": "Set as Discharged",
  "admin.student-detail.set-as-active": "Set as Active",
  "admin.student-detail.col-name": "Name",
  "admin.student-detail.col-id": "ID",
  "admin.student-detail.col-school-name": "School",
  "admin.student-detail.col-student-contact": "Student Contact",
  "admin.student-detail.col-restricted-viewing-of-unattended-classes":
    "Restricted Viewing of Unattended Classes",
  "admin.student-detail.col-address": "Address",
  "admin.student-detail.col-note": "Note",
  "admin.student-detail.col-class-name": "Class",
  "admin.student-detail.col-birthday": "Birthday",
  "admin.student-detail.value-age": " ({age})",
  "admin.student-detail.col-grade": "Grade",
  "admin.student-detail.value-grade": "{grade}",
  "admin.student-detail.col-parent-contact": "Parent Contact",
  "admin.student-detail.col-parent-email": "Parent Email",
  "admin.student-detail.col-discharge-reason": "Discharge Reason",
  "admin.student-detail.btn-register": "Register",
  "admin.student-detail.btn-cancel": "Cancel",
  "admin.student-detail.btn-save": "Save",
  "admin.student-detail.btn-edit": "Edit",
  "admin.student-detail.learning-history": "Learning Reports",
  "admin.student-detail.learning-history-warning":
    "If no class is specified, the learning report history cannot be viewed.",
  "admin.student-detail.learning-history-col-course": "Course",
  "admin.student-detail.learning-history-col-lesson": "Lesson",
  "admin.student-detail.learning-history-col-period": "Period",
  "admin.student-detail.learning-history-col-attendance-stat":
    "Attendance/Absence",
  "admin.student-detail.learning-history-col-test": "Test",
  "admin.student-detail.learning-history-col-note": "Note",
  "admin.student-detail.learning-history-col-publish-report": "Publish Report",
  "admin.student-detail.learning-history-col-counseling": "Counseling",
  "admin.student-detail.learning-history-col-view-report": "View Report",
  "admin.student-detail.learning-history-value-incomplete": "Incomplete",
  "admin.student-detail.learning-history-value-complete": "Complete",
  "admin.student-detail.learning-history-value-lesson": "Lesson {lessonSeq}",
  "admin.student-detail.learning-history-value-lesson-range":
    "Lesson {start}~{end}",
  "admin.student-detail.learning-history-value-lesson-date":
    "{year}-{month}-{day}",
  "admin.student-detail.learning-history-value-lesson-date-range":
    "{year}-{month}-{day} ~ {year2}-{month2}-{day2}",
  "admin.student-detail.learning-history-value-not-test": "ungraded",
  "admin.student-detail.learning-history-value-not-add": "unregistered",
  "admin.student-detail.learning-history-value-not-publish": "not issued",
  "admin.student-detail.learning-history-value-learning-report":
    "Learning Report",
  "admin.student-detail.prev-learning-history": "Previous Learning Reports",
  "admin.student-detail.learning-history-col-class": "Class",
  "admin.student-detail.learning-history-col-lesson-day": "Lesson Date",
  "admin.student-detail.learning-history-col-teacher": "Teacher",
  "admin.student-detail.change-class-info": "Change Class Information",
  "admin.student-detail.change-student-id": "Change Student ID",
  "admin.student-detail.reset-student-password": "Reset Student Password",
  "admin.student-detail.msg-reset-success": "Password reset successfully",
  "admin.student-detail.msg-reset-fail": "Password reset failed",
  "admin.edit-student.title": "Edit Student",
  "admin.edit-student.confirm-edit": "Confirm",
  "admin.edit-student.edit-success": "Edit Student successfully",
  "admin.edit-student.edit-fail": "Edit Student failed",
  "admin.edit-student.restricted-viewing-of-unattended-classes-unlimit":
    "Unrestricted",
  "admin.edit-student.restricted-viewing-of-unattended-classes-ab":
    "Restrict Course A,B",
  "admin.edit-student.restricted-viewing-of-unattended-classes-abc":
    "Restrict Course A,B,C",
  "admin.edit-student.restricted-viewing-of-unattended-classes-abcd":
    "Restrict Course A,B,C,D",
  "admin.change-class-popup-desc":
    "Do you want to change the {name} student's class information to look like this?",
  "admin.change-class-popup-student-info": "Student",
  "admin.change-class-popup-before-class": "Before",
  "admin.change-class-popup-after-class": "After",
  "admin.change-class-popup-change-class-warning":
    "When changing classes, al training data for the current class will be initialized.",
  "admin.reset-student-password-popup-desc":
    "Reset {name}({account}) student's password to default(date of birth)?",
  "admin.reset-student-password-popup-student-info": "Student Information",
  "admin.reset-student-password-popup-default-password": "Default Password",
  "admin.change-student-id-popup-desc":
    "Would you like to change the {name} student's ID to the following?",
  "admin.set-as-discharged-popup-desc":
    "Are you sure you want to set the {name} student as an alumnus? (The class association information will be deleted and login will be blocked.)",

  // 테스트
  "admin.tests.title": "Test",
  "admin.tests.management": "Management",
  "admin.tests.show-deleted": "Show deleted",
  "admin.tests.add": "Add",
  "admin.tests.add-test": "Add Test",
  "admin.tests.show-answers": "View answers",
  "admin.tests.view": "View",
  "admin.tests.print": "Print",
  "admin.tests.default": "Default",
  "admin.tests.print-quiz": "Print quiz",
  "admin.tests.delete": "Delete",
  "admin.tests.checkup": "Checkup",
  "admin.tests.name": "Name",
  "admin.tests.course-name": "{title} Course [{testsCount} Tests]",
  "admin.tests.test-quiz": "[{count} Quiz]",
  "admin.tests.test-deleted": "(Deleted)",
  "admin.tests.edit": "Edit",
  "admin.tests.edit-test": "Edit Test",
  "admin.tests.undo": "Undo",
  "admin.tests.delete-test": "Delete Test",
  "admin.tests.delete-test-confirm":
    "Are you sure you want to delete this test?",
  "admin.tests.delete-test-cancel": "Canceled",
  "admin.tests.cancel": "Cancel",
  "admin.tests.deleted-test": "(Deleted Test)",
  "admin.tests.col-name": "Test Name",
  "admin.tests.col-name-placeholder": "Please enter a Test Name.",
  "admin.tests.col-quiz-ids": "Quiz ID",
  "admin.tests.col-quiz-ids-placeholder": "Plase enter a Quiz ID",
  "admin.tests.confirm-quiz-ids": "OK",
  "admin.tests.quiz-list": "Quiz List",
  // 학습 리포트
  "admin.report.learning-report": "Learning Reports",
  "admin.report.prev-report": "Previous Report",
  "admin.report.next-report": "Next Report",
  "admin.report.test-result": "Test Results",
  "admin.report.teacher-note": "Teacher Notes",
  "admin.report.counseling-history": "Counseling History",
  "admin.report.print-report": "Print Report",
  "admin.report.loading": "... Loading ...",
  "admin.report.no-report": "No report",
  "admin.report.report-title":
    "Learning Report {courseName} Course {start}~{end}",
  "admin.report.course-not-completed": "(Course not completed)",
  "admin.report.class": "Class",
  "admin.report.course": "Course",
  "admin.report.lessonRange": "Lesson {lessonRange}",
  "admin.report.period": "Period",
  "admin.report.add-email-notice": " #학생정보에 학부모 이메일을 등록해주세요 ",
  "admin.report.test-result-notice": " #테스트 채점 결과가 필요합니다 ",
  "admin.report.teacher-note-notice": " #선생님 메모가 필요합니다 ",
  "admin.report.no-report-notice": " #리포트가 발행되지 않았습니다 ",
  "admin.report.no-counseling-notice": " #학습상담이 완료되지 않았습니다 ",
  "admin.report.class-hours": "Lesson Count",
  "admin.report.class-hours-unit": " ",
  "admin.report.class-hours-not-completed": "-",
  "admin.report.number-of-quetures": "Learning Quetures",
  "admin.report.number-of-quetures-unit": "Queture(s)",
  "admin.report.absences": "Absences",
  "admin.report.absences-unit": " ",
  "admin.report.mvp-selection": "MVP Selection",
  "admin.report.mvp-selection-unit": " ",
  "admin.report.lesson": "Lesson",
  "admin.report.lesson-value": "Lesson {lessonSeq}",
  "admin.report.queture-name": "Queture Name",
  "admin.report.key-concepts-and-activities": "Key Concepts and Activities",
  "admin.report.learning-level": "Learning Level",
  "admin.report.learning-level-high": "High",
  "admin.report.learning-level-mid": "Mid",
  "admin.report.learning-level-low": "Low",
  "admin.report.review-tasks": "Review Tasks",
  "admin.report.edit": "Edit",
  "admin.report.test-date": "Test Date",
  "admin.report.correct-partially-wrong": "Correct/ Partially Correct/  Wrong)",
  "admin.report.quiz-count-unit": " ",
  "admin.report.correct-rate": "Correct Rate",
  "admin.report.report": "Report",
  "admin.report.report-issue": "Issue",
  "admin.report.report-issued": "Issued",
  "admin.report.report-not-issued": "Not Issued",
  "admin.report.report-reissue": "Reissue",
  "admin.report.report-issue-confirm":
    "Do you want to send Learning Reports {courseName}-Courses {lessonRange} to parents?",
  "admin.report.report-reissue-confirm":
    "Do you want to resend Learning Reports {courseName}-Courses {lessonRange} to parents?",
  "admin.report.report-not-issued-desc":
    "[Teacher Notes Must Be Registered to Issue a Report]",
  "admin.report.parent-contact": "Parent Contact",
  "admin.report.no-email": "(No Email)",
  "admin.report.result": "Result",
  "admin.report.cancel": "Cancel",
  "admin.report.partially-incorrect": "Partially Correct/  Wrong",
  "admin.report.save": "Save",
  "admin.report.correct-per-question": "Correct Answers per Quiz",
  "admin.report.correct-per-question-tooltip":
    "Correct : o (or 1), Partial Correct: / (or 5),Incorrect: x (or 0), Choose not to solve: - ",
  "admin.newsletter.title": "Newsletter",
  "admin.newsletter.add-newsletter": "Add Newsletter",
  "admin.newsletter.col-seq": "Number",
  "admin.newsletter.col-id": "ID",
  "admin.newsletter.col-title": "Title",
  "admin.newsletter.col-date": "Registration Date",
  "admin.newsletter.save": "Save",
  "admin.newsletter.register": "Register",
  "admin.newsletter.close": "Close",
};
