import React, { Component } from "react";
import { Table, Button, Dropdown, Icon, Menu, Typography, Tag } from "antd";
import { Link } from "react-router-dom";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";

class GuideList extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      editingKey: null,
    };
  }

  render() {
    const { tab, guideList } = this.props;

    let columns = [
      {
        // title: "구분",
        title: <FormattedMessage id="admin.teachers.col-category" />,
        key: "state",
        dataIndex: "state",
        render: (state) => (
          <span>
            {state === "working" ? (
              <Typography.Text>
                <FormattedMessage id="admin.teachers.value-category-empoyed" />
              </Typography.Text>
            ) : (
              <Typography.Text type="danger">
                <FormattedMessage id="admin.teachers.value-category-resignation" />
              </Typography.Text>
            )}
          </span>
        ),
      },
      {
        // title: "이름",
        title: <FormattedMessage id="admin.teachers.col-name" />,
        key: "name",
        dataIndex: "name",
        render: (text, record) => (
          <Link to={`/admin/guide/detail/${record.guideId}`}>
            <span>{text}</span>
          </Link>
        ),
      },
      {
        // title: "아이디",
        title: <FormattedMessage id="admin.teachers.col-id" />,
        key: "account",
        dataIndex: "account",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <FormattedMessage id="admin.teachers.col-contact" />,
        key: "mobile",
        dataIndex: "mobile",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <FormattedMessage id="admin.teachers.col-birthday" />,
        key: "birthday",
        dataIndex: "birthday",
        render: (birthday) => (
          <span>
            {birthday
              ? `${birthday.year}.${birthday.month}.${birthday.day}`
              : null}
          </span>
        ),
      },
      {
        title: <FormattedMessage id="admin.teachers.col-office" />,
        key: "orgAccount",
        dataIndex: "organizationViewList",
        render: (organizationViewList) => (
          <span>
            {organizationViewList.map((e) => (
              <Tag>{e.name}</Tag>
            ))}
          </span>
        ),
      },
      {
        title: <FormattedMessage id="admin.teachers.col-classes" />,
        key: "classrooms",
        dataIndex: "classrooms",
        render: (classrooms) => (
          <span>
            {classrooms.length > 0 ? (
              classrooms.length === 1 ? (
                <Button style={{ cursor: "default" }}>
                  {classrooms[0].name} ({classrooms[0].organization.name})
                </Button>
              ) : (
                <>
                  <Dropdown
                    overlay={
                      <Menu>
                        {classrooms.map((classroom) => (
                          <Menu.Item
                            key={classroom.classroomId}
                            style={{ cursor: "default" }}
                          >
                            {classroom.name} ({classroom.organization.name})
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <Button style={{ cursor: "default" }}>
                      {/* {`모든 ${this.context.className} 보기(${classrooms.length})`} */}
                      <FormattedMessage
                        id="admin.teachers.value-classes-view-all"
                        values={{ count: classrooms.length }}
                      />
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </>
              )
            ) : (
              <FormattedMessage id="admin.teachers.value-classes-none" />
            )}
          </span>
        ),
      },
    ];

    const data = guideList;

    console.log("data: ", data);
    return <Table rowKey="guideId" dataSource={data} columns={columns} />;
  }
}

export default GuideList;
