import React, { Component } from "react";
import { Modal, Typography, Select, Row, Col, Table } from "antd";
import { filter } from "lodash";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";

const locale = process.env.REACT_APP_LOCALE || "ko";
class ChangeGuidePopup extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      selectedGuide: null,
    };
  }

  handleOK() {
    const { onSelect } = this.props;
    onSelect(this.state.selectedGuide);
  }

  render() {
    console.log("guideList: ", this.props.guideList);
    return (
      <Modal {...this.props} onOk={() => this.handleOK()}>
        <div>
          <Typography.Text strong>
            {/* {`해당 ${this.context.className}의 수업을 진행할 ${this.context.guideName}를 선택해주세요. ${this.context.guideName} 변경 시
            기존의 ${this.context.guideName} 계정은 더 이상 해당 ${this.context.className}의 수업을 진행할 수 없게
            됩니다.`} */}
            <FormattedMessage id="admin.classroom-detail.change-teacher-desc" />
          </Typography.Text>
        </div>
        <Select
          style={{ minWidth: "100%", margin: "16px 0" }}
          // placeholder={`해당 ${this.context.className}를 진행할 ${this.context.guideName}를 선택해주세요 `}
          placeholder={
            locale === "en"
              ? "Please select a teacher to conduct the class."
              : `해당 클래스를 진행할 선생님를 선택해주세요 `
          }
          onChange={(v) => {
            console.log(v);
            this.setState({
              selectedGuide: filter(this.props.guideList, { guideId: v })[0],
            });
          }}
        >
          {this.props.guideList.map((guide, index) => (
            <Select.Option key={index} value={guide.guideId}>
              {guide.name}
            </Select.Option>
          ))}
        </Select>
        {this.state.selectedGuide && (
          <Table
            dataSource={[
              {
                key: 1,
                className: this.props.classroomData.name,
                beforeGuideName: this.props.classroomData.guide
                  ? this.props.classroomData.guide.name
                  : null,
                afterGuideName: this.state.selectedGuide.name,
              },
            ]}
            rowKey="key"
          >
            <Table.Column
              title={`${this.context.className} 이름`}
              dataIndex="className"
              key="className"
              render={(text) => <span>{text}</span>}
            />
            <Table.Column
              title={<FormattedMessage id="admin.change-class-popup-before-class" />}
              dataIndex="beforeGuideName"
              key="beforeGuideName"
              render={(text) => <span>{text}</span>}
            />
            <Table.Column
              title={<FormattedMessage id="admin.change-class-popup-after-class" />}
              dataIndex="afterGuideName"
              key="afterGuideName"
              render={(text) => <span>{text}</span>}
            />
          </Table>
        )}
      </Modal>
    );
  }
}

export default ChangeGuidePopup;
