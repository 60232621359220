import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Skeleton,
  Row,
  Col,
  List,
  Tag,
  Spin,
  PageHeader,
  Button,
  Icon,
  Descriptions,
  Typography,
  Table,
  Divider,
  Statistic,
  Popconfirm,
  message,
  Tooltip,
  Input,
} from "antd";
import Axios from "../../../../utils/request";
import { flatten, filter, values } from "lodash";
import ProfileImage from "../../components/ProfileImage";
import AccountApis from "../../../../utils/accountApis";
import * as classroomActions from "store/modules/classroom";
import { ConstantsContext } from "utils/ConstantsContext";
import ResetStudentPasswordPopup from "../../components/Popup/ResetStudentPasswordPopup";
import ChangeStudentClassPopup from "../../components/Popup/ChangeStudentClassPopup";
import { formateQuetureName, getKoreanDate } from "../../../../utils/utils";
import ReactToPrint from "react-to-print";
import { getHost, getDefaultConfig } from "utils/APIUtils";
import { Link } from "react-router-dom";
import moment from "moment";
import ChangeStudentAccountId from "../../components/Popup/ChangeStudentAccountId";
import { FormattedMessage } from "react-intl";
import { getLessonSeq } from "../../../../utils/utils";

const { Title, Text } = Typography;
const locale = process.env.REACT_APP_LOCALE || "ko";

class StudentDetailPage extends Component {
  static contextType = ConstantsContext;

  constructor(props) {
    super(props);

    this.state = {
      showChangeAccountId: false,
      isLoading: false,
      studentData: null,
      showResetStudentPasswordPopup: false,
      showChangeClassPopup: false,
      reportList: null,
      classroomList: [],
      oldClassroomLectureViews: [],
      reason: "",
      editedReason: "",
      editReason: false,
    };
  }

  componentDidMount() {
    const { user, ClassroomActions } = this.props;
    const { studentId } = this.props.match.params;
    console.log("this studentId: ", studentId);
    if (user) {
      this.getStudentDetail(studentId);
      this.getReportList(studentId);
    }
    if (user && user.orgId) {
      ClassroomActions.getClassroomList(user.orgId);
    }
  }

  getOldLectureList(data, studentId) {
    Axios.get(
      `/academy/v2/accounts/${this.props.user.orgId}/students/${studentId}/oldLecture`,
      {
        oldClassroomIds: data,
      }
    ).then((res) => {
      console.log(res, "oldlecture");
    });
  }

  componentDidUpdate(nextProps) {
    const { user } = this.props;
    const { studentId } = this.props.match.params;
    if (this.props.user && this.props.user.type !== nextProps.user.type) {
      this.getStudentDetail(studentId);
    }
  }

  getStudentDetail(studentId) {
    const { user } = this.props;
    this.setState({
      isLoading: true,
    });
    Axios.get(`/academy/v2/accounts/${user.orgId}/students/${studentId}`)
      .then((res) => {
        console.log("student detail: ", res);
        const _studentDetail = { ...res };

        if (res.lectureStatus) {
          let attendanceCount = 0;
          let mvpCount = 0;
          Object.keys(res.lectureStatus).map((key) => {
            if (res.lectureStatus[key].attendance === true) {
              attendanceCount++;
            }
            if (res.lectureStatus[key].mvp === true) {
              mvpCount++;
            }
          });
          _studentDetail["avgAttendances"] =
            attendanceCount / Object.keys(res.lectureStatus).length;
          _studentDetail["mvpCount"] = mvpCount;
          console.log(_studentDetail);
        }

        if (res.oldClassroomLectureViews)
          this.divisionData(res.oldClassroomLectureViews);

        this.setState({
          isLoading: false,
          studentData: _studentDetail,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  divisionData(data) {
    // 클래스별로 한번나누고 코스이름으로 한번더 나눈뒤 모아준다
    let courseNames = [];
    let classroomNames = [];

    let lectures = [];
    data.map((e) => {
      if (courseNames.indexOf(e.courseName) < 0) {
        courseNames.push(e.courseName);
      }
      if (classroomNames.indexOf(e.classroomName) < 0) {
        classroomNames.push(e.classroomName);
      }
    });

    classroomNames.map((crn) /*classroom name*/ => {
      let _data = data.filter((e) => e.classroomName === crn);

      courseNames.map((cn) /*course Name*/ => {
        const __data = _data.filter((e) => e.courseName === cn);
        if (__data.length !== 0) {
          if (__data.length <= 1) {
            lectures.push({
              ...__data[0],
              lectureTitle: __data[0].lectureTitle,
              lessonRange: [__data[0].lectureTitle],
              lessonDateRange: [__data[0].createdTime],
            });
          } else {
            lectures.push({
              classroomName: __data[0].classroomName,
              courseName: __data[0].courseName,
              startDate: __data[0].createdTime,
              endDate: __data[__data.length - 1].createdTime,
              lectureTitle:
                __data[0].lectureTitle +
                " ~ " +
                __data[__data.length - 1].lectureTitle,
              lessonRange: [
                __data[0].lectureTitle,
                __data[__data.length - 1].lectureTitle,
              ],
              lessonDateRange: [
                __data[0].createdTime,
                __data[__data.length - 1].createdTime,
              ],
            });
          }
        }
      });
    });

    this.setState({
      oldClassroomLectureViews: lectures,
    });
  }

  handleStudentState = (state) => {
    AccountApis.changeStudentState(
      this.props.user.orgId,
      this.state.studentData.studentId,
      this.state.reason,
      state
    )
      .then((res) => {
        let msg = "설정 완료";
        if (locale === "en") {
          msg = "Setting Success";
        }
        message.success(msg);
        this.props.history.push("/admin/student/list");
      })
      .catch((e) => {
        let msg = "설정 실패";
        if (locale === "en") {
          msg = "Setting Fail";
        }
        message.error(msg);
      });
  };

  getReportList(studentId) {
    const { user } = this.props;
    this.setState({
      isReportLoading: true,
    });
    Axios.get(`/academy/v2/students/${studentId}/reports`)
      .then((res) => {
        console.log("report list: ", res);
        this.setState({
          isReportLoading: false,
          reportList: res,
        });
      })
      .catch((e) => {
        // do not show error for student without classroom always fails
        //message.error("학습 리스트 조회에 실패하였습니다:", e);
        this.setState({
          isReportLoading: false,
        });
      });
  }

  render() {
    const {
      isLoading,
      showChangeAccountId,
      studentData,
      showResetStudentPasswordPopup,
      showChangeClassPopup,
      reportList,
      editedReason,
      oldClassroomLectureViews,
    } = this.state;
    const { pending, user, userPermission } = this.props;
    if (studentData && studentData.lectures) {
      console.log("student Data: ", studentData.lectures);
    }
    console.log("studentData: ", studentData);
    console.log("detail searchResult: ", this.props.location.searchResult);

    // NOTE: sort by date for some old classroomLectures's seq might be invalid */}
    if (studentData && studentData.lectures) {
      studentData.lectures.sort((a, b) => b.date.localeCompare(a.date));
    }

    return (
      <Skeleton loading={isLoading || pending} active>
        {studentData && (
          <>
            <PageHeader
              title={
                <div>
                  <Title
                    level={2}
                    style={{ marginBottom: 0, display: "inline-block" }}
                  >
                    <FormattedMessage id="admin.student-detail.student" />:{" "}
                    {studentData.name}
                    {studentData.state === "exit" && (
                      <font color="red">
                        {" "}
                        (
                        <FormattedMessage id="admin.student-detail.discharged-student" />
                        )
                      </font>
                    )}
                  </Title>
                  {this.props.location.searchResult && (
                    <Button
                      style={{
                        verticalAlign: "top",
                        marginLeft: "10px",
                        marginTop: "4px",
                      }}
                    >
                      <Link
                        to={{
                          pathname: `/admin/student/list`,
                          searchResult: this.props.location.searchResult,
                        }}
                      >
                        <FormattedMessage id="admin.student-detail.student-list" />
                        <Icon type="rollback" />
                      </Link>
                    </Button>
                  )}
                </div>
              }
              extra={
                (userPermission === "master" ||
                  (userPermission && userPermission.studentUpdate)) && [
                  <Button
                    key="3"
                    onClick={() =>
                      this.props.history.push(
                        `/admin/student/edit/${studentData.studentId}`
                      )
                    }
                  >
                    <FormattedMessage id="admin.student-detail.edit-student" />
                    <Icon type="setting" />
                  </Button>,
                  // <Popconfirm
                  //   key="2"
                  //   title={`비밀번호 초기화 하시겠습니까?`}
                  //   onConfirm={() => {
                  //     AccountApis.resetStudentPassword(
                  //       this.props.user.orgId,
                  //       studentData.studentId
                  //     )
                  //       .then(res => {
                  //         message.success("초기화 완료");
                  //         this.props.onUpdateStudentInfo();
                  //       })
                  //       .catch(e => {
                  //         message.warning("초기화 실패");
                  //       });
                  //   }}
                  //   onCancel={() => message.error("초기화 취소")}
                  //   okText="초기화"
                  //   placement="bottomRight"
                  //   cancelText="취소"
                  // >
                  // </Popconfirm>,

                  <Button
                    onClick={() => {
                      this.setState({
                        showChangeClassPopup: true,
                      });
                    }}
                  >
                    {/* {`${this.context.className}변경`} */}
                    <FormattedMessage id="admin.student-detail.change-class" />
                    <Icon type="setting" />
                  </Button>,

                  <Button
                    onClick={() => {
                      this.setState({
                        showResetStudentPasswordPopup: true,
                      });
                    }}
                  >
                    <FormattedMessage id="admin.student-detail.reset-password" />
                    <Icon type="unlock" />
                  </Button>,
                  <Button
                    onClick={() => {
                      this.setState({ showChangeAccountId: true });
                    }}
                  >
                    <FormattedMessage id="admin.student-detail.change-id" />
                  </Button>,
                  <Popconfirm
                    key="1"
                    title={
                      <>
                        {/* {`${studentData.name}학생을 ${
                          studentData.state === "study"
                          ? this.context.dropoutStudent
                          : this.context.registeredStudent
                        }으로 설정 하시겠습니까? (클래스 연결정보가 삭제되어 로그인이 차단됩니다)`} */}
                        <FormattedMessage
                          id="admin.set-as-discharged-popup-desc"
                          values={{ name: studentData.name }}
                        />
                        {studentData.state === "study" && (
                          <div>
                            <Input.TextArea
                              style={{ width: "520px", height: "200px" }}
                              placeholder={
                                locale === "en"
                                  ? "Please input reasons for discharge."
                                  : "퇴원사유를 입력해주세요."
                              }
                              onChange={(e) => {
                                this.setState({
                                  reason: e.target.value,
                                });
                              }}
                              defaultValue={""}
                            />
                          </div>
                        )}
                      </>
                    }
                    onConfirm={() => {
                      let state =
                        studentData.state === "study" ? "exit" : "study";
                      this.handleStudentState(state);
                    }}
                    onCancel={() =>
                      message.error(locale === "en" ? "Canceled" : "설정 취소")
                    }
                    okText={locale === "en" ? "Setup" : "설정"}
                    placement="bottomRight"
                    cancelText={locale === "en" ? "Cancel" : "취소"}
                  >
                    {studentData.state === "study" ? (
                      <Button type="danger">
                        {/* {`${this.context.dropoutStudent}으로 설정하기`} */}
                        <FormattedMessage id="admin.student-detail.set-as-discharged" />
                        <Icon type="minus-circle" />
                      </Button>
                    ) : (
                      <Button type="primary">
                        {/* {`${this.context.registeredStudent}으로 설정하기`} */}
                        <FormattedMessage id="admin.student-detail.set-as-active" />
                        <Icon type="plus-circle" />
                      </Button>
                    )}
                  </Popconfirm>,
                ]
              }
            >
              {/*
              <Row type="flex">
                <Statistic
                  title="평균 출석율"
                  value={`${
                    studentData.avgAttendances
                      ? (studentData.avgAttendances * 100).toFixed(0)
                      : 0
                  }%`}
                />
                <Statistic
                  style={{
                    margin: "0 32px",
                  }}
                  title="평균 수업 점수"
                  value={`${studentData.avgScore || ""}`}
                />
                <Statistic
                  title=" MVP 선정 횟수"
                  value={`${studentData.mvpCount || 0}회`}
                />
              </Row>
              */}
            </PageHeader>
            <div
              className="admin-page-content"
              ref={(el) => (this.componentRef = el)}
            >
              <Row gutter={24} style={{ padding: 24 }}>
                <Col span={6}>
                  <div
                    className="imgwrap"
                    style={{
                      maxWidth: 230,
                      height: 200,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#F7F9FB",
                      border: "1px dashed #CCCCCC",
                      boxSizing: "border-box",
                      borderRadius: 4,
                      position: "relative",
                    }}
                  >
                    <img
                      src={
                        (studentData &&
                          studentData.profileImage &&
                          studentData.profileImage.signedUrl) ||
                        null
                      }
                      alt=""
                      style={{
                        width: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </Col>
                <Col span={18}>
                  <Descriptions bordered column={2}>
                    <Descriptions.Item
                      label={
                        <FormattedMessage id="admin.student-detail.col-name" />
                      }
                    >
                      {studentData.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FormattedMessage id="admin.student-detail.col-class-name" />
                      }
                    >
                      {studentData.classroom ? (
                        <a
                          onClick={() =>
                            this.props.history.push(
                              `/admin/class/detail/${studentData.classroom.classroomId}`
                            )
                          }
                        >
                          {studentData.classroom.name}
                        </a>
                      ) : null}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FormattedMessage id="admin.student-detail.col-id" />
                      }
                    >
                      {studentData.account}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FormattedMessage id="admin.student-detail.col-birthday" />
                      }
                    >
                      {studentData.birthday
                        ? studentData.birthday.year +
                          "-" +
                          studentData.birthday.month +
                          "-" +
                          studentData.birthday.day
                        : ""}
                      &nbsp;
                      {studentData.birthday ? (
                        <FormattedMessage
                          id="admin.student-detail.value-age"
                          values={{ age: studentData?.birthday?.age }}
                        />
                      ) : (
                        ""
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FormattedMessage id="admin.student-detail.col-school-name" />
                      }
                    >
                      {studentData.school}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FormattedMessage id="admin.student-detail.col-grade" />
                      }
                    >
                      {/* {`${studentData.grade}학년`} */}
                      <FormattedMessage
                        id="admin.student-detail.value-grade"
                        values={{
                          grade: studentData.grade,
                        }}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FormattedMessage id="admin.student-detail.col-student-contact" />
                      }
                    >
                      {studentData.mobile}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FormattedMessage id="admin.student-detail.col-parent-contact" />
                      }
                    >
                      {studentData.parentMobile}
                    </Descriptions.Item>
                    {this.context.type === "campus" && (
                      <Descriptions.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-restricted-viewing-of-unattended-classes" />
                        }
                      >
                        {studentData.hideLecture === "AB" ? (
                          <FormattedMessage id="admin.edit-student.restricted-viewing-of-unattended-classes-ab" />
                        ) : studentData.hideLecture === "ABC" ? (
                          <FormattedMessage id="admin.edit-student.restricted-viewing-of-unattended-classes-abc" />
                        ) : studentData.hideLecture === "ABCD" ? (
                          <FormattedMessage id="admin.edit-student.restricted-viewing-of-unattended-classes-abcd" />
                        ) : (
                          <FormattedMessage id="admin.edit-student.restricted-viewing-of-unattended-classes-unlimit" />
                        )}
                      </Descriptions.Item>
                    )}
                    {this.context.type === "campus" && (
                      <Descriptions.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-parent-email" />
                        }
                      >
                        {studentData.parentEmail}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item
                      label={
                        <FormattedMessage id="admin.student-detail.col-address" />
                      }
                      span={2}
                    >
                      {`${studentData.address.address1 || ""}`}
                      <br />
                      {`${studentData.address.address2 || ""}`}
                      {locale === "en" && (
                        <>
                          {` (${studentData.address.zipCode || ""})`}
                        </>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <FormattedMessage id="admin.student-detail.col-note" />
                      }
                      span={2}
                    >
                      {studentData.description}
                    </Descriptions.Item>
                    {studentData.state === "exit" && (
                      <Descriptions.Item
                        label={
                          <FormattedMessage id="admin.student-detail.col-discharge-reason" />
                        }
                        span={2}
                      >
                        {this.state.editReason ? (
                          <>
                            <Input.TextArea
                              value={editedReason}
                              defaultValue={editedReason}
                              onChange={(e) => {
                                this.setState({
                                  editedReason: e.target.value,
                                });
                              }}
                            />

                            <Button
                              type="primary"
                              size={"sm"}
                              style={{
                                borderColor: "#ff1820",
                                background: "#ff1820",
                                color: "fff",
                                float: "right",
                                margin: "5px",
                              }}
                              onClick={() => {
                                this.setState({ editReason: false });
                              }}
                            >
                              <FormattedMessage id="admin.student-detail.cancel" />
                            </Button>
                            <Button
                              type="primary"
                              size={"sm"}
                              style={{
                                background: "#1890ff",
                                color: "fff",
                                float: "right",
                                margin: "5px",
                              }}
                              onClick={() => {
                                AccountApis.updateStudentReason(
                                  this.props.user.orgId,
                                  studentData.studentId,
                                  this.state.editedReason
                                ).then((e) => {
                                  this.getStudentDetail(studentData.studentId);
                                  this.setState({ editReason: false });
                                });
                              }}
                            >
                              <FormattedMessage id="admin.student-detail.save" />
                            </Button>
                          </>
                        ) : (
                          <>
                            {studentData.reason}
                            <Button
                              type="primary"
                              size={"sm"}
                              style={{
                                background: "#1890ff",
                                color: "fff",
                                float: "right",
                              }}
                              onClick={() => {
                                this.setState({
                                  editReason: true,
                                  editedReason: studentData.reason,
                                });
                              }}
                            >
                              <FormattedMessage id="admin.student-detail.edit" />
                            </Button>
                          </>
                        )}
                      </Descriptions.Item>
                    )}
                    {this.context.type === "school" && (
                      <Descriptions.Item label="QP 총량">
                        {`${studentData.totalPoints || 0} QP`}
                      </Descriptions.Item>
                    )}
                    {this.context.type === "school" &&
                      studentData.classroom &&
                      studentData.classroom.course &&
                      studentData.classroom.course.title && (
                        <Descriptions.Item
                          label={`${studentData.classroom.course.title} QP`}
                        >
                          {`${studentData.point} QP`}
                        </Descriptions.Item>
                      )}
                  </Descriptions>
                </Col>

                <Col span={24} style={{ margin: "24px 0" }}>
                  {studentData && studentData.state !== "exit" ? (
                    reportList && (
                      <>
                        <Row type="flex" justify="space-between">
                          <Title level={4}>
                            {/* {`학습 리포트 이력`} */}
                            <FormattedMessage id="admin.student-detail.learning-history" />
                          </Title>
                          {!studentData.state === "exit" ? (
                            <div>
                              {/* {`* ${this.context.className}가 지정되지 않은 경우 학습 리포트 이력이 조회되지 않습니다`} */}
                              <FormattedMessage id="admin.student-detail.learning-history-warning" />
                            </div>
                          ) : (
                            ""
                          )}
                        </Row>
                        <Table
                          dataSource={reportList}
                          rowKey={"reportTitle"}
                          columns={[
                            {
                              title: (
                                <FormattedMessage id="admin.student-detail.learning-history-col-course" />
                              ),
                              key: "course",
                              render: (text, report) => (
                                <span>
                                  [{report.courseNo}] {report.courseTitle}
                                  {report.courseNo > report.courseCompleted ? (
                                    <font color="grey">
                                      &nbsp;(
                                      <FormattedMessage id="admin.student-detail.learning-history-value-incomplete" />
                                      )
                                    </font>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              ),
                            },
                            {
                              title: (
                                <FormattedMessage id="admin.student-detail.learning-history-col-lesson" />
                              ),
                              key: "range",
                              render: (text, report) => (
                                <span>
                                  {/* {report.lectures.length > 1
                                    ? String(report.lectures[0].title)
                                        .replace("회차", "")
                                        .replace("강", "") +
                                      "~" +
                                      report.lectures[
                                        report.lectures.length - 1
                                      ].title
                                    : report.lectures[0].title} */}
                                  {report.lectures.length > 1 ? (
                                    <FormattedMessage
                                      id="admin.student-detail.learning-history-value-lesson-range"
                                      values={{
                                        start: getLessonSeq(report.lectures[0]),
                                        end: getLessonSeq(
                                          report.lectures[
                                            report.lectures.length - 1
                                          ]
                                        ),
                                      }}
                                    />
                                  ) : (
                                    <FormattedMessage
                                      id="admin.student-detail.learning-history-value-lesson"
                                      values={{
                                        lessonSeq: getLessonSeq(
                                          report.lectures[0]
                                        ),
                                      }}
                                    />
                                  )}
                                </span>
                              ),
                            },
                            {
                              title: (
                                <FormattedMessage id="admin.student-detail.learning-history-col-period" />
                              ),
                              key: "date",
                              dataIndex: "date",
                              render: (text, report) => (
                                <span>
                                  {report.lectures[0].date} ~{" "}
                                  {report.lectures[
                                    report.lectures.length - 1
                                  ].date.substring(5)}
                                </span>
                              ),
                            },
                            {
                              title: (
                                <FormattedMessage id="admin.student-detail.learning-history-col-attendance-stat" />
                              ),
                              align: "center",
                              key: "attendance",
                              render: (text, report) => (
                                <span>
                                  {report.lectures.length - report.absent} /{" "}
                                  {report.courseNo > report.courseCompleted
                                    ? "-"
                                    : report.absent}
                                </span>
                              ),
                            },
                            {
                              title: "MVP",
                              align: "center",
                              key: "mvp",
                              render: (text, report) => (
                                <span>{report.mvp}</span>
                              ),
                            },
                            {
                              title: (
                                <FormattedMessage id="admin.student-detail.learning-history-col-test" />
                              ),
                              align: "center",
                              key: "examResult",
                              render: (text, report) =>
                                report.examResult && report.examSolved > 0 ? (
                                  Math.round(
                                    (100 * report.examCorrect +
                                      50 * report.examPartial) /
                                      report.examSolved
                                  ) + "%"
                                ) : report.courseNo > report.courseCompleted ? (
                                  "-"
                                ) : (
                                  <font color="red">
                                    <FormattedMessage id="admin.student-detail.learning-history-value-not-test" />
                                  </font>
                                ),
                            },
                            {
                              title: (
                                <FormattedMessage id="admin.student-detail.learning-history-col-note" />
                              ),
                              key: "comment",
                              render: (text, report) =>
                                report.comment ? (
                                  <Tooltip title={report.comment}>
                                    {report.comment.length >= 40
                                      ? report.comment.substring(0, 37) + "..."
                                      : report.comment}
                                  </Tooltip>
                                ) : report.courseNo > report.courseCompleted ? (
                                  "-"
                                ) : (
                                  <font color="red">
                                    <FormattedMessage id="admin.student-detail.learning-history-value-not-add" />
                                  </font>
                                ),
                            },
                            {
                              title: (
                                <FormattedMessage id="admin.student-detail.learning-history-col-publish-report" />
                              ),
                              key: "publish",
                              render: (text, report) =>
                                report.reportLogId && report.reportLogTime ? (
                                  <span>
                                    {moment(
                                      new Date(report.reportLogTime)
                                    ).format("YYYY.MM.DD")}
                                  </span>
                                ) : report.courseNo > report.courseCompleted ? (
                                  "-"
                                ) : (
                                  <font color="red">
                                    <FormattedMessage id="admin.student-detail.learning-history-value-not-publish" />
                                  </font>
                                ),
                            },
                            {
                              title: (
                                <FormattedMessage id="admin.student-detail.learning-history-col-counseling" />
                              ),
                              key: "consult",
                              render: (text, report) =>
                                report.consult ? (
                                  <Tooltip title={report.consult}>
                                    {report.consult.length >= 40
                                      ? report.consult.substring(0, 37) + "..."
                                      : report.consult}
                                  </Tooltip>
                                ) : report.courseNo > report.courseCompleted ? (
                                  "-"
                                ) : (
                                  <font color="red">
                                    <FormattedMessage id="admin.student-detail.learning-history-value-incomplete" />
                                  </font>
                                ),
                            },
                            {
                              title: (
                                <FormattedMessage id="admin.student-detail.learning-history-col-view-report" />
                              ),
                              key: "reportDatail",
                              render: (text, report) => (
                                <Button
                                  onClick={() =>
                                    this.props.history.push(
                                      `/admin/student/detail/${studentData.studentId}/report/${report.courseNo}`
                                    )
                                  }
                                >
                                  <FormattedMessage id="admin.student-detail.learning-history-value-learning-report" />
                                </Button>
                              ),
                            },
                          ]}
                        />
                      </>
                    )
                  ) : (
                    <>
                      <Row type="flex" justify="space-between">
                        <Title level={4}>
                          {
                            <FormattedMessage id="admin.student-detail.prev-learning-history" />
                          }
                        </Title>
                      </Row>
                      <Col span={24} style={{ margin: "24px 0" }}>
                        {oldClassroomLectureViews && (
                          <Table
                            dataSource={oldClassroomLectureViews}
                            rowKey={"lectureTitle"}
                            columns={[
                              {
                                title: (
                                  <FormattedMessage id="admin.student-detail.learning-history-col-course" />
                                ),
                                key: "className",
                                render: (text, report) => (
                                  <span>{report.classroomName}</span>
                                ),
                              },
                              {
                                title: (
                                  <FormattedMessage id="admin.student-detail.learning-history-col-course" />
                                ),
                                key: "courseName",
                                render: (text, report) => (
                                  <span>{report.courseName}</span>
                                ),
                              },
                              {
                                title: (
                                  <FormattedMessage id="admin.student-detail.learning-history-col-lesson" />
                                ),
                                key: "lectureTitle",
                                render: (text, report) => (
                                  <span>
                                    {/* {report.lectureTitle} */}
                                    {report.lessonRange?.length > 1 ? (
                                      <FormattedMessage
                                        id="admin.student-detail.learning-history-value-lesson-range"
                                        values={{
                                          start: getLessonSeq({
                                            title: report.lessonRange[0],
                                          }),
                                          end: getLessonSeq({
                                            title: report.lessonRange[1],
                                          }),
                                        }}
                                      />
                                    ) : (
                                      <FormattedMessage
                                        id="admin.student-detail.learning-history-value-lesson"
                                        values={{
                                          lessonSeq: getLessonSeq({
                                            title: report.lessonRange[0],
                                          }),
                                        }}
                                      />
                                    )}
                                  </span>
                                ),
                              },
                              {
                                title: (
                                  <FormattedMessage id="admin.student-detail.learning-history-col-lesson-day" />
                                ),
                                key: "studyDay",
                                render: (text, report) => (
                                  <span>
                                    {/* {getKoreanDate(report.startDate, true)} ~{" "}
                                    {getKoreanDate(report.endDate, true)} */}
                                    {report.lessonDateRange?.length > 1 ? (
                                      <FormattedMessage
                                        id="admin.student-detail.learning-history-value-lesson-date-range"
                                        values={{
                                          year: moment(
                                            report.lessonDateRange[0]
                                          ).format("YYYY"),
                                          month: moment(
                                            report.lessonDateRange[0]
                                          ).format("MM"),
                                          day: moment(
                                            report.lessonDateRange[0]
                                          ).format("DD"),
                                          year2: moment(
                                            report.lessonDateRange[1]
                                          ).format("YYYY"),
                                          month2: moment(
                                            report.lessonDateRange[1]
                                          ).format("MM"),
                                          day2: moment(
                                            report.lessonDateRange[1]
                                          ).format("DD"),
                                        }}
                                      />
                                    ) : (
                                      <FormattedMessage
                                        id="admin.student-detail.learning-history-value-lesson-date"
                                        values={{
                                          year: moment(
                                            report.lessonDateRange[0]
                                          ).format("YYYY"),
                                          month: moment(
                                            report.lessonDateRange[0]
                                          ).format("MM"),
                                          day: moment(
                                            report.lessonDateRange[0]
                                          ).format("DD"),
                                        }}
                                      />
                                    )}
                                  </span>
                                ),
                              },
                              {
                                title: (
                                  <FormattedMessage id="admin.student-detail.learning-history-col-teacher" />
                                ),
                                key: "guide",
                                render: (text, report) => (
                                  <span>{report.teachingGuide}</span>
                                ),
                              },
                            ]}
                          />
                        )}
                      </Col>
                    </>
                  )}

                  {/*
                  <Row type="flex" justify="space-between">
                    <Title level={4}>{`회차별 수업 현황`}</Title>
                    {(userPermission === "master" ||
                      (userPermission && userPermission.studentUpdate)) && (
                      <Button
                        onClick={() => {
                          if (!studentData.classroom) {
                            return;
                          }
                          this.props.history.push(
                            `/admin/student/detail/${studentData.studentId}/report/0`
                          );
                        }}
                      >
                         학습리포트
                      </Button>
                    )}
                  </Row>
                  {studentData && studentData.lectures && (
                    <Table
                      dataSource={studentData.lectures}
                      rowKey={"lectureId"}
                      columns={[
                        {
                          title: "회차",
                          key: "title",
                          dataIndex: "title",
                          render: (text) => <span>{text}</span>,
                        },
                        {
                          title: "수업목록",
                          key: "quetures",
                          dataIndex: "sections",
                          render: (sections) => {
                            return (
                              <ul>
                                {filter(sections, function (o) {
                                  return o.type === "QUETURE";
                                }).map((s, i) => (
                                  <li key={i}>
                                    {formateQuetureName(s.quetureTitle)}
                                  </li>
                                ))}
                              </ul>
                            );
                          },
                        },
                        {
                          title: "수업날짜",
                          key: "date",
                          dataIndex: "date",
                          render: (text) => <span>{text}</span>,
                        },
                        {
                          title: "출결",
                          align: 'center',
                          key: "mvp",
                          dataIndex: "lectureId",
                          render: (id) => (
                            studentData.attendances[id] ? <font color="green">출석</font> : <font color="red">결석</font>
                          ),
                        },
                        {
                          title: "MVP",
                          align: 'center',
                          key: "mvp",
                          dataIndex: "lectureId",
                          render: (id) => (
                            studentData.lectureStatus[id].mvp ? <font>MVP</font> : "-"
                          ),
                        },
                        {
                          title: "과제 깨처보기",
                          align: 'center',
                          key: "homeworkStatus",
                          dataIndex: "lectureId",
                          render: (id) =>
                            {
                              let quetures = studentData.lectureStatus[id].homeworks.filter(h => h.homework.type === 'queture').length;
                              return (
                                  <span>
                                  <font color={quetures > 0 ? 'blue' : 'orange'}>{quetures}</font>
                                  </span>
                              );
                            }
                        },
                      ]}
                    />
                  )}
                  */}
                </Col>
              </Row>
            </div>
            {showChangeAccountId === true && (
              <ChangeStudentAccountId
                title={
                  <FormattedMessage id="admin.student-detail.change-student-id" />
                }
                visible={showChangeAccountId}
                maskClosable={false}
                onSelect={(val) => {
                  if (
                    val !== null &&
                    val !== "" &&
                    val !== studentData.account
                  ) {
                    Axios.put(
                      `/academy/v2/accounts/${this.props.user.orgId}/students/${studentData.studentId}/accountId`,
                      { oldAccountId: studentData.account, accountId: val }
                    )
                      .then((res) => {
                        console.log(" 수정 완료: ", res);
                        this.getStudentDetail(studentData.studentId);
                      })
                      .catch((e) => {
                        console.log(e);
                        message.error("변경 실패");
                      });
                  } else {
                    message.error("변경 실패");
                  }
                  this.setState({
                    showChangeAccountId: false,
                  });
                }}
                onCancel={() => {
                  this.setState({
                    showChangeAccountId: false,
                  });
                }}
                studentData={studentData}
                classroomList={this.props.classroomList}
              ></ChangeStudentAccountId>
            )}
            {showChangeClassPopup === true && (
              <ChangeStudentClassPopup
                title={
                  <FormattedMessage id="admin.student-detail.change-class-info" />
                }
                visible={showChangeClassPopup}
                maskClosable={false}
                onSelect={(classroom) => {
                  console.log(classroom);
                  if (classroom) {
                    // this.updateGuide(classroom);
                    console.log("###classroom:", classroom);
                    AccountApis.changeStudentClass(
                      this.props.user.orgId,
                      studentData.studentId,
                      classroom
                    )
                      .then((res) => {
                        console.log(" 수정 완료: ", res);
                        this.getStudentDetail(studentData.studentId);
                      })
                      .catch((e) => {
                        if (
                          e.response &&
                          e.response.data &&
                          e.response.data.code === 403000
                        ) {
                          console.log(e.response.data);
                          message.error("같은 코스으로만 변경 가능합니다.");
                        } else {
                          console.log(e);
                          message.error("변경 실패");
                        }
                      });
                  }
                  this.setState({
                    showChangeClassPopup: false,
                  });
                }}
                onCancel={() => {
                  this.setState({
                    showChangeClassPopup: false,
                  });
                }}
                studentData={studentData}
                classroomList={this.props.classroomList}
              />
            )}
            {showResetStudentPasswordPopup && (
              <ResetStudentPasswordPopup
                title={
                  <FormattedMessage id="admin.student-detail.reset-student-password" />
                }
                visible={this.state.showResetStudentPasswordPopup === true}
                orgType={this.context.type}
                maskClosable={false}
                handleOk={(password) => {
                  AccountApis.resetStudentPassword(
                    this.props.user.orgId,
                    studentData.studentId,
                    password
                  )
                    .then((res) => {
                      let msg = "초기화 완료";
                      if(locale === "en") {
                        msg = "Password reset successfully"
                      }
                      message.success(msg);
                      // message.warning(
                      //   <FormattedMessage id="admin.student-detail.msg-reset-success" />
                      // );
                      // this.props.onUpdateStudentInfo();
                      this.setState({
                        showResetStudentPasswordPopup: false,
                      });
                    })
                    .catch((e) => {
                      message.warning(
                        <FormattedMessage id="admin.student-detail.msg-reset-fail" />
                      );
                    });
                }}
                onCancel={() => {
                  this.setState({
                    showResetStudentPasswordPopup: false,
                  });
                }}
                studentData={studentData}
              />
            )}
          </>
        )}
      </Skeleton>
    );
  }
}

export default connect(
  ({ auth, classroom }) => ({
    pending: auth.pending || classroom.pending,
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    userPermission: auth.user.type === "org" ? "master" : auth.user.permission,
    classroomList: classroom.classroomList.sort((a, b) =>
      a.name.localeCompare(b.name)
    ),
  }),
  (dispatch) => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
  })
)(StudentDetailPage);
