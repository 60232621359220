export default {
  "hometraining.menu.today": "오늘의 과제",
  "hometraining.menu.review": "깨처 다시보기",
  "hometraining.menu.game": "깨봉놀이터",
  "hometraining.menu.setting": "설정",
  "hometraining.menu.logout": "로그아웃",
  "hometraining.homework-lesson": "{seq}회차 수업과제",
  "hometraining.homework-incomplete": "아직 과제를 완료하지 않았어!",
  "hometraining.homework-complete": "오늘의 과제를 완료했어",
  "hometraining.homework-review-queture": "깨처 다시보러가기",
  "hometraining.homework-todays-assignment": "오늘의 과제 하러가기",
  "hometraining.calendar.date-format": "{year}년 {month}월",
  "hometraining.calendar.today": "오늘",
  "hometraining.calendar.weekday.mon": "월",
  "hometraining.calendar.weekday.tue": "화",
  "hometraining.calendar.weekday.wed": "수",
  "hometraining.calendar.weekday.thu": "목",
  "hometraining.calendar.weekday.fri": "금",
  "hometraining.calendar.weekday.sat": "토",
  "hometraining.calendar.weekday.sun": "일",
  "hometraining.calendar.weekday.monday": "월요일",
  "hometraining.calendar.weekday.tuesday": "화요일",
  "hometraining.calendar.weekday.wednesday": "수요일",
  "hometraining.calendar.weekday.thursday": "목요일",
  "hometraining.calendar.weekday.friday": "금요일",
  "hometraining.calendar.weekday.saturday": "토요일",
  "hometraining.calendar.weekday.sunday": "일요일",
  "hometraining.calendar.class-time": "수업시간 : 매주 {days}",
  "hometraining.calendar.class-end": "수업 완료",
  "hometraining.calendar.mvp-selected": "선정",
  "hometraining.calendar.game-ranking": "게임 랜킹",
  "hometraining.calendar.game-ranking-place": "{place}등",
  "hometraining.calendar.class-scheduled": "수업예정",
  "hometraining.calendar.class": "수업",
  "hometraining.calendar.assignments": "과제",
  "hometraining.calendar.review-in-progress": "깨처 복습 <span>진행중</span>",
  "hometraining.calendar.review-completed": "깨처 복습 <span>완료</span>",
  "hometraining.calendar.mission-in-progress": "학습 미션 <span>진행중</span>",
  "hometraining.calendar.mission-completed": "학습 미션 <span>완료</span>",
  "hometraining.calendar.class-info": "수업정보",
  "hometraining.calendar.class-seq": "{seq}회차 수업",
  "hometraining.calendar.class-queture": "{seq}{ordinal} 깨처",
  "hometraining.calendar.homework-queture": "깨처 영상 복습",
  "hometraining.calendar.homework-quiz": "틀린 문제 풀기",
  "hometraining.calendar.homework-mission": "학습 미션",
  "hometraining.homework-empty": "<오늘의 과제>가 없습니다.",
  "hometraining.homework-lesson-assignments": "{seq}회차 수업 과제",
  "hometraining.homework-lesson-end-date": "{month}월 {day}일 까지",
  "hometraining.homework-queture-complete": "완료",
  "hometraining.homework-queture-incomplete": "미완료",
  "hometraining.homework-queture-summary": "개념입력 보기",
  "hometraining.homework-queture-review": "깨처 복습하기",
  "hometraining.homework-quiz-start": "오답 문제 풀기",
  "hometraining.player-replay": "다시보기",
  "hometraining.player-end": "끝내기",
  "hometraining.player-end-ment": "영상이 끝났습니다",
  "hometraining.quiz-exit": "나기기",
  "hometraining.quiz-confirm-answer-button-cancel": "계속풀래요",
  "hometraining.quiz-confirm-answer-button-confirm": "채점할래요",
  "hometraining.quiz-answer-count": "총 문제 개수 : {count}",
  "hometraining.review-filter-newest": "최신순",
  "hometraining.review-filter-oldest": "오래된 순",
  "hometraining.review-filter-all": "전체",
  "hometraining.review-filter-course": "{courseName} 코스",
  "hometraining.review-filter-select-course": "코스를 선택해주세요",
  "hometraining.review-filter-lesson-range":
    "{startLesson}회차 ~ {endLesson}회차",
  "hometraining.review-filter-select-lesson": "학습 회차를 선택해주세요",
  "hometraining.review-search-input-placeholder": "검색어를 입력해주세요",
  "hometraining.review-empty": "<깨처 다시보기>가 없습니다.",
  "hometraining.review-lesson-title": "{courseName}코스 {seq}{ordinals} 수업",
  "hometraining.review-lesson-date": "{year}년 {month}월 {day}일",
  "hometraining.review-queture": "깨처 복습하기",
  "hometraining.review-resolve-quiz": "문제 다시풀기",
  "hometraining.game-play": "게임하기",
  "hometraining.game-empty": "<깨봉놀이터>에 게임이 없습니다.",
  "hometraining.my-info.my-profile": "나의 정보",
  "hometraining.my-info.password": "비밀번호",
  "hometraining.my-info.set-password": "비밀번호 변경하기",
  "hometraining.my-info.save-password": "비밀번호 변경완료",
  "hometraining.my-info.old-password-placeholder": "기존 비밀번호를 입력하세요.",
  "hometraining.my-info.new-password-placeholder": "새로운 비밀번호를 입력하세요.",
  "hometraining.my-info.confirm-new-password-placeholder": "새로운 비밀번호를 확인해주세요.",
  "hometraining.my-info.new-password-not-matched": "새 비밀번호와 일치하지 않습니다.",
  "hometraining.my-info.birthday": "생년월일",
  "hometraining.my-info.address": "주소",
  "hometraining.my-info.parent-contact": "학부모 연락처",
  "hometraining.my-info.student-contact": "학생 연락처",
  "hometraining.my-info.school": "학교(학년)",
  "hometraining.my-info.grade": "학년",
};
