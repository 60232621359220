import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const MiniCalendar = (props) => {
  const {
    dateRows,
    curWeekIndex,
    curYear,
    curMonth,
    onPrevMonth,
    onNextMonth,
    onChangeWeekIndex,
  } = props;

  useEffect(() => {
    return () => {};
  }, [curMonth, curYear]);

  return (
    <div className="mini-calendar">
      <div className="title">
        <span className="prev" onClick={onPrevMonth}></span>
        <span className="date">
          {/* {`${curYear}년 ${curMonth}월`} */}
          <FormattedMessage
            id="hometraining.calendar.date-format"
            values={{ year: curYear, month: curMonth }}
          />
        </span>
        <span className="next" onClick={onNextMonth}></span>
      </div>
      <table>
        <tbody>
          <tr>
            <th>
              <FormattedMessage id="hometraining.calendar.weekday.sun" />
            </th>
            <th>
              <FormattedMessage id="hometraining.calendar.weekday.mon" />
            </th>
            <th>
              <FormattedMessage id="hometraining.calendar.weekday.tue" />
            </th>
            <th>
              <FormattedMessage id="hometraining.calendar.weekday.wed" />
            </th>
            <th>
              <FormattedMessage id="hometraining.calendar.weekday.thu" />
            </th>
            <th>
              <FormattedMessage id="hometraining.calendar.weekday.fri" />
            </th>
            <th>
              <FormattedMessage id="hometraining.calendar.weekday.sat" />
            </th>
          </tr>
          {dateRows &&
            dateRows.map((r, i) => (
              <tr
                key={i}
                className={classNames("date-row", {
                  "is-weekly": i === curWeekIndex,
                })}
                onClick={() => onChangeWeekIndex(i)}
              >
                {r}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default MiniCalendar;
