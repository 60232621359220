import React, { useState, useEffect } from "react";
import { filter, values, mapValues } from "lodash";
import "./_queturelist.scss";
import CommonApis from "../../utils/commonApis";
import axios from "axios";
import { Skeleton } from "antd";
import { numberToText } from "../../utils/utils";
import classNames from "classnames";
import PopupQueture from "../PopupQueture/PopupQueture";
import SummaryPopup from "./SummaryPopup";
import { FormattedMessage } from "react-intl";

const QuetureList = (props) => {
  const { lecture, homework, onEnterSolveQuiz, onShowQueturePopup } = props;
  const [isLoading, setLoading] = useState(false);
  const [quetureList, setQuetureList] = useState([]);
  const [quetureSummaries, setQuetureSummaries] = useState(null);

  const [summaryPopup, setSummaryPopup] = useState(null);

  useEffect(() => {
    console.log("homework: ", homework);
    const quetureHomework = filter(homework, function (o) {
      return o.type === "queture";
    });

    const quetureIds = values(
      mapValues(quetureHomework, function (o) {
        return o.value;
      })
    );

    if (lecture) {
      const summarySections = filter(
        lecture.sections,
        (o) => o.type === "SUMMARY"
      );

      console.log("summarySections: ", summarySections);
      if (summarySections.length > 0) {
        const _quetureSummaries = {};
        summarySections.map((s) => {
          _quetureSummaries[s.quetureId] = s.summaries && s.summaries[0];
        });
        setQuetureSummaries(_quetureSummaries);
      }
    }

    console.log(quetureHomework);

    if (quetureIds && quetureIds.length > 0) {
      setLoading(true);
      CommonApis.getQuetureDataList(quetureIds).then(
        axios.spread((...responses) => {
          console.log("queture responses: ", responses);
          const _quetureList = [];
          responses.map((res) => {
            if (res.data) {
              const _homework = quetureHomework.find(
                (h) => h.value === res.data.quetureId && h.myHomework
              );
              console.log("homeworkd: ", _homework);
              const _querueData = res.data;
              _querueData["quetureStatus"] = _homework
                ? _homework.myHomework.quetureStatus
                : null;
              _querueData["quizStatus"] = _homework
                ? _homework.quizStatus
                : null;
              _quetureList.push(res.data);
            }
          });

          console.log(_quetureList);
          setQuetureList(_quetureList);
          setLoading(false);
        })
      );
    }

    return () => {};
  }, [homework]);

  console.log(quetureList);
  console.log("queture summaries: ", quetureSummaries);

  return (
    <Skeleton loading={isLoading} active>
      {quetureList &&
        quetureList.length > 0 &&
        quetureList.map((queture, index) => (
          <div
            key={index + "-" + queture.quetureId}
            className={classNames("ht-queture-list", {
              "is-cover":
                index > 0 &&
                (!quetureList[index - 1].quetureStatus ||
                  (quetureList[index - 1].quizStatus !== "ok" &&
                    quetureList[index - 1].quizStatus !== "skip")),
            })}
          >
            {/* 볼 수 없을시 is-cover */}
            <div
              className={classNames("state-tag", {
                "is-complete":
                  queture.quetureStatus &&
                  queture.quetureStatus === "ok" &&
                  (queture.quizStatus === "ok" ||
                    queture.quizStatus === "skip"),
              })}
            >
              <span>
                {queture.quetureStatus &&
                queture.quetureStatus === "ok" &&
                (queture.quizStatus === "ok" || queture.quizStatus === "skip")
                  ? <FormattedMessage id="hometraining.homework-queture-complete" />
                  : <FormattedMessage id="hometraining.homework-queture-incomplete" />}{" "}
              </span>
            </div>
            <div className="img">
              <img
                src={
                  queture.contents &&
                  queture.contents.items &&
                  queture.contents.items.thumbnail &&
                  queture.contents.items.thumbnail.signedUrl
                    ? queture.contents.items.thumbnail.signedUrl
                    : null
                }
                alt=""
              />
            </div>
            <div className="textwrap">
              {/*<strong>{`${numberToText(index + 1)}번째 깨처`}</strong>*/}
              <div className="title">{queture.title}</div>
              {/*<p style={{fontSize: '16px'}}>{queture.conceptTags.join(",")}</p>*/}

              <div className="tag">
                {/*<strong>관련된 개념</strong>*/}
                {/*<p>{queture.conceptTags.join(",")}</p>*/}
                <p style={{ fontSize: "17px" }}>{queture.point}</p>
              </div>
              {quetureSummaries && quetureSummaries[queture.quetureId] && (
                <button
                  className="classmode-btn2"
                  onClick={() =>
                    setSummaryPopup(quetureSummaries[queture.quetureId])
                  }
                >
                  <FormattedMessage id="hometraining.homework-queture-summary" />
                </button>
              )}
            </div>
            <div
              className={classNames("btnwrap", {
                "is-chk":
                  queture.quetureStatus && queture.quetureStatus === "ok",
              })}
            >
              <button
                className={classNames("classmode-btn", {
                  "is-chk":
                    queture.quetureStatus && queture.quetureStatus === "ok",
                })}
                onClick={() => {
                  onShowQueturePopup(queture);
                }}
                style={{
                  width: "180px",
                  flex: 1,
                  display: "plex",
                  textAlign: "center",
                }}
              >
                <FormattedMessage id="hometraining.homework-queture-review" />
              </button>
              {queture.quetureStatus &&
                queture.quetureStatus === "ok" &&
                queture.quizStatus !== "skip" && (
                  <button
                    className={classNames("classmode-btn", {
                      "is-chk":
                        queture.quizStatus && queture.quizStatus === "ok",
                    })}
                    onClick={() => onEnterSolveQuiz(queture)}
                    style={{
                      width: "180px",
                      flex: 1,
                      display: "plex",
                      textAlign: "center",
                    }}
                  >
                    <FormattedMessage id="hometraining.homework-quiz-start" />
                  </button>
                )}
            </div>
          </div>
        ))}
      {summaryPopup && (
        <SummaryPopup
          isOpen={summaryPopup !== null}
          onClose={() => setSummaryPopup(null)}
          summaryInfo={summaryPopup}
        />
      )}
    </Skeleton>
  );
};

export default QuetureList;
