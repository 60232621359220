import React, { Component } from "react";
import { Row, Typography, Radio, Select, Input, Divider } from "antd";
import { orderBy, filter, values, some } from "lodash";
import { ConstantsContext } from "utils/ConstantsContext";
import AccountApis from "../../../utils/accountApis";
import StudentList from "./StudentList";
import { FormattedMessage } from "react-intl";

const pageCount = 10;
class StudentListTab extends Component {
  static contextType = ConstantsContext;
  _mounted = false;
  constructor(props) {
    super(props);

    this.state = {
      studentList: [],
      loading: false,
      page: 0,
      totalPages: 10,
      sortType: this.props.active ? "name asc" : "",
      searchType: "name",
      searchKeyword: "",
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { user, searchResult } = this.props;
    if (user) {
      if (searchResult) {
        console.log("search options: ", searchResult);
        this.setState(
          {
            page: searchResult.page,
            sortType: searchResult.sortType,
            searchType: searchResult.searchType,
            searchKeyword: searchResult.searchKeyword,
          },
          () => this.getStudentList(this.state.page)
        );
      } else {
        this.getStudentList(this.state.page);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.showUploadStudentPopup &&
      prevProps.showUploadStudentPopup !== this.props.showUploadStudentPopup
    ) {
      this.getStudentList(this.state.page);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onPageChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    this.setState(
      {
        page: pagination.current - 1,
      },
      () => {
        this.getStudentList(pagination.current - 1);
      }
    );
  };

  getStudentList = (page) => {
    const { active, currentClassroom, user } = this.props;
    const { sortType, searchType, searchKeyword } = this.state;

    let orderBy = sortType;
    let filter = null;
    let value = null;

    if (orderBy === "unassigned" || orderBy === "myclass") {
      orderBy = null;
      filter = sortType;
      if (searchType && searchKeyword) {
        filter = searchType;
        value = searchKeyword;
      }
    } else if (orderBy === "" && searchKeyword && searchKeyword.length > 0) {
      filter = searchType;
      value = searchKeyword;
    }

    this.setState({ loading: true });
    AccountApis.getStudentList(
      user.orgId,
      active,
      currentClassroom,
      page,
      pageCount,
      orderBy,
      filter,
      value
    ).then((res) => {
      console.log("student list: ", res);
      if (this.mounted) {
        this.setState({
          loading: false,
          studentList: res.list,
          page: res.page,
          totalPages: res.totalPages,
        });
      }
    });
  };

  onChangeSortType = (e) => {
    // const { studentList, user } = this.props;

    // let _newList = studentList;

    // const _sortType = e.target.value;
    // if (_sortType === "high") {
    //   _newList = orderBy(_newList, ({ avgScore }) => avgScore || "", ["desc"]);
    // } else if (_sortType === "low") {
    //   _newList = orderBy(_newList, ({ avgScore }) => avgScore || "", ["asc"]);
    // } else if (_sortType === "noClassroom") {
    //   // _newList = filter(_newList, [
    //   //   ({ classroom }) => classroom || "",
    //   //   ["desc"]
    //   // ]);
    //   _newList = filter(_newList, o => !o.classroom);
    // } else if (_sortType === "myClassroom") {
    //   console.log("my classroom list: ", user.classrooms);
    //   console.log("student list: ", _newList);
    //   _newList = filter(
    //     _newList,
    //     s =>
    //       s.classroom &&
    //       filter(
    //         user.classrooms,
    //         c => c.classroomId === s.classroom.classroomId
    //       ).length > 0
    //   );
    // }

    this.setState(
      {
        page: 0,
        sortType: e.target.value,
        searchKeyword: "",
      },
      () => {
        this.getStudentList(0);
      }
    );
  };

  onSearch = (value) => {
    const _searchType = this.state.searchType;

    console.log("### searchType: ", _searchType, value);

    // const { studentList } = this.props;

    // let _newList = studentList;

    // console.log("search type: ", _searchType);
    // if (value) {
    //   _newList = filter(_newList, function(o) {
    //     console.log(typeof o[_searchType]);
    //     if (_searchType === "mobile") {
    //       return (
    //         (o["mobile"] && o["mobile"].includes(value)) ||
    //         (o["parentMobile"] && o["parentMobile"].includes(value))
    //       );
    //     } else {
    //       return o[_searchType] && o[_searchType].includes(value);
    //     }
    //   });
    // }

    this.setState(
      {
        page: 0,
        sortType: "",
        searchKeyword: value,
      },
      () => {
        this.getStudentList(0);
      }
    );
  };

  render() {
    const { title } = this.props;
    return (
      <>
        <Row type="flex" justify="space-between" style={{ margin: "20px 0" }}>
          <Typography.Title level={4}>{title}</Typography.Title>
          <div>
            {this.props.active && (
              <Radio.Group
                defaultValue="name asc"
                onChange={this.onChangeSortType}
                value={this.state.sortType}
              >
                <Radio value={"name asc"}>
                  <FormattedMessage id="admin.students.list-col-name" />
                  <FormattedMessage id="admin.students.list-filter-by" />
                </Radio>
                <Radio value={"account asc"}>
                  <FormattedMessage id="admin.students.list-col-id" />
                  <FormattedMessage id="admin.students.list-filter-by" />
                </Radio>
                <Radio value={"guide asc"}>
                  <FormattedMessage id="admin.students.list-col-teacher" />
                  <FormattedMessage id="admin.students.list-filter-by" />
                </Radio>
                <Radio value={"classroom asc"}>
                  <FormattedMessage id="admin.students.list-col-class" />
                  <FormattedMessage id="admin.students.list-filter-by" />
                </Radio>
                <Radio value={"school asc"}>
                  <FormattedMessage id="admin.students.list-col-school" />
                  <FormattedMessage id="admin.students.list-filter-by" />
                </Radio>
                {/* <Radio value={"avgScore desc"}>높은점수순</Radio> */}
                <Divider type="vertical" />
                &nbsp; &nbsp;
                {/* NOTE: unassigned and myclass are handled as filter */}
                <Radio value={"unassigned"}>
                  {/* {`${this.context.className} 미배정`} */}
                  <FormattedMessage id="admin.students.list-unassigned-class" />
                </Radio>
                <Divider type="vertical" />
                &nbsp; &nbsp;
                {this.context.type === "school" && (
                  <>
                    <Radio value={"myclass"}>우리반 보기</Radio>
                    <Divider type="vertical" />
                    &nbsp; &nbsp;
                  </>
                )}
                {/*<Radio value={""}>검색</Radio> */}
              </Radio.Group>
            )}
            <Select
              defaultValue="name"
              value={this.state.searchType}
              onChange={(value) =>
                this.setState({
                  searchType: value,
                  sortType: "", // reset sort type on searching
                })
              }
              style={{ width: "125px" }}
            >
              <Select.Option value="name">
                <FormattedMessage id="admin.students.list-col-name" />
              </Select.Option>
              <Select.Option value="account">
                <FormattedMessage id="admin.students.list-col-id" />
              </Select.Option>
              <Select.Option value="guide">
                <FormattedMessage id="admin.students.list-col-teacher" />
              </Select.Option>
              <Select.Option value="classroom">
                <FormattedMessage id="admin.students.list-col-class" />
              </Select.Option>
              <Select.Option value="mobile">
                <FormattedMessage id="admin.students.list-col-student-contact" />
              </Select.Option>
              <Select.Option value="parent_mobile">
                <FormattedMessage id="admin.students.list-col-parent-contact" />
              </Select.Option>
              <Select.Option value="parent_email">
                <FormattedMessage id="admin.students.list-col-parent-email" />
              </Select.Option>
              <Select.Option value="school">
                <FormattedMessage id="admin.students.list-col-school" />
              </Select.Option>
            </Select>
            <FormattedMessage id="admin.students.list-search-palceholder">
              {(placeholder) => (
                <Input.Search
                  placeholder={placeholder}
                  value={this.state.searchKeyword}
                  onChange={(e) =>
                    this.setState({
                      searchKeyword: e.target.value,
                      sortType: "", // reset sort type on searching
                    })
                  }
                  onSearch={(value) => this.onSearch(value)}
                  style={{ width: "200px", marginLeft: "8" }}
                />
              )}
            </FormattedMessage>
          </div>
        </Row>
        <StudentList
          studentList={this.state.studentList}
          onUpdateStudentInfo={() => {
            this.getStudentList(0);
          }}
          loading={this.state.loading}
          onPageChange={this.onPageChange}
          pagination={{
            current: this.state.page + 1,
            defaultPageSize: pageCount,
            total: this.state.totalPages * pageCount,
          }}
          searchOptions={{
            page: this.state.page,
            sortType: this.state.sortType,
            searchType: this.state.searchType,
            searchKeyword: this.state.searchKeyword,
            active: this.props.active,
            currentClassroom: this.props.currentClassroom,
          }}
          {...this.props}
        />
      </>
    );
  }
}

export default StudentListTab;
