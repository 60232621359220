import React, { Component } from "react";
import {
  Table,
  Button,
  List,
  Typography,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Dropdown,
  Icon,
  Menu,
  Checkbox,
} from "antd";
import { Link } from "react-router-dom";
import AccountApis from "../../../utils/accountApis";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = (callback) => {
    return (
      <Checkbox
        onChange={(e) => {
          console.log(e);
          callback(e);
        }}
      />
    );
  };

  renderCell = ({
    getFieldDecorator,
    // ...restProps
  }) => {
    const {
      editing,
      dataindex,
      title,
      record,
      index,
      onUpdate,
      children,
      // ...restProps
    } = this.props;

    const keys = dataindex ? dataindex.split(".") : null;
    const _initialValue = keys ? record[keys[0]][keys[1]] : false;
    return (
      <td>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataindex, {
              valuePropName: "checked",
              initialValue: _initialValue,
            })(this.getInput(onUpdate))}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class GuidePermissionList extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      editingKey: null,
    };
  }

  isEditing = (record) => record.guideId === this.state.editingKey;

  onUpdate = (e) => {
    console.log(e);
    const id = e.target.id;
    const checked = e.target.checked;
    console.log(this.props.form);
  };

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  edit(guideId) {
    this.setState({ editingKey: guideId });
  }

  save(form, guideId) {
    console.log(guideId);
    form.validateFields((error, row) => {
      console.log("row data: ", row);
      if (error) {
        return;
      }
      this.props.onUpdateGuidePermission(guideId, row.permission);
    });
  }

  render() {
    const { tab, guideList } = this.props;
    const components = {
      body: {
        cell: EditableCell,
      },
    };
    let columns = [
      {
        // title: "구분",
        title: <FormattedMessage id="admin.teachers.col-category" />,
        key: "state",
        dataIndex: "state",
        render: (state) => (
          <span>
            {state === "working" ? (
              <Typography.Text>
                <FormattedMessage id="admin.teachers.value-category-empoyed" />
              </Typography.Text>
            ) : (
              <Typography.Text type="danger">
                <FormattedMessage id="admin.teachers.value-category-resignation" />
              </Typography.Text>
            )}
          </span>
        ),
      },
      {
        // title: "이름",
        title: <FormattedMessage id="admin.teachers.col-name" />,
        key: "name",
        dataIndex: "name",
        render: (text, record) => (
          <Link to={`/admin/guide/detail/${record.guideId}`}>
            <span>{text}</span>
          </Link>
        ),
      },
      {
        // title: `담당 ${this.context.className}`,
        title: <FormattedMessage id="admin.teachers.col-classes" />,
        key: "classrooms",
        dataIndex: "classrooms",
        render: (classrooms) => (
          <span>
            {classrooms.length > 0 ? (
              classrooms.length === 1 ? (
                <Button>{classrooms[0].name}</Button>
              ) : (
                <>
                  <Dropdown
                    overlay={
                      <Menu>
                        {classrooms.map((classroom) => (
                          <Menu.Item key={classroom.classroomId}>
                            {classroom.name}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <Button>
                      {/* {`모든 ${this.context.className} 보기(${classrooms.length})`} */}
                      <FormattedMessage
                        id="admin.teachers.value-classes-view-all"
                        values={{ count: classrooms.length }}
                      />
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </>
              )
            ) : (
              <FormattedMessage id="admin.teachers.value-classes-none" />
            )}
          </span>
        ),
      },
      // {
      //   title: "학생 관련 권한",
      //   editable: true,
      //   children: [
      //   ]
      // },
      {
        // title: "학생 전체 열람",
        title: <FormattedMessage id="admin.teachers.col-view-all-students" />,
        editable: true,
        dataIndex: "permission.studentRead",
        key: "permission.studentRead",
        render: (text) => <span>{text ? <Icon type="check" /> : ""}</span>,
      },
      {
        // title: "학생 설정",
        title: <FormattedMessage id="admin.teachers.col-student-settings" />,
        editable: true,
        dataIndex: "permission.studentUpdate",
        key: "permission.studentUpdate",
        render: (text) => <span>{text ? <Icon type="check" /> : ""}</span>,
      },
      {
        // title: "학생 등록",
        title: <FormattedMessage id="admin.teachers.col-enroll-students" />,
        editable: true,
        dataIndex: "permission.studentCreateDelete",
        key: "permission.studentCreateDelete",
        render: (text) => <span>{text ? <Icon type="check" /> : ""}</span>,
      },
      // {
      //   title: "가이드 관련 권한",
      //   editable: true,
      //   children: [
      //   ]
      // },
      // {
      //   title: "가이드 설정",
      //   editable: true,
      //   dataIndex: "permission.guideUpdate",
      //   key: "permission.guideUpdate",
      //   render: text => <span>{text ? <Icon type="check" /> : ""}</span>
      // },
      // {
      //   title: "가이드 등록",
      //   editable: true,
      //   dataIndex: "permission.guideCreateDelete",
      //   key: "permission.guideCreateDelete",
      //   render: text => <span>{text ? <Icon type="check" /> : ""}</span>
      // },
      // {
      //   title: "클래스 관련 권한",
      //   editable: true,
      //   children: [
      //   ]
      // },
      {
        // title: `${this.context.className} 설정`,
        title: <FormattedMessage id="admin.teachers.col-class-settings" />,
        editable: true,
        dataIndex: "permission.classUpdate",
        key: "permission.classUpdate",
        render: (text) => <span>{text ? <Icon type="check" /> : ""}</span>,
      },
      {
        // title: `${this.context.className} 등록`,
        title: <FormattedMessage id="admin.teachers.col-registering-class" />,
        editable: true,
        dataIndex: "permission.classCreateDelete",
        key: "permission.classCreateDelete",
        render: (text) => <span>{text ? <Icon type="check" /> : ""}</span>,
      },
      // {
      //   title: "기타",
      //   editable: true,
      //   children: [
      //   ]
      // },
      {
        // title: "수업시작",
        title: <FormattedMessage id="admin.teachers.start-class" />,
        editable: true,
        dataIndex: "permission.lectureStart",
        key: "permission.lectureStart",
        render: (text) => <span>{text ? <Icon type="check" /> : ""}</span>,
      },
      {
        // title: "뉴스레터관리",
        title: <FormattedMessage id="admin.teachers.col-manage-newsletter" />,
        editable: true,
        dataIndex: "permission.notice",
        key: "permission.notice",
        render: (text) => <span>{text ? <Icon type="check" /> : ""}</span>,
      },
      {
        // title: "전체 선택",
        title: <FormattedMessage id="admin.teachers.col-select-all" />,
        editable: true,
        dataIndex: "permission.all",
        key: "permission.all",
        render: (text) => <span>{text ? <Icon type="check" /> : ""}</span>,
      },
      {
        title: "Operaton",
        key: "operaton",
        render: (text, record, index) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);

          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    type="primary"
                    onClick={() => this.save(form, record.guideId)}
                    style={{ marginRight: 8 }}
                  >
                    <FormattedMessage id="admin.teachers.save" />
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                // title={"정말로 취소 하시겠습니까?"}
                title={<FormattedMessage id="admin.teachers.cancel-confirm" />}
                onConfirm={() => this.cancel(record.guideId)}
              >
                <Button>
                  <FormattedMessage id="admin.teachers.cancel" />
                </Button>
              </Popconfirm>
            </span>
          ) : (
            <span>
              <Button
                onClick={() => {
                  this.setState({
                    editingKey: record.guideId,
                  });
                }}
              >
                <FormattedMessage id="admin.teachers.edit" />
              </Button>
            </span>
          );
        },
      },
    ];
    columns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          dataindex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          onUpdate: this.onUpdate,
        }),
      };
    });

    const data = guideList;

    console.log("data: ", data);
    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          rowKey="guideId"
          components={components}
          dataSource={data}
          columns={columns}
        />
      </EditableContext.Provider>
    );
  }
}

const EditableGuideList = Form.create()(GuidePermissionList);

export default EditableGuideList;
