import React, { Component } from "react";
import {
  Row,
  Col,
  message,
  Modal, Checkbox
} from "antd";
import Axios from "../../../../utils/request";
import NewsletterApis from "../../../../utils/newsletterApis";

class ParentMobileListPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endLoad: false,
      parentMobileList: [],

    }
  }

  load = () => {
    const { orgId } = this.props;
    Axios.get(`/academy/v2/accounts/${orgId}/parentMobiles`).then(e => {
      this.setState({
        parentMobileList: e
      })
    })
  }

  componentDidMount() {
    this.load();
    console.log("this.", this.parentMobileList
    )
  }

  handleOk = () => {
    const { orgId, url } = this.props;
    const { parentMobileList } = this.state;

    let _data =[];
    if(parentMobileList) {
      parentMobileList.map(e => {
        let data={
          name: e.name,
          id: e.id,
          isSend: e.isSend
        }
        console.log(e.isSend)
        _data.push(data)
      })
    }
    NewsletterApis.sendNewsletter(url,orgId,_data).then((res) => {
      message.success("전송완료");
      this.setState({
        loading: false,
      });
      this.props.onClose();
    })
      .catch((e) => {
        message.error("전송실패");
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { parentMobileList } = this.state;
    const { onClose } = this.props;
    console.log("연락처 리스트", parentMobileList)
    return (
      <Modal onOk={() => this.handleOk()} title={"학부모 연락처 목록"} visible={true} onCancel={() => onClose()} okText={"전송"}>
        <div style={{marginBottom : '10px'}}>
          {"학생수 : " + (parentMobileList ? parentMobileList.length : "")} {", 보낼 학생수 : " + (parentMobileList ? parentMobileList.filter(e => {
          return e.isSend == true
        }).length : "")}
        </div>
        <Row>
          <Col span={1}>
          </Col>
          <Col span={5}>
            이름
          </Col>
          <Col span={6}>
            학부모 연락처
          </Col>
          <Col span={6}>
            클래스 이름
          </Col>
          <Col span={6}>
            마지막 수업일
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            {parentMobileList && parentMobileList.map((e, idx) => <div>
                <Checkbox defaultChecked={e.isSend} style={{ marginRight: '10px' }} onChange={(e) => {
                  let data = parentMobileList;
                  data[idx].isSend = e.target.checked;
                  this.setState({
                    parentMobileList: data
                  })
                }}>
                </Checkbox>
                {e.name}
              </div>
            )}
          </Col>
          <Col span={6}>
            {parentMobileList && parentMobileList.map(e => (<div>
                {e.parentMobile ? e.parentMobile : "전화번호없음"}
              </div>)
            )}
          </Col>
          <Col span={6}>
            {parentMobileList && parentMobileList.map(e => (<div>
                {e.className ? e.className  : "전화번호없음"}
              </div>)
            )}
            </Col>
          <Col span={6}>
            {parentMobileList && parentMobileList.map(e => (<div>
                {e.endDate ? e.endDate : "수업날짜없음"}
              </div>)
            )}
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default ParentMobileListPopup;