import React, { useState } from "react";
import classNames from "classnames";

import "./_filter.scss";
import { getKoreanDate } from "../../utils/utils";
import { Input } from "antd";
import CommonApis from "../../utils/commonApis";
import axios from "axios";
import { FormattedMessage } from "react-intl";

const Filter = (props) => {
  const {
    sortType,
    onChangeSortType,
    list,
    onFilter,
    filterValue,
    lectureCountFilter,
    filterCourse,
    onFilterTitle,
    filterValueTitle,
    filterTitle,
    locale,
  } = props;
  // const [tab, setTab] = useState('newest');
  const [filter, setFilter] = useState("off");
  const [filter2, setFilter2] = useState("off");

  const handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      filterTitle(e.target.value);
    }
  };

  function filterToggle() {
    console.log(filterCourse + "filterCourse ");
    setFilter(filter === "off" ? "on" : "off");
  }

  function filterToggle2() {
    setFilter2(filter2 === "off" ? "on" : "off");
  }
  console.log("filterValue: ", filterValue);
  return (
    <div className="ht-filter">
      <ul className="due" style={{ paddingRight: "21.5%" }}>
        <li
          style={{ fontWeight: 700, letterSpacing: "-2%" }}
          className={classNames("", {
            "is-active": sortType === "newest",
          })}
          onClick={() => {
            onChangeSortType("newest");
          }}
        >
          <FormattedMessage id="hometraining.review-filter-newest" />
        </li>
        <li
          style={{ fontWeight: 700, letterSpacing: "-2%" }}
          className={classNames("", {
            "is-active": sortType === "oldest",
          })}
          onClick={() => onChangeSortType("oldest")}
        >
          <FormattedMessage id="hometraining.review-filter-oldest" />
        </li>
      </ul>
      <div
        style={{
          minWidth: "0px",
          minHeight: "0px",
          width: "212px",
          height: "44px",
          marginRight: "8px",
        }}
        className={classNames("ht-filter-wrap", {
          on: filter2 == "on",
        })}
        onClick={filterToggle2}
      >
        <span>
          {/* {filterValueTitle ? filterValueTitle + " 코스" : "코스를 선택해주세요"} */}
          {filterValueTitle ? (
            <FormattedMessage
              id="hometraining.review-filter-course"
              values={{ courseName: filterValueTitle }}
            />
          ) : (
            <FormattedMessage id="hometraining.review-filter-select-course" />
          )}
        </span>
        <ul>
          {filterValueTitle && (
            <li
              onClick={() => {
                onFilterTitle(null);
              }}
            >
              <FormattedMessage id="hometraining.review-filter-all" />
            </li>
          )}
          {filterCourse &&
            filterCourse.map((item) => (
              <li key={item} onClick={() => onFilterTitle(item)}>
                <FormattedMessage
                  id="hometraining.review-filter-course"
                  values={{ courseName: item }}
                />
              </li>
            ))}
        </ul>
      </div>
      <div
        style={{
          minWidth: "0px",
          minHeight: "0px",
          width: "242px",
          height: "44px",
          marginRight: "8px",
        }}
        className={classNames("ht-filter-wrap", {
          on: filter == "on",
        })}
        onClick={filterToggle}
      >
        <span>
          {filterValue ? (
            <FormattedMessage
              id="hometraining.review-filter-lesson-range"
              values={{
                startLesson: filterValue[0],
                endLesson: filterValue[2],
              }}
            />
            // filterValue
          ) : (
            <FormattedMessage id="hometraining.review-filter-select-lesson" />
          )}
        </span>
        <ul>
          {filterValue && (
            <li
              onClick={() => {
                onFilter(null);
              }}
            >
              <FormattedMessage id="hometraining.review-filter-all" />
            </li>
          )}
          {lectureCountFilter &&
            lectureCountFilter
              .sort(function (a, b) {
                let result_A = a.replace(/[^0-9]/g, "");
                let result_B = b.replace(/[^0-9]/g, "");
                return result_A - result_B;
              })
              .map((item, index) => (
                <li
                  key={item}
                  onClick={function () {
                    let str;
                    let splitArr = item.split("~");
                    str = [splitArr[0], "~", splitArr[1]];
                    onFilter(str);
                  }}
                >
                  {/* {item} */}
                  <FormattedMessage
                    id="hometraining.review-filter-lesson-range"
                    values={{
                      startLesson: item.split("~")[0],
                      endLesson: item.split("~")[1],
                    }}
                  />
                </li>
              ))}
        </ul>
      </div>
      <span>
        {/* <FormattedMessage id={"login-form.id-placeholder"}>
                {(placeholder) => (
                  <input
                    type="text"
                    name="account"
                    className=""
                    placeholder={placeholder}
                    required
                    value={form.account}
                    onChange={updateFormData}
                    onKeyPress={handleKeyPress}
                  />
                )}
              </FormattedMessage> */}
        <FormattedMessage id="hometraining.review-search-input-placeholder">
          {(placeholder) => (
            <Input.Search
              style={{
                minWidth: "0px",
                minHeight: "0px",
                width: "304px",
                height: "44px",
                top: "1px",
              }}
              placeholder={placeholder}
              onKeyDown={(e) => handleKeyDown(e)}
            ></Input.Search>
          )}
        </FormattedMessage>
      </span>
    </div>
  );
};

export default Filter;
