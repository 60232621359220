import React from "react";
import { useMouse } from "react-use";
import LectureTooltip from "./LectureTooltip";
import HomeworkTooltip from "./HomeworkTooltip";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

const HomeworkProgress = (props) => {
  const { homework } = props;
  const ref = React.useRef(null);
  const { docX, docY, posX, posY, elX, elY, elW, elH } = useMouse(ref);

  if (!homework) {
    return null;
  }

  return (
    <div className="wrap" ref={ref}>
      <p>
        <span>
          <FormattedMessage id="hometraining.calendar.assignments" />
        </span>
      </p>
      <div className="time">{homework.endDate}</div>
      <FormattedHTMLMessage
        tagName="p"
        id={
          "hometraining.calendar." +
          (homework?.quetureHomework
            ? "review-completed"
            : "review-in-progress")
        }
      />
      {/* <p>
        깨처 복습 <span>{homework.quetureHomework ? "완료" : "진행중"}</span>
      </p> */}
      {homework.uploadList && homework.uploadList.length > 0 && (
        // <p>
        //   학습 미션 <span>{homework.uploadHomework ? "완료" : "진행중"}</span>
        // </p>
        <FormattedHTMLMessage
          tagName="p"
          id={
            "hometraining.calendar." +
            (homework?.uploadHomework
              ? "mission-completed"
              : "mission-in-progress")
          }
        />
      )}
      {homework && (
        <HomeworkTooltip
          homework={homework}
          top={elY}
          left={elX}
          locale={props.locale}
        />
      )}
    </div>
  );
};

export default HomeworkProgress;
