import React, { Component } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { formatProblem, formatText } from "../utils/Formatter";
import { DraggableAnswer, DroppableArea, Image } from "./";
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import classNames from 'classnames'

class DragTextProblem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      answers: null,
      quizId: null
    }
  }

  init = data => {
    const { quizId, quiz, timestamp } = data;
    const { problem, choices } = quiz;

    const { stepQuizAnswers } = this.props;

    if (stepQuizAnswers && stepQuizAnswers.length > 0) {
      const stepAnswersMap = [];
      stepQuizAnswers.map(a => {
        if (a) {
          const answer = choices.find(c => c.key === a);
          stepAnswersMap.push(answer);
        }
      });
      this.setState({
        quizId: quizId,
        answers: stepAnswersMap
      }, () => {
        this.updateProblem(this.props);
      });
    } else {
      // if (answers == null) {
      let answerCount = 0;
      const reg = /\{\{([^\{^}]*)}}/gm;
      let r;
      while ((r = reg.exec(problem))) {
        const val = r[1].trim();
        if (val.length === 0) {
          answerCount++;
        }
      }
      this.setState({
        quizId: quizId,
        answers: new Array(answerCount).fill(null),
      }, () => {
        this.updateProblem(this.props)
      });
      // }
    }
  };

  updateProblem = (props) => {
    const { solvedData, showReallyTrue, data } = props;
    const { quizId, quiz, timestamp } = data;
    const { problem, choices } = quiz;
    const { answers } = this.state;

    let inlineChoices = [];
    var ignoreCount = 0;

    const afterChoices = choices.filter(
      c => !inlineChoices.some(i => i === c.key)
    );

    this.setState({
      problem: formatProblem(problem, quizId + "_timestamp", (index, data) => {
        const key = quizId + "_" + timestamp + "_area_" + index;

        if (data != null && data.length > 0) { // 문제안에 답 처리.
          const choiceIdx = choices.findIndex(c => c.key === data);

          if (choiceIdx >= 0) {
            ignoreCount++;
            console.log('ignored: ', data);
            return (
              <span key={quizId + "_ans_" + index}>
                {formatText(choices[choiceIdx].answer)}
              </span>
            );
          }
        }

        const _index = index - ignoreCount;
        const selected = this.props.dragAnswers && this.props.dragAnswers[_index];
        console.log('selected: ', selected);
        console.log('_index: ', _index);
        return (
          <Droppable key={'dropable-to-' + _index} droppableId={"droppable-to-" + _index} direction={'horizontal'}>
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: 'inline-block' }}>
                <Draggable draggableId={'draggable-' + _index} key={_index} index={_index} isDragDisabled={true}>
                  {
                    (p, s) => (
                      <span ref={p.innerRef}
                        {...p.draggableProps}
                        {...p.dragHandleProps}
                        key={data}
                        // index={index}
                        className={classNames('droppable', {
                          'fill': (selected !== null && selected !== undefined) || showReallyTrue,
                          'is-true': showReallyTrue ? solvedData.solved : (solvedData.solved && solvedData.corrects[_index]),
                          'is-false': !showReallyTrue && solvedData.solved && !solvedData.corrects[_index],
                          // 'is-reallytrue': showReallyTrue && solvedData.solved,
                          'none-event': solvedData && solvedData.solved,
                          'is-dragging': this.props.isDragging,
                          'is-over': s.isDraggingOver
                          // 'none': s.isDraggingOver
                        })}
                        style={{ transform: 'none !important' }}
                      >
                        {
                          showReallyTrue ?
                            <span className="text">{formatText(solvedData.correctAnswers[_index])}</span>
                            :
                            <span className="text">{selected == null ? '' : formatText(selected.answer, new Date().getTime())}</span>
                        }
                      </span>
                    )
                  }
                </Draggable>
                {/* {provided.placeholder} */}
              </div>
            )}
          </Droppable>
        )
      }),
    })
  }

  componentDidMount() {
    const { data } = this.props;

    if (data != null) {
      // this.init(data);
      this.setState({
        answers: this.props.dragAnswers
      }, this.updateProblem(this.props))
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showReallyTrue !== this.props.showReallyTrue) {
      this.setState({
        answers: this.props.dragAnswers
      }, this.updateProblem(this.props))
      // this.updateProblem(this.props)

    }
    if (this.props.solved && !prevProps.solved) {
      this.setState({
        answers: this.props.dragAnswers
      }, this.updateProblem(this.props))
      // this.updateProblem(this.props)
    }
    if (this.props.data && (prevProps.step !== this.props.step || prevProps.data.quizId !== this.props.data.quizId || prevProps.data.timestamp !== this.props.data.timestamp)) {
      // this.init(this.props.data)
      this.setState({
        answers: this.props.dragAnswers
      }, this.updateProblem(this.props))
    }
    if (this.props.data && (this.props.dragAnswers !== prevProps.dragAnswers || this.props.isDragging !== prevProps.isDragging)) {
      this.setState({
        answers: this.props.dragAnswers
      }, this.updateProblem(this.props))
    }
  }

  render() {
    console.log('dragAnswers: ', this.props.dragAnswers);
    console.log('problem: ', this.state.problem);
    return (
      <div className="quiz-content__answer t-answer-ts">
        {this.state.problem}
      </div>
    )
  }
}


export default DragTextProblem;
