import React, { Component } from "react";

import {
  Modal,
  Typography,
  DatePicker,
  Select,
  Row,
  Col,
  Table,
  Input,
  TimePicker,
  Checkbox,
} from "antd";
import { filter } from "lodash";
import moment from "moment";
import { getWeekdayTable } from "../../../../utils/utils";
import { value } from "lodash/seq";
import index from "styled-components/dist/styled-components-macro.esm";
import CheckboxGroup from "antd/es/checkbox/Group";

const weekdays = getWeekdayTable();
const locale = process.env.REACT_APP_LOCALE || "ko";

class ChangeClassroomDayPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scheduleList: null,
      changeTime: null,
      month: null,
      date: [],
      days: [],
    };
  }

  handleOK() {
    const { onSelect, scheduleList, onOk } = this.props;
    this.setState({});
    const a = {
      day: this.state.month,
      time: this.state.changeTime,
    };
    let _date = this.state.date;
    const _data = this.state.days.filter((b) => {
      return _date.includes(b.day);
    });

    onSelect(_data);
  }

  render() {
    // const {onOk} = this.props;
    const { scheduleList } = this.props;
    return (
      <Modal
        {...this.props}
        onOk={() => this.handleOK()}
        // onOk={(value) => {
        //   this.handleOK()
        // }}
      >
        <Checkbox.Group
          style={{ padding: "8px" }}
          onChange={(e) => {
            this.setState({ date: e });
            this.state.days.map((a) => console.log(a));
            e.map((a) => console.log(a));
          }}
        >
          <Checkbox value="mon">{locale === "en" ? "Mon" : "월요일"}</Checkbox>
          <Checkbox value="tue">{locale === "en" ? "Tue" : "화요일"}</Checkbox>
          <Checkbox value="wed">{locale === "en" ? "Wed" : "수요일"}</Checkbox>
          <Checkbox value="thu">{locale === "en" ? "Thu" : "목요일"}</Checkbox>
          <Checkbox value="fri">{locale === "en" ? "Fri" : "금요일"}</Checkbox>
          <Checkbox value="sat">{locale === "en" ? "Sat" : "토요일"}</Checkbox>
          <Checkbox value="sun">{locale === "en" ? "Sun" : "일요일"}</Checkbox>
        </Checkbox.Group>
        <div>
          {this.state.date &&
            this.state.date.map((value, i) => (
              <div>
                {value === "mon" ? (
                  <>{locale === "en" ? "Mon" : "월요일"}</>
                ) : value === "tue" ? (
                  <>{locale === "en" ? "Tue" : "화요일"}</>
                ) : value === "wed" ? (
                  <>{locale === "en" ? "Wed" : "수요일"}</>
                ) : value === "thu" ? (
                  <>{locale === "en" ? "Thu" : "목요일"}</>
                ) : value === "fri" ? (
                  <>{locale === "en" ? "Fri" : "금요일"}</>
                ) : value === "sat" ? (
                  <>{locale === "en" ? "Sat" : "토요일"}</>
                ) : value === "sun" ? (
                  <>{locale === "en" ? "Sun" : "일요일"}</>
                ) : (
                  ""
                )}
                <TimePicker
                  style={{ padding: "5px" }}
                  format="HH:mm"
                  onChange={(val, timeString) => {
                    switch (this.state.date[i]) {
                      case "mon":
                        this.state.days[0] = { day: value, time: timeString };
                        break;
                      case "tue":
                        this.state.days[1] = { day: value, time: timeString };
                        break;
                      case "wed":
                        this.state.days[2] = { day: value, time: timeString };
                        break;
                      case "thu":
                        this.state.days[3] = { day: value, time: timeString };
                        break;
                      case "fri":
                        this.state.days[4] = { day: value, time: timeString };
                        break;
                      case "sat":
                        this.state.days[5] = { day: value, time: timeString };
                        break;
                      case "sun":
                        this.state.days[6] = { day: value, time: timeString };
                        break;
                    }
                    this.setState({ days: this.state.days });
                  }}
                ></TimePicker>
              </div>
            ))}
        </div>
      </Modal>
    );
  }
}

export default ChangeClassroomDayPopup;
