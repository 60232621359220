import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import * as courseActions from "store/modules/course";
import * as guideActions from "store/modules/guide";
import {
  PageHeader,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Select,
  Card,
  Button,
  DatePicker,
  Checkbox,
  TimePicker,
  Divider,
  message,
  Skeleton,
} from "antd";
import moment from "moment";
import { startsWith, filter, includes } from "lodash";
import { parseClassName, getWeekdayTable } from "../../../../utils/utils";
import Axios from "../../../../utils/request";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";

const { Title } = Typography;
const locale = process.env.REACT_APP_LOCALE || "ko";
class ClassroomAddPage extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      courseList: [],
      selectedCourse: null,
      selectedStartDate: null,
      selectedEndDate: null,
      classIndex: 0,
      formData: {
        courseId: null,
        guideId: null,
        startDate: "",
        endDate: "",
        account: "",
        days: [],
        name: "",
        password: "",
        schedule: {},
        studentLimit: 0,
        locale: locale,
      },
    };
  }

  componentDidMount() {
    const { user, ClassroomActions, CourseActions, GuideActions } = this.props;

    if (user) {
      ClassroomActions.getClassroomList(user.orgId);
      CourseActions.getCourseList();
      GuideActions.getGuideList(user.orgId);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.props.form);
    console.log(this.props.form.getFieldValue("courseId"));
    console.log(this.props.form.getFieldValue("startDate"));
    const { selectedCourse, selectedStartDate, classIndex } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const days = [];
        if (values.days) {
          Object.keys(values.days).map((d) => {
            days.push({
              day: d,
              time: moment(values.days[d]).format("HH:mm"),
            });
          });
        }
        const data = {
          courseId: values.courseId,
          guideId: values.guideId,
          days: this.props.orgType === "campus" ? days : null,
          startDate: moment(values.startDate).format("YYYY-MM-DD"),
          locale: values.locale || "ko",
        };
        if (this.context.type === "school") {
          data["name"] = values.name;
          data["endDate"] = moment(values.endDate).format("YYYY-MM-DD");
        }
        console.log("post data: ", data);
        // return;

        this.setState(
          {
            isLoading: true,
          },
          () => {
            this.createClass(data);
          }
        );
      }
    });
  };

  createClass = (data) => {
    const { user, form, ClassroomActions } = this.props;

    Axios.post(`/academy/v2/accounts/${user.orgId}/classrooms`, data)
      .then((res) => {
        let msg = `${res.name} 클래스가 생성완료 되었습니다.\n새 클래스 상세정보 페이지로 이동합니다.`;
        if (locale === "en") {
          msg = `${res.name} class has been created.\nGo to the new class detail page.`;
        }
        message.success(msg);
        console.log("res", res);
        this.setState({
          isLoading: false,
        });
        this.props.history.push(`/admin/class/detail/${res.classroomId}`);
      })
      .catch((e) => {
        console.log(e);
        let msg = "클래스 생성 실패";
        if (locale === "en") {
          msg = "Failed to create class";
        }

        message.error(msg);
        this.setState({
          isLoading: false,
        });
      });
  };

  handleChangeCourse = (value) => {
    const course = filter(this.props.courseList, { courseId: value });
    const selectedCourse = parseClassName(course[0].title);
    this.setState({
      selectedCourse: selectedCourse,
      classIndex: this.getClassIndex(
        selectedCourse,
        this.state.selectedStartDate
      ),
    });
  };

  handleChangeGuide = (value) => {};
  handleChangeStartDate = (value) => {
    const selectedStartDate = value ? moment(value).format("YYYYMM") : null;

    this.setState({
      selectedStartDate: selectedStartDate,
      classIndex: this.getClassIndex(
        this.state.selectedCourse,
        selectedStartDate
      ),
    });
  };
  handleChangeEndDate = (value) => {
    const selectedEndDate = value ? moment(value).format("YYYYMM") : null;

    this.setState({
      selectedEndDate: selectedEndDate,
      classIndex: this.getClassIndex(
        this.state.selectedCourse,
        selectedEndDate
      ),
    });
  };

  handleWeekdayChange = (value) => {
    console.log(value);
  };

  handleChangeTime = (value) => {
    console.log(value);
  };
  getClassIndex(selectedCourse, selectedStartDate) {
    if (selectedCourse && selectedStartDate) {
      return filter(this.props.classroomList, (c) =>
        startsWith(c.name, selectedCourse + "-" + selectedStartDate)
      ).length;
    }

    return 0;
  }
  render() {
    const { pending, orgType, classroomList, courseList, guideList } =
      this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { selectedCourse, selectedStartDate, classIndex } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const weekdays = getWeekdayTable();

    return (
      <>
        <PageHeader
          title={
            <Title level={2}>
              {/* {`${this.context.className} 생성하기`} */}
              <FormattedMessage id="admin.classroom.list-create-class" />
            </Title>
          }
        ></PageHeader>
        <div className="admin-page-content">
          <Skeleton loading={pending} active>
            <Form
              {...formItemLayout}
              style={{ padding: "24px 0", maxWidth: 600 }}
              onSubmit={this.handleSubmit}
            >
              <Form.Item
                label={
                  <FormattedMessage id="admin.classroom-create.label-curriculum" />
                }
              >
                {getFieldDecorator("courseId", {
                  rules: [
                    {
                      required: true,
                      message:
                        locale === "en"
                          ? "Please select a curriculum"
                          : `${this.context.currName}을 선택해주세요`,
                    },
                  ],
                })(
                  <Select
                    placeholder={
                      locale === "en"
                        ? "Please select a curriculum"
                        : `${this.context.currName}을 선택해주세요`
                    }
                    onChange={this.handleChangeCourse}
                  >
                    {courseList
                      .filter((e) => {
                        return !(
                          e.title.indexOf("삭제") !== -1 ||
                          (e.title.match(/_/g) || []).length < 2
                        );
                      })
                      .sort((a, b) => {
                        return a.title < b.title
                          ? -1
                          : a.title > b.title
                          ? 1
                          : 0;
                      })
                      .map((course) => (
                        <Select.Option
                          key={course.courseId}
                          value={course.courseId}
                        >
                          {course.title}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              {orgType === "campus" && (
                <Form.Item
                  label={
                    <FormattedMessage id="admin.classroom-create.label-language" />
                  }
                >
                  {getFieldDecorator("locale", {
                    rules: [
                      {
                        required: true,
                        message:
                          locale === "en"
                            ? "Please select a language"
                            : `언어를 선택해주세요`,
                      },
                    ],
                  })(
                    <Select
                      placeholder={
                        locale === "en"
                          ? "Please select a language"
                          : `언어를 선택해주세요`
                      }
                      // onChange={this.handleChangeCourse}
                    >
                      <Select.Option value="ko">
                        <FormattedMessage id="admin.classroom-create.language-ko" />
                      </Select.Option>
                      <Select.Option value="en">
                        <FormattedMessage id="admin.classroom-create.language-en" />
                      </Select.Option>
                    </Select>
                  )}
                </Form.Item>
              )}
              <Form.Item
                label={
                  <FormattedMessage id="admin.classroom-create.label-teacher" />
                }
              >
                {getFieldDecorator(
                  "guideId",
                  {}
                )(
                  <Select
                    // placeholder={`${this.context.guideName}를 선택해주세요`}
                    placeholder={
                      locale === "en"
                        ? "Please select a teacher"
                        : `선생님를 선택해주세요`
                    }
                    onChange={this.handleChangeGuide}
                  >
                    {guideList.map((guide) => (
                      <Select.Option key={guide.guideId} value={guide.guideId}>
                        {guide.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label={
                  <FormattedMessage id="admin.classroom-create.label-startDate" />
                }
              >
                {getFieldDecorator("startDate", {
                  rules: [
                    {
                      required: true,
                      // message: "수업시작일을 선택해주세요",
                      message:
                        locale === "en"
                          ? "Please select a date to start your class"
                          : `수업시작일을 선택해주세요`,
                    },
                  ],
                })(
                  <DatePicker
                    placeholder={
                      locale === "en"
                        ? "Please select a date to start your class"
                        : `수업시작일을 선택해주세요`
                    }
                    format="YYYY-MM-DD"
                    onChange={this.handleChangeStartDate}
                  />
                )}
              </Form.Item>
              {orgType === "school" && (
                <Form.Item label="수업종료일">
                  {getFieldDecorator("endDate", {
                    rules: [
                      {
                        required: true,
                        message: "수업종료일을 선택해주세요",
                      },
                    ],
                  })(
                    <DatePicker
                      placeholder="수업을 종료할 날짜를 선택해주세요"
                      format="YYYY-MM-DD"
                      onChange={this.handleChangeEndDate}
                    />
                  )}
                </Form.Item>
              )}
              {this.context.type === "school" && (
                <Form.Item label={`${this.context.className} 이름`}>
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "이름을 입력해주세요.",
                      },
                    ],
                  })(<Input placeholder="이름 입력해주세요." />)}
                </Form.Item>
              )}
              {orgType === "campus" && selectedStartDate && (
                <Form.Item
                  label={
                    <FormattedMessage id="admin.classroom-create.label-lessonDay" />
                  }
                >
                  {getFieldDecorator("checkedWeekdays", {
                    rules: [
                      {
                        required: true,
                        // message: "수업일을 설정해주세요",
                        message:
                          locale === "en"
                            ? "Please select a lesson day"
                            : `수업일을 설정해주세요`,
                      },
                    ],
                  })(
                    <Checkbox.Group onChange={this.handleWeekdayChange}>
                      <Row type="flex" gutter={8}>
                        {weekdays.map((d, i) => (
                          <Col key={i}>
                            <Checkbox
                              value={d.value}
                              // disabled={
                              //   getFieldValue("checkedWeekdays") &&
                              //   getFieldValue("checkedWeekdays").length === 2 &&
                              //   !includes(
                              //     getFieldValue("checkedWeekdays"),
                              //     d.value
                              //   )
                              // }
                            >
                              {locale === "en" ? d.label_en : d.label}
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  )}
                </Form.Item>
              )}
              {getFieldValue("checkedWeekdays") &&
                getFieldValue("checkedWeekdays").map((k, index) => (
                  <Form.Item
                    key={index}
                    label={
                      index === 0 ? (
                        <FormattedMessage id="admin.classroom-create.lesson-schedule" />
                      ) : (
                        ""
                      )
                    }
                    wrapperCol={{ offset: 8 }}
                  >
                    <span style={{ display: "block" }}>
                      {locale === "en" ? (
                        <>{filter(weekdays, { value: k })[0].label_en} Lesson</>
                      ) : (
                        <>{filter(weekdays, { value: k })[0].label}수업</>
                      )}
                    </span>
                    {getFieldDecorator(`days[${k}]`, {
                      initialValue: moment("00:00", "HH:mm"),
                      rules: [
                        {
                          required: true,
                          // message: "수업시간표를 선택해주세요",
                          message:
                            locale === "en"
                              ? "Please select a lesson schedule"
                              : `수업시간표를 선택해주세요`,
                        },
                      ],
                    })(
                      <TimePicker
                        format="HH:mm"
                        onChange={this.handleChangeTime}
                        minuteStep={5}
                      />
                    )}
                  </Form.Item>
                ))}

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.isLoading}
                >
                  <FormattedMessage id="admin.classroom-create.next" />
                </Button>
                <Button
                  onClick={() => {
                    this.props.history.push("/admin/class");
                  }}
                  style={{ marginLeft: 16 }}
                >
                  <FormattedMessage id="admin.classroom-create.cancel" />
                </Button>
              </Form.Item>
            </Form>
          </Skeleton>
        </div>
      </>
    );
  }
}

export default connect(
  ({ auth, classroom, course, guide }) => ({
    pending:
      auth.pending || classroom.pending || course.pending || guide.pending,
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    classroomList: classroom.classroomList,
    courseList: course.courseList,
    guideList: guide.guideList,
    orgType: auth.user.orgType,
  }),
  (dispatch) => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
    CourseActions: bindActionCreators(courseActions, dispatch),
    GuideActions: bindActionCreators(guideActions, dispatch),
  })
)(Form.create()(ClassroomAddPage));
