import React, { Component } from "react";
import classNames from "classnames";
import { formatText } from "../utils/Formatter";

class TextSelector extends Component {
  state = {
    showOptions: false,
  };

  handleOptions = (type, e) => {
    const { index, list } = this.props;
    // console.log(type, e.target);

    if (type === "close") {
      this.setState({
        showOptions: false,
      });
    } else {
      this.setState({
        showOptions: true,
      });
    }
  };

  handleItemClick = (item) => {
    const { index, onSelectionChanged } = this.props;

    onSelectionChanged(index, item);

    this.setState({
      showOptions: false,
    });
  };

  render() {
    const {
      index,
      list,
      quizType,
      longest,
      answers,
      selected,
      onSelectionChanged,
      onMobileSelectionChanged,
      solvedData,
      showReallyTrue,
    } = this.props;
    const selectedVal =
      (list[selected] && list[selected].replace("fix:", "")) || "";
    let realAnswer = null;
    if (showReallyTrue && solvedData.solved) {
      const realAnswerIndex = solvedData.correctAnswers[index];
      realAnswer = list[realAnswerIndex].replace("fix", "");
    }
    const isFix = list && list.length > 0 && list[0].includes("fix:");

    let onlyViewStyle = {};
    if (quizType === "onlyView" && !showReallyTrue && longest) {
      let width = longest.replace("$$", "").trim().length * 12;
      onlyViewStyle = { width: width };
    }
    console.log("onlyviewstyle: ", onlyViewStyle);
    console.log("quizType: ", quizType);
    console.log("longest: ", longest);
    // console.log('text selector rendering: ', selected);
    return (
      <div
        className={classNames("t-answer-ts-input", {
          "none-event": solvedData && solvedData.solved,
        })}
        onMouseLeave={(e) => this.handleOptions("close", e)}
        onMouseOver={(e) => this.handleOptions("show", e)}
      >
        <div
          // onClick={() => this.handleOptions('show')}
          className={classNames("t-answer-ts-input-wrap", {
            "short-input": isFix,
            "is-chk": !showReallyTrue && selected !== null && selected > -1,
            "is-true":
              !showReallyTrue &&
              selected > -1 &&
              solvedData.solved &&
              solvedData.corrects[index],
            "is-false":
              !showReallyTrue &&
              selected > -1 &&
              solvedData.solved &&
              !solvedData.corrects[index],
            "is-reallytrue": showReallyTrue && solvedData.solved,
          })}
        >
          {this.props.quizType === "printView" ? (
            <ul
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid #ccc",
                borderRadius: 8,
                padding: "0 8px",
              }}
            >
              {list.map((item, i) => {
                item = item.replace("fix:", "");
                return (
                  <li
                    key={`only-view-${i}`}
                    style={{
                      color: "#333a53"
                    }}
                  >
                    {formatText(item, i)}
                    { (i < list.length - 1) && <span style={{padding: "0 7px", color: '#cccccc'}}>|</span>}
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="selected-text" style={onlyViewStyle}>
              {showReallyTrue && solvedData.solved
                ? formatText(realAnswer, "real-answer" + selected)
                : formatText(selectedVal, selected)}
            </div>
          )}
        </div>
        <div
          className={classNames(`t-answer-ts-select`, {
            block: this.state.showOptions,
          })}
        >
          <div className="t-answer-ts-select-title none">
            <div className="arrow">
              <span
                onClick={() =>
                  onSelectionChanged(index, selected, "up", list.length)
                }
              ></span>
              <span
                onClick={() =>
                  onSelectionChanged(index, selected, "down", list.length)
                }
              ></span>
            </div>
            <span
              className="submit"
              onClick={(e) => this.handleOptions("close", e)}
            >
              완료
            </span>
          </div>
          <ul className="list">
            {list.map((item, i) => {
              item = item.replace("fix:", "");
              return (
                <li
                  key={i}
                  className={classNames({
                    "is-selected": i === selected,
                  })}
                  // onClick={() => onSelectionChanged(index, i)}>
                  onClick={() => this.handleItemClick(i)}
                >
                  {formatText(item, i)}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default TextSelector;
