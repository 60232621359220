import ClassroomListPage from "../pages/admin/classroom/list";
import Home from "../pages/home";
import ClassroomDetailPage from "../pages/admin/classroom/detail";
import ClassroomDashboard from "../pages/admin/classroom/dashboard";
import StudentListPage from "../pages/admin/student/list";
import StudentDetailPage from "../pages/admin/student/detail";
import StudentReportPage from "../pages/admin/student/report";
import GuideListPage from "../pages/admin/guide/list";
import GuideDetailPage from "../pages/admin/guide/detail";
import NoticeListPage from "../pages/admin/notice/list";
import StudentAddPage from "../pages/admin/student/add";
import StudentEditPage from "../pages/admin/student/edit";

import ClassroomStudentList from "../pages/admin/classroom/studentList";
import LecturePreviewPage from "../pages/admin/classroom/preview";
import LectureReportPage from "../pages/admin/classroom/report";
import LectureWorkBookPage from "../pages/admin/classroom/workbook/LectureWorkBookPage";
import LectureSolvingBookPage from "../pages/admin/classroom/workbook/LectureSolvingBookPage";
import ClassroomAddPage from "../pages/admin/classroom/add";
import ExamManagePage from "../pages/admin/classroom/exam";
import mockupRoutes from "./mockupRoutes";
import TestQuizPage from "../pages/test/quiz";
import TestQueturePage from "../pages/test/queture";
import GuideAddPage from "../pages/admin/guide/add";
import GuideEditPage from "../pages/admin/guide/edit";
import ClassroomLecturesPage from "../pages/admin/classroom/lectures";
import NoticeAddPage from "../pages/admin/notice/add";
import NoticeEditPage from "../pages/admin/notice/edit";
import NoticeDetailPage from "../pages/admin/notice/detail";
import PostListPage from "../pages/admin/board/list";
import PostAddPage from "../pages/admin/board/add";
import PostEditPage from "../pages/admin/board/edit";
import PostDetailPage from "../pages/admin/board/detail";
import NewsletterListPage from "../pages/admin/Newsletter/list";
import NewsletterAddPage from "../pages/admin/Newsletter/add";
import NewsletterDetailPage from "../pages/admin/Newsletter/detail";
import NewsletterEditPage from "../pages/admin/Newsletter/edit";

export default [
  {
    path: "/",
    exact: true,
    permissions: ["org"],
    redirect: "/admin/class",
    component: ClassroomListPage,
  },
  {
    path: "/admin/class",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: ClassroomListPage,
  },
  {
    path: "/admin/class/add",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: ClassroomAddPage,
  },
  {
    path: "/admin/exams",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: ExamManagePage,
  },
  {
    path: "/admin/class/list",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: ClassroomListPage,
  },
  {
    path: "/admin/class/detail/:id",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: ClassroomDetailPage,
  },
  {
    path: "/admin/class/detail/:id/lectures",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: ClassroomLecturesPage,
  },
  {
    path: "/admin/class/detail/:id/student/:studentId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: ClassroomStudentList,
  },
  {
    path: "/admin/class/detail/:id/lecture-preview/:lectureId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: LecturePreviewPage,
  },
  {
    path: "/admin/class/detail/:id/lecture-report/:lectureId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: LectureReportPage,
  },
  {
    path: "/admin/class/detail/:id/lecture-workbook/:lectureId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: LectureWorkBookPage,
  },
  {
    path: "/admin/class/detail/:id/lecture-solvingbook/:lectureId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: LectureSolvingBookPage,
  },
  {
    path: "/admin/student/list",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: StudentListPage,
  },
  {
    path: "/admin/student/add",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: StudentAddPage,
  },
  {
    path: "/admin/student/detail/:studentId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: StudentDetailPage,
  },
  {
    path: "/admin/student/detail/:studentId/report/:courseNo",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: StudentReportPage,
  },
  {
    path: "/admin/student/edit/:studentId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: StudentEditPage,
  },
  {
    path: "/admin/guide/list",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: GuideListPage,
  },
  {
    path: "/admin/guide/add",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: GuideAddPage,
  },
  {
    path: "/admin/guide/detail/:guideId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: GuideDetailPage,
  },
  {
    path: "/admin/guide/edit/:guideId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: GuideEditPage,
  },
  {
    path: "/admin/newsletter",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: NewsletterListPage,
  },
  {
    path: "/admin/newsletter/add",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: NewsletterAddPage,
  },
  {
    path: "/admin/newsletter/edit/:newsletterId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: NewsletterEditPage,
  },
  {
    path: "/admin/newsletter/detail/:newsletterId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: NewsletterDetailPage,
  },//
  {
    path: "/admin/notice",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: NoticeListPage,
  },
  {
    path: "/admin/notice/add",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: NoticeAddPage,
  },
  {
    path: "/admin/notice/edit/:noticeId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: NoticeEditPage,
  },
  {
    path: "/admin/notice/detail/:noticeId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: NoticeDetailPage,
  },
  {
    path: "/admin/board",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: PostListPage,
  },
  {
    path: "/admin/board/add",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: PostAddPage,
  },
  {
    path: "/admin/board/edit/:postId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: PostEditPage,
  },
  {
    path: "/admin/board/detail/:postId",
    exact: true,
    permissions: ["org"],
    redirect: "/login",
    component: PostDetailPage,
  },
];
