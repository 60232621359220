import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./_button.scss";
import LecturePathPopup from "../../../pages/classMode/components/LecturePathPopup";
import { indexOf, keys, pickBy } from "lodash";
import {
  getOrdinals,
  getSectionName,
  numberToText,
} from "../../../utils/utils";
import classNames from "classnames";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";

const BottomToolbar = (props) => {
  const {
    seq,
    sectionIndex,
    settings,
    sections,
    onNext,
    onPrev,
    onToggleSettings,
    onLeave,
  } = props;
  const [showLecturePathPopup, setLecturePathPopup] = useState(false);
  const { showNextSection, showTeachingGuide } = settings;

  const quetureSectionIndexes = keys(
    pickBy(sections, function (o) {
      return o.type === "QUETURE";
    })
  );

  function getQuetureIndex(index) {
    const quetureSectionIndex = indexOf(quetureSectionIndexes, "" + index);

    return quetureSectionIndex + 1;
    // return numberToText(1 + quetureSectionIndex);
  }

  let locale = props.intl.locale || "ko";
  console.log("bottom bar props", props);

  return (
    <div className="bottom-toolbar">
      <div className="attend-chk">
        <div className="attend-chk__list">
          {/* lecture-rout 를 마진탑 0부터  -410까지 하는 방법.*/}
          <ul className="lecture-route">
            {sections.map((sec, index) => (
              <div key={index}>
                {index === sectionIndex && (
                  <li
                    className={classNames({
                      "is-gone-view": index < sectionIndex,
                      "is-view": index === sectionIndex,
                    })}
                  >
                    <p>
                      {sec.type === "QUETURE" ? (
                        <FormattedMessage
                          id="classroom.section-name-queture"
                          values={{
                            seq: getQuetureIndex(index),
                            ordinal: getOrdinals(
                              locale,
                              getQuetureIndex(index),
                              "등"
                            ),
                          }}
                        />
                      ) : (
                        <FormattedMessage
                        id={getSectionName(sec.type)}
                        />
                      )}
                    </p>
                    {/* <p>
                      {sec.type === "QUETURE"
                        ? getSectionName(sec.type, getQuetureIndex(index))
                        : getSectionName(sec.type)}
                    </p> */}
                  </li>
                )}
              </div>
            ))}
          </ul>
        </div>
        <div
          className={classNames("attend-chk__btn", {
            "is-on": showLecturePathPopup,
          })}
          onClick={() => {
            setLecturePathPopup(!showLecturePathPopup);
          }}
        ></div>
        {showLecturePathPopup && (
          <LecturePathPopup
            isOpen={showLecturePathPopup}
            onSelect={() => {
              setLecturePathPopup(false);
            }}
            locale={locale}
            {...props}
          />
        )}
      </div>
      <div className="numbered-list">
        <span className="left" onClick={onPrev}></span>
        <span className="num">
          {sectionIndex + 1} / {sections && sections.length}
        </span>
        <span className="right" onClick={onNext}></span>
      </div>
      <ul className="type">
        <li onClick={() => onToggleSettings("showNextSection")}>
          <FormattedMessage id="classroom.preview-next-page" />
        </li>
        {/* <li>페이지 모두보기</li> */}

        <li onClick={onLeave}>
          {sectionIndex === sections.length - 1 ? (
            <FormattedMessage id="classroom.end-class" />
          ) : (
            <FormattedMessage id="classroom.exit-class" />
          )}
          {/* <Link to="/admin/class">수업모드 나가기</Link> */}
        </li>
      </ul>
    </div>
  );
};

export default injectIntl(BottomToolbar);
