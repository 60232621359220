import Axios from "./request";
import { message } from "antd";
import { getDefaultConfig, getHost, SESSION_KEY } from "./APIUtils";
import axios from "axios";
import dayjs from "dayjs";

const apiPrefix = "/academy/v2/class/";

const lectures = (classroomId, page = 0, count = 10, type) => {
  let query = `?page=${page}&count=${count}&type=${type}`;

  return Axios.get(`${apiPrefix}${classroomId}/lectures${query}`);
};
const getLecture = (classroomId, lectureId) => {
  return Axios.get(`${apiPrefix}${classroomId}/lectures/${lectureId}`);
};
const updateLectureTime = (classroomId, lectureId, dateTime) => {
  return Axios.post(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/update-time?dateTime=${dateTime}`
  );
  // return Axios.post(`${apiPrefix}${classroomId}/lectures/${lectureId}/update-time?timestamp=${timestamp}`);
};
const start = (classroomId, lectureId) => {
  const dateTime = dayjs().format("YYYY-MM-DDTHH:mm:ss");
  return Axios.post(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/start?dateTime=${dateTime}`
  )
    .then((res) => {
      console.log("start lecture: ", res);
      return res;
    })
    .catch((e) => {
      message.warning("수업 시작 실패");
    });
};
const current = (classroomId) => {
  return Axios.get(`${apiPrefix}${classroomId}/lectures/current`);
};
const pause = (classroomId, lectureId) => {
  return Axios.post(`${apiPrefix}${classroomId}/lectures/${lectureId}/pause`)
    .then((res) => {
      console.log("pause lecture: ", res);
      return res;
    })
    .catch((e) => {
      message.warning("수업 시작 실패");
    });
};
const resume = (classroomId, lectureId) => {
  return Axios.post(`${apiPrefix}${classroomId}/lectures/${lectureId}/resume`)
    .then((res) => {
      console.log("resume lecture: ", res);
      return res;
    })
    .catch((e) => {
      message.warning("수업 재시작 실패");
    });
};
const move = (classroomId, lectureId, section, page = 0) => {
  Axios.post(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/move?section=${section}&page=${page}`
  )
    .then((res) => {
      console.log("move lecture: ", res);
      // Reset Timeout
      const stored = localStorage.getItem(SESSION_KEY);
      if (stored == null) {
        console.log("token is empty");
      }

      const host = getHost();
      const { token, refreshToken, orgId: storedOrgId } = JSON.parse(stored);

      // axios.post(
      //   `${getHost()}/academy/v2/auth/refresh`,
      //   {
      //     token: token,
      //     refreshToken: refreshToken
      //   },
      //   {
      //     ...getDefaultConfig()
      //   })

      return res;
    })
    .catch((e) => {
      message.warning("섹션 이동 실패");
    });
};
const complete = (classroomId, lectureId) => {
  return Axios.post(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/complete`
  );
};
const toggleOnline = (classroomId, lectureId, checked) => {
  return Axios.put(
    `/academy/v2/class/${classroomId}/lectures/${lectureId}/online/${checked}`,
    null
  );
};
const attendance = (classroomId, lectureId, studentId, status) => {
  const dateTime = dayjs().format("YYYY-MM-DDTHH:mm:ss");
  return Axios.post(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/attendance?studentId=${studentId}&status=${status}&dateTime=${dateTime}`
  )
    .then((res) => {
      console.log("check attendance: ", res);
      return res;
    })
    .catch((e) => {
      message.warning("출석 체크 실패");
    });
};
const solve = (classroomId, lectureId, data) => {
  return Axios.post(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/quizzes/${data.quizId}/solve`,
    data
  );
};

const groups = (classroomId, lectureId, data) => {
  return Axios.put(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/groups`,
    data
  )
    .then((res) => {
      console.log("groups: ", res);
      return res;
    })
    .catch((e) => {
      message.warning("팀설정 실패");
    });
};
const mvps = (classroomId, lectureId, data) => {
  return Axios.put(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/mvps`,
    data
  )
    .then((res) => {
      console.log("mvps: ", res);
      return res;
    })
    .catch((e) => {
      message.warning("mvp 실패");
    });
};

const delay = (classroomId, lectureId) => {
  return Axios.post(`${apiPrefix}${classroomId}/lectures/${lectureId}/delay`);
};
const gameScore = (classroomId, lectureId, gameName, data) => {
  return Axios.put(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/games/${gameName}/score`,
    data
  );
};

const setHomework = (classroomId, lectureId, homeworkId, data) => {
  if (homeworkId) {
    if (data.value) {
      return Axios.put(
        `${apiPrefix}${classroomId}/lectures/${lectureId}/homeworks/${homeworkId}`,
        data
      );
    } else {
      return Axios.delete(
        `${apiPrefix}${classroomId}/lectures/${lectureId}/homeworks/${homeworkId}`
      );
    }
  } else {
    return Axios.put(
      `${apiPrefix}${classroomId}/lectures/${lectureId}/homeworks`,
      {
        homeworks: [data],
      }
    );
  }
};

const setLectureScore = (classroomId, lectureId, studentId, data) => {
  return Axios.post(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/students/${studentId}/score`,
    data
  );
};

const _setHomeworkScore = (
  classroomId,
  lectureId,
  studentId,
  homeworkId,
  score
) => {
  return Axios.post(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/students/${studentId}/homeworks/${homeworkId}/score?score=${score}`,
    null
  );
};

const setReport = (classroomId, lectureId, data) => {
  return Axios.put(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/report`,
    data
  );
};

const submitSummaries = (classroomId, lectureId, quetureId, data) => {
  return Axios.post(
    `${apiPrefix}${classroomId}/lectures/${lectureId}/summaries/${quetureId}`,
    data
  );
};

const ClassroomApis = {
  lectures,
  getLecture,
  updateLectureTime,
  start,
  current,
  pause,
  resume,
  move,
  complete,
  attendance,
  solve,
  groups,
  mvps,
  delay,
  gameScore,
  setHomework,
  setLectureScore,
  _setHomeworkScore,
  setReport,
  toggleOnline,
  submitSummaries,
};

export default ClassroomApis;
