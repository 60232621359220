import React, { useState, useEffect } from "react";
import classNames from "classnames";
import HomeworkProgress from "./HomeworkProgress";
import LectureTooltip from "./LectureTooltip";
import LectureContent from "./LectureContent";
import { diffDays } from "../../../utils/utils";
import { map } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { _ } from "core-js";

const WeeklyCalendar = (props) => {
  const {
    weeklyData,
    classroom,
    homework,
    onGetLectureDays,
    onToday,
    onPrevWeek,
    onNextWeek,
    lectureStatus,
    user,
  } = props;
  const [lectures, setLectures] = useState(null);
  const [homeworkData, setHomeworkData] = useState(null);
  const [calendarTitle, setCalendarTitle] = useState("");
  const today = new Date();
  const locale = props.intl.locale;

  const weekNameIds = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  useEffect(() => {
    const _lectures = {};

    if (weeklyData) {
      const firstDay = getKeyDate(weeklyData[0]);
      const lastDay = getKeyDate(weeklyData[6]);

      const firstDate = new Date(firstDay);
      const lastDate = new Date(lastDay);

      const _calendarTitles = [];

      Object.keys(classroom.lectures).map((key, index) => {
        const newKey = key.split(" ")[0];
        // const myDate = new Date("2019-01-01");
        // console.log(newKey + " - " + myDate.getWeek());
        const _curLectureDate = new Date(newKey);
        if (_curLectureDate >= firstDate && _curLectureDate <= lastDate) {
          _lectures[newKey] = classroom.lectures[key][0];
          let _title =
            locale === "en"
              ? `${classroom.lectures[key][0].seq}`
              : classroom.lectures[key][0].title;
          _calendarTitles.push(_title);
        }
      });

      let _calendarTitle = _calendarTitles.join(", ");
      if (locale === "en") {
        _calendarTitle = "Lesson " + _calendarTitle;
      }
      setCalendarTitle(_calendarTitle);

      if (homework && homework.orgDate && homework.endDate) {
        const _homeworkData = { ...homework };
        _homeworkData["dDay"] = homework.endDate;

        const homeworkStartDate = new Date(homework.orgDate.split(" ")[0]);
        const homeworkEndDate = new Date(homework.endDate);
        let _colSpan = 0;
        if (firstDate > homeworkStartDate) {
          _colSpan = diffDays(homeworkEndDate, firstDate) + 1;
          _homeworkData["startCol"] = firstDay;
        } else if (homeworkEndDate < lastDate) {
          _colSpan = diffDays(homeworkEndDate, homeworkStartDate) + 1;
          _homeworkData["startCol"] = homework.orgDate.split(" ")[0];
        } else {
          _colSpan = diffDays(lastDate, homeworkStartDate) + 1;
          _homeworkData["startCol"] = homework.orgDate.split(" ")[0];
        }
        _homeworkData["colSpan"] = _colSpan;
        console.log("colSpan: ", _colSpan);
        if (homework.quetureList && homework.quetureList.length > 0) {
          let _isDone = true;
          homework.quetureList.map((queture) => {
            if (!queture.isDone) {
              _isDone = false;
            }
          });
          _homeworkData["quetureHomework"] = _isDone;
        }
        if (homework.uploadList && homework.uploadList.length > 0) {
          let _isDone = true;
          homework.uploadList.map((upload) => {
            if (!upload.isDone) {
              _isDone = false;
            }
          });
          _homeworkData["uploadHomework"] = _isDone;
        }
        setHomeworkData(_homeworkData);
      }
    }

    setLectures(_lectures);

    return () => {};
  }, [classroom, homework, weeklyData]);

  function getKeyDate(r) {
    return (
      "" +
      r.year +
      "-" +
      (r.month < 10 ? "0" + r.month : r.month) +
      "-" +
      (r.date < 10 ? "0" + r.date : r.date)
    );
  }

  console.log("homeworkData: ", homeworkData);
  console.log("homeworkData: ", homework);
  console.log("weekly calendar lectures: ", lectures);
  console.log("class time: ", classroom.days);
  return (
    <div className="class-info__calendar">
      <div className="title">
        <strong>{calendarTitle}</strong>
        {calendarTitle && classroom && (
          <span>
            {/* {`수업시간 : 매주 ${onGetLectureDays(classroom.days)}`} */}
            <FormattedMessage
              id="hometraining.calendar.class-time"
              values={{ days: onGetLectureDays(classroom.days) }}
            />
          </span>
        )}
        <ul className="calendar-control">
          <li className="prev" onClick={onPrevWeek}></li>
          <li className="today" onClick={onToday}>
            <FormattedMessage id="hometraining.calendar.today" />
          </li>
          <li className="next" onClick={onNextWeek}></li>
        </ul>
      </div>

      <div className="calendar">
        <ul className="table-line">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <table className="table-calendar">
          <tbody>
            <tr>
              {weeklyData &&
                weeklyData.map((r, i) => {
                  const isTodayClass =
                    r.date === today.getDate() &&
                    r.month === today.getMonth() + 1 &&
                    r.year === today.getFullYear()
                      ? "is-today"
                      : "";

                  return (
                    <th key={"th-" + i} className={isTodayClass}>
                      <strong>{r.date}</strong>
                      <span>
                        {/* {weekNames[i]} */}
                        <FormattedMessage
                          id={"hometraining.calendar.weekday." + weekNameIds[i]}
                        />
                      </span>
                    </th>
                  );
                })}
            </tr>
            <tr>
              {weeklyData &&
                weeklyData.map((r, i) => {
                  const key =
                    "" +
                    r.year +
                    "-" +
                    (r.month < 10 ? "0" + r.month : r.month) +
                    "-" +
                    (r.date < 10 ? "0" + r.date : r.date);
                  // is-today, is-weekly
                  // r["lecture"] = lectures[key] || null;

                  let lecture = lectures[key] || null;
                  return (
                    <td
                      key={i}
                      className={classNames({
                        "is-orange": lecture && lecture.state === "completed",
                        "is-green": lecture && lecture.state === "pending",
                      })}
                    >
                      {lecture && (
                        <LectureContent
                          user={user}
                          lectureStatus={lectureStatus}
                          lecture={lecture}
                          locale={locale}
                        />
                      )}
                    </td>
                  );
                })}
            </tr>
            <tr>
              {homeworkData &&
                weeklyData &&
                weeklyData.map((r, i) => {
                  const key =
                    "" +
                    r.year +
                    "-" +
                    (r.month < 10 ? "0" + r.month : r.month) +
                    "-" +
                    (r.date < 10 ? "0" + r.date : r.date);

                  let _colSpan = 0;
                  if (key === homeworkData.startCol) {
                    _colSpan = homeworkData.colSpan;
                  }

                  return (
                    <td
                      key={"check-homework-" + i}
                      className={classNames({
                        "is-red": key === homeworkData.startCol,
                      })}
                      colSpan={_colSpan}
                    >
                      {homeworkData && _colSpan > 0 && (
                        <HomeworkProgress
                          homework={homeworkData}
                          locale={locale}
                        />
                      )}
                    </td>
                  );
                })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default injectIntl(WeeklyCalendar);
