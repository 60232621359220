import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as classroomActions from "store/modules/classroom";
import * as courseActions from "store/modules/course";
import * as guideActions from "store/modules/guide";
import {
  Layout,
  Menu,
  Breadcrumb,
  PageHeader,
  Typography,
  Skeleton,
  Collapse,
  Icon,
  Tag,
  Divider,
  Statistic,
  Checkbox,
  Radio,
  Button,
  Row,
  Col,
  Input,
} from "antd";
import styles from "./styles.module.scss";
import ClassTable from "../dashboard/component/ClassTable";
import AccountApis from "../../../../utils/accountApis";
import { groupBy, values } from "lodash";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage } from "react-intl";

const { Title } = Typography;

class ClassroomListPage extends Component {
  static contextType = ConstantsContext;
  constructor(props) {
    super(props);

    this.state = {
      filterType: null,
      // filterType:
      todayOnly: false,
      finishedOnly: false,
      showDeleted: false,
      // OR
      searchName: null,
    };
  }

  componentDidMount() {
    const { user, ClassroomActions, CourseActions, GuideActions } = this.props;
    console.log("mount dashboard: ", user);

    if (user && user.orgId) {
      ClassroomActions.getClassroomList(user.userId, user.type);
      // CourseActions.getCourseList();
      // GuideActions.getGuideList(user.orgId);
      this.getOrgInfo(user.orgId);
    }
  }

  componentDidUpdate(nextProps) {
    const { user, ClassroomActions, CourseActions, GuideActions } = this.props;
    if (this.props.user && this.props.user.type !== nextProps.user.type) {
      console.log("receive props: ", nextProps);
      ClassroomActions.getClassroomList(user.orgId);
      // CourseActions.getCourseList();
      // GuideActions.getGuideList(user.orgId);
      this.getOrgInfo(user.orgId);
    }
  }

  getOrgInfo = (orgId) => {
    AccountApis.getOrgInfo(orgId).then((res) => {
      console.log("org info: ", res);
    });
  };

  render() {
    const { classroomList, courseList, guideList, user, userPermission } =
      this.props;
    const { filterType, todayOnly, finishedOnly, showDeleted, searchName } =
      this.state;

    const { Panel } = Collapse;

    const customPanelStyle = {
      background: "#fff",
      borderRadius: 4,
      marginBottom: 24,
      // border: 0,
      overflow: "hidden",
    };

    console.log("render dashboard: ", this.props.auth.user);
    console.log("classroom list: ", classroomList);
    console.log("course list: ", courseList);
    console.log("guide lsit: ", guideList);

    console.log("today only: ", todayOnly);
    console.log("finished only: ", finishedOnly);
    console.log("show deleted: ", showDeleted);

    return (
      <Skeleton loading={this.props.pending} active>
        <PageHeader
          key="1"
          title={
            <Title level={2} style={{ marginBottom: 0 }}>
              {/* {`${this.context.className} 목록`} */}
              <FormattedMessage id="admin.classroom.list-title" />
            </Title>
          }
          extra={[
            <Radio.Group
              defaultValue=""
              value={filterType}
              onChange={(e) => {
                console.log("### radio:", e.target.value);
                this.setState({
                  filterType: e.target.value,
                  todayOnly: false,
                  finishedOnly: false,
                  showDeleted: false,
                  searchName: null,
                  [e.target.value]: true,
                });
              }}
            >
              <Radio value={"todayOnly"}>
                <FormattedMessage id="admin.classroom.list-filter-today" />
              </Radio>
              <Radio value={"finishedOnly"}>
                {/* 종료된 {this.context.className}만 보기 */}
                <FormattedMessage id="admin.classroom.list-filter-terminated" />
              </Radio>
              <Radio value={"showDeleted"}>
                {/* 삭제된 {this.context.className}만 보기 */}
                <FormattedMessage id="admin.classroom.list-filter-deleted" />
              </Radio>
            </Radio.Group>,
            <FormattedMessage id="admin.classroom.list-search-placeholder">
              {(text) => (
                <Input.Search
                  placeholder={text}
                  value={searchName}
                  onClick={() =>
                    this.setState({
                      filterType: null,
                      todayOnly: false,
                      finishedOnly: false,
                      showDeleted: false,
                    })
                  }
                  onChange={(e) =>
                    this.setState({
                      filterType: null,
                      todayOnly: false,
                      finishedOnly: false,
                      showDeleted: false,
                      searchName: e.target.value,
                    })
                  }
                  onSearch={(value) => {}}
                  style={{ width: "200px", marginLeft: "8" }}
                />
              )}
            </FormattedMessage>,
            <span key="1">
              {(userPermission === "master" ||
                (userPermission && userPermission.classCreateDelete)) && (
                <Button
                  key="1"
                  type="primary"
                  onClick={() => this.props.history.push("/admin/class/add")}
                >
                  {/* {`${this.context.className} 생성하기`} */}
                  <FormattedMessage id="admin.classroom.list-create-class" />
                  <Icon type="plus-circle" />
                </Button>
              )}
            </span>,
          ]}
        />
        <div className="admin-page-content">
          <ClassTable
            classroomList={classroomList}
            todayOnly={todayOnly}
            finishedOnly={finishedOnly}
            showDeleted={showDeleted}
            searchName={searchName}
            orgType={this.props.orgType}
            hasGuidePermission={
              userPermission === "master" ||
              (userPermission && userPermission.guideCreateDelete)
            }
          />
        </div>
      </Skeleton>
    );
  }
}

export default connect(
  ({ auth, classroom, course, guide }) => ({
    pending: auth.pending || classroom.pending,
    auth: auth,
    user: auth.user,
    logged: auth.logged,
    orgType: auth.user.orgType,
    userPermission: auth.user.type === "org" ? "master" : auth.user.permission,
    classroomList: classroom.classroomList,
    // courseList: course.courseList,
    // guideList: guide.guideList
  }),
  (dispatch) => ({
    ClassroomActions: bindActionCreators(classroomActions, dispatch),
    CourseActions: bindActionCreators(courseActions, dispatch),
    GuideActions: bindActionCreators(guideActions, dispatch),
  })
)(ClassroomListPage);
