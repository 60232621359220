import React from "react";
import { Spin } from "antd";
import { FormattedMessage } from "react-intl";

const Classmodebtn = props => {
  const { text, loading } = props;

  return (
    <>
      <button {...props} className="classmode-btn">
        {loading ? <FormattedMessage id="login-form.login-loading" />: text}
      </button>
    </>
  );
};

export default Classmodebtn;
