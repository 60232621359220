import React, { useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb, Typography } from "antd";
import styles from "./styles.module.scss";
import RightContent from "./RightContent";
import StudentHeader from "../StudentHeader";
import { Link, withRouter } from "react-router-dom";
import { ConstantsContext } from "utils/ConstantsContext";
import { FormattedMessage, injectIntl } from "react-intl";
const { Header, Content, Footer } = Layout;

const GlobalHeader = (props) => {
  const { user, userPermission, location } = props;
  const [current, setCurrent] = useState("classroom");
  const context = React.useContext(ConstantsContext);

  useEffect(() => {
    console.log("location: ", location.pathname);
    if (location.pathname.startsWith("/admin/class")) {
      setCurrent("classroom");
    } else if (location.pathname.startsWith("/admin/student")) {
      setCurrent("student");
    } else if (location.pathname.startsWith("/admin/guide")) {
      setCurrent("guide");
    } else if (location.pathname.startsWith("/admin/newsletter")) {
      setCurrent("newsletter");
    } else if (location.pathname.startsWith("/admin/board")) {
      setCurrent("board");
    } else if (location.pathname.startsWith("/admin/exams")) {
      setCurrent("exams");
    } else {
      setCurrent("classroom");
    }
    return () => {};
  }, [location]);

  console.log("userPermission: ", userPermission);
  
  const locale = props.intl.locale;
  console.log("header locale: ", locale);
  return (
    <>
      {user && user.type === "student" ? (
        <StudentHeader />
      ) : (
        <Header>
          <div style={{ display: "flex" }}>
            <span className={styles.logo} onClick={() => {}}>
              <Link to="/admin/class">
                {context.type === "school" ? (
                  <FormattedMessage id={"admin.header.campus-type-school"} />
                ) : (
                  <FormattedMessage id={"admin.header.campus-type-campus"} />
                )}
              </Link>
            </span>
            <span className={styles.subLogo}>{user.orgName}</span>

            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={["/admin/class"]}
              selectedKeys={[current]}
              style={{ lineHeight: "64px", flex: "1" }}
              onClick={(e) => {
                setCurrent({
                  current: e.key,
                });
              }}
            >
              <Menu.Item key="classroom">
                <Link to="/admin/class">
                  {/* {context.className}관리 */}
                  <FormattedMessage
                    id="admin.header.menu-classroom"
                    values={{
                      name: context.classNameIntl[locale],
                    }}
                  />
                </Link>
              </Menu.Item>
              <Menu.Item key="student">
                <Link to="/admin/student/list">
                  <FormattedMessage id="admin.header.menu-student" />
                </Link>
              </Menu.Item>
              {userPermission === "master" && (
                <Menu.Item key="guide">
                  <Link to="/admin/guide/list">
                    <FormattedMessage id="admin.header.menu-guide" />
                  </Link>
                </Menu.Item>
              )}
              {context.type === "campus" && (
                <Menu.Item key="exams">
                  <Link to="/admin/exams">
                    <FormattedMessage id="admin.header.menu-test" />
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item key="newsletter">
                <Link to="/admin/newsletter">
                  <FormattedMessage id="admin.header.menu-newsletter" />
                </Link>
              </Menu.Item>
              {/*<Menu.Item key="enquiry">*/}
              {/*  <Link to="/admin/enquiry">문의게시판</Link>*/}
              {/*</Menu.Item>*/}
              {context.type === "school" && (
                <Menu.Item key="board">
                  <Link to="/admin/board">
                    <FormattedMessage id="admin.header.menu-board" />
                  </Link>
                </Menu.Item>
              )}
            </Menu>
            <RightContent user={user} />
          </div>
        </Header>
      )}
    </>
  );
};

export default withRouter(injectIntl(GlobalHeader));
