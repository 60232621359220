export default {
  "admin.header.campus-type-campus": "깨봉 아카데미",
  "admin.header.campus-type-school": "깨봉 캠퍼스",
  "admin.header.menu-classroom": "{name}관리",
  "admin.header.menu-student": "학생관리",
  "admin.header.menu-guide": "선생님관리",
  "admin.header.menu-test": "테스트",
  "admin.header.menu-newsletter": "뉴스레터",
  "admin.header.menu-board": "게시판",
  "admin.header.menu-logout": "로그아웃",
  "admin.classroom.list-title": "클래스 목록",
  "admin.classroom.list-filter-today": "오늘 수업만 보기",
  "admin.classroom.list-filter-terminated": "종료된 클래스만 보기",
  "admin.classroom.list-filter-deleted": "삭제된 클래스만 보기",
  "admin.classroom.list-search-placeholder": "클래스 이름",
  "admin.classroom.list-create-class": "클래스 생성하기",
  "admin.classroom.list-col-course": "{course}코스",
  "admin.classroom.list-col-course-other": "기타",
  "admin.classroom.list-col-class": "클래스",
  "admin.classroom.list-col-branch": "지점",
  "admin.classroom.list-col-status": "상태",
  "admin.classroom.list-col-progress": "진행현황",
  "admin.classroom.list-col-schedule": "수업스케줄",
  "admin.classroom.list-col-start-date": "시작일",
  "admin.classroom.list-col-end-date": "종료/예정일",
  "admin.classroom.list-col-students": "학생수",
  "admin.classroom.list-col-teacher": "선생님",
  "admin.classroom.list-value-delete": "(삭제)",
  "admin.classroom.list-value-current": "현행",
  "admin.classroom.list-value-terminated": "*종료*",
  "admin.classroom.list-value-force-terminated": "*강제종료*",
  "admin.classroom.list-value-lesson-of-total": "{seq}회차 / 총{total}회차",
  "admin.classroom.list-value-mon": "월요일",
  "admin.classroom.list-value-tue": "화요일",
  "admin.classroom.list-value-wed": "수요일",
  "admin.classroom.list-value-thu": "목요일",
  "admin.classroom.list-value-fri": "금요일",
  "admin.classroom.list-value-sat": "토요일",
  "admin.classroom.list-value-sun": "일요일",
  "admin.classroom.list-value-student-count": "{count}명",
  "admin.classroom.list-value-no-teacher": "(선생님 없음)",
  "admin.classroom-detail.title": "클래스: {classroomName}",
  "admin.classroom-detail.upcoming-course": "다음 예정 코스 : {nextCourse}",
  "admin.classroom-detail.course-tba": "미정",
  "admin.classroom-detail.course-deleted": "(삭제됨)",
  "admin.classroom-detail.course-terminated": "*종료됨*",
  "admin.classroom-detail.course-force-terminated": "*강제종료됨*",
  "admin.classroom-detail.edit": "편집",
  "admin.classroom-detail.confirm": "확인",
  "admin.classroom-detail.cancel": "취소",
  "admin.classroom-detail.management": "관리기능",
  "admin.classroom-detail.add-class": "수업추가",
  "admin.classroom-detail.change-class-schedule": "수업 스케줄 변경",
  "admin.classroom-detail.change-class-name": "클래스 이름 변경",
  "admin.classroom-detail.reset-class": "클래스 초기화",
  "admin.classroom-detail.delete-class": "클래스 삭제",
  "admin.classroom-detail.class-force-quit": "클래스 강제 종료",
  "admin.classroom-detail.summary":
    "진도: {current}/{total}회차{status} 학생수: {studentCount}명 선생님: ",
  "admin.classroom-detail.summary-none": "(없음)",
  "admin.classroom-detail.total-class-list": "전체 클래스 목록",
  "admin.classroom-detail.total-class-count": "총{count}개",
  "admin.classroom-detail.running-classes": "운영중인 클래스",
  "admin.classroom-detail.terminated-classes": "종료된 클래스",
  "admin.classroom-detail.class-management": "클래스 수업관리",
  "admin.classroom-detail.add-student": "수강 학생 추가",
  "admin.classroom-detail.change-teacher": "클래스 선생님 변경",
  "admin.classroom-detail.view-all-class": "수업관리 전체보기",
  "admin.classroom-detail.col-lesson": "회차",
  "admin.classroom-detail.col-list": "수업목록",
  "admin.classroom-detail.col-date": "수업날짜",
  "admin.classroom-detail.col-preparation": "수업준비",
  "admin.classroom-detail.col-progress": "수업진행",
  "admin.classroom-detail.value-lesson": "{lessonSeq}회차",
  "admin.classroom-detail.value-preview": "미리보기",
  "admin.classroom-detail.value-check-assignments": "과제확인",
  "admin.classroom-detail.value-setting-assignments": "과제설정",
  "admin.classroom-detail.value-start": "수업시작",
  "admin.classroom-detail.value-postpone": "수업연기",
  "admin.classroom-detail.value-completed": "수업완료",
  "admin.classroom-detail.delete-lesson": "수업삭제",
  "admin.classroom-detail.reset-lesson": "수업초기화",
  "admin.classroom-detail.complete-lesson": "완료처리",
  "admin.classroom-detail.student-list": "수강 학생 목록({count})",
  "admin.classroom-detail.student-col-name": "이름",
  "admin.classroom-detail.student-col-id": "아이디",
  "admin.classroom-detail.student-col-age": "나이",
  "admin.classroom-detail.student-col-birth": "생년월일",
  "admin.classroom-detail.student-col-school": "학교",
  "admin.classroom-detail.student-col-contact": "학부모 연락처",
  "admin.classroom-detail.student-col-recent-assignment": "최근과제/깨처",
  "admin.classroom-detail.student-col-recent-report":
    "최근 학습리포트 채점/메모/발행/상담/보기",
  "admin.classroom-detail.student-value-age": "{age}세",
  "admin.classroom-detail.student-value-no-assignments": "(과제없음)",
  "admin.classroom-detail.student-value-course-not-completed":
    "(코스 미완료) / ",
  "admin.classroom-detail.student-value-not-check-answer": "미채점",
  "admin.classroom-detail.student-value-not-exam": "결시",
  "admin.classroom-detail.student-value-registered": "등록",
  "admin.classroom-detail.student-value-not-register": "미등록",
  "admin.classroom-detail.student-value-published": "발행",
  "admin.classroom-detail.student-value-not-publish": "미발행",
  "admin.classroom-detail.student-value-completed": "완료",
  "admin.classroom-detail.student-value-incomplete": "미완료",
  "admin.classroom-detail.student-value-lesson": "{lessonSeq}회차",
  "admin.classroom-detail.student-value-lesson-range": "{start}~{end}회차",
  "admin.classroom-detail.reset-lesson-title":
    '"{seq}회차" 수업을 초기화 하시겠습니까? (출석,풀이결과,과제 등 연관 기록이 모두 삭제 됩니다)',
  "admin.classroom-detail.delay-lesson-desc":
    '"{seq}회차" 수업을 연기처리 하시겠습니까?',
  "admin.classroom-detail.delete-lesson-desc":
    '"{seq}회차" 수업을 삭제하시겠습니까? (수업에 대한 모든 정보가 삭제됩니다 )',
  "admin.classroom-detail.force-quit-class-desc":
    "클래스 ({class})를 강제 종료 하시겠습니까?",
  "admin.classroom-detail.init-class-desc":
    "클래스 ({class})를 초기화 하시겠습니까?  **주의** 모든 수업 및 연관 기록이 삭제됩니다.",
  "admin.classroom-detail.delete-class-desc":
    "클래스 ({class})를 삭제 하시겠습니까?",
  "admin.classroom-detail.change-teacher-desc":
    "해당 클래스의 수업을 진행할 선생님를 선택해주세요. 선생님 변경 시 기존의 선생님 계정은 더 이상 해당 클래스의 수업을 진행할 수 없게됩니다.",
  "admin.classroom-detail.change-class-date": "{lessonSeq}회차 수업 시각 변경",
  "admin.classroom-detail.current-value": "기존값",
  "admin.classroom-detail.change-value": "변경값",
  //클래스 생성하기
  "admin.classroom-create.label-curriculum": "커리큘럼 선택",
  "admin.classroom-create.label-language": "언어 선택",
  "admin.classroom-create.label-teacher": "선생님 배성",
  "admin.classroom-create.label-startDate": "수업시작일",
  "admin.classroom-create.label-lessonDay": "수업일 설정",
  "admin.classroom-create.next": "다음",
  "admin.classroom-create.cancel": "취소",
  "admin.classroom-create.language-ko": "한국어",
  "admin.classroom-create.language-en": "영어",
  "admin.classroom-create.lesson-schedule": "수업시간표",
  // 수업내용미리보기
  "admin.preview-lesson.title": "수업내용 미리보기",
  "admin.preview-lesson.picture-quiz": "그림퀴즈",
  "admin.preview-lesson.math-picture-diary": "수학그림일기",
  "admin.preview-lesson.workbook-excercises": "워크북 문제집",
  "admin.preview-lesson.workbook-commentary": "워크북 해설집",
  "admin.preview-lesson.activity-documents": "활동문서",
  "admin.preview-lesson.workbooks": "워크북",
  "admin.preview-lesson.commentaries": "해설집",
  "admin.preview-lesson.reference": "[참고]",
  "admin.preview-lesson.tab-queture": "{quetureName}(깨처)",
  "admin.preview-lesson.tab-game": "{gameName}(게임)",
  "admin.preview-lesson.tab-activity": "{activityName}(활동)",
  "admin.preview-lesson.theme-name": "테마명",
  "admin.preview-lesson.related-concepts": "관련된 개념",
  "admin.preview-lesson.lesson-plans": "깨처 교안",
  "admin.preview-lesson.view-lesson-plans": "교안보기",
  "admin.preview-lesson.unregistered-lesson-plan": "교안 미등록",
  "admin.preview-lesson.queture-video": "꺠처영상",
  "admin.preview-lesson.queture-point": "꺠처 포인트",
  "admin.preview-lesson.caution": "주의사항",
  "admin.preview-lesson.view-quiz": "문제보기",
  "admin.preview-lesson.game-name": "게임이름",
  "admin.preview-lesson.learning-keyword": "학습 키우드",
  "admin.preview-lesson.game-desc": "게임 설명",
  "admin.preview-lesson.start-game": "게임시작",
  "admin.preview-lesson.activity-name": "활동 이름",
  "admin.preview-lesson.activity-preparation": "준비물",
  "admin.preview-lesson.activity-desc": "활동 내용",
  "admin.preview-lesson.activity-detail": "활동 보기",
  "admin.preview-lesson.activity-video": "활동 영상",
  "admin.preview-lesson.quiz-order-name": "{order}{ordinal}문제",
  // 수업관리 전체보기
  "admin.classroom-lectures.title": "수업관리 전체보기",
  "admin.classroom-lectures.management": "관리기능",
  "admin.classroom-lectures.upcoming": "진행 예정 수업",
  "admin.classroom-lectures.completed": "완료된 수업",
  // 선생님관리
  "admin.teachers.title": "선생님 관리",
  "admin.teachers.add-teacher": "선생님 등록하기",
  "admin.teachers.total-teachers": "전체 선생님 목록 {count}명",
  "admin.teachers.basic-info": "기본정보",
  "admin.teachers.set-permission": "권한설정",
  "admin.teachers.col-category": "구분",
  "admin.teachers.col-id": "아이디",
  "admin.teachers.col-name": "이름",
  "admin.teachers.col-contact": "연락처",
  "admin.teachers.col-birthday": "생년월일",
  "admin.teachers.col-office": "담당 지점",
  "admin.teachers.col-classes": "담당 클래스",
  "admin.teachers.value-category-empoyed": "재직중",
  "admin.teachers.value-category-resignation": "퇴사",
  "admin.teachers.value-classes-view-all": "모든 클래스 보기({count})",
  "admin.teachers.value-classes-none": "없음",
  "admin.teachers.col-view-all-students": "학생 전체 열람",
  "admin.teachers.col-student-settings": "학생 설정",
  "admin.teachers.col-enroll-students": "학생 등록",
  "admin.teachers.col-class-settings": "클래스 설정",
  "admin.teachers.col-registering-class": "클래스 등록",
  "admin.teachers.col-start-class": "수업시작",
  "admin.teachers.col-manage-newsletter": "뉴스레터관리",
  "admin.teachers.col-select-all": "전체 선택",
  "admin.teachers.edit": "수정하기",
  "admin.teachers.save": "저장",
  "admin.teachers.cancel": "취소",
  "admin.teachers.cancel-confirm": "정말로 취소 하시겠습니까?",
  "admin.teachers.col-hire-date": "입사일",
  "admin.teachers.col-address": "주소",
  "admin.teachers.register": "등록완료",
  "admin.teachers.teacher-name": "선생님: {name}",
  "admin.teachers.edit-teacher": "선생님 정보 수정하기",
  "admin.teachers.reset-password": "비밀번호 초기화",
  "admin.teachers.reset-password-desc":
    "비밀번호를 기본값(생년월일)으로 초기화하시겠습니까?",
  "admin.teachers.reset": "초기화",
  "admin.teachers.change-to-retire": "퇴사상태로 변경",
  "admin.teachers.change-to-retire-desc":
    "{name}선생님을 퇴사상태로 변경 하시겠습니까?",
  "admin.teachers.change-to-working": "재직상태로 변경",
  "admin.teachers.change-to-working-desc":
    "{name}선생님을 재직상태로 변경 하시겠습니까?",
  "admin.teachers.change": "변경",
  "admin.teachers.delete-class-desc": "담장 {name} 지점을 제거 하시겠습니까?",
  "admin.teachers.manage-learning-reports": "학습 리포트 관리 현황",
  "admin.teachers.col-class": "클래스",
  "admin.teachers.col-recently-completed-course": "최근 완료 코스",
  "admin.teachers.col-lesson": "회차",
  "admin.teachers.col-period": "수업 기간",
  "admin.teachers.col-student": "학생",
  "admin.teachers.col-test-score": "테스트점수",
  "admin.teachers.col-note": "선생님메모",
  "admin.teachers.col-publish-report": "리포트발행",
  "admin.teachers.col-consultation-history": "상담내역",
  "admin.teachers.col-view-report": "리포트 보기",
  "admin.teachers.value-state-complete": "완료",
  "admin.teachers.value-state-incomplete": "미완료",
  "admin.teachers.value-state-not-publish": "미발생",
  "admin.teachers.value-state-not-test": "미채점",
  "admin.teachers.value-state-register": "등록",
  "admin.teachers.value-state-not-register": "미등록",
  "admin.teachers.value-lesson": "{lessonRange}회차",
  "admin.teachers.value-view-learning-report": "학습리포트",
  "admin.teachers.edit-teachers": "선생님 상세정보 수정",
  "admin.teachers.edit-confirm": "수정완료",

  // 학생관린
  "admin.students.title": "학생관리",
  "admin.students.add-student": "학생 등록하기",
  "admin.students.active-class-students": "활성클래스 재학생 목록 보기",
  "admin.students.inactive-class-students": "비활성클래스 재학생 목록 보기",
  "admin.students.discharged-students": "퇴원생 목록 보기",
  "admin.students.all-active-class-students": "전체 활성클래스 재학생 목록",
  "admin.students.all-inactive-class-students": "전체 비활성클래스 재학생 목록",
  "admin.students.all-discharged-students": "전체 퇴원생 목록",
  "admin.students.list-col-name": "이름",
  "admin.students.list-col-age": "나이",
  "admin.students.list-col-id": "아이디",
  "admin.students.list-col-teacher": "선생님",
  "admin.students.list-col-class": "클래스",
  "admin.students.list-col-school": "학교",
  "admin.students.list-col-none": "없음",
  "admin.students.list-filter-by": "순",
  "admin.students.list-unassigned-class": "클래스 미배정",
  "admin.students.list-col-student-contact": "학생 연락처",
  "admin.students.list-col-parent-contact": "학부모 연락처",
  "admin.students.list-col-parent-email": "학부모 이메일",
  "admin.students.list-col-learning-management": "학습관리",
  "admin.students.list-col-learning-report": "학습리포트",
  "admin.students.list-search-palceholder": "한글자 이상 입력해주세요.",
  // 학생 상세
  "admin.student-detail.student": "학생",
  "admin.student-detail.discharged-student": "퇴원생",
  "admin.student-detail.student-list": "학생목록",
  "admin.student-detail.edit-student": "학생 정보 수정하기",
  "admin.student-detail.change-class": "클래스 변경하기",
  "admin.student-detail.reset-password": "비밀번호 초기화",
  "admin.student-detail.change-id": "아이디 변경",
  "admin.student-detail.set-as-discharged": "퇴원생으로 설정하기",
  "admin.student-detail.set-as-active": "재학생으로 설정하기",
  "admin.student-detail.col-name": "이름",
  "admin.student-detail.col-id": "아이디",
  "admin.student-detail.col-school-name": "학교명",
  "admin.student-detail.col-student-contact": "학생 연락처",
  "admin.student-detail.col-restricted-viewing-of-unattended-classes":
    "미수강 수업 조회제한",
  "admin.student-detail.col-address": "주소",
  "admin.student-detail.col-note": "메모",
  "admin.student-detail.col-class-name": "클래스 이름",
  "admin.student-detail.col-birthday": "생년월일",
  "admin.student-detail.value-age": " ({age}세)",
  "admin.student-detail.col-grade": "학년",
  "admin.student-detail.value-grade": "{grade}학년",
  "admin.student-detail.col-parent-contact": "학부모 연락처",
  "admin.student-detail.col-parent-email": "학부모 이메일",
  "admin.student-detail.col-discharge-reason": "퇴원사유",
  "admin.student-detail.btn-register": "등록완료",
  "admin.student-detail.btn-cancel": "취소",
  "admin.student-detail.btn-save": "저장",
  "admin.student-detail.btn-edit": "편집",
  "admin.student-detail.learning-history": "학습 리포트 이력",
  "admin.student-detail.learning-history-warning":
    "클래스가 지정되지 않은 경우 학습 리포트 이력이 조회되지 않습니다",
  "admin.student-detail.learning-history-col-course": "코스",
  "admin.student-detail.learning-history-col-lesson": "회차",
  "admin.student-detail.learning-history-col-period": "수업시간",
  "admin.student-detail.learning-history-col-attendance-stat": "출석/결석",
  "admin.student-detail.learning-history-col-test": "테스트",
  "admin.student-detail.learning-history-col-note": "선생님메모",
  "admin.student-detail.learning-history-col-publish-report": "리포트발행",
  "admin.student-detail.learning-history-col-counseling": "학습상담",
  "admin.student-detail.learning-history-col-view-report": "리포트 보기",
  "admin.student-detail.learning-history-value-incomplete": "미완료",
  "admin.student-detail.learning-history-value-complete": "완료",
  "admin.student-detail.learning-history-value-lesson": "{lessonSeq}회차",
  "admin.student-detail.learning-history-value-lesson-range":
    "{start}회차 ~ {end}회차",
  "admin.student-detail.learning-history-value-lesson-date":
    "{year}년 {month}월 {day}일",
  "admin.student-detail.learning-history-value-lesson-date-range":
    "{year}년 {month}월 {day}일 ~ {year2}년 {month2}월 {day2}일",
  "admin.student-detail.learning-history-value-not-test": "미채점",
  "admin.student-detail.learning-history-value-not-add": "미동록",
  "admin.student-detail.learning-history-value-not-publish": "미발행",
  "admin.student-detail.learning-history-value-learning-report": "학습리포트",
  "admin.student-detail.prev-learning-history": "이전 학습 이력",
  "admin.student-detail.learning-history-col-class": "클래스",
  "admin.student-detail.learning-history-col-lesson-day": "수업일",
  "admin.student-detail.learning-history-col-teacher": "선생님",
  "admin.student-detail.change-class-info": "클래스 정보 변경",
  "admin.student-detail.change-student-id": "아이디 변경",
  "admin.student-detail.reset-student-password": "비밀번호 초기화하기",
  "admin.student-detail.msg-reset-success": "초기화 완료",
  "admin.student-detail.msg-reset-fail": "초기화 실패",
  "admin.edit-student.title": "학생 상세정보 수정",
  "admin.edit-student.confirm-edit": "수정완료",
  "admin.edit-student.edit-success": "학생 수정 완료",
  "admin.edit-student.edit-fail": "학생 수정 실패",
  "admin.edit-student.restricted-viewing-of-unattended-classes-unlimit":
    "제한 없음",
  "admin.edit-student.restricted-viewing-of-unattended-classes-ab":
    "A,B반 수업 조회제한",
  "admin.edit-student.restricted-viewing-of-unattended-classes-abc":
    "A,B,C반 수업 조회제한",
  "admin.edit-student.restricted-viewing-of-unattended-classes-abcd":
    "A,B,C,D반 수업 조회제한",
  "admin.change-class-popup-desc":
    "{name}학생의 클래스 정보를 아래와 같이 변경하시겠습니까?",
  "admin.change-class-popup-student-info": "학생 정보",
  "admin.change-class-popup-before-class": "변경 전 클래스",
  "admin.change-class-popup-after-class": "변경 후 클래스",
  "admin.change-class-popup-change-class-warning":
    "클래스 변경 시 현재 클래스의 학습 데이터는 모두 초기화됩니다.",
  "admin.reset-student-password-popup-desc":
    "{name}({account})학생의 비밀번호를 기본값(생년월일)으로 초기화하시겠습니까?",
  "admin.reset-student-password-popup-student-info": "학생 정보",
  "admin.reset-student-password-popup-default-password": "비밀번호 기본값",
  "admin.change-student-id-popup-desc":
    "{name} 학생의 아이디를 아래와 같이 변경하시겠습니까?",
  "admin.set-as-discharged-popup-desc":
    "{name}학생을 퇴원생으로 설정 하시겠습니까? (클래스 연결정보가 삭제되어 로그인이 차단됩니다)",

  // 테스트
  "admin.tests.title": "테스트",
  "admin.tests.management": "관리기능",
  "admin.tests.show-deleted": "삭제된 항목도 보기",
  "admin.tests.add": "추가",
  "admin.tests.add-test": "테스트 추가",
  "admin.tests.show-answers": "정답보기",
  "admin.tests.view": "보기",
  "admin.tests.print": "프린트",
  "admin.tests.default": "기본",
  "admin.tests.print-quiz": "문제 출력",
  "admin.tests.delete": "삭제",
  "admin.tests.checkup": "체크업",
  "admin.tests.name": "이름",
  "admin.tests.course-name": "{title} 코스 [테스트{testsCount}개]",
  "admin.tests.test-quiz": "[문제 {count}개]",
  "admin.tests.test-deleted": "(삭제됨)",
  "admin.tests.edit": "수정",
  "admin.tests.edit-test": "테스트 수정",
  "admin.tests.undo": "복원",
  "admin.tests.delete-test": "테스트 삭제",
  "admin.tests.delete-test-confirm":
    "테스트를 삭제하시겠습니까? (기록은 지워지지 않고, 숨김 처리됩니다)",
  "admin.tests.delete-test-cancel": "테스트 삭제취소",
  "admin.tests.cancel": "취소",
  "admin.tests.deleted-test": "(삭제된 테스트)",
  "admin.tests.col-name": "이름",
  "admin.tests.col-name-placeholder": "테스트 이름을 입력해주세요.",
  "admin.tests.col-quiz-ids": "문제 아이디 입력",
  "admin.tests.col-quiz-ids-placeholder": "문제 아이디를 입력해 주세요.",
  "admin.tests.confirm-quiz-ids": "아이디 확인",
  "admin.tests.quiz-list": "문제 리스트",
  // 학습 리포트
  "admin.report.learning-report": "학습리포트",
  "admin.report.prev-report": "이전 리포트",
  "admin.report.next-report": "다음 리포트",
  "admin.report.test-result": "테스트 결과",
  "admin.report.teacher-note": "선생님 메모",
  "admin.report.counseling-history": "학습상담 내역",
  "admin.report.print-report": "리포트 출력",
  "admin.report.loading": "... 로딩중입니다 ...",
  "admin.report.no-report":
    "학습 완료된 코스가 없어 리포트가 생성되지 않습니다.",
  "admin.report.report-title": "학습 리포트 {courseName}코스 {start}~{end}회차",
  "admin.report.course-not-completed": "(코스 미완료)",
  "admin.report.class": "클래스",
  "admin.report.course": "코스",
  "admin.report.lessonRange": "{lessonRange}회차",
  "admin.report.period": "기간",
  "admin.report.add-email-notice": " #학생정보에 학부모 이메일을 등록해주세요 ",
  "admin.report.test-result-notice": " #테스트 채점 결과가 필요합니다 ",
  "admin.report.teacher-note-notice": " #선생님 메모가 필요합니다 ",
  "admin.report.no-report-notice": " #리포트가 발행되지 않았습니다 ",
  "admin.report.no-counseling-notice": " #학습상담이 완료되지 않았습니다 ",
  "admin.report.class-hours": "수업시수",
  "admin.report.class-hours-unit": "회",
  "admin.report.class-hours-not-completed": "미완료",
  "admin.report.number-of-quetures": "학습깨처 수",
  "admin.report.number-of-quetures-unit": "깨처",
  "admin.report.absences": "결석",
  "admin.report.absences-unit": "번",
  "admin.report.mvp-selection": "MVP 선정",
  "admin.report.mvp-selection-unit": "번",
  "admin.report.lesson": "회차",
  "admin.report.lesson-value": "{lessonSeq}회차",
  "admin.report.queture-name": "깨처 이름",
  "admin.report.key-concepts-and-activities": "주료개념 및 활동",
  "admin.report.learning-level": "학습수준",
  "admin.report.learning-level-high": "상",
  "admin.report.learning-level-mid": "중",
  "admin.report.learning-level-low": "하",
  "admin.report.review-tasks": "복습과제",
  "admin.report.edit": "수정",
  "admin.report.test-date": "수행일자",
  "admin.report.correct-partially-wrong": "정답/부분정답/오답",
  "admin.report.quiz-count-unit": "개",
  "admin.report.correct-rate": "정답률",
  "admin.report.report": "리포트",
  "admin.report.report-issue": "발행하기",
  "admin.report.report-issued": "발행됨",
  "admin.report.report-not-issued": "미발행",
  "admin.report.report-reissue": "재발행하기",
  "admin.report.report-issue-confirm":
    "{studentName}학생의 학습 리포트 {courseName}코스 {lessonRange}를 학부모에게 전송하시겠습니까?",
  "admin.report.report-reissue-confirm":
    "{studentName}학생의 학습 리포트 {courseName}코스 {lessonRange}를 학부모에게 다시 발생하여 전송하시겠습니까?",
  "admin.report.report-not-issued-desc":
    "[선생님 메모를 등록해야 리포트를 발행할 수 있습니다.]",
  "admin.report.parent-contact": "학부모 연락처",
  "admin.report.no-email": "(이메일 미등록)",
  "admin.report.result": "결시",
  "admin.report.cancel": "취소",
  "admin.report.partially-incorrect": "부분정답/오답",
  "admin.report.save": "저장",
  "admin.report.correct-per-question": "문항별 정오답",
  "admin.report.correct-per-question-tooltip":
    "정답: o (또는1), 부분정답: / (또는5), 오답: x (또는0), 안풀기로 한 문제: -",
  "admin.newsletter.title": "뉴스레터",
  "admin.newsletter.add-newsletter": "뉴스레터 등록하기",
  "admin.newsletter.col-seq": "번호",
  "admin.newsletter.col-id": "아이디",
  "admin.newsletter.col-title": "타이틀",
  "admin.newsletter.col-date": "등록일",
  "admin.newsletter.save": "저장",
  "admin.newsletter.register": "등록",
  "admin.newsletter.close": "닫기",
};
