export default {
  "login.admin-login": "Campus Login",
  "login.student-login": "Student Login",
  "login-form.id-placeholder": "Please enter your ID.",
  "login-form.password-placeholder": "Please enter your password.",
  "login-form.login-button": "Login",
  "login-form.login-loading": "Logging in...",
  "login-error-message":
    "An invalid account or password was entered. Please try again.",
};
